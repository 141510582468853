import React from "react";

import formatDateSlash from "helpers/formatTimeSlash";

import ActionsComponent from "./actionsComponent";


const CodesCountComponent = ({ value }) => {
    const numberOfCodesUsed = typeof value?.usedCount !== "undefined" ? value?.usedCount : "-";

    if (value?.numberOfEmails) {
        const totalNumberOfCodes = value?.numberOfUses * value?.numberOfEmails;

        return `${numberOfCodesUsed}/${totalNumberOfCodes} used`
    }

    return `${numberOfCodesUsed}/${value?.numberOfUses} used`
};

export const activationCodeTableHeader = [
    { label: "Activation code body", sortOption: false, classname: "font-size-13 table-caption-label" },
    { label: "Start date", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "End date", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Can be used", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    {
        label: "Action",
        sortOption: false,
        classname: "font-size-13 table-caption-label disable-word-wrap action-table-cell"
    },
];

export const activationCodeTableStructure = [
    { name: "code", classname: "font-size-14 pt-20px pb-20px border-bottom fullname-table-cell", keyProp: "code" },
    {
        name: "startDate",
        classname: "font-size-13 pt-20px pb-20px border-bottom",
        keyProp: "startDate",
        Component: (({ value }) => formatDateSlash(value))
    },
    {
        name: "endDate",
        classname: "font-size-13 pt-20px pb-20px border-bottom",
        keyProp: "endDate",
        Component: (({ value }) => formatDateSlash(value))
    },
    { name: "numberOfUses", classname: "font-size-13 pt-20px pb-20px border-bottom", Component: CodesCountComponent },
    // { name: "actions", classname: "font-size-13 pt-20px pb-20px border-bottom", keyProp: "id", Component: (({value}) => value) },
]

export const structureWithActions = (handleEdit, handleDelete) => {
    return [
        ...activationCodeTableStructure,
        { name: "actions", classname: "font-size-13 pt-20px pb-20px pr-2 pl-3 border-bottom", Component: (({value}) => <ActionsComponent item={value} handleEdit={handleEdit} handleDelete={handleDelete} withCSVDownloadButton/>) },
    ]
}
