import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale, CalendarContainer } from 'react-datepicker';
import { monthNames } from '../../../constants/monthNames';
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import formatDateSlash from '../../../helpers/formatTimeSlash';
import sliceMonthName from '../../../helpers/sliceMonthName';
import { dateBySlash } from '../../../constants/dateFormats';

registerLocale("en-GB", en);

const MyContainer = ({ className, children }) => {
    return (
        <div className="custom-calendar">
            <CalendarContainer className={className}>
                {children}
            </CalendarContainer>
        </div>
    );
};

const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => {
    return (
        <div className="custom-datepicker-header mb-3 ">
            <label className="ml-4 font-weight-bold date-label">{monthNames[date.getMonth()]} {date.getFullYear()}</label>
            <section className="text-center mr-3 mt-1">
                <label className="chevron-left-button" onClick={() => { decreaseMonth() }} disabled={prevMonthButtonDisabled}>
                    <i className="mdi mdi-chevron-left chevron-size" />
                </label>
                <label className="chevron-right-button" onClick={() => { increaseMonth() }} disabled={nextMonthButtonDisabled}>
                    <i className="mdi mdi-chevron-right chevron-size" />
                </label>
            </section>
        </div>
    )
};

export default function CustomDatePicker({ errors, touched, ComponentStart, ComponentEnd, setFieldValue, currentStartDay, currentEndDay, isSickNote }) {
    const [startDate, setStartDate] = useState(currentStartDay && new Date(currentStartDay));
    const [endDate, setEndDate] = useState(currentEndDay ? new Date(currentEndDay) : null);
    const [prevIsSickNote, setPrevIsSickNote] = useState(isSickNote);

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setFieldValue("caseFrom", start)
        setEndDate(end);
        setFieldValue("caseTo", end || '');
    };

    const CustomInput = ({ value, onClick }) => (
        <section className="d-flex flex-row justify-content-between">
            <section className="d-flex flex-row">
                <label className={"pt-2 " + (errors.startDate && touched.startDate && "error-text")}>From</label>
                <section className="d-flex flex-column ml-2">
                    <input
                        type="text"
                        placeholder={dateBySlash}
                        value={value && formatDateSlash(value)}
                        onClick={onClick}
                        className="custom-calendar-output-field cursor-pointer form-control"
                    />
                    <ComponentStart />
                </section>
            </section>
            <section className="d-flex flex-row">
                <label className={"pt-2 " + (errors.endDate && touched.endDate && "error-text")}>To</label>
                <section className="d-flex flex-column ml-2">
                    <input
                        type="text"
                        placeholder={dateBySlash}
                        value={endDate && formatDateSlash(endDate)}
                        onClick={onClick}
                        className="custom-calendar-output-field cursor-pointer form-control"
                    />
                    <ComponentEnd />
                </section>
            </section>
        </section>
    );

    useEffect(() => {
        if (prevIsSickNote !== isSickNote) {
            setStartDate('');
            setEndDate('');
            setFieldValue("caseFrom", '');
            setFieldValue("caseTo", '');
            setPrevIsSickNote(isSickNote);
        }
    }, [isSickNote, setFieldValue]);

    return (
        <>
            <DatePicker
                calendarContainer={MyContainer}
                renderCustomHeader={(props) => <CustomHeader  {...props} />}
                locale="en-GB"
                formatWeekDay={sliceMonthName}
                customInput={<CustomInput />}
                startDate={startDate}
                endDate={endDate}
                disabledKeyboardNavigation
                selected={startDate}
                onChange={onChange}
                selectsRange
                shouldCloseOnSelect={!endDate}
            />
        </>
    )
}