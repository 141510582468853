import React from 'react'
import { Badge } from 'reactstrap';
import getBadgeValues from '../../../helpers/getBadgeValues';

export default function StatusBadge({ user }) {
    const { color, value } = getBadgeValues(user);

    return (
        <Badge
            color={color}
            className="font-size-10 font-color-white ml-2 text-center patient-status-badge"
        >
            {value}
        </Badge>
    );
}