import React from "react";
import fileIcon from '../../../../assets/images/ic-file-blue.svg';
import thrashIcon from '../../../../assets/images/ic-thrash-black.svg';
import { DOCUMENT_TYPES, IMAGE_TYPES } from "../../../../constants/uploadFileConstants";

const FileRow = ({ file, handleClick, handleDelete }) => {

    return (
        <div className="border rounded px-3_5 py-2 d-flex flex-row justify-content-between mb-3_5" >
            <div className="d-flex flex-row align-items-center">
                { IMAGE_TYPES.includes(file.type) && (
                    <img
                        src={file.link}
                        alt="Image"
                        className="rounded file-row-icon cursor-pointer"
                        onClick={() => handleClick(file)}
                    />
                )}
                { DOCUMENT_TYPES.includes(file.type) && (
                    <div className="d-flex align-items-center justify-content-center bg-opacity-50 rounded file-row-icon file-row-icon-bg ">
                        <img
                            src={fileIcon}
                            alt=""
                            className="img-fluid cursor-pointer"
                            onClick={() => handleClick(file)}
                        />
                    </div>
                )}
                    <label
                        className="cursor-pointer font-size-13 file-row-label pl-4 m-0 text-break"
                        onClick={() => handleClick(file)}
                    >
                    {file.name}
                </label>
            </div>
            {handleDelete && (
                <div
                    className="d-flex flex-row align-items-center pr-1 pl-3"
                    onClick={handleDelete}
                >
                    <img
                        src={thrashIcon}
                        alt=""
                        className="cursor-pointer"
                    />
                </div>
            )}
        </div>
    )
}

export default FileRow;