import React from 'react';
import ReactPaginate from 'react-paginate';

export default function PaginationTableFooter({ total, handleChangePage, currentOffset, limit }) {
    const pagesCount = Math.ceil(total / limit);
    const initialPage = currentOffset /limit;
    return (
        <div className="pagination pagination-rounded justify-content-center">
            <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pagesCount}
                onPageChange={handleChangePage}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'custom-shevron'}
                previousLinkClassName={'page-link'}
                nextClassName={'custom-shevron'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
                initialPage={initialPage}
                disabledClassName={'disabled-page-button'}
            />
        </div>
    )
}