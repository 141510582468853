import AvField from 'availity-reactstrap-validation/lib/AvField';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import React from 'react';
import { strWithNoOnlySpaces } from '../../constants/validityPatterns';
import { Modal, Card } from "reactstrap";
import { MIN_NOTE, MAX_COMMENT } from '../../constants/lengthValidity';

export default function CreateCommentModal({ visibleModal, handleClose, handleSubmit }) {

    const errorMessage = `Must be from ${MIN_NOTE} to ${MAX_COMMENT} symbols and cannot contain spaces only`;
    
    const validate = {
        required: { value: true, errorMessage: errorMessage },
        pattern: { value: strWithNoOnlySpaces, errorMessage: errorMessage },
        minLength: { value: MIN_NOTE, errorMessage: errorMessage },
        maxLength: { value: MAX_COMMENT, errorMessage: errorMessage }
    }

    return (
        <Modal
            isOpen={visibleModal}
            className="modal-size"
            centered={true}
            toggle={handleClose}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body">
                    <header className="font-size-22">Create comment</header>
                    <AvForm onValidSubmit={handleSubmit} className="mt-2">
                        <AvField type="textarea" rows={5} label="Enter comment" name="text" validate={validate} placeholder="Additional comment" />
                        <section className="d-flex flex-row justify-content-between">
                            <button className="btn btn-primary w-100 mt-4 mr-3">Save</button>
                            <button className="btn btn-danger w-100 mt-4 ml-3" onClick={handleClose}>Cancel</button>
                        </section>
                    </AvForm>
                </div>
            </Card>
        </Modal>
    )
}