import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";

const DefaultComponent = ({ value: children }) => children;

const NestedTableBody = ({
    data,
    structure,
    clickRowAction,
    updateTable,
    getAppointmentSlots,
    rowClassname,
    childrenFieldName,
    childRowClassName,
    clickChildRowAction,
    pickedAppointment = null,
    childrenStructure = null
}) => {
    const history = useHistory();
    const pickedRowId = pickedAppointment?.id;
    return (
        <tbody>
        {data.map((dataRow, index) => {

            const childData = _.get(dataRow, childrenFieldName, [])

            return (
                <Fragment key={index}>
                    <tr
                        className={(pickedRowId === dataRow.id && "picked-slot-bg ") + " cursor-pointer " + rowClassname}
                        onClick={() => clickRowAction && clickRowAction(dataRow)}
                    >
                        {structure.map(({ name, classname, keyProp, Component = DefaultComponent }) => {
                            return (
                                <td key={name} className={" " + classname} >
                                    <Component className="table-text" value={_.get(dataRow, keyProp, dataRow)} history={history} updateTable={updateTable} getAppointmentSlots={getAppointmentSlots} />
                                </td>
                            )
                        })}
                    </tr>
                    {childData.map((childRow, indexChild) => {
                        return (
                            <tr
                                key={`${index}-${indexChild}`}
                                className={"cursor-pointer " + childRowClassName}
                                onClick={() => clickChildRowAction && clickChildRowAction(dataRow, childRow)}
                            >
                                {childrenStructure.map(({ name, classname, keyProp, Component = DefaultComponent }) => {

                                    return (
                                        <td key={name} className={" " + classname}>
                                            <Component className="table-text child-row-color" value={_.get(childRow, keyProp, childRow)}/>
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </Fragment>

            )
        })}
        </tbody>
    )
}

export default NestedTableBody;