import {FILE_MIME_TYPES, FILE_TYPES, IMAGE_MIME_TYPES} from "../constants/uploadFileConstants";

const fileTypeFromMimeType = (mimeType) => {
    switch (true) {
        case IMAGE_MIME_TYPES.includes(mimeType):
            return FILE_TYPES.IMAGE;
        case FILE_MIME_TYPES.includes(mimeType):
            return FILE_TYPES.PDF;
        default:
            return null;
    }
}

export default fileTypeFromMimeType;