import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Col, Spinner, Table} from "reactstrap";
import qs from "qs";
import * as _ from "lodash";
import toastr from "toastr";

import CancelModal from "components/manageSessionModal/cancelModal";
import ActivationCodeService from "services/activationCode";
import SimpleTableBody from "components/simpleTable/simpleTableBody";
import parseIntFromStr from "helpers/parseIntFromStr";
import {ACTIVATION_CODE_LIMIT} from "constants/paginationLimits";
import queryParser from "helpers/queryStringParser";
import InfoModal from "components/manageSessionModal/infoModal";

import PaginationTableFooter from "../patientManagement/tableComponents/paginationFooter";
import TableHeader from "../patientManagement/tableComponents/tableHeader";
import {activationCodeTableHeader, structureWithActions} from "./tableComponents/activationCodesDataStructure";
import NoCouponsPlaceholder from "./tableComponents/noCouponsPlaceholder";
import CreateCouponForm from "./activationCodeComponents/createCouponForm";

const SUCCESS_CODE_CREATION_MESSAGE = "New activation codes have been successfully generated";
const SUCCESS_CODE_UPDATING_MESSAGE = "Activation code has been successfully updated";
const SUCCESS_CODE_DELETING_MESSAGE = "The activation code has been deleted";

const ActivationCode = () => {
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [activationCodePagination, setActivationCodePagination] = useState({});
    const [activationCodes, setActivationCodes] = useState([]);
    const [isOpenCouponModal, setIsOpenCouponModal] = useState(false);
    const [editActivationCode, setEditActivationCode] = useState(null);
    const [deleteActivationCodeId, setDeleteActivationCodeId] = useState(null);
    const [isOpenInvalidEmailsModal, setIsOpenInvalidEmailsModal] = useState(false);
    const [invalidEmailsList, setInvalidEmailsList] = useState([]);

    const searchQuery = queryParser(location.search)

    const setNewSearchQuery = (incomeQuery) => {
        const newQuery = {
            ...searchQuery,
            ...incomeQuery,
        }
        const query = qs.stringify(newQuery);
        history.push({
            search: query,
        });
    }

    const handleChangePage = ({ selected }) => {
        if (!isNaN(selected)) {
            setNewSearchQuery({ offset: selected * ACTIVATION_CODE_LIMIT });
        }
    }

    const editFn = useCallback((coupon) => {
        const {
            id,
            code,
            numberOfUses,
            startDate,
            endDate,
            hasAttachedEmails
        } = coupon;

        setEditActivationCode({
            id,
            code,
            numberOfUses: String(numberOfUses),
            startDate,
            endDate,
            hasAttachedEmails
        })
    }, [setEditActivationCode])

    const deleteFn = ({ id }) => {
        setDeleteActivationCodeId(id);
    }

    const activationCodeDataStructure = useMemo(() => structureWithActions(editFn, deleteFn), []);

    const handleCreateCoupon = useCallback(async (values) => {
        try {
            const response = await ActivationCodeService.createActivationCode(values);

            if (response.data?.invalidEmails?.length) {
                setIsOpenInvalidEmailsModal(true);
                setInvalidEmailsList(response.data?.invalidEmails)
            }

            toastr.success(SUCCESS_CODE_CREATION_MESSAGE);
            await getActivationCodes();

        } finally {
            setIsOpenCouponModal(false);
        }
    }, [setIsOpenCouponModal])

    const handleUpdateCoupon = useCallback(async (values) => {
        // eslint-disable-next-line no-unused-vars
        const { codesCount, id, ...rest } = values || {};

        try {
            const response = await ActivationCodeService.updateActivationCode(id, rest);

            if (response.data?.invalidEmails?.length) {
                setIsOpenInvalidEmailsModal(true);
                setInvalidEmailsList(response.data?.invalidEmails)
            }

            toastr.success(SUCCESS_CODE_UPDATING_MESSAGE);
            await getActivationCodes();

        } finally {
            setEditActivationCode(null);
        }
    }, [setEditActivationCode, editActivationCode])

    const handleDeleteCoupon = useCallback(async () => {
        try {
            await ActivationCodeService.deleteActivationCode(deleteActivationCodeId);

            toastr.success(SUCCESS_CODE_DELETING_MESSAGE);
            await getActivationCodes();
        } finally {
            setDeleteActivationCodeId(null);
        }
    }, [deleteActivationCodeId])

    const getActivationCodes = useCallback(async () => {
        try {
            setIsLoading(true);
            const { data, pagination } = await ActivationCodeService.getActivationCodesList({
                limit: ACTIVATION_CODE_LIMIT,
                offset: searchQuery.offset
            });
            setActivationCodes(data);
            setActivationCodePagination(pagination);
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, setActivationCodes, setActivationCodePagination, searchQuery.offset])

    useEffect(() => {
        getActivationCodes();
    }, [getActivationCodes])

    return (
        <div className="page-content mx-2 pb-2">
            <div className="card justify-content-between pr-20px pl-20px pt-20px activate-code-page">
                <div className="d-flex flex-wrap justify-content-between align-items-end h-100 mb-4">
                    <Col sm={6} lg={4} className="p-0">
                        <label className="details-caption">
                            Activation code management
                        </label>
                    </Col>
                    <Col sm={5} lg={4} className="p-0 header-button">
                        <button
                            className="btn btn-primary call-form-button w-100  mt-1 mt-sm-0"
                            onClick={() => setIsOpenCouponModal(true)}
                        >
                            Generate activations code
                        </button>
                    </Col>
                </div>
                {isLoading &&
                    <div className="coupon-table d-flex justify-content-center align-items-center">
                        <Spinner className="mx-auto" color="info"/>
                    </div>}
                {!isLoading && activationCodes.length > 0 && (
                    <>
                        <section className="activate-code-table-wrap">
                            <Table className="table mb-0">
                                <TableHeader columns={activationCodeTableHeader} clickCellAction={_.noop}/>
                                <SimpleTableBody data={activationCodes} structure={activationCodeDataStructure}/>
                            </Table>
                        </section>
                        {!!activationCodePagination?.totalCount && (
                            <section className="mt-4">
                                <PaginationTableFooter
                                    total={activationCodePagination.totalCount}
                                    handleChangePage={handleChangePage}
                                    currentOffset={parseIntFromStr(searchQuery.offset)}
                                    limit={ACTIVATION_CODE_LIMIT}
                                />
                            </section>
                        )}
                    </>
                )}
                {!isLoading && !activationCodes.length && <NoCouponsPlaceholder/>}
            </div>
            <CreateCouponForm
                isOpen={isOpenCouponModal}
                toggle={() => setIsOpenCouponModal(false)}
                handleSubmit={handleCreateCoupon}
            />
            <CreateCouponForm
                isOpen={!!editActivationCode}
                toggle={() => setEditActivationCode(null)}
                handleSubmit={handleUpdateCoupon}
                initialValues={editActivationCode || {}}
                isEdit={true}
            />
            {deleteActivationCodeId && (
                <CancelModal
                    visibleModal={!!deleteActivationCodeId}
                    setVisibleModal={() => setDeleteActivationCodeId(null)}
                    handleClose={handleDeleteCoupon}
                    text="This activation code body will be deleted, are you sure?"
                    header="Delete Activation code body"
                />
            )}
            {isOpenInvalidEmailsModal &&
                <InfoModal
                    visibleModal={isOpenInvalidEmailsModal}
                    close={() => {
                        setIsOpenInvalidEmailsModal(false)
                        setInvalidEmailsList([]);
                    }}
                    header="List of invalid emails"
                    buttonText="Ok"
                    modalClassName="activate-code__invalid-emails-modal"
                >
                    <ul className="activate-code__invalid-emails-list">
                        {invalidEmailsList.map((email) => <li key={email}>{email}</li>)}
                    </ul>
                </InfoModal>
            }
        </div>
    )
}

export default ActivationCode;
