import React, { useMemo } from 'react';
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import { withNamespaces } from 'react-i18next';

// Redux
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// action
import { userForgetPassword } from "../../store/actions";
import Button from '../../components/buttons/button';

// import images
import changePasswordLogo from "../../assets/images/changePasswordLogo.svg";
import AvatarLogo from '../../components/avatar/avatarLogo';
//import toasts
import toastr from '../../components/toastr';
import { EMAIL_MAX_LENGTH } from '../../constants/lengthValidity';

const ForgetPasswordPage = ({ t, userForgetPassword }) => {

    async function handleValidSubmit(event, values) {
        await userForgetPassword(values, toastr);
    }

    const emailValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: t("Please enter your email") },
            email: { value: true, errorMessage: t("Email is invalid") },
            maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: t("Invalid email format") },
        }
    ), [t]);

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="fas fa-home h2"></i></Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden card-auth">
                                <div className="form-caption-auth">
                                    <Row className="h-100 w-100">
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">{t("Restore password")}</h5>
                                                <span className="text-nowrap">{t("Enter your email address")}</span>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end caption-profile-img">
                                            <img src={changePasswordLogo} alt="" height="113" />
                                        </Col>
                                        <AvatarLogo />
                                    </Row>
                                </div>
                                <CardBody className="pt-0 pb-0 mt-4">
                                    <div className="p-2">

                                        <AvForm
                                            className="form-horizontal mt-4"
                                            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                        >

                                            <div className="form-group">
                                                <AvField
                                                    name="email"
                                                    label={t("Your email")}
                                                    className="form-control mb-0"
                                                    placeholder={t("Enter your email")}
                                                    type="email"
                                                    validate={emailValidationRules}
                                                />
                                            </div>
                                            <Row className="form-group">
                                                <Col className="text-right">
                                                    <Button className="primary" text={t("Send restore link")} disabled={false} />
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
    return { forgetError, forgetSuccessMsg };
};

export default connect(mapStatetoProps, { userForgetPassword })(withNamespaces()(ForgetPasswordPage));
