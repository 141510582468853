import * as yup from "yup";

import {
    appointmentDurationError,
    endTimeSmallerThenStart,
    incorrectDate,
    requiredError,
    sessionDurationError
} from "constants/errorMessages";
import {MAX_DURATION_VALUE, MIN_DURATION_VALUE} from "constants/lengthValidity";

import validateEndTime from "./validateEndTime";


export const sessionValidationSchema = yup.object().shape({
    doctorId: yup.number().required(requiredError).typeError(requiredError),
    startDate: yup.string(incorrectDate).required(incorrectDate).typeError(incorrectDate),
    endDate: yup.string(incorrectDate).required(incorrectDate).typeError(incorrectDate),
    startsAt: yup.string(sessionDurationError).required(sessionDurationError).typeError(sessionDurationError),
    endsAt: yup.string(sessionDurationError).required(sessionDurationError).test('minTime', endTimeSmallerThenStart, function (value) {
        return validateEndTime(this.parent.startsAt, value);
    }).typeError(sessionDurationError),
    appointmentDuration: yup.number()
        .required(appointmentDurationError)
        .integer(appointmentDurationError)
        .min(MIN_DURATION_VALUE, appointmentDurationError)
        .max(MAX_DURATION_VALUE, appointmentDurationError)
        .typeError(appointmentDurationError),
    priority: yup.number().required(requiredError).typeError(requiredError),
});
