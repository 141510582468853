import React from 'react';
import footer from '../../../assets/images/im-bc.svg';
import { tapGPemail, tapGPSiteLabel, tapGPSiteLink } from '../../../constants/emailAdress';

export default function PDFFooter({ id, text }) {
    return (
        <section className="position-relative ml-3">
            <img src={footer} alt="" height="100" />
            <section className="letter-id d-flex flex-column">
                <label>{text}</label>
                <label>{id}</label>
            </section>
            <section className="letter-adress d-flex flex-column align-items-end">
                <a href={tapGPSiteLink} target="_blank" rel="noopener noreferrer" className="email-link mb-2">{tapGPSiteLabel}</a>
                <label className="">Please email  <a href={`mailto:${tapGPemail}`} className="email-link"><u>{tapGPemail}</u></a> to check the validity of this document</label>
            </section>
        </section>
    );
}