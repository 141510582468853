import React, {useCallback, useEffect, useState} from "react";
import {Modal} from "reactstrap";
import {ErrorMessage, Formik} from "formik";
import * as _ from "lodash"
import * as yup from "yup";

import {
    errorMessage,
    gmcLengthError,
    invalidEmailFormat,
    requiredError,
    signatureFormatError,
    signatureSizeError,
    stringValidationError
} from "constants/errorMessages";
import {
    EMAIL_MAX_LENGTH,
    MAX_GMC_LENGTH,
    MIN_GMC_LENGTH,
    NAME_MAX_LENGTH,
    NAME_MIN_LENGTH
} from "constants/lengthValidity";
import {strWithNoOnlySpaces} from "constants/validityPatterns";
import DropzoneFile from "components/Common/dropzoneFile";
import {ALLOWED_SIGNATURE_FILE, MAX_SIGNATURE_SIZE} from "constants/filesValidity";
import getImageBlob from "services/getImageBlob";
import {EXTENSION_TYPES} from "constants/uploadFileConstants";

const schema = yup.object().shape({
    email: yup.string()
        .email(invalidEmailFormat)
        .max(EMAIL_MAX_LENGTH, invalidEmailFormat)
        .required(requiredError),
    firstName: yup.string()
        .required(requiredError)
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .matches(strWithNoOnlySpaces, stringValidationError),
    lastName: yup.string()
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .required(requiredError)
        .matches(strWithNoOnlySpaces, stringValidationError),
    gmc: yup.string()
        .required(requiredError)
        .min(MIN_GMC_LENGTH, requiredError)
        .max(MAX_GMC_LENGTH, gmcLengthError),
    file: yup.mixed()
        .required(requiredError)
        .test(
            "fileSize",
            signatureSizeError,
            value => value && value.size <= MAX_SIGNATURE_SIZE
        )
        .test(
            "FileType",
            signatureFormatError,
            value => value && ALLOWED_SIGNATURE_FILE.includes(value.type)
        )
});

const CREATE_BUTTON_NAME = "Create";
const UPDATE_BUTTON_NAME = "Save";
const UPDATE_TITLE = "Edit";
const CREATE_TITLE = "Create new";

const CreateClinicianForm = ({
    isOpen,
    toggle,
    isEdit = false,
    initialValues = {},
    handleSubmit = _.noop,
}) => {
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [previousImage, setPreviousImage] = useState(null);

    const initialState = {
        firstName: "",
        lastName: "",
        email: "",
        gmc: "",
        file: null,
        ...initialValues,
    }

    const acceptFileTypes = {
        "image/*": ALLOWED_SIGNATURE_FILE.map((item) => item.replace("image/", ".")),
    }

    const onSubmit = useCallback(async (values) => {
        try {
            setFormSubmitting(true);
            handleSubmit(values);
        } finally {
            setFormSubmitting(false);
        }

    }, [])

    useEffect(() => {
        if (isOpen && initialValues?.link) {
            getImageBlob(initialValues?.link).then((blob) => {

                const file = new File(
                    [blob],
                    `image${EXTENSION_TYPES[blob?.type]}`,
                    {type: blob?.type}
                );
                setPreviousImage([file]);
            })
        }
    }, [isOpen])

    return (
        <Modal
            isOpen={isOpen}
            centered
        >
            <Formik
                initialValues={{
                    ...initialState
                }}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(true);
                    onSubmit(values);
                    setSubmitting(false);
                }}
                validationSchema={schema}
                enableReinitialize
            >
                {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                  }) => {

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="p-40px h-100 w-100">
                                <div>
                                    <label className="font-size-22 font-weight-normal">
                                        {`${isEdit ? UPDATE_TITLE : CREATE_TITLE} clinician`}
                                    </label>
                                </div>

                                <label className="mt-2">Email address</label>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter email address"
                                    className="form-control w-100"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                />
                                <ErrorMessage name="email" render={errorMessage}/>

                                <label className="mt-2">First name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    placeholder="Enter first name"
                                    className="form-control w-100"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                />
                                <ErrorMessage name="firstName" render={errorMessage}/>

                                <label className="mt-2">Last name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Enter last name"
                                    className="form-control w-100"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                />
                                <ErrorMessage name="lastName" render={errorMessage}/>

                                <label className="mt-2">GMC</label>
                                <input
                                    type="text"
                                    name="gmc"
                                    placeholder="Enter GMC"
                                    className="form-control w-100"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.gmc}
                                />
                                <ErrorMessage name="gmc" render={errorMessage}/>

                                <DropzoneFile
                                    title="Click to upload signature"
                                    className="clinician-dropzone"
                                    onReceiveFile={(file) => setFieldValue("file", file)}
                                    acceptedFormats={acceptFileTypes}
                                    initialFile={previousImage}
                                />
                                <ErrorMessage name="file" render={errorMessage}/>

                                <section
                                    className="d-flex flex-column flex-sm-row w-100 justify-content-center modal-actions coupon-form-wrapper-buttons">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                        disabled={formSubmitting}
                                    >
                                        {isEdit ? UPDATE_BUTTON_NAME : CREATE_BUTTON_NAME}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger w-100"
                                        onClick={toggle}
                                        disabled={formSubmitting}
                                    >
                                        Cancel
                                    </button>
                                </section>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default CreateClinicianForm;
