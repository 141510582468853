import React, { useEffect, useState, useCallback } from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import { Row, Col } from 'reactstrap';
import PatientManagementService from '../../services/patientsManagement';
import { SEARCH_LIMIT } from '../../constants/lengthValidity';
import formatDateSlash from '../../helpers/formatTimeSlash';
import parseChildren from "../../helpers/parseChildren";

const { Input, Option, MenuList, Menu, Placeholder } = components;

const DropdownIndicator = () => {
    return null;
};

const IndicatorSeparator = ({ innerProps }) => {
    return <span {...innerProps} />;
};

const inputBlur = "input-blur";
const menuClose = "menu-close";

const customStyles = {
    control: () => ({
        height: 35,
        width: "100%",
        border: "1px solid #ced4da",
        display: 'block',
        paddingLeft: '10px',
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: 1.5,
        color: '#495057',
        backgroundColor: '#fff',
        backgroundClip: 'padding-box',
        borderRadius: '0.25rem',
        transition: 'border-color 0.15s ease-in -out, box-shadow 0.15s ease-in -out',
    }),
    menu: styles => ({
        ...styles,
        width: "100%",
        maxHeight: 250,
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: 250,
    }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 0,
    })
}

const CustomInput = props => {
    if (props.isHidden) {
        return <Input {...props} />;
    }
    return (
        <div className="search-input">
            <Input {...props} />
        </div>
    );
};

const CustomOption = props => {
    return (
        <div className="search-options-big">
            <Option {...props} />
        </div>
    );
};

const NoOptionsMessage = () => {
    return (
        <div className="search-options-big my-2 ml-2">
            No results
        </div>
    );
};

const IndicatorsContainer = () => {
    return null;
};

const CustomMenuList = props => {
    return (
        <MenuList {...props}>
            <div className="menu-list-styles">Choose a patient</div>
            { props.children}
        </MenuList >
    );
};

const CustomMenu = props => {
    return (
        <div className="custom-design-scroll">
            <Menu {...props}>{props.children}</Menu>
        </div>
    );
};

const CustomPlaceholder = props => {
    return <Placeholder {...props} ><span className="search-placeholder">Enter patient</span></Placeholder>;
};

function Search({ handleChange }) {
    const [query, setQuery] = useState("");
    const [patients, setPatients] = useState([]);

    async function onInputChange(inputValue, action) {
        if (action.action !== inputBlur && action.action !== menuClose) {
            setQuery(inputValue)
        }
    }

    async function handleInputChange(selected) {
        handleChange(selected.value)
    }

    function generateLabel(option) {
        return (
            <Row>
                <Col>{option.firstName + " " + option.lastName}</Col>
                <Col>{formatDateSlash(option.birthday)}</Col>
            </Row>
        );
    }

    const getOptions = useCallback(
        async () => {
            const response = await PatientManagementService.getPatients({ query: query, limit: SEARCH_LIMIT });
            // flat children
            const data = parseChildren(response?.data || [])
            const searchedPatients = data.map(option => ({
                value: option,
                label: generateLabel(option),
            }));
            setPatients(searchedPatients);
        },
        [query],
    )

    useEffect(() => {
        getOptions();
    }, [getOptions]);

    return (
        <Select
            closeMenuOnSelect={true}
            styles={customStyles}
            components={{
                DropdownIndicator,
                IndicatorSeparator,
                Placeholder: CustomPlaceholder,
                Input: CustomInput,
                Option: CustomOption,
                NoOptionsMessage,
                IndicatorsContainer,
                Menu: CustomMenu,
                MenuList: CustomMenuList
            }}
            options={patients}
            onChange={handleInputChange}
            inputValue={query}
            onInputChange={onInputChange}
            filterOption={() => { return true }}
        />
    )
}

export default Search;