import Http from './axios-config';

class AdminsService {

    constructor() {
        this.http = new Http();
    }

    async getAppointmentsPrices() {
        return await this.http.get("/admins/appointments/prices");
    }

    async updateAppointmentPrice(params) {
        return await this.http.put("/admins/appointments/prices", params);
    }

}

export default new AdminsService();
