export default async function uploadAllData(getData, query) {
    let slots = [];
    try {
        const serverResponse = await getData(query);
        slots = [...serverResponse.data];
        let offset = 0;
        while (serverResponse.pagination.totalCount > offset) {
            offset += 100;
            const nextServerResponse = await getData({ ...query, offset });
            slots = [...slots, ...nextServerResponse.data];
        }
    } catch {
       //empty 
    }
    return slots;
}