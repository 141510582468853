import React from 'react';
import { NavLink, NavItem, Nav, Card} from "reactstrap";
import classnames from 'classnames';

export default function Tabs({ tabs, activeTab, toggleCustomJustified}) {
    return (
        <Card>
            <Nav tabs className="nav-tabs-custom">
                {tabs.map(tab => (
                    <NavItem className="tab-element text-center py-1" key={tab.index}>
                        <NavLink
                            className={"cursor-pointer " + classnames({
                                active: activeTab === tab.index
                            })}
                            onClick={() => {
                                toggleCustomJustified(tab.index);
                            }}
                        >
                            <span className="d-none d-sm-block">{tab.label}</span>
                        </NavLink>
                    </NavItem>
                ))}
            </Nav>
        </Card>
    );
}
