import React from "react";
import { Route, Redirect } from "react-router-dom";
import StorageService from '../../services/StorageService';
import { CLINICAL_ADMIN } from '../../constants/roles';
import { ChosenPatientContext } from '../../pages/Dashboard/tableContext';
import { USER, SESSION } from '../../constants/storageConstants';

const storageService = new StorageService();

function PrivateRoute({ layout: Layout, component: Component, ...rest }) {
    const isTokenActual = storageService.getValueByKey(SESSION);
    const isUserClinicalAdmin = storageService.getValueByKey(USER)?.role === CLINICAL_ADMIN;

    if (!isTokenActual && !isUserClinicalAdmin) {
        storageService.deleteValueByKey(SESSION);
        storageService.deleteValueByKey(USER);
        return (
            <Redirect to="/login" />
        );
    }

    return <Route {...rest} render={(props) => (
        <Layout>
            <ChosenPatientContext.Provider >
                <Component {...props} />
            </ChosenPatientContext.Provider>
        </Layout>
    )} />;
}

export default PrivateRoute;