import React from 'react';

export default function AvatarLogo({ avatar, height, width, name, className }) {
    return (
        <>
            {avatar ?
                <img src={avatar} alt="" className="rounded-circle" height={height} width={width} />
                :
                <div className={className ? className : 'custom-avatar-size font-size-16'}>
                    <span className="avatar-title rounded-circle w-100 h-100">
                        {name.charAt(0).toUpperCase()}
                    </span>
                </div>

            }
        </>
    )
}