import {
  FORGET_PASSWORD,
  RESTORE_PASSWORD,
} from "./actionTypes";

export const userForgetPassword = (userValues, toastr) => {
  return {
    type: FORGET_PASSWORD,
    payload: { userValues, toastr }
  };
};

export const changePassword = (newPassword, setShowSuccessModal, toastr) => {
  return {
    type: RESTORE_PASSWORD,
    payload: {
      newPassword, setShowSuccessModal, toastr,
    }
  }
}