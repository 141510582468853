import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, CardBody, Card, Container, Spinner } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import InputWithEyeOption from '../../components/passwordFieldWithEye/passwordFieldWithEye';
import { useHistory, useLocation } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
// actions
import { changePassword } from '../../store/actions';
import AvatarLogo from '../../components/avatar/avatarLogo';
import Button from '../../components/buttons/button';
// import images
import changePasswordLogo from "../../assets/images/changePasswordLogo.svg";
import ChangePasswordSuccessModal from './changePasswordSuccessModal';
import { passwordValidation } from '../../constants/validityPatterns';
import { withNamespaces } from 'react-i18next';
import queryParser from '../../helpers/queryStringParser';
import AuthentificationService from '../../services/authentification';
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../../constants/lengthValidity';

const ChangePassword = ({ t, changePassword }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [checkingLinkRelevance, setCheckingLinkRelevance] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const { token } = queryParser(location.search);

    const passwordValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: t("Password is required") },
            maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: t("Password must be from 8 to 50 symbols") },
            minLength: { value: PASSWORD_MIN_LENGTH, errorMessage: t("Password must be from 8 to 50 symbols") },
            pattern: { value: passwordValidation, errorMessage: t("Password should contain at least one letter and one digit and one capital letter, can’t contain spaces only") },
        }
    ), [t]);

    const passwordConfirmValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: t("Password doesn't match") },
            match: { value: "newPassword", errorMessage: t("Password doesn't match") }
        }
    ), [t]);

    async function handleValidSubmit(e, values) {
        setIsSubmitting(true);
        const newPassword = {
            password: values.newPassword,
            token: token,
        }
        await changePassword(newPassword, setShowSuccessModal);
        setIsSubmitting(false);
    }

    function handleSubmitSuccessModal() {
        history.push("/login");
    }

    const checkLinkRelevance = useCallback(
        async () => {
            try {
                setCheckingLinkRelevance(true)
                await AuthentificationService.checkExpiredDate({ token });
                setCheckingLinkRelevance(false);
            } catch {
                history.push(`/expired-link?token=${token}`);
            }
        },
        [history, token],
    );

    useEffect(() => {
        checkLinkRelevance()
    }, [checkLinkRelevance]);

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            {!checkingLinkRelevance ? <Card className="card-auth overflow-hidden">
                                <div className="form-caption-auth">
                                    <Row className="h-100 w-100">
                                        <Col className="col-7">
                                            <div className="text-primary pl-4 py-4">
                                                <h5 className="text-primary">{t("Change your password")}</h5>
                                                <span className="text-nowrap">{t("Create new password")}</span>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end caption-profile-img">
                                            <img src={changePasswordLogo} alt="" height="113" />
                                        </Col>
                                        <AvatarLogo />
                                    </Row>
                                </div>

                                <CardBody className="pt-5">

                                    <div className="p-2">

                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}
                                        >
                                            <div className="form-group position-relative">
                                                <InputWithEyeOption
                                                    label={t("New password")}
                                                    placeholder={t("Enter new password")}
                                                    validate={passwordValidationRules}
                                                    name={"newPassword"}
                                                />
                                            </div>

                                            <div className="form-group position-relative">
                                                <InputWithEyeOption
                                                    label={t("Confirm password")}
                                                    placeholder={t("Enter confirm password")}
                                                    validate={passwordConfirmValidationRules}
                                                    name={"confirmPassword"}
                                                />
                                            </div>
                                            <Button className="primary" disabled={isSubmitting} text={t("Confirm")} />
                                        </AvForm>
                                    </div>

                                </CardBody>
                            </Card>
                                :
                                <Spinner />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <ChangePasswordSuccessModal handleSubmit={handleSubmitSuccessModal} visibleModal={showSuccessModal} />
        </React.Fragment >
    );
}

export default connect(null, { changePassword })(withNamespaces()(ChangePassword));

