import React from "react";
import ActionsComponent from "../../ActivationCode/tableComponents/actionsComponent";

const SignatureImg = ({value}) => <img src={value} alt="sign" className="table-signature"/>

export const cliniciansTableHeader = [
    {label: "First name", sortOption: false, classname: "font-size-13 table-caption-label"},
    {label: "Last name", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap"},
    {label: "Email address", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap"},
    {label: "GMC", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap"},
    {label: "Signature", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap"},
    {
        label: "Action",
        sortOption: false,
        classname: "font-size-13 table-caption-label disable-word-wrap action-table-cell"
    },
];

export const cliniciansTableStructure = [
    {
        name: "firstName",
        classname: "font-size-14 pt-20px pb-20px border-bottom fullname-table-cell",
        keyProp: "firstName"
    },
    {name: "lastName", classname: "font-size-13 pt-20px pb-20px border-bottom", keyProp: "lastName"},
    {name: "email", classname: "font-size-13 pt-20px pb-20px border-bottom", keyProp: "email"},
    {name: "gmc", classname: "font-size-13 pt-20px pb-20px border-bottom", keyProp: "doctor.gmc"},
    {
        name: "signature",
        classname: "font-size-13 pt-20px pb-20px border-bottom",
        keyProp: "doctor.link",
        Component: SignatureImg
    }, //todo: img component
    // { name: "actions", classname: "font-size-13 pt-20px pb-20px border-bottom", keyProp: "id", Component: (({value}) => value) },
]

export const cliniciansStructureWithActions = (handleEdit) => {
    return [
        ...cliniciansTableStructure,
        {
            name: "actions",
            classname: "font-size-13 pt-20px pb-20px pr-2 pl-3 border-bottom",
            Component: (({value}) => <ActionsComponent item={value} handleEdit={handleEdit}/>)
        },
    ]
}