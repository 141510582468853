import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Col, Container, Row, Spinner} from "reactstrap";
import {AvForm} from "availity-reactstrap-validation";

import changePasswordPatient from "assets/images/changePasswordPatient.svg";
import Button from 'components/buttons/button';
import InputWithEyeOption from "components/passwordFieldWithEye/passwordFieldWithEye";
import queryParser from "helpers/queryStringParser";
import PatientVerificationService from 'services/patientVerification';
import {PATIENT_ROUTES} from "constants/routes";

import {ChangePasswordByPatientSuccessModal} from "./components/ChangePasswordByPatientSuccessModal";
import {SwitchToMobileDevice} from "./components/SwitchToMobileDevice";
import {PASSWORD_CONFIRM_VALIDATION_RULES, PASSWORD_VALIDATION_RULES} from "./helpers";


export const ChangePasswordByPatient = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [checkingLinkRelevance, setCheckingLinkRelevance] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const { token } = queryParser(location.search);

    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent) && navigator.maxTouchPoints > 0;
    const isAndroid = /Android/i.test(navigator.userAgent) && navigator.maxTouchPoints > 0;

    async function handleValidSubmit(e, values) {
        try {
            setIsSubmitting(true);
            const newPassword = {
                password: values.newPassword,
                token: token,
            }
            await PatientVerificationService.saveNewPassword(newPassword);
            setIsSubmitting(false);

            setShowSuccessModal(true);
        } catch {
            //empty
        }
    }

    const checkLinkRelevance = useCallback(
        async () => {
            try {
                setCheckingLinkRelevance(true)
                await PatientVerificationService.checkExpiredDatePassword({ token });
                setCheckingLinkRelevance(false);
            } catch {
                history.push(`${PATIENT_ROUTES.passwordExpiredLink}?token=${token}`);
            }
        },
        [history, token],
    );

    if (!isIOS && !isAndroid) return (
        <SwitchToMobileDevice/>
    );


    useEffect(() => {
        checkLinkRelevance();
    }, [checkLinkRelevance]);

    return (
        <>
            <div className="public-patient-change-password d-flex align-items-center justify-content-center">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            {!checkingLinkRelevance ? (
                                    <div className="card-auth px-3 d-flex flex-column align-items-center">
                                        <h5 className="public-patient-change-password__heading">Create new password</h5>
                                        <img className="change-password-image" src={changePasswordPatient} alt=""/>
                                        <div className="w-100">

                                            <AvForm
                                                className="form-horizontal"
                                                onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                            >

                                                <div className="form-group position-relative">
                                                    <InputWithEyeOption
                                                        placeholder={"New password"}
                                                        validate={PASSWORD_VALIDATION_RULES}
                                                        name={"newPassword"}
                                                    />
                                                </div>

                                                <div className="form-group position-relative">
                                                    <InputWithEyeOption
                                                        placeholder={"Confirm password"}
                                                        validate={PASSWORD_CONFIRM_VALIDATION_RULES}
                                                        name={"confirmPassword"}
                                                    />
                                                </div>
                                                <Button className="primary" disabled={isSubmitting} text="Change password"/>
                                            </AvForm>
                                        </div>
                                    </div>
                                )
                                :
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner/>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            <ChangePasswordByPatientSuccessModal visibleModal={showSuccessModal}/>
        </>
    )
};
