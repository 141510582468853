import React from "react";


export default ({children}) => {
    return (
        <section className="mt-5 d-flex flex-row w-100 justify-content-center modal-actions ">
            {children}
        </section>
    )
};
