import Http from './axios-config';
import Axios from 'axios';
import StorageService from "./StorageService";
import { SESSION } from '../constants/storageConstants';

const storageService = new StorageService();

export const sendRefreshToken = async (refreshToken) => {
    const response = await Axios.put(`${Http.api.common}/${Http.versions.v1}/clinical-admins/sessions`, refreshToken);
    storageService.addValueByKey(SESSION, response.data.data);
    return response;
};