import React, { useState, useMemo } from 'react';
import { Row, Col, CardBody, Card, Container, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

// Redux
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import AvatarLogo from '../../components/avatar/avatarLogo';
// actions
import { loginUser, apiError } from '../../store/actions';
import InputWithEyeOption from '../../components/passwordFieldWithEye/passwordFieldWithEye';
// import images
import logoGP from "../../assets/images/logoGP.svg";
import Button from '../../components/buttons/button';
import { EMAIL_MAX_LENGTH } from '../../constants/lengthValidity';
import validateEmail from '../../helpers/validateFunctions/validateEmail';

import AuthentificationService from '../../services/authentification';

const useValidateField = () => {
    const [data, setData] = useState({
        dirty: false,
        value: "",
        showError: false
    });
    const onChange = (event) => setData(() => {
        event.persist();
        return {
            dirty: true,
            value: event.target.value
        }
    })
    const showCustomError = () => {
        const showError = !!(!data.dirty && !data.value);
        setData((prevState) => {
            return {
                ...prevState,
                showError: showError
            }
        })
    }
    const hideError = () => {
        setData((prevState) => {
            return {
                ...prevState,
                dirty: true,
                showError: false,
            }
        })
    }
    return [data, onChange, showCustomError, hideError]
};

const Login = ({ t, loginUser }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successRequestMessage, setSuccessRequestMessage] = useState(false);
    const history = useHistory();

    const [email, setEmail, showCustomError, hideEmailError] = useValidateField();
    const [password, setPassword, showCustomPasswordError, hidePasswordError] = useValidateField();

    const emailValidationRules = useMemo(() => ({
        required: { value: true, errorMessage: t("Email is required") },
        email: { value: true, errorMessage: t("Invalid email format") },
        maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: t("Invalid email format") },
    }), [t]);

    const passwordValidationRules = useMemo(() => ({
        required: { value: true, errorMessage: t("Password is required") },
    }), [t]);

    const oneTimePasswordValidationRules = useMemo(() => ({
        required: { value: true, errorMessage: t("Password is required ") },
    }), [t])

    async function handleValidSubmit(e, values) {
        setIsSubmitting(true);
        const userValues = {
            email: values.email,
            password: values.password,
            oneTimePassword: values.oneTimePassword,
        }
        await loginUser(userValues, history);
        setIsSubmitting(false);
    }

    async function handleSendOnTimePwd() {
        showCustomError();
        showCustomPasswordError();
        if (validateEmail(email.value) && password.value) {
            const userValues = {
                email: email.value,
                password: password.value,
            }
            try {
                await AuthentificationService.sendOneTimePassword(userValues);
                setSuccessRequestMessage(true);
            } catch {
                //empty
            }
        }
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="card-auth overflow-hidden">
                                <div className="form-caption-auth">
                                    <Row className="h-100 w-100">
                                        <Col className="col-7">
                                            <div className="text-primary px-4 pt-4">
                                                <h5 className="text-primary">{t("Welcome Back !")}</h5>
                                                <span className="text-nowrap">{t("Sign in to continue to TapGP")}</span>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end caption-profile-img">
                                            <img src={logoGP} alt="" height="113" />
                                        </Col>
                                        <AvatarLogo />
                                    </Row>
                                </div>

                                <CardBody className="pt-5 pb-0">

                                    <div className="px-2 pt-2 pb-0">

                                        <AvForm
                                            className="form-horizontal"
                                            onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}
                                            onInvalidSubmit={() => { hideEmailError(); hidePasswordError() }}
                                        >
                                            <div className="form-group position-relative mb-4">
                                                <AvField
                                                    name="email"
                                                    label={t("Your email")}
                                                    className="form-control mb-0"
                                                    placeholder={t("Enter your email")}
                                                    type="email"
                                                    validate={emailValidationRules}
                                                    onChange={(event) => setEmail(event)}
                                                    onBlur={() => hideEmailError()}
                                                />
                                                <label className="validation-error">{email.showError && t("Provide login credentials first")}</label>
                                            </div>
                                            <div className="form-group position-relative">
                                                <InputWithEyeOption
                                                    label={t("Password")}
                                                    placeholder={t("Enter password")}
                                                    validate={passwordValidationRules}
                                                    onChange={(event) => setPassword(event)}
                                                    name={"password"}
                                                    onBlur={() => hidePasswordError()}
                                                />
                                                <label className="validation-error">{password.showError && t("Provide login credentials first")}</label>
                                            </div>

                                            <div className="form-group">
                                                <section className="d-flex flex-row justify-content-between mb-2 mt-2">
                                                    <label className="mb-0">One-time password</label>
                                                    <p
                                                        className="font-weight-normal text-primary cursor-pointer mb-0"
                                                        onClick={handleSendOnTimePwd}
                                                    >
                                                        {t("Send me one-time password")}
                                                    </p>

                                                </section>
                                                <AvField
                                                    name="oneTimePassword"
                                                    type="password"
                                                    placeholder={t("Enter one-time password")}
                                                    validate={oneTimePasswordValidationRules}
                                                />
                                            </div>
                                            <Button disabled={isSubmitting} className="primary" text={t("Log in")} />
                                        </AvForm>
                                        <div className="restore-password-label text-center position-relative mb-1">
                                            <Link to="/forgot-password" className="text-muted ml-4"><i className="mdi mdi-lock lock-size mr-1"></i>{t("Restore password")}</Link>
                                        </div>
                                    </div>

                                </CardBody>
                            </Card>


                            {successRequestMessage ? <Alert color="success"><span className="success-alert-message">{t("Password sent successfully. Check your mail")}</span></Alert> : null}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default connect(mapStatetoProps, { loginUser, apiError })(withNamespaces()(Login));

