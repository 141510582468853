import {EMAIL_MAX_LENGTH, PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "constants/lengthValidity";
import {patientPasswordValidation} from "constants/validityPatterns";

//const APP_STORE_LINK = "https://apps.apple.com/gb/app/tapgp-online-gp-appointments/id1551935531";
//const GOOGLE_PLAY_MARKET_LINK = "https://play.google.com/store/apps/details?id=com.doctorathome.tapgp";

const APP_LINK_SCHEMA_PREFIX = {
    dev: "dev",
    stage: "stage"
};
export const getLinkToApp = (hostname) => {
    if (hostname.includes(APP_LINK_SCHEMA_PREFIX.dev)) {
        return `${APP_LINK_SCHEMA_PREFIX[APP_LINK_SCHEMA_PREFIX.dev]}.doctorathome.uk://sign-in`;
    }

    if (hostname.includes(APP_LINK_SCHEMA_PREFIX.stage)) {
        return `${APP_LINK_SCHEMA_PREFIX[APP_LINK_SCHEMA_PREFIX.stage]}.doctorathome.uk://sign-in`;
    }

    return "doctorathome.uk://sign-in";
};

export const PASSWORD_VALIDATION_RULES = {
    required: { value: true, errorMessage: "Password is required" },
    maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: "Password must be from 8 to 50 symbols" },
    minLength: { value: PASSWORD_MIN_LENGTH, errorMessage: "Password must be from 8 to 50 symbols" },
    pattern: {
        value: patientPasswordValidation,
        errorMessage: "Password should contain at least one letter and one digit and one capital letter, can’t contain spaces only"
    },
};


export const PASSWORD_CONFIRM_VALIDATION_RULES = {
    required: { value: true, errorMessage: "Password doesn't match" },
    match: { value: "newPassword", errorMessage: "Password doesn't match" }
};

export const EMAIL_VALIDATION_RULES = {
    required: { value: true, errorMessage: "Invalid email format" },
    email: { value: true, errorMessage: "Invalid email format" },
    maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: "Invalid email format" },
}
