import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Modal, Spinner } from 'reactstrap';
import logo from '../../../assets/images/logo.jpg';
import GeneralInfo from './generalInfo';
import PDFFooter from './pdfFooter';
import moment from 'moment';
import { Formik, ErrorMessage } from 'formik';
import { requiredError, errorMessage, commentLengthError } from '../../../constants/errorMessages';
import * as yup from 'yup';
import CustomDatePicker from './datepicker';
import { dateBySlash } from '../../../constants/dateFormats';
import ModalButtons from './letterComponents/modalButtons';
import SickCheckboxes from './letterComponents/sickCheckboxes';
import SickRadios from './letterComponents/sickRadios';
import generateDocumentID from '../../../helpers/generateDocumentID';
import { FIT_IDENTIFICATION, SICK_IDENTIFICATION } from '../../../constants/documentStatuses';
import { MIN_NOTE, MAX_COMMENT } from '../../../constants/lengthValidity';
import CallsService from "../../../services/calls";
import { parseBitMask } from "../../../helpers/generateBitmask";
import { BenefitsMaskValues, sickArray } from "../../../constants/callConstants";
import {
    DEFAULT_CLINICIAN_FIRST_NAME, DEFAULT_CLINICIAN_GMC,
    DEFAULT_CLINICIAN_LAST_NAME,
    DEFAULT_CLINICIAN_SIGNATURE
} from "../../../constants/defaultDoctor";

const schema = yup.object().shape({
    checked: yup.array(),
    type: yup.string(requiredError).required(requiredError).typeError(requiredError).trim(requiredError),
    reason: yup.string(requiredError).required(requiredError).typeError(requiredError).trim(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError),
    comments: yup.string(requiredError).required(requiredError).typeError(requiredError).trim(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError),
    caseFrom: yup.string(requiredError).required(requiredError).typeError(requiredError).trim(requiredError),
    caseTo: yup.string(requiredError).required(requiredError).typeError(requiredError).trim(requiredError),
});

export default function CreateSickLetter({
    sickID,
    patientID,
    saveSickLetter,
    sendSickLetter,
    appointment,
    patient = null,
    onClose = null,
    submitButton= "Send",
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [isSendRequired, setIsSendRequired] = useState(false);

    const initialValues = {
        // checked: [],
        type: SICK_IDENTIFICATION,
        reason: '',
        comments: '',
        caseFrom: '',
        caseTo: '',
    }

    const [initialState, setInitialState] = useState(initialValues);

    const appointmentDate = useMemo(() => moment(appointment?.date).format(dateBySlash), [appointment])

    const doctorsName = appointment?.doctor?.firstName ? `${appointment?.doctor?.firstName} ${appointment?.doctor?.lastName || ""}`
        : `${DEFAULT_CLINICIAN_FIRST_NAME} ${DEFAULT_CLINICIAN_LAST_NAME}`


    const doctorsInfo = useMemo(() => [
        {
            label: "Doctor’s signature:",
            value: <img src={ appointment?.doctorInfo?.link || DEFAULT_CLINICIAN_SIGNATURE} alt="" width="77" height="55"/>,
            classname: 'col-sm-3 my-auto'
        },
        {
            label: "Date of statement:",
            value: appointmentDate
        },
        {
            label: "Doctor’s name:",
            value: doctorsName
        },
        {
            label: "GMC:",
            value: appointment?.doctorInfo?.gmc || DEFAULT_CLINICIAN_GMC,
        },
    ], [appointment, appointmentDate]);

    const appointmentInfo = useMemo(() => [
        {
            label: "Patient’s name:",
            value: `${patient?.firstName || appointment?.patient?.firstName} ${ patient?.lastName || appointment?.patient?.lastName}`
        },
        {
            label: "Patient’s address:",
            value: patient?.address?.address || appointment?.patient?.address?.address
        },
        {
            label: "I assessed you case on:",
            value: appointmentDate
        },
    ], [appointment, appointmentDate]);

    const getFormData = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await CallsService.getSickLetter(sickID);
            const {
                reason = initialState.reason,
                comments = initialState.comments,
                caseFrom = initialState.caseFrom,
                caseTo = initialState.caseTo,
                benefits = "",
                isFitNote = false,
            } = data || {};

            const itemsFromBitmask = sickArray
                .map((item) => parseBitMask(benefits, BenefitsMaskValues?.[item]) ? item : false)
                .filter(Boolean);

            setInitialState(prevState => ({
                ...prevState,
                reason,
                comments,
                caseFrom,
                caseTo,
                checked: itemsFromBitmask,
                type: isFitNote ? FIT_IDENTIFICATION : SICK_IDENTIFICATION,
            }));
        } catch (e) {
            setInitialState(initialValues);
        } finally {
            setIsLoading(false);
        }
    }, [appointment, setInitialState])

    const submitForm = useCallback(async (values, ...rest) => {
        try {
            setFormSubmitting(true);
            await saveSickLetter({
                ...values,
                fitSickNoteId: sickID,
            }, ...rest);
            if (isSendRequired) {
                await sendSickLetter(
                    appointment.id,
                    sickID,
                )
                setIsSendRequired(false);
            }
        } finally {
            setFormSubmitting(false);
        }
    }, [setFormSubmitting, sickID, isSendRequired])


    useEffect(() => {
        getFormData();
    }, [getFormData])

    return (
        <Modal
            isOpen={true}
            className="pdf-modal"
        >
            {isLoading && (
                <div className="patients-table d-flex justify-content-center align-items-center">
                    <Spinner className="mx-auto" color="info" />
                </div>
            )}
            {!isLoading && (
                <Formik
                    initialValues={{
                        ...initialState,
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        submitForm(values)
                        setSubmitting(false)
                    }}
                    enableReinitialize
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        setFieldValue,
                    }) => {
                        const documentID = generateDocumentID(values.type, sickID, patientID);

                        return (
                            <form onSubmit={handleSubmit} className="">
                                <div className="p-5 h-100 w-100 modal-container">
                                    <label className="ml-3 mt-2 font-size-22 font-weight-normal">Create fit/sick note</label>
                                    <div className="pdf-header mt-2  ml-3 w-100" />
                                    <Card className="ml-3 pdf-container mb-0 pt-0 pb-4">
                                        <section className="d-flex flex-row justify-content-between mt-5">
                                            <section className="d-flex flex-column">
                                                <label className="info-page-header">Private Statement of Fitness for Work/School</label>
                                                <label className="status-label mt-1">Date issued: {appointmentDate}</label>
                                            </section>
                                            <img src={logo} alt="" width="190" height="98" />
                                        </section>


                                        <GeneralInfo info={appointmentInfo} />

                                        <label className="mt-2">Because of the following condition(s)</label>
                                        <input
                                            type="text"
                                            name="reason"
                                            placeholder="Enter the reason"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.["reason"]}
                                        />
                                        <ErrorMessage name="reason" render={errorMessage} />

                                        <SickRadios isInvalid={errors.type && touched.type} />

                                        <SickCheckboxes isInvalid={errors.checked && touched.checked} />

                                        <label className="mt-2">Comments, including functional effects of your condition(s):</label>
                                        <input
                                            type="text"
                                            name="comments"
                                            placeholder="Enter comments"
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values?.["comments"]}
                                        />
                                        <ErrorMessage name="comments" render={errorMessage} />

                                        <section className="d-flex flex-row mt-4">
                                            <label className="w-50 pt-2">This will be the case from:</label>
                                            <CustomDatePicker
                                                errors={errors}
                                                touched={touched}
                                                ComponentStart={() => (<ErrorMessage name="caseFrom" render={errorMessage} />)}
                                                ComponentEnd={() => (<ErrorMessage name="caseTo" render={errorMessage} />)}
                                                currentStartDay={values?.["caseFrom"]}
                                                currentEndDay={values?.["caseTo"]}
                                                setFieldValue={setFieldValue}
                                                isSickNote={values.type === SICK_IDENTIFICATION}
                                            />
                                        </section>

                                        <GeneralInfo info={doctorsInfo} />
                                    </Card>

                                    <PDFFooter id={documentID} text="Fit/Sick note ID" />

                                    <ModalButtons
                                        handleSubmit={handleSubmit}
                                        handleCancel={onClose}
                                        submitButtonName={submitButton}
                                        disabledSubmit={formSubmitting}
                                        disabledCancel={formSubmitting}
                                        isLoading={!isSendRequired && formSubmitting}
                                        isSendLoading={isSendRequired && formSubmitting}
                                        disabledSend={formSubmitting}
                                        handleSend={(e) => {
                                            setIsSendRequired(true);
                                            handleSubmit(e)
                                        }}
                                    />
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            )}
        </Modal >
    );
}



