import React, {useCallback, useEffect, useState} from 'react';
import toastr from "toastr";

import AdminsService from 'services/admins';
import Icon from "components/Icon";
import {ICONS} from "constants/svgIcons";

import {ChangeAppointmentPriceModal} from "./ChangeAppointmentPriceModal";


export const AppointmentPriceSection = () => {
        const [price, setPrice] = useState(0);
        const [isChangePriceModalOpen, setIsChangePriceModalOpen] = useState(false);

        const getAppointmentsPrice = async () => {
            const response = await AdminsService.getAppointmentsPrices();

            setPrice(response.data?.price / 100);
        }


        useEffect(() => {
            getAppointmentsPrice();
        }, []);

        const handleChangePrice = useCallback(async (values) => {

            const newPrice = +values?.newPrice.replace(",", ".") * 100;

            try {
                await AdminsService.updateAppointmentPrice({ price: newPrice });

                toastr.success("Appointment price was successfully changed");
                await getAppointmentsPrice();

            } catch {
                // empty
            } finally {
                setIsChangePriceModalOpen(false);
            }
        }, []);

        return (
            <>
                <section className="appointment-price-section d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="appointment-price-section__label">Price per appointment</div>
                        <div className="appointment-price-section__value">£{price}</div>
                    </div>
                    <button className="btn btn-outline-primary appointment-price-section__change-price-button"
                            onClick={() => setIsChangePriceModalOpen(true)}>
                        <Icon icon={ICONS.editIcon} alt=""/>
                        <span>Change price</span>
                    </button>
                </section>
                <ChangeAppointmentPriceModal
                    isOpen={isChangePriceModalOpen}
                    handleSubmit={handleChangePrice}
                    initialValues={{ currentPrice: price, newPrice: "" }}
                    close={() => setIsChangePriceModalOpen(false)}
                />
            </>
        )
    }
;
