export const ADD_PHOTO_ACTION_ID = 1;
export const DELETE_PHOTO_ACTION_ID = 2;
export const ADD_PDF_ACTION_ID = 3;
export const DELETE_PDF_ACTION_ID = 4;
export const UPDATE_TEXT_ACTION_ID = 5;
export const VIEW_THE_RECORD_ID = 6;
export const RESET_PASSWORD = 7;
export const UPLOAD_ID = 8;
export const DELETE_ID = 9;

export const ADD_PHOTO_ACTION_LABEL = "Added a photo";
export const DELETE_PHOTO_ACTION_LABEL = "Deleted a photo";
export const ADD_PDF_ACTION_LABEL = "Added a file";
export const DELETE_PDF_ACTION_LABEL = "Deleted a file";
export const UPDATE_TEXT_ACTION_LABEL = "Updated the text";
export const VIEW_THE_RECORD_LABEL = "Viewed the record";
export const RESET_PASSWORD_LABEL = "Reset password";
export const UPLOAD_ID_LABEL = "Added ID";
export const DELETE_ID_LABEL = "Deleted ID";

export const LOG_HISTORY_ACTION_TYPES = {
    [ADD_PHOTO_ACTION_ID]: ADD_PHOTO_ACTION_LABEL,
    [DELETE_PHOTO_ACTION_ID]: DELETE_PHOTO_ACTION_LABEL,
    [ADD_PDF_ACTION_ID]: ADD_PDF_ACTION_LABEL,
    [DELETE_PDF_ACTION_ID]: DELETE_PDF_ACTION_LABEL,
    [UPDATE_TEXT_ACTION_ID]: UPDATE_TEXT_ACTION_LABEL,
    [VIEW_THE_RECORD_ID]: VIEW_THE_RECORD_LABEL,
    [RESET_PASSWORD]: RESET_PASSWORD_LABEL,
    [UPLOAD_ID]: UPLOAD_ID_LABEL,
    [DELETE_ID]: DELETE_ID_LABEL
}
