export const MAX_FILE_SIZE = 5_242_880; // bytes

export const IMAGE_JPG = "image/jpg";
export const IMAGE_JPEG = "image/jpeg";
export const IMAGE_PNG = "image/png";
export const FILE_PDF = "application/pdf"

export const ALLOWED_EXTENSIONS_FILE = [
    IMAGE_JPEG,
    IMAGE_JPG,
    IMAGE_PNG,
    FILE_PDF
];

export const ERROR_SIZE_MESSAGE = "File size is too large. Files cannot be larger than 5 MB";
export const ERROR_ALLOWED_EXTENSION_MESSAGE =
    "Invalid file format. Only pdf format is allowed";

export const MAX_SIGNATURE_SIZE = 10_485_760 // bytes
export const MAX_SELFIE_SIZE = 10_485_760 // bytes

export const ALLOWED_SIGNATURE_FILE = [
    IMAGE_PNG,
]

export const ALLOWED_SELFIE_FILE = [
    IMAGE_JPEG,
    IMAGE_JPG,
    IMAGE_PNG,
]

