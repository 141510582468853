import {useCallback, useEffect, useState} from "react";

import formatDate from "helpers/formatDate";
import AppointmentsService from "services/appointments";
import formatTime from "helpers/formatTime";
import getCurrentTimezoneOffset from "helpers/getCurrentTimezoneOffset";
import formatTimeString from "helpers/formatServerSessionTime";

const slotTimeFormat = "hh:mm A";

const getAvailablePrioritiesOptions = ({ totalPriorities = 5, takenPriorities }) => {
    const priorityOptions = [];
    new Array(totalPriorities).fill().forEach((acc, index) => {
        const priority = index + 1;
        priorityOptions.push({
            value: priority,
            label: priority,
            isDisabled: takenPriorities.find(option => option.priority === priority)
        });
    });

    return priorityOptions;
};

export const useSessionCreateAndManage = ({ currentSession, isEdit }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSessionDurationDisabled, setIsSessionDurationDisabled] = useState(!isEdit);
    const [isPriorityFieldDisabled, setIsPriorityFieldDisabled] = useState(!isEdit);
    const [sessionParams, setSessionParams] = useState({
        doctorId: currentSession?.doctorId || "",
        startDate: currentSession?.startDate || "",
        endDate: currentSession?.endDate || "",
        startsAt: currentSession ? formatTimeString(currentSession?.startsAt, slotTimeFormat) : "",
        endsAt: currentSession ? formatTimeString(currentSession?.endsAt, slotTimeFormat) : ""
    });
    const [takenTimeSlots, setTakenTimeSlots] = useState([]);
    const [availablePrioritiesOptions, setAvailablePriorities] = useState([]);

    const handleSessionValidityChange = useCallback((start, end) => {
        setSessionParams(prevValue => {
            return {
                ...prevValue,
                startDate: formatDate(start),
                endDate: formatDate(end)
            }
        });
    }, []);

    const getTakenTimeSlots = async () => {
        if (!sessionParams.doctorId || !sessionParams.startDate || !sessionParams.endDate || sessionParams.endDate === "Invalid date") return;

        setIsLoading(true);

        // eslint-disable-next-line no-unused-vars
        const { startsAt, endsAt, ...requestParams } = sessionParams;

        const modifiedRequestParams = isEdit ? {
            ...requestParams,
            withoutCurrent: true,
            sessionId: currentSession?.id
        } : requestParams;

        try {
            const response = await AppointmentsService.getTakenTimeSlots(modifiedRequestParams);

            setTakenTimeSlots(response.data);
            setIsSessionDurationDisabled(false);

        } catch {
            //empty
        } finally {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        getTakenTimeSlots();
    }, [sessionParams.doctorId, sessionParams.startDate, sessionParams.endDate]);

    const getAvailablePriorities = async () => {
        if (!sessionParams.doctorId
            || !sessionParams.startDate
            || !sessionParams.endDate
            || sessionParams.endDate === "Invalid date"
            || !sessionParams.startsAt
            || !sessionParams.endsAt
        ) return;

        setIsLoading(true);

        // eslint-disable-next-line no-unused-vars
        const { appointmentDuration, doctorId, ...requestParams } = sessionParams;

        const modifiedRequestParams = isEdit ? {
            ...requestParams,
            withoutCurrent: true,
            sessionId: currentSession?.id
        } : requestParams;

        try {
            const response = await AppointmentsService.getTakenPriorities({
                ...modifiedRequestParams,
                startsAt: formatTime(sessionParams.startsAt),
                endsAt: formatTime(sessionParams.endsAt),
                timezoneOffset: getCurrentTimezoneOffset()
            });


            const availablePriorities = getAvailablePrioritiesOptions({
                totalPriorities: response.totallyCountOfPriority,
                takenPriorities: response.data
            });

            setAvailablePriorities(availablePriorities);

            setIsPriorityFieldDisabled(false);

        } catch {
            //empty
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getAvailablePriorities();
    }, [sessionParams]);

    return {
        isSessionDurationDisabled,
        isPriorityFieldDisabled,
        takenTimeSlots,
        availablePrioritiesOptions,
        setSessionParams,
        handleSessionValidityChange,
        isLoading
    };

};
