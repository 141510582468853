import React from "react";


const ModalHeadline = ({children}) => {
    return (
        <header className="font-size-22 d-flex flex-row justify-content-between">{children}</header>
    )
};

export default ModalHeadline;
