import React, { useState, useMemo } from 'react';
import Select from "../../../components/Select"
import ModalButtons from "../../../components/Modal/ModalButtons"
import ModalHeadline from "../../../components/Modal/ModalHeadline"
import Label from "../../../components/Label";
import FormSection from "../../../components/FormSection";
import useMediaDevices from '../../../helpers/useMediaDevices';
import { Spinner } from 'reactstrap';

export default function DeviceSettingsModal({ onClose, selectDevices, selectedDevices }) {

    const [selectedCamera, setSelectedCamera] = useState(selectedDevices.video);
    const [selectedMicro, setSelectedMicro] = useState(selectedDevices.audio);

    const { isAllDevices, audio, video } = useMediaDevices();

    const micros = useMemo(() => transformToSelectOptions(audio), [audio]);
    const cameras = useMemo(() => transformToSelectOptions(video), [video]);

    function transformToSelectOptions(devices) {
        return devices.map((device) => {
            return {
                label: device.label,
                value: device.deviceId,
            }
        })
    }

    function handleSubmit() {
        selectDevices(selectedCamera, selectedMicro)
        onClose();
    }

    return (
        isAllDevices ?
            <>
                <ModalHeadline>Settings option</ModalHeadline>

                <FormSection className={'mt-4'}>
                    <Label> Camera settings </Label>
                    <Select
                        onChange={(option) => setSelectedCamera(option.value)}
                        options={cameras}
                        defaultValue={cameras.find(camera => camera.value === selectedCamera) || cameras[0]}
                    />
                </FormSection>

                <FormSection className={'mt-4'}>
                    <Label> Microphone settings </Label>
                    <Select
                        onChange={(option) => setSelectedMicro(option.value)}
                        options={micros}
                        defaultValue={micros.find(micro => micro.value === selectedMicro) || micros[0]}
                    />
                </FormSection>

                <ModalButtons>
                    <button className="btn btn-primary w-100" onClick={handleSubmit}>Save</button>
                    <button className="btn btn-danger ml-4 w-100" onClick={onClose}>Cancel</button>
                </ModalButtons>
            </>
            :
            <section className="w-100 h-100 text-center">
                <Spinner className="mx-auto" color="info" />
            </section>
    )
}
