import React from 'react';
import { withNamespaces } from 'react-i18next';
import { CardBody, Card } from "reactstrap";
import expiredLinkLogo from '../../assets/images/expired-verification-link.svg';
import { userForgetPassword } from "../../store/actions";
import { connect } from "react-redux";
import toastr from '../../components/toastr';
import queryParser from '../../helpers/queryStringParser';

function ExpiredLink({ t, location, userForgetPassword }) {
    const { token } = queryParser(location.search);

    async function handleResendLink() {
        await userForgetPassword({ token }, toastr);
    }

    return (
        <Card className="expiredLinkCard overflow-hidden text-center mx-auto">
            <CardBody className="p-2">
                <img src={expiredLinkLogo} alt="" />
                <div className="">
                    <label className="expiredLinkLabel mt-4">{t("Your link has expired")}</label>
                    <div className="mt-5 text-center">
                        <button className="btn btn-primary w-100" onClick={handleResendLink}>{t("Resend Link")}</button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default connect(null, { userForgetPassword })(withNamespaces()(ExpiredLink))
