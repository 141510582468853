import React, { useState } from "react";
import sliceMonthName from "../../../helpers/sliceMonthName";
import DatePicker from "react-datepicker";
import { CustomHeader, MyContainer } from "../../../components/Common/calendarComponents";
import formatTimeToDMMMYYYY from "../../../helpers/formatTimeToDMMMYYYY";
import formatDateToUK from "../../../helpers/formatDateToUK";

const START_FIELD_NAME = "startDate";
const END_FIELD_NAME = "endDate"

const START_PLACEHOLDER = "Select start date";
const END_PLACEHOLDER = "Select end date";

const ActivationCodeDatePicker = ({ ComponentStart, ComponentEnd, setFieldValue, currentStartDay, currentEndDay }) => {
    const [startDate, setStartDate] = useState(currentStartDay && new Date(currentStartDay));
    const [endDate, setEndDate] = useState(currentEndDay ? new Date(currentEndDay) : null);

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setFieldValue(START_FIELD_NAME, formatDateToUK(start))
        setEndDate(end);
        setFieldValue(END_FIELD_NAME, formatDateToUK(end) || '');
    };

    const CustomInput = ({ value, onClick }) => (
        <section className="d-flex flex-column flex-sm-row justify-content-between coupon-form-wrapper">
            <section className="d-flex flex-column">
                <label className={"pt-2 "}>Start date</label>
                <section className="d-flex flex-column">
                    <input
                        type="text"
                        placeholder={START_PLACEHOLDER}
                        value={value && formatTimeToDMMMYYYY(value)}
                        onClick={onClick}
                        className="custom-calendar-output-field cursor-pointer form-control w-100"
                    />
                    <ComponentStart />
                </section>
            </section>
            <section className="d-flex flex-column">
                <label className={"pt-2 "}>End date</label>
                <section className="d-flex flex-column">
                    <input
                        type="text"
                        placeholder={END_PLACEHOLDER}
                        value={endDate && formatTimeToDMMMYYYY(endDate)}
                        onClick={onClick}
                        className="custom-calendar-output-field cursor-pointer form-control w-100"
                    />
                    <ComponentEnd />
                </section>
            </section>
        </section>
    );

    return (
        <>
            <DatePicker
                calendarContainer={MyContainer}
                renderCustomHeader={(props) => <CustomHeader  {...props} />}
                locale="en-GB"
                formatWeekDay={sliceMonthName}
                customInput={<CustomInput />}
                startDate={startDate}
                endDate={endDate}
                disabledKeyboardNavigation
                selected={startDate}
                onChange={onChange}
                selectsRange
                shouldCloseOnSelect={!endDate}
            />
        </>
    )
}

export default ActivationCodeDatePicker;