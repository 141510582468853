import {ReactComponent as settingsLogo} from '../assets/images/bx-tone.svg';
import {ReactComponent as analitictsLogo} from '../assets/images/ic-user-analytics-24.svg';
import {ReactComponent as managmentLogo} from '../assets/images/ic-patients-management-24.svg';
import {ReactComponent as appointmentsLogo} from '../assets/images/ic-appointment-24.svg';
import {ReactComponent as activationCodeLogo} from '../assets/images/ic-activation-code-24.svg';
import {ReactComponent as cliniciansLogo} from '../assets/images/ic-clinicians-management.svg';
import {ReactComponent as editIcon} from 'assets/images/ic-edit.svg';
import {ReactComponent as fileSimple} from 'assets/images/ic-file-simple.svg';
import {ReactComponent as trashIcon} from "assets/images/ic-thrash-black.svg";
import {ReactComponent as envelopeWithArrow} from "assets/images/ic-envelope-with-arrow.svg";

export const SETTINGS = "settings";
export const ANALYTICS = "analytics";
export const MANAGEMENT = "management";
export const APPOINTMENTS = "appointments";
export const ACTIVATION = "activation";
export const CLINICIANS = "clinicians";

export const ICONS = {
    editIcon: "editIcon",
    fileSimple: "fileSimple",
    trashIcon: "trashIcon",
    envelopeWithArrow: "envelopeWithArrow"
};

export const icons = {
    [SETTINGS]: settingsLogo,
    [ANALYTICS]: analitictsLogo,
    [MANAGEMENT]: managmentLogo,
    [APPOINTMENTS]: appointmentsLogo,
    [ACTIVATION]: activationCodeLogo,
    [CLINICIANS]: cliniciansLogo,
    [ICONS.editIcon]: editIcon,
    [ICONS.fileSimple]: fileSimple,
    [ICONS.trashIcon]: trashIcon,
    [ICONS.envelopeWithArrow]: envelopeWithArrow
}
