import axios from "axios";

const getImageBlob = async (url) => {
    const {data} = await axios.get(url, {
        responseType: "blob",
    })

    return data
}

export default getImageBlob;