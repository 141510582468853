import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

toastr.options = {
    positionClass: "toast-top-right",
    timeOut: 10000,
    preventDuplicates: true,
    newestOnTop: true,
}

export default toastr;