import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Col, Spinner, Table} from "reactstrap";
import {useHistory, useLocation} from "react-router-dom";
import queryParser from "../../helpers/queryStringParser";
import qs from "qs";
import {CLINICIANS_LIMIT} from "../../constants/paginationLimits";
import PaginationTableFooter from "../patientManagement/tableComponents/paginationFooter";
import parseIntFromStr from "../../helpers/parseIntFromStr";
import NoCliniciansPlaceholder from "./cliniciansComponents/noCliniciansPlaceholder";
import {cliniciansStructureWithActions, cliniciansTableHeader} from "./tableComponents/cliniciansDataStructure";
import * as _ from "lodash";
import TableHeader from "../patientManagement/tableComponents/tableHeader";
import SimpleTableBody from "../../components/simpleTable/simpleTableBody";
import CreateClinicianForm from "./cliniciansComponents/createClinicianForm";
import CliniciansService from "../../services/clinicians";
import S3Service from "../../services/S3Service";
import {FILE_TYPES} from "../../constants/uploadFileConstants";
import toastr from "toastr";

const CREATE_SUCCESS_MESSAGE = "The invitation has been successfully sent";
const UPDATE_SUCCESS_MESSAGE = "The information has been successfully updated";

const Clinicians = () => {
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenCreateClinicianModal, setIsOpenCreateClinicianModal] = useState(false);
    const [editClinicianData, setEditClinicianData] = useState(null);
    const [cliniciansPagination, setCliniciansPagination] = useState({});
    const [clinicians, setClinicians] = useState([]);

    const searchQuery = queryParser(location.search)

    const setNewSearchQuery = (incomeQuery) => {
        const newQuery = {
            ...searchQuery,
            ...incomeQuery,
        }
        const query = qs.stringify(newQuery);
        history.push({
            search: query,
        });
    }

    const handleChangePage = ({selected}) => {
        if (!isNaN(selected)) {
            setNewSearchQuery({offset: selected * CLINICIANS_LIMIT});
        }
    }

    const handleEdit = useCallback((admin) => {
        const {
            id,
            firstName,
            lastName,
            email,
            doctor: {
                doctorSignId,
                gmc,
                link,
            },
        } = admin;

        setEditClinicianData({
            id,
            firstName,
            lastName,
            email,
            doctorSignId,
            gmc,
            link,
        })
    }, [setEditClinicianData])

    const dataStructure = useMemo(() => cliniciansStructureWithActions(handleEdit), [handleEdit]);

    const getClinicians = useCallback(async () => {
        try {
            setIsLoading(true);
            const {data, pagination} = await CliniciansService.getCliniciansList({
                limit: CLINICIANS_LIMIT,
                offset: searchQuery.offset
            });

            setClinicians(data);
            setCliniciansPagination(pagination);
        } finally {
            setIsLoading(false);
        }
    }, [setIsLoading, setClinicians, setCliniciansPagination, searchQuery.offset])

    const handleCreateClinician = useCallback(async (values) => {
        try {
            const {file, ...rest} = values || {};
            const {firstName, lastName, email, gmc} = rest || {};

            const fileData = await S3Service.uploadFile(file, FILE_TYPES.SIGNATURE);

            await CliniciansService.createClinician({
                firstName,
                lastName,
                email,
                gmc,
                doctorSignId: fileData?.id,
            })
            toastr.success(CREATE_SUCCESS_MESSAGE);
            await getClinicians();
        } finally {
            setIsOpenCreateClinicianModal(false);
        }
    }, [setIsOpenCreateClinicianModal])

    const handleUpdateClinician = useCallback(async (values) => {
        try {
            const {id, file, ...rest} = values || {};
            const {firstName, lastName, email, gmc} = rest || {};

            const fileData = await S3Service.uploadFile(file, FILE_TYPES.SIGNATURE);

            await CliniciansService.updateClinician(id, {
                firstName,
                lastName,
                email,
                gmc,
                doctorSignId: fileData?.id,
            })
            toastr.success(UPDATE_SUCCESS_MESSAGE);
            await getClinicians();
        } finally {
            setEditClinicianData(null);
        }
    }, [setEditClinicianData])

    useEffect(() => {
        getClinicians();
    }, [getClinicians])

    return (
        <div className="page-content mx-2 pb-2">
            <div className="card justify-content-between pr-20px pl-20px pt-20px activate-code-page">
                <div className="d-flex flex-wrap justify-content-between align-items-end h-100 mb-4">
                    <Col sm={6} lg={4} className="p-0">
                        <label className="details-caption">
                            Clinicians management
                        </label>
                    </Col>
                    <Col sm={5} lg={4} className="p-0 header-button">
                        <button
                            className="btn btn-primary call-form-button w-100  mt-1 mt-sm-0"
                            onClick={() => setIsOpenCreateClinicianModal(true)}
                        >
                            Create new clinician
                        </button>
                    </Col>
                </div>
                {isLoading &&
                    <div className="patients-table d-flex justify-content-center align-items-center">
                        <Spinner className="mx-auto" color="info"/>
                    </div>}
                {!isLoading && clinicians.length > 0 && (
                    <>
                        <section className="activate-code-table-wrap">
                            <Table className="table mb-0">
                                <TableHeader columns={cliniciansTableHeader} clickCellAction={_.noop}/>
                                <SimpleTableBody data={clinicians} structure={dataStructure}/>
                            </Table>
                        </section>
                        {cliniciansPagination?.totalCount && (
                            <section className="mt-4">
                                <PaginationTableFooter
                                    total={cliniciansPagination.totalCount}
                                    handleChangePage={handleChangePage}
                                    currentOffset={parseIntFromStr(searchQuery.offset)}
                                    limit={CLINICIANS_LIMIT}
                                />
                            </section>
                        )}
                    </>
                )}
                {!isLoading && !clinicians.length && <NoCliniciansPlaceholder/>}
            </div>
            <CreateClinicianForm
                isOpen={isOpenCreateClinicianModal}
                toggle={() => setIsOpenCreateClinicianModal(false)}
                handleSubmit={handleCreateClinician}
            />
            <CreateClinicianForm
                isEdit
                isOpen={editClinicianData}
                initialValues={editClinicianData}
                toggle={() => setEditClinicianData(null)}
                handleSubmit={handleUpdateClinician}
            />
        </div>
    )
}

export default Clinicians;