export default class StorageService {
    getValueByKey(key){
        return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;
    }

    deleteValueByKey(key){
        sessionStorage.removeItem(key);
    }

    addValueByKey(key, value){
        sessionStorage.setItem(key, JSON.stringify(value));
    }
}
