import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Col, Row} from "reactstrap";
import {AvField, AvForm} from 'availity-reactstrap-validation';

import {MAX_NOTE, MIN_NOTE} from 'constants/lengthValidity';
import {strWithNoOnlySpaces} from 'constants/validityPatterns';
import AppointmentsService from 'services/appointments';
import CallsService from 'services/calls';
import {
    ACCEPT_STATUS,
    FIT_MODAL,
    FIT_NOTE_AGREED_TYPE,
    PRESCRIPTION_AGREED_TYPE,
    PRESCRIPTION_MODAL,
    REFERAL_AGREED_TYPE,
    REFERRAL_MODAL,
    sickArray
} from 'constants/callConstants';
import {SICK_IDENTIFICATION} from 'constants/documentStatuses';
import generateBitmask from 'helpers/generateBitmask';
import {NOTES_VARIABLES} from 'constants/storageConstants';
import {BEFORE_UNLOAD} from 'constants/windowActions';
import StorageService from 'services/StorageService';
import {AutoSizeTextArea} from "components/form/AutoSizeTextArea";
import {consultationNotesFormFields} from "constants/appointment";

import CreateReferralLetter from '../letters/referralLetter';
import Prescription from '../letters/prescription';
import CreateSickLetter from '../letters/fitSickNote';

export default function ConsultationNotes({
    formRef,
    setShowFeedbackModal,
    appointment,
    setIsAgreedToShareInfo,
    documentsToFill,
    setIsSubmitting,
}) {

    const savedValues = new StorageService().getValueByKey(NOTES_VARIABLES);

    const [showDocumentModal, setShowDocumentModal] = useState('');
    const [consultationNotes, setConsultationNotes] = useState(savedValues?.notes || {
        problem: "",
        history: "",
        examination: "",
        pastMedicalHX: "",
        comments: "",
        safetyNet: "",
    });

    const agreedDocs = useMemo(() => {
        if (savedValues?.docs.length) {
            return [...savedValues?.docs, ...documentsToFill]
        }
        return documentsToFill
    }, [savedValues, documentsToFill])

    const [referralID, setReferralID] = useState(null);
    const [sickID, setSickID] = useState(null);
    const [prescriptionID, setPrescriptionID] = useState(null);

    const patientID = useMemo(() => appointment?.patient.id, [appointment]);

    const documentsOperations = {
        [REFERAL_AGREED_TYPE]: () => setShowDocumentModal(REFERRAL_MODAL),
        [FIT_NOTE_AGREED_TYPE]: () => setShowDocumentModal(FIT_MODAL),
        [PRESCRIPTION_AGREED_TYPE]: () => setShowDocumentModal(PRESCRIPTION_MODAL),
    };

    const errorMessage = `Must be from ${MIN_NOTE} to ${MAX_NOTE} symbols and cannot contain spaces only`
    const validate = {
        required: { value: true, errorMessage: errorMessage },
        pattern: { value: strWithNoOnlySpaces, errorMessage: errorMessage },
        minLength: { value: MIN_NOTE, errorMessage: errorMessage },
        maxLength: { value: MAX_NOTE, errorMessage: errorMessage }
    }

    function* openDocumentsModals(documentsArray) {
        for (let modalCounter = 0; modalCounter <= documentsArray.length - 1; modalCounter++) {
            yield documentsOperations[documentsArray[modalCounter]]();
        }
        return setShowFeedbackModal(true)
    }

    /* eslint-disable-next-line */
    const documentSequence = useMemo(() => openDocumentsModals(agreedDocs), [documentsToFill]);

    async function sendReferralLetter(values) {
        const newValues = {
            ...values,
            appointmentSlotId: appointment.id,
        }
        try {
            await CallsService.sendReferralLetter(newValues);
            setShowDocumentModal('');
            documentSequence.next();
        } finally {
            //empty
        }
    }

    async function sendSickLetter({ type, checked, ...other }) {
        const itemsForBitmask = sickArray.map((item) => !!checked.includes(item))

        const newValues = {
            ...other,
            benefits: generateBitmask(itemsForBitmask),
            isFitNote: type !== SICK_IDENTIFICATION,
            appointmentSlotId: appointment.id
        }
        try {
            await CallsService.sendSickLetter(newValues);
            setShowDocumentModal('');
            documentSequence.next();
        } finally {
            //empty
        }
    }

    async function sendPrescriptionLetter(drugs) {
        const values = {
            drugs: [...drugs],
            appointmentSlotId: appointment.id,
        }
        try {
            await CallsService.sendPrescriptionLetter(values);
            setShowDocumentModal('');
            documentSequence.next();
        } finally {
            //empty
        }
    }

    async function handleSubmitForm(event, { problem, history, pastMedicalHX, examination, comments, safetyNet }) {
        setIsSubmitting(true);

        const notes = {
            problem,
            history,
            pastMedicalHX,
            examination,
            comments,
            safetyNet
        };
        setConsultationNotes(notes);

        if (agreedDocs.includes(REFERAL_AGREED_TYPE)) {
            documentSequence.next();
            return;
        }
        try {
            const newNotes = {
                problem: notes.problem,
                history: notes.history,
                pastMedicalHX: notes.pastMedicalHX,
                examination: notes.examination,
                comments: notes.comments,
                safetyNet: notes.safetyNet,
            }
            await AppointmentsService.sendConsultation(appointment.id, newNotes);
            documentSequence.next();
        } catch {
            //empty
        } finally {
            setIsSubmitting(false);
        }
    }

    async function sendRequestForReferral() {
        try {
            const { data } = await CallsService.sendReferralRequest({ appointmentSlotId: appointment.id });
            setReferralID(data.id)
        } finally {
            //empty
        }
    }

    async function sendRequestToFitNote() {
        try {
            const { data } = await CallsService.sendFitRequest({ appointmentSlotId: appointment.id })
            setSickID(data.id)
        } finally {
            //empty
        }
    }

    async function sendRequestForPrescription() {
        try {
            const { data } = await CallsService.sendPrescriptionRequest({ appointmentSlotId: appointment.id })
            setPrescriptionID(data.id)
            if (data.status === ACCEPT_STATUS) {
                setIsAgreedToShareInfo(true);
            }
        } finally {
            //empty
        }
    }

    const saveValuesHandler = useCallback(
        () => {
            new StorageService().addValueByKey(NOTES_VARIABLES, {
                notes: consultationNotes,
                docs: agreedDocs,
                refID: savedValues?.refID ? savedValues.refID : referralID,
                sickID: savedValues?.sickID ? savedValues.sickID : sickID,
                presID: savedValues?.presID ? savedValues.presID : prescriptionID,
            });
        },
        [consultationNotes, agreedDocs, prescriptionID, sickID, referralID, savedValues],
    )

    function handleChangeConsultationValues(value, fieldName) {
        setConsultationNotes(prevState => (
            {
                ...prevState,
                [fieldName]: value,
            }
        ))
    }

    useEffect(() => {
        window.addEventListener(BEFORE_UNLOAD, saveValuesHandler);
        return () => {
            window.removeEventListener(BEFORE_UNLOAD, saveValuesHandler);
        }
    }, [saveValuesHandler]);

    return (
        <Card className="call-consultation-notes custom-design-scroll">
            <label className="consultation-label">Consultation notes</label>
            <AvForm onValidSubmit={handleSubmitForm} ref={formRef}>
                <div className="call-consultation-notes__inputs-section custom-design-scroll">
                    {consultationNotesFormFields.map(note => (
                        <React.Fragment key={note.name}>
                            <AvField
                                type="textarea"
                                rows={1}
                                name={note.name}
                                label={note.label}
                                placeholder="Additional comment"
                                validate={validate}
                                onChange={(e, v) => handleChangeConsultationValues(v, note.name)}
                                value={consultationNotes[note.name] || ""}
                                className="form-control"
                                tag={AutoSizeTextArea}
                            />
                        </React.Fragment>
                    ))}
                </div>
                <Row className="d-flex flex-wrap">
                    <Col lg={4}>
                        <button className="btn btn-primary call-form-button w-100" type="button"
                                onClick={sendRequestForReferral}>Create Referral payment
                        </button>
                    </Col>
                    <Col lg={4}>
                        <button className="btn btn-primary call-form-button w-100" type="button"
                                onClick={sendRequestToFitNote}>Create Sick payment
                        </button>
                    </Col>
                    <Col lg={4}>
                        <button type="button" className="btn btn-primary call-form-button w-100"
                                onClick={sendRequestForPrescription}>Create Prescription
                        </button>
                    </Col>
                </Row>
            </AvForm>
            {showDocumentModal === REFERRAL_MODAL &&
                <CreateReferralLetter
                    handleClose={() => setShowDocumentModal('')}
                    appointment={appointment}
                    consultationNotes={consultationNotes}
                    referralID={savedValues?.refID ? savedValues.refID : referralID}
                    patientID={patientID}
                    saveReferralLetter={sendReferralLetter}
                />}
            {showDocumentModal === PRESCRIPTION_MODAL &&
                <Prescription
                    handleClose={() => setShowDocumentModal('')}
                    prescriptionID={savedValues?.presID ? savedValues.presID : prescriptionID}
                    patientID={patientID}
                    sendPrescriptionLetter={sendPrescriptionLetter}
                    appointment={appointment}
                />}
            {showDocumentModal === FIT_MODAL &&
                <CreateSickLetter
                    handleClose={() => setShowDocumentModal('')}
                    sickID={savedValues?.sickID ? savedValues.sickID : sickID}
                    patientID={patientID}
                    sendSickLetter={sendSickLetter}
                    appointment={appointment}
                />}
        </Card>
    )
}
