import moment from 'moment';

import formatTimeString from "../helpers/formatServerSessionTime";
import formatTime from "../helpers/formatTime";

import {TIME_PICKER_STEP} from './lengthValidity';
import {timeFormat} from './timeFormatEn-GB';

const slotTimeFormat = "HH:mm:ss";

const isTimeTaken = (time, takenSlots, isEndTime) => {
    let isAvailable = true;

    for (let slot of takenSlots) {
        const start = formatTimeString(slot?.startsAt, slotTimeFormat);
        const end = formatTimeString(slot?.endsAt, slotTimeFormat);

        const formattedTime = `${formatTime(time)}:00`;

        if (isAvailable) {
            isAvailable = isEndTime
                ? (formattedTime >= end || formattedTime <= start)
                : (formattedTime >= end || formattedTime < start);

        }

    }

    return isAvailable;
}

export default function getTimeOptionsArray(takenTimeSlots, isEndTime) {
    const timeOptions = [];
    new Array(24).fill().forEach((acc, index) => {
        const timeOption = moment({ hour: index }).format(timeFormat);

        const timeOptionWithMinutes = moment({ hour: index, minute: TIME_PICKER_STEP }).format(timeFormat);

        timeOptions.push({ label: `${timeOption}`, value: `${timeOption}` });
        timeOptions.push({ label: `${timeOptionWithMinutes}`, value: `${timeOptionWithMinutes}` });
    })

    return timeOptions.filter(option => isTimeTaken(option.value, takenTimeSlots, isEndTime));
}
