import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Card } from "reactstrap";
import cancelIcon from '../../assets/images/ic-close-black.svg';
import timeIcon from '../../assets/images/bx-time-five.svg';
import moment from 'moment';
import AppointmentsService from '../../services/appointments';
import CreateCommentModal from './createCommentModal';
import { MAX_COMMENTS_COUNT } from '../../constants/lengthValidity';

export default function CommentsModal({ visibleModal, handleClose, appointment, updateTable }) {
    const [comments, setComments] = useState([]);
    const [createCommentModal, setCreateCommentModal] = useState(false);

    const isButtonDisabled = comments.length >= MAX_COMMENTS_COUNT;

    const getAppointmentComments = useCallback(
        async () => {
            try {
                const serverResponse = await AppointmentsService.getAppointmentComments(appointment.id);
                setComments(serverResponse.data);
            } catch {
                //empty
            }
        },
        [appointment],
    )

    async function handleCreateComment(e, values) {
        try {
            await AppointmentsService.addComment(appointment.id, values);
            updateTable((prevState) => {
                const currentSession = prevState.find(session => session.id === appointment.appointmentSessionId);
                const currentAppointment = currentSession.appointments.find((slot) => slot.id === appointment.id);
                currentAppointment.commentsCount = currentAppointment.commentsCount ? currentAppointment.commentsCount + 1 : 1;
                const newTable = [...prevState];
                return newTable;
            })
        } finally {
            setCreateCommentModal(false);
        }
    }

    useEffect(() => {
        if (!createCommentModal) {
            getAppointmentComments();
        }
    }, [getAppointmentComments, createCommentModal])

    return (
        <Modal
            isOpen={visibleModal}
            className="modal-size"
            centered={true}
            toggle={handleClose}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body modal-text-styles custom-design-scroll">
                    <header className="font-size-22 d-flex flex-row justify-content-between">Comment<img src={cancelIcon} alt="" onClick={handleClose} className="cursor-pointer" /></header>

                    <section className="mt-4 comments-modal ">
                        {comments.map((comment) => (
                            <section key={comment.id} className="d-flex flex-column mt-2" >
                                <label className="comments-admin-name">{comment.user.firstName + " " + comment.user.lastName}</label>
                                <label className="comments-text">{comment.text}</label>
                                <section className="d-flex flex-row justify-content-between w-50 mt-1">
                                    <label className="comment-time">{moment(comment.createdAt).format('D MMM, YYYY')}</label>
                                    <span>
                                        <img src={timeIcon} alt="" className="mr-1" />
                                        <label className="comment-time">{moment(comment.createdAt).format('LT')}</label>
                                    </span>
                                </section>
                            </section>
                        ))}
                    </section>

                    <section className="d-flex justify-content-center w-100 mt-5">
                        <button className="btn btn-primary w-50 " onClick={() => setCreateCommentModal(true)} disabled={isButtonDisabled}>Create comment</button>
                    </section>
                </div>

            </Card>
            <CreateCommentModal
                visibleModal={createCommentModal}
                handleSubmit={handleCreateComment}
                handleClose={() => setCreateCommentModal(false)}
            />
        </Modal>
    )
}