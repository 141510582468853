import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TabContent, TabPane, Card, CardBody, Container, Table, Spinner } from "reactstrap";
import Search from '../../components/searchField/searchField';
import TableHeader from './tableComponents/tableHeader';
import TableBody from '../../components/sessionTable/tableBody';
import PaginationTableFooter from './tableComponents/paginationFooter';
import filterIcon from '../../assets/images/ic-filter-white.svg';
import tablePlaceholder from '../../assets/images/im-placeholder-table.svg';
import { useHistory, useLocation } from 'react-router-dom';
import Tabs from '../../components/tabPane';
import PatientManagementService from '../../services/patientsManagement';
import { PATIENTS_LIMIT } from '../../constants/paginationLimits';
import {
    childStructure,
    defaultStructure,
    tableHeaderChecked,
    tableHeaderWaiting
} from './tableComponents/patientTableDataStructure';
import qs from 'qs';
import queryParser from '../../helpers/queryStringParser';
import * as _ from 'lodash';
import { ASC, DESC } from '../../constants/sortTypes';
import parseIntFromStr from '../../helpers/parseIntFromStr';
import Filters from './tableComponents/filters';
import { WAITING_CONFIRMATION, CHECKED, WAITING_CONFIRMATION_v2, PatientManagementTabs } from '../../constants/patientMagagementTypes';
import NestedTableBody from "./tableComponents/nestedTableBody";

export default function PatientManagement() {
    const history = useHistory();
    const location = useLocation();

    const searchQuery = useMemo(() => queryParser(location.search), [location.search]);

    const { ageType = [], isBlocked = [], addingDateTypes = [], isWaiting = [] } = searchQuery;
    const [patients, setPatients] = useState([]);
    const [servicePagination, setServicePagination] = useState({});
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        ageType,
        isBlocked,
        addingDateTypes,
        isWaiting,
    });

    const filtersLength = useMemo(() => getFiltersCount(filters), [filters]);

    function getFiltersCount(filtersObject) {
        const count = Object.values(filtersObject).flat().length;
        return count;
    }

    function handleClickOnRow(row) {
        history.push(`/patients-management/patients?id=${row.id}`);
    }
    function handleClickOnChildRow(row, childRow) {
        history.push(`/patients-management/patients/child?id=${childRow?.id}&parentId=${row?.id}`);
    }

    function handleClickCell(row) {
        if (!row.sort) {
            return;
        }
        const sortQuery = {
            orderBy: row.sort,
            orderType: ASC,
        }
        if (row.sort === searchQuery.orderBy) {
            sortQuery.orderType = searchQuery.orderType === DESC ? ASC : DESC;
        }
        setNewSearchQuery(sortQuery);
    }

    function toggleCustomJustified(tab) {
        setIsDataLoading(true)
        resetFilters();
        history.push({
            search: qs.stringify({ documentCheckStatus: tab, offset: 0 })
        })
    }

    function handleSearch(string) {
        setNewSearchQuery({ query: string });
    }

    function handleChangePage({ selected }) {
        if (!isNaN(selected)) {
            setNewSearchQuery({ offset: selected * PATIENTS_LIMIT });
        }
    }

    function setNewFilters(newFilters) {
        setFilters(newFilters)
        setNewSearchQuery(newFilters)
        setShowFilters(false)
    }

    function setNewSearchQuery(incomeQuery) {
        const newQuery = {
            ...searchQuery,
            ...incomeQuery,
        }
        const query = qs.stringify(newQuery);
        history.push({
            search: query,
        });
    }

    function transformQuery({ ageType = [], isBlocked = [], ...other }) {
        const query = {
            ...other
        }
        if (ageType.length === 1) {
            query.ageType = ageType[0];
        }
        if (isBlocked.length === 1) {
            query.isBlocked = !!parseIntFromStr(isBlocked[0]);
        }
        return query;
    }

    const getPatients = useCallback(
        async () => {
            setIsDataLoading(true)
            const queryData = {
                ...searchQuery,
                limit: PATIENTS_LIMIT,
                offset: _.get(searchQuery, "offset", 0),
                orderBy: _.get(searchQuery, "orderBy", "createdAt"),
                orderType: _.get(searchQuery, "orderType", "desc"),
                query: _.get(searchQuery, "query", ""),
            }
            if (searchQuery.isWaiting) {
                queryData.documentCheckStatus = searchQuery.isWaiting;
            } else {
                queryData.documentCheckStatus = [WAITING_CONFIRMATION, WAITING_CONFIRMATION_v2];
            }
            if (parseIntFromStr(searchQuery.documentCheckStatus) === CHECKED) {
                queryData.documentCheckStatus = [CHECKED];
                queryData.showDeleted = true;
            }
            delete queryData.isWaiting;
            try {
                const patientsResponse = await PatientManagementService.getPatients(transformQuery(queryData));
                setServicePagination(patientsResponse.pagination);
                setPatients(patientsResponse.data);
            } finally {
                setIsDataLoading(false)
            }
        },
        [searchQuery],
    )

    function resetFilters() {
        setFilters({});
        const emptyQuery = {
            offset: _.get(searchQuery, "offset", 0),
            documentCheckStatus: _.get(searchQuery, "documentCheckStatus", 1),
        }
        history.push({
            search: qs.stringify(emptyQuery),
        })
    }

    useEffect(() => {
        getPatients();
    }, [getPatients]);

    return (
        <div className="page-content">
            <Container fluid>
                <Tabs tabs={PatientManagementTabs} activeTab={parseIntFromStr(_.get(searchQuery, "documentCheckStatus", WAITING_CONFIRMATION))} toggleCustomJustified={toggleCustomJustified} />

                <Card className="mt-2 patients-table">
                    <div className="pt-4 pb-0 d-flex flex-row justify-content-between mx-4 search-section">
                        <Search handleSearch={handleSearch} options={patients} initialValue={_.get(searchQuery, "query", "")} />
                        <section>
                            {!!filtersLength && <button className="btn btn-outline text-primary mr-4" onClick={resetFilters}>Reset Filter</button>}
                            <button className="btn btn-primary filter-option position-relative"
                                onClick={() => setShowFilters(true)}
                            >
                                <img src={filterIcon} alt="" className="filter-icon" />Filter
                            {!!filtersLength && <div className="filters-count">{filtersLength}</div>}
                            </button>
                        </section>
                    </div>

                    {!isDataLoading ? !!patients.length &&
                        <CardBody className="mt-0 pt-0 h-100">
                            <TabContent activeTab={parseIntFromStr(_.get(searchQuery, "documentCheckStatus", WAITING_CONFIRMATION))} className="h-100">
                                <TabPane tabId={WAITING_CONFIRMATION} className="p-2 h-100">
                                    <section className="d-flex flex-column justify-content-between">
                                        <section>
                                            <div className="table-responsive mt-2">
                                                <div className="border-top table-text">
                                                    <Table className="table mb-0 h-100">
                                                        <TableHeader columns={tableHeaderWaiting} clickCellAction={handleClickCell} />
                                                        <TableBody data={patients} structure={defaultStructure} clickRowAction={handleClickOnRow} />
                                                    </Table>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="mt-5">
                                            <PaginationTableFooter total={servicePagination.totalCount} handleChangePage={handleChangePage} currentOffset={parseIntFromStr(searchQuery.offset)} limit={PATIENTS_LIMIT} />
                                        </section>
                                    </section>
                                </TabPane>
                                <TabPane tabId={CHECKED} className="p-2 patients-table">
                                    <section className="d-flex flex-column justify-content-between">
                                        <section>
                                            <div className="table-responsive mt-2">
                                                <div className="border-top table-text">
                                                    <Table className="table mb-0 h-100">
                                                        <TableHeader columns={tableHeaderChecked} clickCellAction={handleClickCell} />
                                                        <NestedTableBody
                                                            data={patients}
                                                            structure={defaultStructure}
                                                            childrenFieldName="children.data"
                                                            childrenStructure={childStructure}
                                                            childRowClassName="child-row"
                                                            clickChildRowAction={handleClickOnChildRow}
                                                            clickRowAction={handleClickOnRow}
                                                        />
                                                    </Table>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="mt-5">
                                            <PaginationTableFooter total={servicePagination.totalCount} handleChangePage={handleChangePage} currentOffset={parseIntFromStr(searchQuery.offset)} limit={PATIENTS_LIMIT} />
                                        </section>
                                    </section>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                        :
                        <div className="patients-table d-flex justify-content-center align-items-center">
                            <Spinner className="mx-auto" color="info" />
                        </div>
                    }
                    {!patients.length &&
                        <section className="d-flex flex-column align-items-center patients-placeholder">
                            <img src={tablePlaceholder} alt="" />
                            <label className="patients-placeholder-label">There is no information yet</label>
                        </section>
                    }
                </Card>
            </Container>
            {showFilters &&
                <Filters visibleModal={showFilters}
                    applyFilters={setNewFilters}
                    handleClose={() => setShowFilters(false)}
                    activeTab={parseIntFromStr(_.get(searchQuery, "documentCheckStatus", 1))}
                    searchQuery={searchQuery}
                />
            }
        </div>
    )
}