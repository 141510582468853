import React from 'react'
import { Card } from "reactstrap";
import './infoPagesStyles.scss';

export default function PrivacyPolicy() {
    return (
        <div className="info-page">
            <Card className="info-page-card">
                <div>
                    <h1 className="info-page-header">Privacy Policy</h1>
                    <h5 className="info-page-subheader mt-4">Medium length headline</h5>
                    <section className="mt-4">
                        <article className="info-page-text">Dolor est non id non incididunt sunt.
                        Duis est culpa tempor incididunt velit ipsum excepteur id incididunt labore aliqua. Consectetur ad mollit aliqua incididunt.
                        Lorem elit nostrud mollit dolor magna nulla adipisicing exercitation incididunt minim ut magna.
                        Deserunt voluptate irure consectetur mollit sint ipsum ipsum consectetur esse et aute.

                        Ullamco proident et minim amet ullamco Lorem amet.
                        Exercitation elit id nostrud mollit esse ad officia duis duis pariatur exercitation deserunt aliquip et.
                        Adipisicing excepteur id veniam labore ea duis laboris ipsum.
                <br />
                            <br />
                Tempor veniam aute veniam pariatur aliquip anim ad enim pariatur.
                Et exercitation laboris dolore occaecat culpa reprehenderit eiusmod esse dolor aute labore cillum.
                Commodo enim in in ipsum anim Lorem duis aliqua est ad ipsum reprehenderit irure.

                Esse do elit enim consectetur non consequat culpa nulla.
                Id commodo sit anim incididunt eiusmod eiusmod deserunt aliquip ullamco non.
                Voluptate tempor in veniam aute cillum aliquip occaecat sunt ullamco labore occaecat elit veniam occaecat.
                Do do occaecat cupidatat voluptate non nostrud dolore sunt et proident.
                Cupidatat sit ipsum culpa labore reprehenderit ullamco in occaecat consequat eu et cillum pariatur minim.
                </article>
                        <h5 className="info-page-subheader mt-5">Medium length headline</h5>
                        <article className="info-page-text mt-2">
                            Non eu non est deserunt. Sint culpa consequat est ea dolor aliquip laborum nisi sit.
                            Magna nisi id aliqua ipsum magna dolore nostrud laborum mollit fugiat duis minim.
                            Ea exercitation laboris mollit eu minim nisi elit mollit ea.
                            Aliqua nostrud anim aliqua mollit deserunt non minim dolore.
                            Reprehenderit laboris fugiat id labore enim dolore commodo anim aliqua ullamco magna tempor eu eiusmod.
                            Enim pariatur dolore proident aliquip.
                <br />
                            <br />
                Elit esse aute amet ad nisi dolore amet sit. Commodo anim nostrud ea quis aliqua nostrud laboris minim sunt.
                Labore non pariatur adipisicing anim labore laboris. Laboris veniam ipsum in cillum tempor qui mollit veniam
                cupidatat cupidatat laborum culpa magna. Deserunt aliquip consectetur commodo ut magna.

                Cillum aute cupidatat laborum exercitation Lorem eiusmod ut magna exercitation tempor magna deserunt eu elit.
                Sint sit qui velit pariatur anim esse deserunt proident voluptate minim. In commodo nostrud incididunt ad in sit tempor
                esse ea adipisicing do ipsum sit commodo.
                <br />
                            <br />
                Aliquip adipisicing ut laborum minim. Dolor adipisicing sint est magna laborum ut cillum mollit fugiat eiusmod veniam.
                Excepteur eu amet eu quis velit aliqua non minim magna reprehenderit. Consequat veniam culpa tempor duis sit ea magna
                consectetur quis occaecat laboris.

                Elit laboris adipisicing quis proident magna dolore deserunt ipsum. Irure occaecat fugiat aliqua fugiat occaecat Lorem
                duis do irure incididunt aute in. Pariatur anim eiusmod ex aliqua cillum quis commodo. Lorem ad occaecat dolor nostrud.
                Non tempor duis in aliqua dolor. Dolore voluptate sunt anim quis pariatur aliquip laboris qui. Reprehenderit ullamco ea
                qui reprehenderit.
                <br />
                            <br />
                Minim culpa aliquip incididunt occaecat aliquip veniam officia. Aliquip nisi pariatur magna enim elit adipisicing
                consectetur duis sunt id adipisicing labore culpa. Sint laboris nulla minim do consectetur anim veniam ipsum culpa occaecat
                pariatur esse.
                <ul className="pl-4">
                                <br />
                                <li>
                                    Elit laboris adipisicing quis proident magna dolore deserunt ipsum. Irure occaecat fugiat aliqua fugiat occaecat Lorem
                                    duis do irure incididunt aute in. Elit laboris adipisicing quis proident magna dolore deserunt ipsum. Irure occaecat fugiat aliqua fugiat occaecat Lorem
                                    duis do irure incididunt aute in. Pariatur anim eiusmod ex aliqua cillum quis commodo. Lorem ad occaecat dolor nostrud.
                                    Non tempor duis in aliqua dolor. Dolore voluptate sunt anim quis pariatur aliquip laboris qui. Reprehenderit ullamco ea
                                    qui reprehenderit.
                        </li>
                                <br />
                                <li>
                                    Minim culpa aliquip incididunt occaecat aliquip veniam officia. Aliquip nisi pariatur magna enim elit adipisicing
                                    consectetur duis sunt id adipisicing labore culpa. Sint laboris nulla minim do consectetur anim veniam ipsum culpa occaecat
                                    pariatur esse.
                        </li>
                            </ul>
                        </article>

                    </section>
                </div >
            </Card>
        </div>
    );
}
