import React, {useEffect, useState} from 'react';
import Select, {components} from 'react-select';

import searchIcon from 'assets/images/ic-search-gray.svg';

const { Input, Option, Menu, Placeholder } = components;

const DropdownIndicator = () => {
    return null;
};

const IndicatorSeparator = ({ innerProps }) => {
    return <span {...innerProps} />;
};

const customStyle = {
    control: (styles, { isDisabled}) => ({
        height: 35,
        width: "100%",
        paddingLeft: '35px',
        border: "1px solid #ced4da",
        borderRadius: '0.25rem',
        color: '#495057',
        fontSize: '0.8125rem',
        fontWeight: 400,
        lineHeight: 1.5,
        display: 'block',
        backgroundClip: 'padding-box',
        transition: 'border-color 0.15s ease-in -out, box-shadow 0.15s ease-in -out',
        backgroundColor: isDisabled ? "#f2f2f2" : "transparent"
    }),
    menu: styles => ({
        ...styles,
        width: "100%",
        maxHeight: 250,
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: 250,
    }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 0,
    }),
    placeholder: styles => ({
        ...styles,
        color: "#788195",
    }),
}

const CustomInput = props => {
    if (props.isHidden) {
        return <Input {...props} />;
    }
    return (
        <div className="search-input">
            <Input {...props} />
        </div>
    );
};

const CustomOption = props => {
    return (
        <div>
            <Option {...props} />
        </div>
    );
};

const NoOptionsMessage = () => {
    return (
        <div className="search-options my-2 ml-2">
            No options
        </div>
    );
};

const IndicatorsContainer = () => {
    return null;
};

const CustomMenu = props => {
    return (
        <div className="custom-design-scroll">
            <Menu {...props}>{props.children}</Menu>
        </div>
    );
};

const CustomPlaceholder = props => {
    return <Placeholder {...props} />;
};

export const CustomSearchInput = ({
    handleSearch,
    options = [],
    initialValue,
    handleChange,
    placeholder = "Search...",
    customStyles = customStyle,
    isDisabled = false
}) => {
    const [query, setQuery] = useState(initialValue);

    useEffect(() => setQuery(initialValue), [initialValue])

    function onInputChange(inputValue, action) {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setQuery(inputValue)
            handleSearch(inputValue)
        }
    }

    return (
        <span className="d-flex flex-row align-items-center custom-search-input">
            <img src={searchIcon} alt="" className="search-icon"/>

            <Select
                closeMenuOnSelect={true}
                styles={customStyles}
                className="custom-design-scroll w-100"
                components={{
                    DropdownIndicator,
                    IndicatorSeparator,
                    Placeholder: CustomPlaceholder,
                    Input: CustomInput,
                    Option: CustomOption,
                    NoOptionsMessage,
                    IndicatorsContainer,
                    Menu: CustomMenu
                }}
                options={options}
                onChange={handleChange}
                value={query}
                onInputChange={onInputChange}
                placeholder={placeholder}
                isDisabled={isDisabled}
            />
        </span>
    )
}
