import React, { useMemo } from 'react';
import { Row, Col, Card, CardBody, Container } from "reactstrap";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// action
import Button from '../../components/buttons/button';
// import images
import changePasswordLogo from "../../assets/images/changePasswordLogo.svg";
import AvatarLogo from '../../components/avatar/avatarLogo';
//import toasts
import toastr from '../../components/toastr';
import { EMAIL_MAX_LENGTH } from '../../constants/lengthValidity';
import PatientVerificationService from '../../services/patientVerification';

const ResendEmailLink = () => {

    async function handleValidSubmit(event, values) {
        try {
            await PatientVerificationService.resendEmailLink(values);
            toastr.success("A verification link was sent to your email address");
        } catch {
            //empty
        }
    }

    const emailValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: "Invalid email format" },
            email: { value: true, errorMessage: "Invalid email format" },
            maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: "Invalid email format" },
        }
    ), []);

    return (
        <div className="account-pages my-5 pt-sm-5">
            <Container className="mt-5 pt-5">
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden card-auth">
                            <div className="form-caption-auth">
                                <Row className="h-100 w-100">
                                    <Col className="col-7">
                                        <div className="text-primary p-4">
                                            <h5 className="text-primary">Resend verification link</h5>
                                            <span className="text-nowrap">Enter your email address</span>
                                        </div>
                                    </Col>
                                    <Col className="col-5 align-self-end caption-profile-img">
                                        <img src={changePasswordLogo} alt="" height="113" />
                                    </Col>
                                    <AvatarLogo />
                                </Row>
                            </div>
                            <CardBody className="pt-0 pb-0 mt-4">
                                <div className="p-2">
                                    <AvForm
                                        className="form-horizontal mt-4"
                                        onValidSubmit={(event, values) => handleValidSubmit(event, values)}
                                    >
                                        <div className="form-group">
                                            <AvField
                                                name="email"
                                                label="Your email"
                                                className="form-control mb-0"
                                                placeholder="Enter your email"
                                                type="email"
                                                validate={emailValidationRules}
                                            />
                                        </div>
                                        <Row className="form-group">
                                            <Col className="text-right">
                                                <Button className="primary" text="Resend link" disabled={false} />
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ResendEmailLink;
