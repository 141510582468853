import React, { useEffect } from 'react';

// MetisMenu
import MetisMenu from "metismenujs";
import { NavLink, withRouter } from "react-router-dom";


import { sidebarLinks } from '../../constants/sidebarLinks';
import Icon from '../../components/Icon';
//i18n
import { withNamespaces } from 'react-i18next';

const SidebarContent = (props) => {
    useEffect(() => {

        const pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            let matchingMenuItem = null;
            const ul = document.getElementById("side-menu");
            const items = ul.getElementsByTagName("a");
            for (let i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [props.location.pathname]);

    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    }

    return (
        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled custom-sidebar-menu" id="side-menu">
                    <li className="menu-title">{props.t('Menu')}</li>
                    {sidebarLinks.map((link) => {
                        return (
                            <li key={link.to}>
                                <NavLink to={link.to} className="waves-effect sidebar-link" activeClassName="active-sidebar-link">
                                    <Icon icon={link.icon} isActive={link.to === props.location.pathname} />
                                    <span className="ml-2">{link.label}</span>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withNamespaces()(SidebarContent));


