import React, { useState, useMemo } from 'react';
import { Col, CardBody, Card, Container, Alert } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import InputWithEyeOption from '../../components/passwordFieldWithEye/passwordFieldWithEye';
import AuthentificationService from '../../services/authentification';
// import images
import { passwordValidation } from '../../constants/validityPatterns';
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../../constants/lengthValidity';
import { passwordDoesntMatch, passwordRequired, currentPasswordRequired, passwordLength, passwordContains } from '../../constants/errorMessages';

export default function Settings() {
    const [successRequestMessage, setSuccessRequestMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const oldPasswordValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: currentPasswordRequired },
        }
    ), []);

    const passwordValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: passwordRequired },
            maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: passwordLength },
            minLength: { value: PASSWORD_MIN_LENGTH, errorMessage: passwordLength },
            pattern: { value: passwordValidation, errorMessage: passwordContains },
        }
    ), []);

    const passwordConfirmValidationRules = useMemo(() => (
        {
            required: { value: true, errorMessage: passwordDoesntMatch },
            match: { value: "newPassword", errorMessage: passwordDoesntMatch }
        }
    ), []);

    async function handleValidSubmit(e, values) {
        setIsSubmitting(true);
        try {
            await AuthentificationService.changePasswordUseOldPassword({
                password: values.password,
                newPassword: values.newPassword,
            })
            setSuccessRequestMessage(true);
        } finally {
            setIsSubmitting(false);
        }
    }

    return (
        <div className="page-content account-pages">
            <Container fluid>
                <Col lg={6} xl={4}>
                    <Card className="card-auth overflow-hidden">
                        <CardBody className="">
                            <label className="details-caption p-2">Change password</label>
                            <div className="p-2">
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}
                                >
                                    <div className="form-group position-relative">
                                        <InputWithEyeOption
                                            label="Old password"
                                            placeholder="Enter old password"
                                            validate={oldPasswordValidationRules}
                                            name="password"
                                        />
                                    </div>

                                    <div className="form-group position-relative">
                                        <InputWithEyeOption
                                            label="New password"
                                            placeholder="Enter new password"
                                            validate={passwordValidationRules}
                                            name="newPassword"
                                        />
                                    </div>

                                    <div className="form-group position-relative">
                                        <InputWithEyeOption
                                            label="Confirm Password"
                                            placeholder="Enter confirm password"
                                            validate={passwordConfirmValidationRules}
                                            name="confirmPassword"
                                        />
                                    </div>
                                    <section className="w-100 d-flex justify-content-center">
                                        <button className="btn btn-primary w-75 mt-2" disabled={isSubmitting}>Change password</button>
                                    </section>

                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                    {successRequestMessage ? <Alert color="success"><span className="success-alert-message">Password successfully changed</span></Alert> : null}
                </Col>
            </Container>
        </div>
    );
}