import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import StorageService from "../../../services/StorageService";
import { USER, SESSION } from '../../../constants/storageConstants';
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';
import AuthentificationService from '../../../services/authentification';
import UserDevicesService from '../../../services/userDevices';

const storageService = new StorageService();

function* loginUser({ payload: { userValues, history } }) {
    try {
        const newUserValues = { ...userValues };
        const { data: { user, session } } = yield call([AuthentificationService, AuthentificationService.login], newUserValues);
        yield put(loginSuccess({ user, session }));
        storageService.addValueByKey(USER, user);
        storageService.addValueByKey(SESSION, session);
        import('../../../services/firebase').then((firebase) => {
            const messaging = firebase.default.messaging();
            messaging.getToken({ vapidKey: `${process.env.REACT_APP_VAPID_KEY}` }).then((currentToken) => {
                if (currentToken) {
                    UserDevicesService.addDevice(currentToken);
                }
            }).catch(() => {
                // ...
            });
        });
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        yield call([AuthentificationService, AuthentificationService.logout]);
        yield put(logoutUserSuccess())
        storageService.deleteValueByKey(SESSION);
        storageService.deleteValueByKey(USER);
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default authSaga;