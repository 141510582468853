import React from "react";
import {Card, Modal} from "reactstrap";
import {ErrorMessage, Formik} from "formik";

import closeIcon from "assets/images/ic-close-black.svg";
import addPhotoIcon from "assets/images/addPhotoIcon.svg";
import Select from "components/Select";
import DropzoneFile from "components/Common/dropzoneFile";
import {errorMessage} from "constants/errorMessages";
import {ALLOWED_SIGNATURE_FILE} from "constants/filesValidity";

import {addDocumentsValidationSchema, getPatientIDTypesList} from "../helpers";


export const AddDocumentsModal = ({ visibleModal, handleClose, patient, handleSaveDocuments }) => {
    const acceptFileTypes = {
        "image/*": ALLOWED_SIGNATURE_FILE.map((item) => item.replace("image/", ".")),
    }

    const addPhotoIconComponent = <img src={addPhotoIcon} alt="Add photo icon" className="mb-4"/>;

    const patientIDTypes = getPatientIDTypesList(patient);

    return (
        <Modal
            isOpen={visibleModal}
            className="big-modal add-patient-documents-modal"
            centered={true}
            toggle={handleClose}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body modal-text-styles">
                    <header className="font-size-22 d-flex flex-row justify-content-between">
                        Add document
                        <img src={closeIcon}
                             alt=""
                             onClick={handleClose}
                             className="cursor-pointer"/>
                    </header>

                    <Formik
                        initialValues={{
                            documentType: "",
                            selfie: null,
                            document: null
                        }}
                        onSubmit={handleSaveDocuments}
                        validationSchema={addDocumentsValidationSchema}
                    >
                        {({
                            isSubmitting,
                            setFieldValue,
                            handleSubmit
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <section className="d-flex flex-column mt-4 mb-2">
                                    <label className="add-patient-documents-modal__document-select-label">Choose type of the document</label>
                                 <div className="add-patient-documents-modal__document-select">
                                     <Select
                                         options={patientIDTypes}
                                         onChange={(option) => setFieldValue("documentType", option.value)}
                                     />
                                 </div>
                                    <ErrorMessage name="documentType" render={errorMessage}/>
                                </section>
                                <section className="d-flex flex-row justify-content-center">
                                    <div
                                        className="add-patient-documents-modal__dropzone-section d-flex flex-column mr-4">
                                        <DropzoneFile
                                            title="Add selfie of the patient"
                                            className="add-patient-documents-modal__dropzone"
                                            onReceiveFile={(file) => setFieldValue("selfie", file)}
                                            acceptedFormats={acceptFileTypes}
                                            iconComponent={addPhotoIconComponent}
                                        />
                                        <ErrorMessage
                                            name="selfie" render={errorMessage}/>
                                    </div>
                                    <div className="add-patient-documents-modal__dropzone-section d-flex flex-column">
                                        <DropzoneFile
                                            title="Add document photo"
                                            className="add-patient-documents-modal__dropzone"
                                            onReceiveFile={(file) => setFieldValue("document", file)}
                                            acceptedFormats={acceptFileTypes}
                                            iconComponent={addPhotoIconComponent}
                                        />
                                        <ErrorMessage
                                            name="document" render={errorMessage}/>
                                    </div>
                                </section>
                                <section
                                    className="add-patient-documents-modal__buttons d-flex flex-row justify-content-between mx-auto mt-2">
                                    <button
                                        className="btn btn-primary w-100 mt-4"
                                        disabled={isSubmitting}
                                        type="submit"
                                    >
                                        Save
                                    </button>
                                    <button
                                        className="btn btn-outline-primary w-100 mt-4"
                                        onClick={handleClose}
                                        disabled={isSubmitting}
                                        type="button"
                                    >
                                        Cancel
                                    </button>
                                </section>
                            </form>
                        )}
                    </Formik>
                </div>
            </Card>
        </Modal>
    )
};
