import { useState, useEffect } from 'react';
import { AUDIO_INPUT, VIDEO_INPUT } from '../constants/devicesKinds';
import { MS_IN_SEC } from '../constants/timeConstants';

const getMediaDevices = () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return Promise.reject("Not supported");
    }
    return navigator.mediaDevices.enumerateDevices({ audio: true, video: true });
};

const getDevicesByKind = (kind) => {
    return getMediaDevices()
        .then((devices) => {
            return devices.filter(device => device.kind === kind)
        });
};

export default function useMediaDevices(timer = false) {
    const [isAllDevices, setIsAllDevices] = useState(false);
    const [devices, setDevices] = useState({
        audio: [],
        video: [],
    });

    async function getDevices() {
        const allMicros = await getDevicesByKind(AUDIO_INPUT);
        const allCameras = await getDevicesByKind(VIDEO_INPUT);
        const allDevices = {
            audio: allMicros,
            video: allCameras,
        }
        setDevices(allDevices);

        if (allMicros.length && allCameras.length) {
            setIsAllDevices(true);
        }
        return allDevices;
    }

    useEffect(() => {
        getDevices();
        if (!timer) {
            return () => { };
        }
        const checkDevises = setInterval(async () => {
            const devices = await getDevices();
            if (devices.audio.length && devices.video.length) {
                setIsAllDevices(true);
                clearInterval(checkDevises);
            } else {
                setIsAllDevices(false);
            }
        }, MS_IN_SEC);

        return () => clearInterval(checkDevises);

    }, [timer]);

    return {
        isAllDevices,
        audio: devices.audio,
        video: devices.video,
    };
}