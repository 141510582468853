import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Modal, Spinner } from 'reactstrap';
import logo from '../../../assets/images/logo.jpg';
import GeneralInfo from './generalInfo';
import PDFFooter from './pdfFooter';
import moment from 'moment';
import ModalButtons from './letterComponents/modalButtons';
import generateDocumentID from '../../../helpers/generateDocumentID';
import { REFERRAL_IDENTIFICATION } from '../../../constants/documentStatuses';
import { Formik, ErrorMessage } from 'formik';
import {
    requiredError,
    errorMessage,
    stringValidationError,
    notesLengthError,
    patientInfoValidationError
} from '../../../constants/errorMessages';
import * as yup from 'yup';
import { NAME_MAX_LENGTH, MIN_NOTE, MAX_NOTE, MAX_PATIENT_INFO } from '../../../constants/lengthValidity';
import { dateBySlash } from '../../../constants/dateFormats';
import CallsService from "../../../services/calls";
import {
    DEFAULT_CLINICIAN_FIRST_NAME,
    DEFAULT_CLINICIAN_GMC,
    DEFAULT_CLINICIAN_LAST_NAME,
    DEFAULT_CLINICIAN_SIGNATURE
} from "../../../constants/defaultDoctor";

const schema = yup.object().shape({
    letterRecipient: yup
        .string(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .trim(stringValidationError),
    patientInfo: yup
        .string(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, patientInfoValidationError)
        .max(MAX_PATIENT_INFO, patientInfoValidationError)
        .trim(patientInfoValidationError),
    problem: yup
        .string(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, notesLengthError)
        .max(MAX_NOTE, notesLengthError)
        .trim(notesLengthError),
    history: yup
        .string(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, notesLengthError)
        .max(MAX_NOTE, notesLengthError)
        .trim(notesLengthError),
    pastMedicalHX: yup
        .string(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, notesLengthError)
        .max(MAX_NOTE, notesLengthError)
        .trim(notesLengthError),
    examination: yup
        .string(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, notesLengthError)
        .max(MAX_NOTE, notesLengthError)
        .trim(notesLengthError),
    comments: yup
        .string(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, notesLengthError)
        .max(MAX_NOTE, notesLengthError)
        .trim(notesLengthError),
    safetyNet: yup
        .string(requiredError)
        .typeError(requiredError)
        .min(MIN_NOTE, notesLengthError)
        .max(MAX_NOTE, notesLengthError)
        .trim(notesLengthError),
});

export default function CreateReferralLetter({
    consultationNotes = {},
    referralID,
    patientID,
    appointment,
    saveReferralLetter,
    sendReferralLetter,
    patient = null,
    onClose = null,
    submitButton = "Send",
    isConsultationRequired = true
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [isSendRequired, setIsSendRequired] = useState(false);

    const initialValues = {
        letterRecipient: "",
        patientInfo: "",
        problem: isConsultationRequired ? consultationNotes?.problem : "",
        history: isConsultationRequired ? consultationNotes?.history : "",
        pastMedicalHX: isConsultationRequired ? consultationNotes?.pastMedicalHX : "",
        examination: isConsultationRequired ? consultationNotes?.examination : "",
        comments: isConsultationRequired ? consultationNotes?.comments : "",
        safetyNet: isConsultationRequired ? consultationNotes?.safetyNet : "",
    }
    const [initialState, setInitialState] = useState(initialValues)

    const documentID = useMemo(() => generateDocumentID(REFERRAL_IDENTIFICATION, referralID, patientID), [referralID, patientID]);

    const doctorsName = appointment?.doctor?.firstName ? `${appointment?.doctor?.firstName} ${appointment?.doctor?.lastName || ""}`
        : `${DEFAULT_CLINICIAN_FIRST_NAME} ${DEFAULT_CLINICIAN_LAST_NAME}`


    const doctorsInfo = [
        { label: "Doctor’s name:", value: `Dr ${doctorsName}` },
        { label: "GMC:", value: appointment?.doctorInfo?.gmc || DEFAULT_CLINICIAN_GMC },
    ]

    const appointmentInfo = [
        {
            label: "Appointment date:",
            value: moment(appointment.date).format(dateBySlash)
        },
        {
            label: "Patient’s name:",
            value: `${patient?.firstName || appointment?.patient?.firstName} ${ patient?.lastName || appointment?.patient?.lastName}`
        },
        {
            label: "Patient’s address:",
            value: patient?.address?.address || appointment?.patient?.address?.address
        },
        {
            label: "Date of birth:",
            value: moment(patient?.birthday || appointment?.patient?.birthday).format(dateBySlash)
        },
    ]

    const getFormData = useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await CallsService.getReferralLetter(referralID);
            const {
                letterRecipient = initialState.letterRecipient,
                patientInfo = initialState.patientInfo,
                problem = initialState.problem,
                history = initialState.history,
                pastMedicalHX = initialState.pastMedicalHX,
                examination = initialState.examination,
                comments = initialState.comments,
                safetyNet = initialState.safetyNet,
            } = data || {};

            setInitialState(prevState => ({
                ...prevState,
                letterRecipient,
                patientInfo,
                problem,
                history,
                pastMedicalHX,
                examination,
                comments,
                safetyNet,
            }));
        } catch (e) {
            setInitialState(initialValues);
        } finally {
            setIsLoading(false);
        }
    }, [appointment, setInitialState])

    const submitForm = useCallback(async (values, ...rest) => {
        try {
            setFormSubmitting(true);
            await saveReferralLetter({
                ...values,
                referralLetterId: referralID,
            }, ...rest)
            if (isSendRequired) {
                await sendReferralLetter(
                    appointment.id,
                    referralID,
                )
                setIsSendRequired(false);
            }
        } finally {
            setFormSubmitting(false);
        }
    }, [setFormSubmitting, referralID, isSendRequired])

    useEffect(() => {
        getFormData();
    }, [getFormData])

    return (
        <Modal
            isOpen={true}
            className="pdf-modal"
        >
            {isLoading && (
                <div className="patients-table d-flex justify-content-center align-items-center">
                    <Spinner className="mx-auto" color="info" />
                </div>
            )}
            {!isLoading && (
                <Formik
                    initialValues={{
                        ...initialState
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true)
                        submitForm(values)
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <form onSubmit={handleSubmit} className="">
                            <div className="p-5 h-100 w-100 modal-container">
                                <label className="ml-3 mt-2 font-size-22 font-weight-normal">Create referral letter</label>
                                <div className="pdf-header mt-2  ml-3 w-100" />
                                <Card className="ml-3 pdf-container mb-0 pt-0 pb-4">
                                    <section className="d-flex flex-row justify-content-between mt-5">
                                        <img src={logo} alt="" width="190" height="98" />
                                        <section className="d-flex flex-column align-items-end font-size-16">
                                            <label>tapGP limited</label>
                                            <label>6th Floor</label>
                                            <label>Amp House</label>
                                            <label>London</label>
                                            <label>CR0 2LX</label>
                                        </section>
                                    </section>

                                    <input
                                        type="text"
                                        name="letterRecipient"
                                        placeholder=""
                                        className="form-control mt-2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.letterRecipient}
                                    />
                                    <ErrorMessage name="letterRecipient" render={errorMessage} />

                                    <GeneralInfo info={appointmentInfo} />

                                    <textarea
                                        rows={1}
                                        name="patientInfo"
                                        className="form-control document-field-input mt-4"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.patientInfo}
                                    />
                                    <ErrorMessage name="patientInfo" render={errorMessage} />

                                    <label className="pdf-label-ask mt-4">Please see my consultations notes below</label>

                                    <label className="mt-2 document-field-label">Problem</label>
                                    <textarea
                                        rows={1}
                                        name="problem"
                                        className="form-control document-field-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.problem}
                                    />
                                    <ErrorMessage name="problem" render={errorMessage} />

                                    <label className="mt-2 document-field-label">History</label>
                                    <textarea
                                        rows={5}
                                        name="history"
                                        className="form-control document-field-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.history}
                                    />
                                    <ErrorMessage name="history" render={errorMessage} />

                                    <label className="mt-2 document-field-label">Past medical Hx</label>
                                    <textarea
                                        rows={1}
                                        name="pastMedicalHX"
                                        className="form-control document-field-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.pastMedicalHX}
                                    />
                                    <ErrorMessage name="pastMedicalHX" render={errorMessage} />

                                    <label className="mt-2 document-field-label">Examination</label>
                                    <textarea
                                        rows={1}
                                        name="examination"
                                        className="form-control document-field-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.examination}
                                    />
                                    <ErrorMessage name="examination" render={errorMessage} />

                                    <label className="mt-2 document-field-label">Comments</label>
                                    <textarea
                                        rows={1}
                                        name="comments"
                                        className="form-control document-field-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.comments}
                                    />
                                    <ErrorMessage name="comments" render={errorMessage} />

                                    <label className="mt-2 document-field-label">Safety net</label>
                                    <textarea
                                        rows={1}
                                        name="safetyNet"
                                        className="form-control document-field-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.safetyNet}
                                    />
                                    <ErrorMessage name="safetyNet" render={errorMessage} />

                                    <section className="d-flex flex-row mb-0">
                                        <label className="pdf-label-colleague">Yours faithfully,</label>
                                        <img
                                            src={appointment?.doctorInfo?.link || DEFAULT_CLINICIAN_SIGNATURE}
                                            alt="signature"
                                            className="pdf-label-colleague__signature"
                                        />
                                    </section>

                                    <GeneralInfo info={doctorsInfo} />
                                </Card>

                                <PDFFooter id={documentID} text="Referral letter ID" />

                                <ModalButtons
                                    handleSubmit={handleSubmit}
                                    handleCancel={onClose}
                                    submitButtonName={submitButton}
                                    disabledSubmit={formSubmitting}
                                    disabledCancel={formSubmitting}
                                    isLoading={!isSendRequired && formSubmitting}
                                    isSendLoading={isSendRequired && formSubmitting}
                                    disabledSend={formSubmitting}
                                    handleSend={(e) => {
                                        setIsSendRequired(true);
                                        handleSubmit(e);
                                    }}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            )}
        </Modal>
    );
}



