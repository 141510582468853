import React from 'react';
import { Modal, Card } from "reactstrap";
import cancelIcon from '../../assets/images/ic-close-black.svg';

export default function ConfirmModal({ visibleModal, handleSubmit, handleClose, text, header }) {
    return (
        <Modal
            isOpen={visibleModal}
            className="modal-size"
            centered={true}
            toggle={handleClose}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body modal-text-styles">
                    <header className="font-size-22 d-flex flex-row justify-content-between">{header}<img src={cancelIcon} alt="" onClick={handleClose} className="cursor-pointer" /></header>
                    <p className="mt-4 w-100 pr-2">
                        {text}
                    </p>
                    <section className="d-flex flex-row justify-content-between">
                        <button className="btn btn-primary w-100 mt-4 mr-3" onClick={handleSubmit}>Yes</button>
                        <button className="btn btn-outline-primary w-100 mt-4 ml-3" onClick={handleClose}>No</button>
                    </section>
                </div>

            </Card>
        </Modal>
    )
}