import {
    ALLOWED_EXTENSIONS_FILE,
    ERROR_ALLOWED_EXTENSION_MESSAGE,
    ERROR_SIZE_MESSAGE,
    MAX_FILE_SIZE
} from "../../constants/filesValidity";

const validateFile = (file, setError) => {
    if (file.size > MAX_FILE_SIZE) {
        setError(ERROR_SIZE_MESSAGE);
        return false;
    }
    if (!ALLOWED_EXTENSIONS_FILE.includes(file.type)) {
        setError(ERROR_ALLOWED_EXTENSION_MESSAGE);
        return false;
    }
    return true;
};

export default validateFile;