import React from 'react';
import { Modal, Card, Container } from "reactstrap";

import emailSent from 'assets/images/emailsSent.svg';

export const ResetPatientPasswordLinkSuccessModal = ({ visibleModal}) => {
    return (
        <Container>
            <Modal
                isOpen={visibleModal}
                className="patient-modal"
                centered={true}
            >
                <Card className="success-password-modal">
                    <div className="modal-body modal-text-styles text-center">
                        <img src={emailSent} alt="" className="emailSentLogo" />
                        <p className="text-center pt-1 mt-4 mb-0">
                            A link to reset your password was sent to your inbox
                        </p>
                    </div>
                </Card>
            </Modal>
        </Container>
    );
}

