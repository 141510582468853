import React, {useCallback, useMemo} from "react";
import {Modal} from "reactstrap";
import {ErrorMessage, Formik} from "formik";
import * as yup from "yup";

import {
    activationCodeCountError,
    activationCodeFormatError,
    activationCodeMaxTimesError,
    activationCodeMinTimesError,
    activationCodeRequiredError,
    errorMessage,
    requiredError
} from "constants/errorMessages";

import {
    MAX_ACTIVATION_CODE_COUNT,
    MAX_ACTIVATION_CODE_LENGTH,
    MAX_ACTIVATION_CODE_TIMES,
    MIN_ACTIVATION_CODE_COUNT,
    MIN_ACTIVATION_CODE_LENGTH,
    MIN_ACTIVATION_CODE_TIMES,
} from "constants/lengthValidity";

import OneOrMoreInput from "./OneOrMoreInput";
import ActivationCodeDatePicker from "./activationCodeDatePicker";
import {UploadFileSection} from "./UploadFileSection";
import { lettersAndNumbers } from "constants/validityPatterns";

const schema = yup.object().shape({
    codesCount: yup.number(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .min(MIN_ACTIVATION_CODE_COUNT, activationCodeCountError)
        .max(MAX_ACTIVATION_CODE_COUNT, activationCodeCountError),

    numberOfUses: yup.number(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .min(MIN_ACTIVATION_CODE_TIMES, activationCodeCountError)
        .max(MAX_ACTIVATION_CODE_TIMES, activationCodeCountError),

    code: yup.string(requiredError)
        .when("codesCount", {
            is: (value) => parseInt(value) <= MIN_ACTIVATION_CODE_COUNT,
            then: yup.string()
                .required(activationCodeRequiredError)
                .typeError(activationCodeRequiredError)
                .matches(lettersAndNumbers, activationCodeFormatError)
                .min(MIN_ACTIVATION_CODE_LENGTH, activationCodeMinTimesError)
                .max(MAX_ACTIVATION_CODE_LENGTH, activationCodeMaxTimesError)
        }),

    startDate: yup.string(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .trim(requiredError),

    endDate: yup.string(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .trim(requiredError),
});

const GENERATE = "Generate";
const UPDATE = "Update";

const CreateCouponForm = ({ isOpen, toggle, handleSubmit, initialValues = {}, isEdit = false}) => {

    const initialForm = {
        code: "",
        numberOfUses: "1",
        codesCount: "1",
        startDate: "",
        endDate: "",
        emails: []
    }

    const initialState = {
        ...initialForm,
        ...initialValues
    }

    const onSubmit = useCallback((values) => {
        const {
            // eslint-disable-next-line no-unused-vars
            hasAttachedEmails,
            code,
            numberOfUses,
            codesCount,
            emails,
            ...rest
        } = values;

        handleSubmit({
            ...(Number(codesCount) <= MIN_ACTIVATION_CODE_COUNT && { code }),
            ...(emails.length && { emails }),
            numberOfUses,
            codesCount,
            ...rest
        })
    }, [])

    return (
        <Modal
            isOpen={isOpen}
            centered
        >
            <Formik
                initialValues={{
                    ...initialState
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    onSubmit(values);
                    setSubmitting(false);
                }}
                validationSchema={schema}
                enableReinitialize
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                }) => {
                    const disableNumberOfUses = useMemo(() => parseInt(values.codesCount) > 1, [values.codesCount]);
                    const disableNumberOfCodes = useMemo(() => (
                        values?.code.length > 0 || values?.emails?.length
                    ), [values.code, values.emails])

                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="p-40px h-100 w-100">
                                <div>
                                    <label className="font-size-22 font-weight-normal">
                                        {`${isEdit ? UPDATE : GENERATE} activation code`}
                                    </label>
                                </div>


                                <label className="mt-2">Activation code body</label>
                                <input
                                    type="text"
                                    name="code"
                                    placeholder="Enter activation code body"
                                    className="form-control coupon-form-input w-100"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.code}
                                    disabled={disableNumberOfUses}
                                />
                                <ErrorMessage name="code" render={errorMessage}/>

                                {(!initialValues?.code || initialValues.hasAttachedEmails) && <UploadFileSection/>}

                                <OneOrMoreInput
                                    fieldName="numberOfUses"
                                    error={errors?.numberOfUses}
                                    touched={touched?.numberOfUses}
                                    value={values?.numberOfUses}
                                    setFieldValue={setFieldValue}
                                    resetTo={disableNumberOfUses ? initialForm.numberOfUses : null}
                                    initialValue={isEdit ? initialState.numberOfUses : undefined}
                                />

                                {!isEdit && (
                                    <div className="mb-2">
                                        <label className="mb-2">The number of codes</label>
                                        <input
                                            type="text"
                                            name="codesCount"
                                            placeholder="Enter the number of codes"
                                            className="form-control coupon-form-input"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.codesCount}
                                            disabled={disableNumberOfCodes}
                                        />
                                        <ErrorMessage name="codesCount" render={errorMessage}/>
                                    </div>
                                )}

                                <ActivationCodeDatePicker
                                    errors={errors}
                                    touched={touched}
                                    ComponentStart={() => (<ErrorMessage name="startDate" render={errorMessage}/>)}
                                    ComponentEnd={() => (<ErrorMessage name="endDate" render={errorMessage}/>)}
                                    currentStartDay={values?.["startDate"]}
                                    currentEndDay={values?.["endDate"]}
                                    setFieldValue={setFieldValue}
                                />
                                <section
                                    className="d-flex flex-column flex-sm-row w-100 justify-content-center modal-actions coupon-form-wrapper-buttons">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                    >
                                        {isEdit ? UPDATE : GENERATE}
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger w-100"
                                        onClick={toggle}
                                    >
                                        Cancel
                                    </button>
                                </section>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default CreateCouponForm;
