import React from 'react'
import SmallSpinner from "../../../../components/Common/SmallSpinner";

export default function ModalButtons({
    handleSubmit,
    submitButtonName = "Send",
    disabledSubmit = false,
    handleCancel = null,
    cancelButtonName = "Cancel",
    disabledCancel = false,
    handleSend = null,
    disabledSend = false,
    sendButtonName = "Send",
    isLoading = false,
    isSendLoading = false,
}) {
    return (
        <section className="mt-5 d-flex flex-row w-100 justify-content-center">
            <button
                className="btn btn-primary mr-4 pgf-button" onClick={handleSubmit}
                disabled={disabledSubmit}
            >
                {isLoading ? <SmallSpinner /> : submitButtonName}
            </button>
            {handleSend && (
                <button
                    className="btn btn-outline-primary mr-4 pgf-button" onClick={handleSend}
                    disabled={disabledSend}
                    type="button"
                >
                    {isSendLoading ? <SmallSpinner /> : sendButtonName}
                </button>
            )}
            {handleCancel && (
                <button
                    className="btn btn-danger mr-4 pgf-button" onClick={handleCancel}
                    disabled={disabledCancel}
                >
                    {cancelButtonName}
                </button>
            )}
        </section>
    )
}