import React from "react";

import thrashIcon from "assets/images/ic-thrash-red.svg";
import editIcon from "assets/images/ic-edit-black.svg";
import Icon from "components/Icon";
import {ICONS} from "constants/svgIcons";
import StorageService from "services/StorageService";
import {SESSION} from "constants/storageConstants";


const ActionsComponent = ({ item, handleEdit, handleDelete, withCSVDownloadButton }) => {
    const token = new StorageService().getValueByKey(SESSION)?.accessToken;

    return (
        <div className="d-flex flex-wrap justify-content-between align-items-center action-buttons">
            {withCSVDownloadButton && (
                <a
                    className="downloadLink"
                    title="Download CSV"
                    href={`${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_API_VERSION}/clinical-admins/activation-codes/${item.id}/csv?access_token=${token}`}
                >
                    <Icon icon={ICONS.envelopeWithArrow}/>
                </a>
            )}
            {handleEdit && <img src={editIcon} alt="" onClick={() => handleEdit(item)} className="cursor-pointer"/>}
            {handleDelete &&
                <img src={thrashIcon} alt="" onClick={() => handleDelete(item)} className="cursor-pointer"/>}
        </div>
    )
}

export default ActionsComponent;
