import {useCallback, useState} from "react";
import toastr from "toastr";

import {FIT_MODAL, PRESCRIPTION_MODAL, REFERRAL_MODAL, sickArray} from "constants/callConstants";
import {APPOINTMENTS_LIMIT} from "constants/paginationLimits";
import transformAppointmentHistory, {addActionHandlers} from "helpers/transformAppointmentHistory";
import {useUpdateChosenPatientContext} from "pages/Dashboard/tableContext";
import CallsService from "services/calls";
import generateBitmask from "helpers/generateBitmask";
import {SICK_IDENTIFICATION} from "constants/documentStatuses";
import deleteEmptyFields from "helpers/deleteEmptyFields";
import S3Service from "services/S3Service";
import PatientManagementService from "services/patientsManagement";
import AppointmentsService from "services/appointments";
import {childBuilder} from "helpers/childBuilder";
import {FILE_RESIZE_OPTIONS, UPLOAD_FILE_TYPES} from "constants/uploadFileConstants";
import formatDate from "helpers/formatDate";

const setupPatientService = (parentId = null) => {
    if (parentId) {
        return [
            async (id) => {
                const child = await PatientManagementService.getPatientChildById(id);
                const parent = await PatientManagementService.getPatientById(parentId);

                return { data: childBuilder(child?.data, parent?.data) }
            },
            async (id, params) => await PatientManagementService.getPatientsHistory(parentId, {
                ...params,
                childId: id
            }),
            async ({ fileId, userId }) => await PatientManagementService.attachPatientFile({
                fileId,
                userId: parseInt(parentId),
                childId: userId,
            })
        ]
    }

    return [
        async (id) => await PatientManagementService.getPatientById(id),
        async (id, params) => PatientManagementService.getPatientsHistory(id, params),
        async ({ fileId, userId }) => await PatientManagementService.attachPatientFile({
            fileId,
            userId,
        })
    ]
}


const useAppointmentsUtils = ({ setShowNoteModalType, searchQuery }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [patient, setPatient] = useState({});
    const [patientHistory, setPatientHistory] = useState([]);
    const [historyPagination, setHistoryPagination] = useState({});
    const [isPatientInfoLoading, setIsPatientInfoLoading] = useState(false);

    const [patientAppointments, setPatientAppointments] = useState([]);

    const [referralID, setReferralID] = useState(null);
    const [sickID, setSickID] = useState(null);
    const [currentAppointment, setCurrentAppointment] = useState(null);

    const [getPatientService, getPatientHistoryService, attachPatientFileService] = setupPatientService(searchQuery?.parentId && searchQuery.parentId);

    const updatePatient = useUpdateChosenPatientContext();

    const openReferral = useCallback(async (appointmentSlotId, refID) => {
        if (refID) {
            setReferralID(refID);
        } else {
            const { data } = await CallsService.createReferralLetter({ appointmentSlotId });
            setReferralID(data.id);
        }

    }, [setReferralID])

    const openSickFitNote = useCallback(async (appointmentSlotId, sickID) => {
        if (sickID) {
            setSickID(sickID);
        } else {
            const { data } = await CallsService.createSickLetter({ appointmentSlotId });
            setSickID(data.id);
        }
    }, [setSickID])

    const createDocumentService = {
        [REFERRAL_MODAL]: openReferral,
        [FIT_MODAL]: openSickFitNote,
    }

    const createNote = useCallback(async (selectedAppointment, noteType, docId = null) => {
        setCurrentAppointment(selectedAppointment);
        setShowNoteModalType(noteType);

        await createDocumentService[noteType]?.(
            selectedAppointment?.id,
            docId
        );

    }, [setCurrentAppointment, setShowNoteModalType, setPatient])

    const getPatient = useCallback(
        async () => {
            setIsPatientInfoLoading(true)
            try {
                const serverResponse = await getPatientService(searchQuery.id);
                setPatient(serverResponse.data);
                updatePatient({ patient: serverResponse.data })
            } finally {
                setIsPatientInfoLoading(false);
            }
        },
        [searchQuery.id, updatePatient],
    )

    const getPatientHistory = useCallback(
        async () => {
            try {
                setIsLoading(true);
                const { data, pagination } = await getPatientHistoryService(searchQuery.id, {
                    limit: APPOINTMENTS_LIMIT,
                    offset: searchQuery.offset
                });
                setPatientHistory(data.map(appointment =>
                    addActionHandlers(
                        transformAppointmentHistory(appointment),
                        {
                            [REFERRAL_MODAL]: createNote,
                            [FIT_MODAL]: createNote,
                            [PRESCRIPTION_MODAL]: createNote,
                        }
                    )
                ));
                setPatientAppointments(data)
                setHistoryPagination(pagination);
            } finally {
                setIsLoading(false);
            }
        },
        [searchQuery.offset, searchQuery.id],
    )


    const uploadFile = useCallback(
        async (file, fileType, isContentTypeRequired) => {
            try {
                const { id } = await S3Service.uploadFile(
                    file,
                    fileType,
                    isContentTypeRequired
                );

                await attachPatientFileService({
                    fileId: id,
                    userId: patient.id
                })
            } finally {
                await getPatient();
            }
        },
        [getPatient, patient]
    )

    const closeSickFitModal = useCallback(() => {
        setShowNoteModalType("");
        setSickID(null);
        getPatientHistory();
    }, [setShowNoteModalType, setSickID]);

    const closeReferralModal = useCallback(() => {
        setShowNoteModalType("");
        setReferralID(null);
        getPatientHistory();
    }, [setShowNoteModalType, setReferralID]);


    const sendReferralLetter = useCallback(async (values) => {
        const newValues = {
            ...values,
            appointmentSlotId: currentAppointment.id
        }
        try {
            const cleanNewValues = deleteEmptyFields(newValues);

            await CallsService.sendReferralLetter(cleanNewValues);
            closeReferralModal();
        } finally {
            await getPatientHistory();
        }
    }, [closeReferralModal, currentAppointment, getPatientHistory])

    const sendSickLetter = useCallback(async ({ type, checked, caseFrom, caseTo, ...other }) => {
        const itemsForBitmask = sickArray.map((item) => !!checked.includes(item));

        const benefits = generateBitmask(itemsForBitmask);
        const newValues = {
            ...other,
            ...(benefits && { benefits }),
            isFitNote: type !== SICK_IDENTIFICATION,
            appointmentSlotId: currentAppointment.id,
            caseFrom: formatDate(caseFrom),
            caseTo: formatDate(caseTo),
        }
        try {
            await CallsService.sendSickLetter(newValues);
            closeSickFitModal();
        } finally {
            await getPatientHistory();
        }
    }, [closeSickFitModal, currentAppointment, getPatientHistory])

    const generateAndSendFitSickNote = useCallback(async (appointmentID, sickID) => {
        await CallsService.generateAndSendFitSickNote({
            fitSickNoteId: sickID,
            appointmentSlotId: appointmentID,
        })
    }, [])

    const generateAndSendReferralLetter = useCallback(async (appointmentID, referralID) => {
        await CallsService.generateAndSendReferralLetter({
            referralLetterId: referralID,
            appointmentSlotId: appointmentID,
        })
    }, [])

    const deleteFile = useCallback(
        async (fileId) => {
            try {
                await PatientManagementService.deletePatientFile(fileId)
            } finally {
                await getPatient();
            }
        },
        [getPatient]
    );

    const deleteReasonFile = useCallback(
        async (fileId) => {
            try {
                await PatientManagementService.deleteReasonPatientFile(fileId)
            } finally {
                await getPatientHistory();
            }
        },
        [getPatientHistory]
    );

    const sendEmail = useCallback(async (id) => {
        toastr.info("Sending...")
        await CallsService.createAllDocuments(id);
        toastr.success("Documents sent!")
    }, []);

    const sendTrustPilot = useCallback(async (id) => {
        toastr.info("Sharing...")
        await AppointmentsService.sendTrustPilot(id);
        toastr.success("Documents shared!")
    }, []);

    const blockUnblockConsultationNotes = useCallback(async (id, isHidden) => {
        try {
            await AppointmentsService.showHideConsultationNotes(id, isHidden)
        } finally {
            getPatientHistory();
        }
    }, []);

    const handleSaveDocuments = useCallback(async (values) => {
        const isChild = Boolean(patient?.parentId);

        const selfieTypeCode = isChild ? UPLOAD_FILE_TYPES.childPhoto : UPLOAD_FILE_TYPES.patientAvatar;

        try {
            const { selfie, document } = values || {};

            const selfieFileData = await S3Service.uploadFile(selfie, selfieTypeCode);

            toastr.success("Selfie was successfully saved");

            const documentFileData = await S3Service.uploadFile(document, UPLOAD_FILE_TYPES.document);

            toastr.success("Document was successfully saved");

            await PatientManagementService.resizeFile(selfieFileData?.id, {
                size: FILE_RESIZE_OPTIONS.threeHundredWidthAndHeight
            });

            await PatientManagementService.resizeFile(documentFileData?.id, {
                size: FILE_RESIZE_OPTIONS.autoWidthAndThreeHundredHeight
            });

            if (isChild) {
                await PatientManagementService.saveChildDocuments(patient?.id, {
                    avatarId: selfieFileData?.id,
                    [values.documentType]: documentFileData?.id,
                })
            } else {
                await PatientManagementService.savePatientDocuments(patient?.id, {
                    avatarId: selfieFileData?.id,
                    [values.documentType]: documentFileData?.id,
                })
            }

            toastr.success("Documents were successfully saved");

        } finally {
            await getPatient();
        }
    }, [getPatient, patient]);

    const deletePatientID = useCallback(
        async (fileId) => {
            try {
                await PatientManagementService.deletePatientID(fileId)
            } finally {
                await getPatient();
            }
        },
        [getPatient]
    );

    const editConsultation = useCallback(
        async (id, notes) => {
            try {
                await AppointmentsService.editConsultation(id, notes);
            } finally {
                await getPatientHistory();
            }
        }, [getPatientHistory])

    return {
        isLoading,
        isPatientInfoLoading,
        uploadFile,
        createNote,
        patient,
        getPatient,
        getPatientHistory,
        patientHistory,
        historyPagination,
        patientAppointments,
        currentAppointment,
        referralID,
        sickID,
        closeSickFitModal,
        closeReferralModal,
        sendReferralLetter,
        sendSickLetter,
        generateAndSendFitSickNote,
        generateAndSendReferralLetter,
        deleteFile,
        deleteReasonFile,
        sendEmail,
        sendTrustPilot,
        blockUnblockConsultationNotes,
        handleSaveDocuments,
        deletePatientID,
        editConsultation
    }
}

export default useAppointmentsUtils;
