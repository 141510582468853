import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { Col, Container, Row, Spinner } from "reactstrap";
import ChosenPatient from '../../components/chosenPatient';
import ConsultationNotes from './components/consultationsNotes';
import AppointmentsService from '../../services/appointments';
import { useUpdateChosenPatientContext } from '../Dashboard/tableContext';
import { useHistory, useLocation } from 'react-router-dom';
import queryParser from '../../helpers/queryStringParser';
import VideoComponent from './videoComponent';
import FeedbackModal from './components/feedBackModal';
import CallsService from '../../services/calls';
import moment from 'moment';
import ErrorPlaceholder from './components/errorPlaceholder';
import useMediaDevices from '../../helpers/useMediaDevices';
import generateBitMask from '../../helpers/generateBitmask';
import StorageService from '../../services/StorageService';
import { CALL_VARIABLES, NOTES_VARIABLES } from '../../constants/storageConstants';
import {setChildIfExists} from "../../helpers/childBuilder";

function Call() {

    const location = useLocation();
    const setChosenPatient = useUpdateChosenPatientContext();
    const [token, setToken] = useState(null);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [room, setRoom] = useState(null)
    const [appointment, setAppointment] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isAgreedToPrescription, setIsAgreedToShareInfo] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [documentsToFill, setDocumentsToFill] = useState([]);

    const formRef = useRef();

    const { id } = queryParser(location.search);
    const history = useHistory();

    const { isAllDevices } = useMediaDevices(true);

    const duration = useMemo(
        () => moment(appointment?.endsAt).diff(appointment?.startsAt),
        [appointment]
    );

    const getRoomAndToken = useCallback(
        async () => {
            try {
                const serverResponse = await CallsService.startCall(id);
                setToken(serverResponse.data.token);
                setRoom(serverResponse.data.roomSid);
                const { data } = await AppointmentsService.getAppointment(id);
                setChosenPatient(setChildIfExists(data));
                setAppointment(data);
            } finally {
                setIsLoading(false);
            }
        },
        [id, setChosenPatient],
    )

    async function sendFeedback({
        videoQuality,
        soundQuality,
        microQuality,
        connectQuality,
        connentToPatient,
        connectToDoc,
        ...values }) {

        const issuesMask = generateBitMask([videoQuality, soundQuality, microQuality, connectQuality, connentToPatient, connectToDoc]);
        const feedbackValues = {
            issuesMask,
            ...values,
        }
        await CallsService.sendFeedback(id, feedbackValues);
        setShowFeedbackModal(false);
        history.push("/appointments");
    }

    useEffect(() => {
        getRoomAndToken();
    }, [getRoomAndToken]);


    useEffect(() => {
        return () => {
            new StorageService().deleteValueByKey(CALL_VARIABLES);
            new StorageService().deleteValueByKey(NOTES_VARIABLES);
        }
    }, []);

    return (
        <div className="calls-page-content">
            <Container fluid>
                <ChosenPatient showPhoneFirst />
                <Row>
                    <Col xl={6} className="calls-page-content__call">
                        {token && isAllDevices && !isLoading &&
                            <VideoComponent
                                formRef={formRef}
                                roomName={room}
                                token={token}
                                appointment={appointment}
                                duration={duration}
                                isAgreedToPrescription={isAgreedToPrescription}
                                setDocumentsToFill={setDocumentsToFill}
                                isSubmitting={isSubmitting}
                            />
                        }
                        {isLoading &&
                            <div className="patients-table d-flex justify-content-center align-items-center">
                                <Spinner className="mx-auto" color="info" />
                            </div>
                        }
                        {!isAllDevices && !isLoading && <ErrorPlaceholder />}
                    </Col>
                    <Col xl={6}>
                        <ConsultationNotes
                            formRef={formRef}
                            setShowFeedbackModal={setShowFeedbackModal}
                            appointment={appointment}
                            setIsAgreedToShareInfo={setIsAgreedToShareInfo}
                            documentsToFill={documentsToFill}
                            setDocumentsToFill={setDocumentsToFill}
                            setIsSubmitting={setIsSubmitting}
                        />
                    </Col>
                </Row>
            </Container>
            <FeedbackModal visibleModal={showFeedbackModal} handleSubmit={sendFeedback} isDocumentsLoading={false}/>
        </div>
    )
}

export default Call;
