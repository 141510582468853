import React from 'react'

export default function TableHeader({ columns }) {
    return (
        <thead className="thead-light">
            <tr>
                {columns.map((column, index) => <th key={index}>{column}</th>)}
            </tr>
        </thead>
    )
}