import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import expiredLinkLogo from 'assets/images/expired-verification-link.svg';
import queryParser from 'helpers/queryStringParser';
import {PATIENT_ROUTES} from "constants/routes";

import {getLinkToApp} from "./helpers";

export const ExpiredPatientPasswordLink = () => {
    const history = useHistory();
    const location = useLocation();

    const linkToApp = getLinkToApp(window.location.hostname);

    const { token } = queryParser(location.search);

    function handleResendLink() {
        history.push({
            pathname: PATIENT_ROUTES.resendPasswordLink,
            search: `token=${token}`,
        })
    }

    return (
        <div className="public-patient-expired-link d-flex align-items-center justify-content-center">
            <div
                className="public-patient-expired-link__content d-flex flex-column align-items-center justify-content-center text-center">
                <img src={expiredLinkLogo} alt=""/>
                <div className="p-4 m-1">
                    We&apos;re sorry, the password reset link has expired. Please tap the button below to resend the
                    link.
                </div>
                <div className="w-100">
                    <button className="btn btn-primary w-100" onClick={handleResendLink}>Resend password link</button>
                </div>
                <a className="btn btn-sign-in w-100" href={linkToApp}>Sign in</a>
            </div>
        </div>
    )
}
