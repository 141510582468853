import React from "react";
import {Modal} from "reactstrap";
import {ErrorMessage, Formik} from "formik";
import * as yup from "yup";

import {appointmentPriceError, errorMessage, requiredError, stringValidationError} from "constants/errorMessages";
import {APPOINTMENT_PRICE_MAX_LENGTH, APPOINTMENT_PRICE_MIN_LENGTH,} from "constants/lengthValidity";
import {digitsWithNoSpaces} from "constants/validityPatterns";


const priceValidationSchema = yup.object().shape({
    newPrice: yup.string(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .min(APPOINTMENT_PRICE_MIN_LENGTH, appointmentPriceError)
        .max(APPOINTMENT_PRICE_MAX_LENGTH, appointmentPriceError)
        .matches(digitsWithNoSpaces, stringValidationError),

});

export const ChangeAppointmentPriceModal = ({ isOpen, initialValues = {}, handleSubmit, close }) => {
    return (
        <Modal
            isOpen={isOpen}
            centered
        >
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={priceValidationSchema}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="p-40px h-100 w-100">
                                <div>
                                    <label className="font-size-22 font-weight-normal">
                                        Change appointment price
                                    </label>
                                </div>
                                <label className="mt-1 pt-1">Current appointment price</label>
                                <input
                                    type="text"
                                    name="currentPrice"
                                    className="form-control coupon-form-input w-100"
                                    value={`£${values.currentPrice}`}
                                    disabled={true}
                                />
                                <div className="mb-2 mt-2 pt-1">
                                    <label className="mb-2">New appointment price</label>
                                    <input
                                        type="text"
                                        name="newPrice"
                                        placeholder="Enter new appointment price"
                                        className="form-control coupon-form-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.newPrice}
                                    />
                                    <ErrorMessage name="newPrice" render={errorMessage}/>
                                </div>


                                <section
                                    className="d-flex flex-column flex-sm-row w-100 justify-content-center modal-actions coupon-form-wrapper-buttons">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                    >
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger w-100"
                                        onClick={close}
                                    >
                                        Cancel
                                    </button>
                                </section>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
};
