import React from 'react'
import { Card } from 'reactstrap';
import placeholder from '../../../assets/images/im-placeholders-error.svg';

export default function ErrorPlaceholder() {
    return (
        <Card className="call-error-placeholder">
            <img alt="" src={placeholder} className="call-error-placeholder__image" />
            <span className="call-error-placeholder__bold-label">
                An Error occured when trying to get access to Camera and/or Microphone
            </span>
            <span className="call-error-placeholder__label">
                To make a call,  you need to turn your camera and microphone on. This will let people see and hear you during the call
            </span>
        </Card>
    );
}