import React from "react";

import StarFill from '../../assets/images/bxs-star_active.svg';
import StarEmpty from '../../assets/images/bxs-star_inactive.svg';

const MAX_STARS = 5;

export default function Rating({ rating }) {
    const emptyStarsCount = MAX_STARS - rating;

    return (
        <div className="rating-container">
            {
                rating > 0
                    ? new Array(rating)
                        .fill()
                        .map((el, index) => <img key={index} src={StarFill} alt="Star fill" />)
                    : undefined
            }
            {
                emptyStarsCount > 0
                    ? new Array(emptyStarsCount)
                        .fill()
                        .map((el, index) => <img key={index} src={StarEmpty} alt="Star empty" />)
                    : undefined
            }
        </div>
    );
}
