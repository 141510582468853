import fileIcon from "assets/images/ic-file.svg";
import imageIcon from "assets/images/ic-picture.svg";
import {FILE_PDF, IMAGE_JPEG, IMAGE_JPG, IMAGE_PNG} from "./filesValidity";

export const FILE_TYPES = {
    PDF: 5,
    IMAGE: 6, //6
    APPOINTMENT_PDF: 2,
    APPOINTMENT_IMAGE: 1,
    SIGNATURE: 7,
};

export const IMAGE_TYPES = [
    FILE_TYPES.IMAGE,
    FILE_TYPES.APPOINTMENT_IMAGE,
    FILE_TYPES.SIGNATURE,
]

export const DOCUMENT_TYPES = [
    FILE_TYPES.PDF,
    FILE_TYPES.APPOINTMENT_PDF,
]

export const IMAGE_JPG_CONTENT_TYPE = ".jpg";
export const IMAGE_JPEG_CONTENT_TYPE = ".jpeg";
export const IMAGE_PNG_CONTENT_TYPE = ".png";
export const FILE_PDF_CONTENT_TYPE = ".pdf"

export const EXTENSION_TYPES = {
    [IMAGE_JPG]: IMAGE_JPG_CONTENT_TYPE,
    [IMAGE_JPEG]: IMAGE_JPEG_CONTENT_TYPE,
    [IMAGE_PNG]: IMAGE_PNG_CONTENT_TYPE,
    [FILE_PDF]: FILE_PDF_CONTENT_TYPE,
}

export const IMAGE_MIME_TYPES = [IMAGE_PNG, IMAGE_JPEG, IMAGE_JPG];
export const FILE_MIME_TYPES = [FILE_PDF];

export const IMAGE_FILE_OPTION_ICON = imageIcon;
export const IMAGE_FILE_OPTION_ALT = "Image";
export const IMAGE_FILE_OPTION_LABEL = "Add Photo";
export const IMAGE_FILE_OPTION_MIME_TYPE = IMAGE_MIME_TYPES.toString();
export const IMAGE_FILE_OPTION_FILE_TYPE = FILE_TYPES.IMAGE;

export const PDF_FILE_OPTION_ICON = fileIcon;
export const PDF_FILE_OPTION_ALT = "PDF";
export const PDF_FILE_OPTION_LABEL = "Select File";
export const PDF_FILE_OPTION_MIME_TYPE = FILE_PDF;
export const PDF_FILE_OPTION_FILE_TYPE = FILE_TYPES.PDF;

export const DROPDOWN_ITEMS = [
    {
        icon: IMAGE_FILE_OPTION_ICON,
        alt: IMAGE_FILE_OPTION_ALT,
        label: IMAGE_FILE_OPTION_LABEL,
        mimeType: IMAGE_FILE_OPTION_MIME_TYPE,
        fileType: IMAGE_FILE_OPTION_FILE_TYPE,
    },
    {
        icon: PDF_FILE_OPTION_ICON,
        alt: PDF_FILE_OPTION_ALT,
        label: PDF_FILE_OPTION_LABEL,
        mimeType: PDF_FILE_OPTION_MIME_TYPE,
        fileType: PDF_FILE_OPTION_FILE_TYPE
    }
];

export const UPLOAD_FILE_TYPES = {
    patientAvatar: 1,
    appointmentReason: 2,
    parentId: 3,
    document: 4,
    pdfDocument: 5,
    patientRecordImage: 6,
    doctorSign: 7,
    childPhoto: 8,
    childId: 9
};

export const FILE_RESIZE_OPTIONS = {
    threeHundredWidthAndHeight: 1,
    threeHundredWidthAndAutoHeight: 2,
    autoWidthAndThreeHundredHeight: 3
};

export const EMPTY_FILE_SIZE = 0;
export const EMPTY_FILE_ERROR = "The file is empty.";

export const BYTES_IN_KILOBYTE = 1024;
export const MAX_CSV_FILE_SIZE = 500 * BYTES_IN_KILOBYTE; // 500KB
export const ALLOWED_CSV_FILE_EXTENSION = ["csv"];
export const CSV_FILE_EXTENSION = ".csv";
export const ERROR_CSV_FILE_SIZE_MESSAGE = `Invalid file size. Max. size ${MAX_CSV_FILE_SIZE/BYTES_IN_KILOBYTE} KB`;
export const ERROR_ALLOWED_CSV_FILE_TYPE_MESSAGE = "File is not supported. Please upload a csv format file";
