import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import qs from 'qs';
import formatDateSlash from '../../../helpers/formatTimeSlash';
import * as _ from 'lodash';
import { isInfoShared } from '../../../constants/sharedInfoObj';
import checkForCallDisabled from '../../../helpers/checkCallButtonDisabled';
import StorageService from '../../../services/StorageService';
import { CALL_VARIABLES, NOTES_VARIABLES } from '../../../constants/storageConstants';

export default function Details({ appointment }) {

    const history = useHistory();

    const detailsInfo = [
        { label: "Adding date :", value: formatDateSlash(appointment.createdAt) },
        { label: "Agreed to share info with GP :", value: _.get(isInfoShared, appointment?.patient?.isInfoShared, '-') },
    ];

    const isButtonDisabled = checkForCallDisabled(appointment);

    function handleStartCall() {
        new StorageService().deleteValueByKey(CALL_VARIABLES);
        new StorageService().deleteValueByKey(NOTES_VARIABLES);
        history.push({
            pathname: '/call',
            search: qs.stringify({ id: appointment.id }),
        })
    }

    return (
        <Card className="details-documents history-appointment-details">
            <label className="details-caption history-appointment-details__title">Appointment details</label>
            <dl className="row mb-0 pl-2">
                {detailsInfo.map((infoRow, index) => (
                    <React.Fragment key={index}>
                        <dt className="py-2 pt-3 col-sm-8">{infoRow.label}</dt>
                        <dd className="py-2 pt-3 col-sm-4">{infoRow.value}</dd>
                        <span className="border-bottom w-100" />
                    </React.Fragment>
                ))}
            </dl>
            <button
                className="btn background-warning px-5 align-self-center mt-4"
                disabled={isButtonDisabled}
                onClick={handleStartCall}>
                Start a call
            </button>
        </Card>
    );
}

