import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Modal, Card } from "reactstrap";
import CancelModal from '../manageSessionModal/cancelModal';
import Select from "react-select";
import { Formik, ErrorMessage, Field } from 'formik';
import * as yup from 'yup';
import CustomDatePicker from '../Common/customDatePicker';
import { requiredError, errorMessage, commentLengthError } from '../../constants/errorMessages';
import Search from '../searchField/searchFieldFormInput';
import { MAX_RANGE_LENGTH } from '../../constants/lengthValidity';
import formatDateOutput from '../../helpers/formatDateOutput';
import uploadAllData from '../../helpers/uploadAllData';
import AppointmentsService from '../../services/appointments';
import getTimeOptions from '../../helpers/getTimeOptions';
import Label from '../Label/labelWithError';
import { MIN_NOTE, MAX_COMMENT } from '../../constants/lengthValidity';
import { SIMPLE_APPOINTMENT, PAID_APPOINTMENT } from '../../constants/appointmentTypes';

const schema = yup.object().shape({
    userId: yup.number().required(requiredError).typeError(requiredError),
    date: yup.string(requiredError).required(requiredError),
    time: yup.object().required(requiredError),
    reason: yup.string().required(requiredError).trim(commentLengthError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError),
});

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: '150px',
        cursor: 'pointer',
    }),
    option: (styles) => ({
        ...styles,
        cursor: 'pointer',
    }),
    control: (styles) => ({
        ...styles,
        cursor: 'pointer',
    }),
}

const CustomInput = ({ value, onClick }) => (
    <input
        type="text"
        placeholder="Enter date"
        value={formatDateOutput(value)}
        onClick={onClick}
        className="custom-calendar-output-field cursor-pointer form-control"
    />
);

export default function CreateAppointment({ visibleModal, handleClose, handleSubmit, defaultValues, pickedDate }) {
    const [visibleCancelModal, setVisibleCancelModal] = useState(false);
    const [dateOutput, setDateOutput] = useState(pickedDate);
    const [slots, setSlots] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [options, setOptions] = useState([]);

    const initialValues = {
        userId: '',
        date: pickedDate,
        time: '',
        reason: '',
        appointmentType: [],
    }

    const maxDate = useMemo(() => new Date().getTime() + MAX_RANGE_LENGTH, []);

    const onChange = (date, setFieldValue) => {
        setDateOutput(date);
        setFieldValue("date", date);
        setFieldValue("time", "");
        setSelectedOption(null);
    };

    const getDaySlots = useCallback(
        async () => {
            const slots = await uploadAllData(AppointmentsService.getAppointmentsSlotsByDate.bind(AppointmentsService),
                { date: dateOutput, type: defaultValues.type }
            );
            setSlots(slots);
            setOptions(getTimeOptions(slots));
        },
        [dateOutput, defaultValues.type],
    );

    useEffect(() => {
        getDaySlots();
    }, [getDaySlots])

    return (
        <Modal
            isOpen={visibleModal}
            centered={true}
            toggle={() => setVisibleCancelModal(true)}
        >
            <Card className="p-4 mb-0">
                <label className="pt-4 pl-4 font-size-22">Create Appointment</label>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        const pickedSlot = slots.find((slot) => slot.id === values.time.id);
                        const appointmentType = values.appointmentType.length ? SIMPLE_APPOINTMENT : PAID_APPOINTMENT;
                        handleSubmit(pickedSlot.id, {
                            ...(values?.childId && { childId: values.childId } ),
                            userId: values.userId,
                            reason: values.reason,
                            appointmentType,
                        });
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} className="form-horizontal px-4 mb-3">
                            <div className="form-group mb-2 mt-4">

                                <Label text="Patient" isInvalid={errors.userId && touched.userId} />
                                <Search
                                    handleChange={(value) => {
                                        if (value?.isChild) {
                                            setFieldValue("userId", value.parentId);
                                            setFieldValue("childId", value.id)
                                        } else {
                                            setFieldValue("userId", value.id)
                                        }
                                    }}

                                />
                                <ErrorMessage name="userId" render={errorMessage} />

                                <section className="d-flex flex-row justify-content-between mt-4">
                                    <section className="d-flex flex-column">
                                        <Label text="Date" isInvalid={errors.date && touched.date} />
                                        <CustomDatePicker
                                            handleChangeDate={(dates) => onChange(dates, setFieldValue)}
                                            customInput={<CustomInput />}
                                            minDate={Date.now()}
                                            maxDate={maxDate}
                                            isSelected={dateOutput}
                                            showCalendar={false}
                                        />
                                        <ErrorMessage name="date" render={errorMessage} />
                                    </section>
                                    <section className="d-flex flex-column">
                                        <Label text="Time" isInvalid={errors.time && touched.time} />
                                        <Select
                                            onChange={(option) => {
                                                setSelectedOption(option)
                                                setFieldValue("time", option.value)
                                            }}
                                            value={selectedOption}
                                            options={options}
                                            className="custom-calendar-output-field custom-design-scroll"
                                            placeholder="Enter start"
                                            styles={customStyles}
                                        />
                                        <ErrorMessage name="time" render={errorMessage} />
                                    </section>
                                </section>
                            </div>
                            <div className="form-group mb-2 mt-4">
                                <section className="mt-2">
                                    <Label text="Reason" isInvalid={errors.reason && touched.reason} />
                                    <input type="text"
                                        name="reason"
                                        placeholder="Enter Reason"
                                        value={values.reason}
                                        className="w-100 form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="reason" render={errorMessage} />
                                </section>
                            </div>
                            <div className="custom-control custom-checkbox simple-appointment-checkbox">
                                <Field type="checkbox" name="appointmentType" value="2" id="2" className="custom-control-input" />
                                <label
                                    className="custom-control-label simple-appointment-checkbox__label"
                                    htmlFor="2"
                                >
                                    I’m creating the simple appointment
                                </label>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <button className="btn btn-primary modal-button-styles" type="submit" disabled={isSubmitting}>Save</button>
                                <button className="btn btn-danger modal-button-styles" type="button" onClick={() => setVisibleCancelModal(true)}>Cancel</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Card>
            {visibleModal && <CancelModal visibleModal={visibleCancelModal} setVisibleModal={setVisibleCancelModal} handleClose={handleClose} />}
        </Modal>
    );
}