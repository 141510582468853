import Http from './axios-config';

class AddressService {
    constructor() {
        this.http = new Http();
    }

    async addAddress(values) {
        return await this.http.post('/addresses', values);
    }
}

export default new AddressService();