import React, {useCallback, useState} from 'react';
import { Card, Modal } from 'reactstrap';
import FullScreenMode from '../../Dashboard/appointmentsDetails/fullScreenMode';
import FileRow from "./uploadedFiles/fileRow";
import { FILE_TYPES } from "../../../constants/uploadFileConstants";
import CancelModal from "../../../components/manageSessionModal/cancelModal";

export default function AttachedPhoto({ files, onDeleteFile }) {
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [deleteItemId, setDeleteItemId] = useState(null);

    const handleClick = useCallback((file) => {
        if (file?.type === FILE_TYPES.APPOINTMENT_IMAGE) {
            setSelectedPhoto(file?.link || "");
        }
        if (file?.type === FILE_TYPES.APPOINTMENT_PDF) {
            const a = document.createElement("a");
            a.setAttribute("href", file.link)
            a.setAttribute("target", "_blank")
            a.click();
        }
    }, [selectedPhoto])

    const handleDelete = useCallback(async () => {
        try {
            const deleteFile = files.find(item => deleteItemId === item.id)
            await onDeleteFile(deleteFile.fileId || 0);
        } finally {
            setDeleteItemId(null);
        }
    }, [setDeleteItemId, deleteItemId])

    return (
        <>
            <Card className="patient-details px-4 py-3">
                <label className="details-caption mb-2">Attached photo and file</label>
                <div className="attached-photos-and-files-content custom-container-scrollbar">
                    {files.length > 0 ? (
                            files.map((file) =>
                                <FileRow
                                    key={file.id}
                                    file={file}
                                    handleClick={handleClick}
                                    handleDelete={() => setDeleteItemId(file.id)}
                                />
                            ))
                        :
                        <label>-</label>}
                </div>
            </Card>
            <Modal
                isOpen={Boolean(selectedPhoto)}
                size="xl"
                toggle={() => setSelectedPhoto(null)}
            >
                <FullScreenMode
                    enableZoom={false}
                    photo={selectedPhoto}
                    handleClose={() => setSelectedPhoto(null)}
                />
            </Modal>
            {deleteItemId && (
                <CancelModal
                    visibleModal={!!deleteItemId}
                    setVisibleModal={() => setDeleteItemId(null)}
                    handleClose={handleDelete}
                    text="Are you sure you want to delete this file/image?"
                    header="Delete"
                />
            )}
        </>
    );
}