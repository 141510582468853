import Http from './axios-config';

class AppointmentsService {

    constructor() {
        this.http = new Http();
    }

    /**
     *
     * @param {*} date
     */
    async getAppointmentsSessionByDate(date) {
        return await this.http.get("/clinical-admins/appointments/sessions", {
            params: { date: date }
        });
    }

    async createAppointmentsSession(session) {
        return await this.http.post("/clinical-admins/appointments/sessions", session);
    }

    async deleteAppointmentSession(id, values) {
        return await this.http.delete(`/clinical-admins/appointments/sessions/${id}`, {
            disableToast: true,
            data: values
        });
    }

    async getAppointmentsSlotsByDate(params) {
        return await this.http.get(`/clinical-admins/appointments/slots`, { params });
    }

    async blockUnblockAppointmentSlot(id) {
        return await this.http.patch(`/clinical-admins/appointments/slots/${id}/blocked`);
    }

    async setDNAStatus(id, status) {
        return await this.http.patch(`/clinical-admins/appointments/slots/${id}/status`, status);
    }

    async sendConsultation(id, notes) {
        return await this.http.post(`/clinical-admins/appointments/slots/${id}/consultations`, notes);
    }

    async editConsultation(id, notes) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/consultations`, notes);
    }

    async getAppointmentComments(id) {
        return await this.http.get(`/clinical-admins/appointments/slots/${id}/comments`);
    }

    async addComment(id, comment) {
        return await this.http.post(`/clinical-admins/appointments/slots/${id}/comments`, comment);
    }

    async rescheduleAppointment(id, values) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/schedule`, values);
    }

    async createAppointment(id, values) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/patients`, values);
    }

    async deleteAppointment(id) {
        return await this.http.delete(`/clinical-admins/appointments/slots/${id}`);
    }

    async getAppointment(id) {
        return await this.http.get(`/clinical-admins/appointments/slots/${id}`);
    }

    async squeezeAppointmentSlot(id) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/squeeze`);
    }

    async updateAppointmentActualStartsTime(id, time) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/times`, { actualStartsAt: time });
    }

    async updateAppointmentActualEndsTime(id, time) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/times`, { actualEndsAt: time });
    }

    async editAppointmentSession(id, values) {
        return await this.http.put(`/clinical-admins/appointments/sessions/${id}`, values);
    }

    async getSessionById(id) {
        return await this.http.get(`/clinical-admins/appointments/sessions/${id}`);
    }

    async sendTrustPilot(id) {
        return await this.http.post(`/clinical-admins/appointments/${id}/trust-pilot/email`);
    }

    async showHideConsultationNotes(id, isHidden) {
        return await this.http.patch(`/clinical-admins/appointments/slots/${id}/consultations/is-hide`, {
            isHidden
        });
    }

    async getTakenTimeSlots(params) {
        return await this.http.get("/clinical-admins/appointments/sessions/timeslots", { params });
    }

    async getTakenPriorities(params) {
        return await this.http.get("/clinical-admins/appointments/sessions/priority", { params });
    }

}

export default new AppointmentsService();
