import {useCallback, useEffect, useState} from "react";

import CliniciansService from "services/clinicians";
import {SEARCH_LIMIT} from "constants/paginationLimits";

export const useSearchDoctors = () => {
    const [doctors, setDoctors] = useState([]);

    const handleDoctorSearch = useCallback(
        async (string) => {
            try {
                const doctorsResponse = await CliniciansService.getCliniciansList({
                    limit: SEARCH_LIMIT,
                    query: string,
                });

                setDoctors(doctorsResponse.data);
            } catch {
                //empty
            }
        },
        [],
    )


    useEffect(() => {
        handleDoctorSearch();
    }, [handleDoctorSearch]);

    const doctorsOptions = doctors.map(doctor => {
        return {
            value: doctor?.doctorId,
            label: `${doctor?.firstName} ${doctor?.lastName}`
        }
    });

    return {
        handleDoctorSearch,
        doctorsOptions
    }
};
