import React, {useState, useMemo, useEffect, useCallback} from 'react';
import { Card } from "reactstrap";
import editIcon from '../../../assets/images/ic-edit-black.svg';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import PatientManagementService from '../../../services/patientsManagement';
import CancelModal from '../../../components/manageSessionModal/cancelModal';
import { isInfoShared } from '../../../constants/sharedInfoObj';

export default function PersonalInfo({ patient, isChild = false }) {

    const [isPatientBlocked, setIsPatientBlocked] = useState(false);
    const [visibleBlockModal, setVisibleBlockModal] = useState(false);
    const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);

    const isPatientDeleted = useMemo(() => patient.isDeleted, [patient]);

    const patientInfo = [
        { label: "Confirmation status :", value: patient.isVerified ? "Approved" : 'Pending' },
        { label: "Account status :", value: isPatientBlocked ? "Blocked" : 'Active' },
        { label: "GP adress :", value: patient.surgeryAddress?.address || <span>-</span>, labelClassname: "py-4 col-sm-6", valueClassname: "col-sm-6 " + (!patient.adress?.adress && 'pt-4') },
        { label: "Agreed to share info with GP :", value: _.get(isInfoShared, patient.isInfoShared, '-'), labelClassname: "col-sm-9", valueClassname: "col-sm-3" },
    ];

    const history = useHistory();

    const handleEditPatientInfo = useCallback(() => {
        if (isChild) {
            history.push({
                pathname: '/patients-management/patients/child/edit',
                search: qs.stringify({ id: patient.id }),
            })
        } else {
            history.push({
                pathname: '/patients-management/patients/edit',
                search: qs.stringify({ id: patient.id }),
            })
        }
    }, [history, patient])

    async function handleBlockPatient() {
        try {
            await PatientManagementService.blockUnBlockPatient(patient.id, { isBlocked: !isPatientBlocked });
            setIsPatientBlocked((prevState) => !prevState);
        } finally {
            setVisibleBlockModal(false)
        }
    }

    async function handleDeletePatient() {
        try {
            await PatientManagementService.deletePatientById(patient.id);
            history.push('/patients-management');
        } finally {
            setVisibleDeleteModal(false);
        }
    }

    useEffect(() => {
        setIsPatientBlocked(patient.isBlocked)
    }, [patient]);

    return (
        <>
            <Card className="patient-details px-4 py-3">
                <section className="d-flex flex-row justify-content-between">
                    <label className="details-caption">Personal details</label>
                    <button className="btn btn-outline" onClick={handleEditPatientInfo} disabled={isPatientDeleted}><img src={editIcon} alt="" /></button>
                </section>
                <dl className="row mb-0 pl-2">
                    {patientInfo.map((infoRow, index) => (
                        <React.Fragment key={index}>
                            <dt className={"py-2 pt-3 " + _.get(infoRow, "labelClassname", "col-sm-6")}>{infoRow.label}</dt>
                            <dd className={"py-2 pt-3 " + _.get(infoRow, "valueClassname", "col-sm-6")}>{infoRow.value}</dd>
                            <span className="border-bottom w-100" />
                        </React.Fragment>
                    ))}
                </dl>
                <section className="d-flex flex-row justify-content-between mt-4 mb-2">
                    {!isChild && (
                        <>
                            <button className="btn btn-primary w-50 mr-2" disabled={isPatientDeleted} onClick={() => setVisibleBlockModal(true)}>{isPatientBlocked ? "Unblock" : "Block"}</button>
                            <button className="btn btn-outline-primary w-50 ml-2" disabled={isPatientDeleted} onClick={() => setVisibleDeleteModal(true)}>Delete</button>
                        </>
                    )}
                </section>
            </Card>
            <CancelModal visibleModal={visibleBlockModal}
                setVisibleModal={setVisibleBlockModal}
                handleClose={handleBlockPatient}
                text={`Are you sure you want to ${isPatientBlocked ? "unblock" : "block"} this Patient?`}
                header={`${isPatientBlocked ? "Unblock" : "Block"} Patient`}
            />
            <CancelModal visibleModal={visibleDeleteModal}
                setVisibleModal={setVisibleDeleteModal}
                handleClose={handleDeletePatient}
                text="Are you sure you want to remove this patient?"
                header="Remove Patient"
            />
        </>
    )
}