import React from 'react'
import { Field, ErrorMessage } from 'formik';
import { errorMessage } from '../../../../constants/errorMessages';
import { phasedReturn, amendedDuties, alteredHours, workplaceAdaptations } from '../../../../constants/callConstants';

export default function SickCheckboxes({ isInvalid }) {
    return (
        <div className="w-75">
            <label className={"mb-3 drug-supply " + (isInvalid && "error-text")}>If available, and with your employer’s agreement you may benefit from: checkboxes (multiple choice):</label>
            <section className="d-flex flex-row justify-content-between">
                <section className="d-flex flex-column">
                    <div className="custom-control custom-checkbox" >
                        <Field type="checkbox" name="checked" value={phasedReturn} id="1" className="custom-control-input" />
                        <label className="custom-control-label checkbox-label" htmlFor="1">A phased return to work</label>
                    </div>

                    <div className="custom-control custom-checkbox mt-2">
                        <Field type="checkbox" name="checked" value={amendedDuties} id="2" className="custom-control-input" />
                        <label className="custom-control-label checkbox-label" htmlFor="2">Amended duties</label>
                    </div>
                </section>

                <section className="d-flex flex-column">
                    <div className="custom-control custom-checkbox" >
                        <Field type="checkbox" name="checked" value={alteredHours} id="3" className="custom-control-input" />
                        <label className="custom-control-label checkbox-label" htmlFor="3">Altered hours</label>
                    </div>

                    <div className="custom-control custom-checkbox mt-2" >
                        <Field type="checkbox" name="checked" value={workplaceAdaptations} id="4" className="custom-control-input" />
                        <label className="custom-control-label checkbox-label" htmlFor="4">Workplace adaptations</label>
                    </div>
                </section>
            </section>
            <ErrorMessage name="checked" render={errorMessage} />
        </div>
    )
}