import React from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {
    requiredError,
    errorMessage,
    drugNameError,
    drugDoseError,
    drugFormError,
    drugInstructionsError,
    supplyNumericError
} from '../../../constants/errorMessages';
import {
    MIN_DRUG,
    MAX_DRUG_NAME,
    MAX_DRUG_DOSE,
    MAX_DRUG_FORM,
    MAX_DRUG_AMOUNT,
    MAX_DRUG_UNSTRUCTIONS,
    MAX_DRUG_INPUTS
} from '../../../constants/lengthValidity';

const schema = yup.object().shape({
    name: yup.string(drugNameError)
        .required(requiredError)
        .min(MIN_DRUG, drugNameError)
        .max(MAX_DRUG_NAME, drugNameError)
        .trim(drugNameError)
        .typeError(drugNameError),
    dose: yup.string(drugDoseError)
        .required(requiredError)
        .min(MIN_DRUG, drugDoseError)
        .max(MAX_DRUG_DOSE, drugDoseError)
        .trim(drugDoseError)
        .typeError(drugDoseError),
    form: yup.string(drugFormError)
        .required(requiredError)
        .min(MIN_DRUG, drugFormError)
        .max(MAX_DRUG_FORM, drugFormError)
        .trim(drugFormError)
        .typeError(drugFormError),
    instructions: yup
        .string(drugInstructionsError)
        .required(requiredError)
        .min(MIN_DRUG, drugInstructionsError)
        .trim(drugInstructionsError)
        .max(MAX_DRUG_UNSTRUCTIONS, drugInstructionsError)
        .typeError(drugInstructionsError),
    amountNumber: yup.number(supplyNumericError)
        .required(requiredError)
        .min(MIN_DRUG, supplyNumericError)
        .max(MAX_DRUG_AMOUNT, supplyNumericError)
        .typeError(supplyNumericError),
    amountText: yup.string(drugFormError)
        .required(requiredError)
        .min(MIN_DRUG, drugFormError)
        .max(MAX_DRUG_FORM, drugFormError)
        .trim(drugFormError)
        .typeError(drugFormError),
});

const firstPart = [
    { type: 'text', name: 'name', placeholder: "Example: Paracetamol", classname: 'drug-inputs__first' },
    { type: 'text', name: 'dose', placeholder: "Example: 500mg", classname: 'drug-inputs__second' },
    { type: 'text', name: 'form', placeholder: "Example: tablets", classname: 'drug-inputs__third' },
];

const secondPart = [
    { type: 'number', name: 'amountNumber', placeholder: "Amount", classname: 'drug-inputs__second' },
    { type: 'text', name: 'amountText', placeholder: "Amount", classname: 'drug-inputs__second' },
    { type: 'text', name: 'form', placeholder: "Drug form", classname: 'drug-inputs__fifth' },
]

export default function DrugForm({ createDrug, currentDrugFormCount, setFormErrors }) {

    const initialValues = {
        name: "",
        dose: "",
        form: "",
        instructions: "",
        amountNumber: '',
        amountText: "",
    }

    return (
        <section className="d-flex flex-column drug-inputs">
            <Formik
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={(values) => {
                    createDrug(values, currentDrugFormCount);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => {

                    const ifEvenOneValueExist = Object.values(values).filter(value => value).length;
                    if (Object.keys(errors).length && ifEvenOneValueExist) {
                        setFormErrors((prevState) => {
                            if(prevState[currentDrugFormCount]){
                                return prevState;
                            }
                            const newState = [...prevState];
                            newState[currentDrugFormCount] = true;
                            return newState;
                        });
                    } else {
                        setFormErrors(prevState => {
                            if(!prevState[currentDrugFormCount]){
                                return prevState;
                            }
                            return [...prevState].filter((item, index) => index !== currentDrugFormCount)
                        });
                    }

                    return (
                        <form onSubmit={handleSubmit}
                            className="w-100 d-flex flex-column"
                            onKeyUp={ifEvenOneValueExist >= MAX_DRUG_INPUTS && handleSubmit}
                        >
                            <section className="d-flex flex-row justify-content-between">
                                {firstPart.map(({ type, name, placeholder, classname }, index) => (
                                    <section key={index} className={"d-flex flex-column " + classname}>
                                        <input type={type}
                                            className="form-control"
                                            placeholder={placeholder}
                                            value={values[name]}
                                            name={name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage name={name} render={errorMessage} />
                                    </section>
                                ))}
                            </section>

                            <input type="text"
                                className="form-control drug-inputs__fouth"
                                placeholder="Drug taking instructions"
                                name="instructions"
                                value={values.instructions}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <ErrorMessage name="instructions" render={errorMessage} />

                            <section className="d-flex flex-row justify-content-between">
                                <label className="drug-supply w-25 drug-inputs__label form-control">Please supply:</label>
                                {secondPart.map(({ type, name, placeholder, classname }, index) => (
                                    <section key={index} className={"d-flex flex-column " + classname}>
                                        <input type={type}
                                            className="form-control"
                                            placeholder={placeholder}
                                            value={values[name]}
                                            name={name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage name={name} render={errorMessage} />
                                    </section>
                                ))}
                            </section>
                        </form>
                    );
                }}
            </Formik>

        </section>
    );
}