import React from 'react'
import { Card } from "reactstrap";
import * as _ from 'lodash';

export default function DoctorsInfo({ doctorsInfo }) {
    return (
        <Card className="patient-details px-4 pt-3 pb-1 mb-0">
            <section className="d-flex flex-row justify-content-between">
                <label className="details-caption">Doctor info</label>
            </section>
            <dl className="row mb-0 pl-2">
                {doctorsInfo.map((infoRow, index) => (
                    <React.Fragment key={index}>
                        <dt className={"py-2 pt-3 col-sm-6 " + _.get(infoRow, "labelClassname", "")}>{infoRow.label}</dt>
                        <dd className={"py-2 pt-3 col-sm-6 " + _.get(infoRow, "valueClassname", "")}>{infoRow.value}</dd>
                        {index !== (doctorsInfo.length - 1) && <span className="border-bottom w-100" />}
                    </React.Fragment>
                ))}
            </dl>
        </Card>
    );
}