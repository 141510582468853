import React, { useState } from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import searchIcon from '../../assets/images/ic-search-gray.svg';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import formatDateSlash from '../../helpers/formatTimeSlash';
import parseChildren from "../../helpers/parseChildren";

const { Input, Option, Menu, Placeholder } = components;

const DropdownIndicator = () => {
    return null;
};

const IndicatorSeparator = ({ innerProps }) => {
    return <span {...innerProps} />;
};

const customStyles = {
    control: () => ({
        width: 265,
        height: 35,
        paddingLeft: "35px",
        border: "1px solid #ced4da",
        borderRadius: '50px',
    }),
    menu: styles => ({
        ...styles,
        width: 265,
        maxHeight: 250,
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: 250,
    }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 0,
    })
}

const CustomInput = props => {
    if (props.isHidden) {
        return <Input {...props} />;
    }
    return (
        <div className="search-input">
            <Input {...props} />
        </div>
    );
};

const CustomOption = props => {
    return (
        <div className="search-options">
            <Option {...props} />
        </div>
    );
};

const NoOptionsMessage = () => {
    return (
        <div className="search-options my-2 ml-2">
            No options
        </div>
    );
};

const IndicatorsContainer = () => {
    return null;
};

const CustomMenu = props => {
    return (
        <div className="custom-design-scroll">
            <Menu {...props}>{props.children}</Menu>
        </div>
    );
};

const CustomPlaceholder = props => {
    return <Placeholder {...props} ><span className="search-placeholder">Search...</span></Placeholder>;
};

function Search({ handleSearch, options, initialValue }) {
    const [query, setQuery] = useState(initialValue);
    const history = useHistory();

    async function onInputChange(inputValue, action) {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setQuery(inputValue)
            handleSearch(inputValue)
        }
    }

    async function handleChange(selected) {
        if (selected?.value?.isChild) {
            history.push(`/patients-management/patients/child?id=${selected?.value?.id}&parentId=${selected?.value?.parentId}`);
        } else {
            history.push(`/patients-management/patients?id=${selected.value.id}`)
        }
    }

    function generateLabel(option) {
        return (
            <Row>
                <Col lg={6}>{option.firstName + " " + option.lastName}</Col>
                <Col lg={6}>{option.birthday ? formatDateSlash(option.birthday) : '-'}</Col>
            </Row>
        );
    }

    function generateOptions() {
        const searchOptions = parseChildren(options || [])

        return searchOptions.map(option => ({
            value: option,
            label: generateLabel(option),
        }));
    }

    return (
        <span className="d-flex flex-row align-items-center">
            <img src={searchIcon} alt="" className="search-icon" />

            <Select
                closeMenuOnSelect={true}
                styles={customStyles}
                className="custom-design-scroll"
                components={{
                    DropdownIndicator,
                    IndicatorSeparator,
                    Placeholder: CustomPlaceholder,
                    Input: CustomInput,
                    Option: CustomOption,
                    NoOptionsMessage,
                    IndicatorsContainer,
                    Menu: CustomMenu
                }}
                options={generateOptions()}
                onChange={handleChange}
                inputValue={query}
                onInputChange={onInputChange}
                filterOption={() => { return true }}
            />
        </span>
    )
}

export default Search;