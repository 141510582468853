export const consultationNotesFormFields = [
    {
        name: "problem",
        label: "Problem",
    },
    {
        name: "history",
        label: "History",
    },
    {
        name: "pastMedicalHX",
        label: "Past medical HX",
    },
    {
        name: "examination",
        label: "Examination",
    },
    {
        name: "comments",
        label: "Comments",
    },
    {
        name: "safetyNet",
        label: "Safety net",
    }
];
