import React from 'react';
import logoTAP from "../../assets/images/logoTAP.svg";

export default function AvatarLogo() {
    return (
        <div className="ml-4">
            <div className="avatar-md profile-user-wid ml-3">
                <span className="avatar-title rounded-circle bg-light">
                    <img src={logoTAP} alt="" className="rounded-circle" height="70" />
                </span>
            </div>
        </div>
    )
}