import React from 'react';
import * as _ from 'lodash';

export default function ExtraInfo({ extraInfo, showPhoneFirst }) {
    
    const parentDoc = _.get(extraInfo, 'parent.document.link', null);

    return (
        <section className="d-flex flex-row justify-content-between">

            <section className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <label className="font-size-13 custom-text-secondary">Patient Address</label>
                    <label className="font-size-16 text-wrap">{extraInfo.address}</label>
                </div>

                {!showPhoneFirst &&
                    <div className="d-flex flex-column">
                        <label className="font-size-13 custom-text-secondary">Phone</label>
                        <label className="font-size-16">{extraInfo.phone}</label>
                    </div>
                }

                <div className={"d-flex flex-column " + (showPhoneFirst && 'mt-2')}>
                    <label className="font-size-13 custom-text-secondary">Reason</label>
                    <label className="font-size-16">{extraInfo.reason?.reason || '-'}</label>
                </div>
                {showPhoneFirst &&
                    <div className="mt-2 d-flex flex-column">
                        <label className="font-size-13 custom-text-secondary">Agreed to share info with GP</label>
                        <label className="font-size-16">{extraInfo.agreement}</label>
                    </div>
                }
            </section>

            <section className="d-flex flex-row justify-content-between patient-extra-section">
                <section className="d-flex flex-column">
                    <label className="font-size-13 custom-text-secondary">Attached photo</label>
                    {extraInfo.reason?.reasonPhoto ?
                        <img src={extraInfo.reason.reasonPhoto.link}
                            alt=""
                            className="align-self-start attached-photo mr-2"
                        />
                        :
                        <label className="font-size-16">-</label>
                    }
                </section>

                {extraInfo.parent &&
                    <>
                        <section className="d-flex flex-column">
                            <label className="label-parents-info">Parents info</label>
                            <section className="d-flex flex-row">
                                <div className="d-flex flex-column mt-2 mr-4">
                                    <label className="font-size-13 custom-text-secondary">First name</label>
                                    <label className="font-size-16">{extraInfo.parent.firstName}</label>
                                    <div className="d-flex flex-column mt-1 pt-1">
                                        <label className="font-size-13 custom-text-secondary">Phone number</label>
                                        <label className="font-size-16">{extraInfo.parent.phone}</label>
                                    </div>
                                </div>
                                <div className="d-flex flex-column mt-2">
                                    <label className="font-size-13 custom-text-secondary">Last name</label>
                                    <label className="font-size-16">{extraInfo.parent.lastName}</label>
                                </div>
                            </section>
                        </section>


                        <section className="d-flex flex-column mr-4">
                            <label className="font-size-13 custom-text-secondary">Parent document</label>
                            {parentDoc ? <img src={parentDoc} alt="" className="attached-photo" /> : <label className="font-size-16">-</label>}
                        </section>
                    </>
                }
            </section>
        </section>
    )
}
