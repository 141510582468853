import moment from "moment";

const compareDates = (d1, d2) => {
    return moment(d1).diff(d2);
}

export const compareDatesInObject = (propName) => (o1, o2) => {
    return compareDates(o1?.[propName], o2?.[propName]);
}

export default compareDates;