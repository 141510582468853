import React from 'react';
import {Card, Container, Modal} from "reactstrap";

import changePasswordByPatientSuccess from 'assets/images/changePasswordByPatientSuccess.svg';

import {getLinkToApp} from "../helpers";

export const ChangePasswordByPatientSuccessModal = ({ visibleModal}) => {
    const linkToApp = getLinkToApp(window.location.hostname);

    return (
        <Container>
            <Modal
                isOpen={visibleModal}
                className="patient-modal"
                centered={true}
            >
                <Card className="success-password-modal">
                    <div className="modal-body modal-text-styles text-center">
                        <img src={changePasswordByPatientSuccess} alt="" className="changePasswordSuccessLogo"/>
                        <p className="text-center mt-4 mb-0">
                            Your password has been updated!
                        </p>
                    </div>
                    <a className="btn w-100" href={linkToApp}>Ok</a>
                </Card>
            </Modal>
        </Container>
    );
}
