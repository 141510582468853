import { MAX_ALERTS_COUNT } from '../constants/lengthValidity';

export default function addNewAlert(setState, alert) {
    setState(prevState => {
        if (prevState.length >= MAX_ALERTS_COUNT) {
            prevState.shift();
            return [...prevState, alert];
        }
        return [...prevState, alert];
    })
}