import React, { useState } from 'react';
import { Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import printerIcon from '../../../assets/images/ic-printer-black.svg';
import menuIcon from "../../../assets/images/ic-vertical-dots-black.svg";
import mailIcon from "../../../assets/images/ic-mail-black.svg";
import mailTrustPilot from "../../../assets/images/ic-mail-with-arrow.svg";

const DefaultComponent = ({ value: children }) => children;

export default function AppointmentDetails({appointmentDetails, children, sendEmailFn, trustPilotFn}) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => setIsDropdownOpen(prev => !prev)

    return (
        <Card className="patient-details px-4 pt-3 pb-1">
            <section className="d-flex flex-row justify-content-between">
                <label className="details-caption ">Appointments details</label>
                <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="span"
                    >
                        <img
                            src={menuIcon}
                            alt="UploadFile"
                            className="cursor-pointer pr-3"
                        />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem
                            className="d-flex flex-row n py-3 p_x-18px align-items-center cursor-pointer"
                        >
                            <img
                                src={printerIcon}
                                alt=""
                                className="pr-2"
                            />
                            <label className="font-size-16 font-weight-light-medium m-0">
                                Print
                            </label>
                        </DropdownItem>
                        <DropdownItem
                            className="d-flex flex-row py-3 p_x-18px align-items-center cursor-pointer"
                            onClick={() => sendEmailFn()}
                        >
                            <img
                                src={mailIcon}
                                alt=""
                                className="pr-2"
                            />
                            <label className="font-size-16 font-weight-light-medium m-0">
                                Send email
                            </label>
                        </DropdownItem>
                        <DropdownItem
                            className="d-flex flex-row py-3 p_x-18px align-items-center cursor-pointer"
                            onClick={() => trustPilotFn()}
                        >
                            <img
                                src={mailTrustPilot}
                                alt=""
                                className="pr-2"
                            />
                            <label className="font-size-16 font-weight-light-medium m-0">
                                Share with trustpilot
                            </label>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </section>
            <dl className="row mb-0 pl-2">
                {appointmentDetails.map((
                    {
                        Component = DefaultComponent,
                        ActionComponent = DefaultComponent,
                        label,
                        value,
                        action,
                        valueClassname,
                        labelClassname,
                        actionClassname,
                        actionHandler,
                    },
                    index
                ) => (
                    <React.Fragment key={index}>
                        <dt className={"py-2 pt-3 col-sm-6 " + labelClassname}>{label}</dt>
                        { !!value && (
                            <dd className={`py-2 pt-3 ${ action ? "col-sm-4" : "col-sm-6" } ` + valueClassname}>
                                <Component value={value} />
                            </dd>
                        ) }
                        { !!action && (
                            <dd className={"py-2 pt-3 text-nowrap col-sm-2 " + actionClassname}>
                                <ActionComponent value={action} handler={actionHandler} />
                            </dd>
                        ) }
                        {index !== (appointmentDetails.length - 1) && <span className="border-bottom w-100" />}
                    </React.Fragment>
                ))}
            </dl>
            {children}
        </Card>
    )
}