import React, { useState } from 'react';
import CommentsModal from './commentsModal';

export default function CommentsCount({ value, updateTable }) {
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    return (
        <div onClick={() => setShowCommentsModal(true)}>
            <span >{typeof value.commentsCount === 'number' && value.commentsCount}</span>
            {showCommentsModal && <CommentsModal
                visibleModal={showCommentsModal}
                handleClose={() => setShowCommentsModal(false)}
                appointment={value}
                updateTable={updateTable}
            />}
        </div>
    )
}