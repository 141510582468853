import React from 'react'
import { Field, ErrorMessage } from 'formik';
import { errorMessage } from '../../../../constants/errorMessages';
import { FIT_IDENTIFICATION, SICK_IDENTIFICATION } from '../../../../constants/documentStatuses';

export default function SickRadios({ isInvalid }) {
    return (
        <div role="group" className="mt-2">
            <label className={"mb-3 drug-supply " + (isInvalid && "error-text")}>I advise you that (single choice):</label>
            <section className="d-flex flex-row justify-content-between">
                <div className="custom-control custom-radio mb-3 custom-z-index">
                    <Field type="radio" name="type" value={SICK_IDENTIFICATION} id="customRadio1" className="custom-control-input custom-z-index" />
                    <label className="custom-control-label" htmlFor="customRadio1">You are not fit for work</label>
                </div>
                <div className="custom-control custom-radio custom-z-index">
                    <Field type="radio" name="type" value={FIT_IDENTIFICATION} id="customRadio2" className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="customRadio2">You may be fit for work taking account of the following advice</label>
                </div>
            </section>
            <ErrorMessage name="type" render={errorMessage} />
        </div>
    );
}