import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Table,
    UncontrolledDropdown
} from "reactstrap";
import {withNamespaces} from 'react-i18next';

import verticalOptionsLabel from 'assets/images/ic-more-black-vertical.svg';
import AppointmentsService from 'services/appointments';
import CancelModal from 'components/manageSessionModal/cancelModal';
import InfoModal from 'components/manageSessionModal/infoModal';
import formatTimeString from 'helpers/formatServerSessionTime';
import {useChosenPatientContext, useUpdateChosenPatientContext} from 'pages/Dashboard/tableContext';
import {MESSAGES} from 'constants/serverMessages';
import parseIntFromStr from 'helpers/parseIntFromStr';
import formatDate from 'helpers/formatDate';
import formatTime from 'helpers/formatTime';
import getCurrentTimezoneOffset from 'helpers/getCurrentTimezoneOffset';
import {setChildIfExists} from "helpers/childBuilder";

import ManageSessionModal from '../manageSessionModal/manageSessionModal';
import CreateAppointment from '../appointments/appointments';

import {dataStructure, MANAGE_SESSION, REMOVE_SESSION, tableHeader, tableOptions} from './tableInfo';
import TableBody from './tableBody';
import TableHeader from './tableHeader';

function SessionTable({
    t,
    tableData,
    handleCreateAppointment,
    pickedDate,
    updateTable,
    getAppointmentSlots,
    getSessionsByDate
}) {
    const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [showCantDeleteModal, setShowCantDeleteModal] = useState(false);
    const [errorMessage, setErrorText] = useState("");
    const [showCreateAppointmentModal, setSowCreateAppointmentModal] = useState(false);
    const [pickedSlot, setPickerSlot] = useState(null);

    const minDateToCreateSession = Date.now();

    const setChosenPatient = useUpdateChosenPatientContext();
    const selectedSlot = useChosenPatientContext();

    async function getChosenAppointment(appointment) {
        try {
            if (appointment.child && appointment.patient) {
                const serverResponse = await AppointmentsService.getAppointment(appointment.id)
                setChosenPatient(setChildIfExists(serverResponse.data))
            } else if (appointment.patient) {
                const serverResponse = await AppointmentsService.getAppointment(appointment.id)
                setChosenPatient(serverResponse.data)
            } else {
                setChosenPatient(appointment)
            }
        } catch {
            //empty
        }
    }

    async function handleEditSession(values) {
        const session = {
            ...values,
            startDate: formatDate(values.startDate),
            endDate: formatDate(values.endDate),
            startsAt: formatTime(values.startsAt),
            endsAt: formatTime(values.endsAt),
            appointmentDuration: parseIntFromStr(values.appointmentDuration),
            timezoneOffset: getCurrentTimezoneOffset(),
            withoutCurrent: true
        };
        try {
            await AppointmentsService.editAppointmentSession(tableData.id, session);
            setShowCreateSessionModal(false);
            await getSessionsByDate(pickedDate, true);
            await getAppointmentSlots(pickedDate)
        } catch {
            //empty
        }
    }

    function handleClickOption(option) {
        if (option === MANAGE_SESSION) {
            setShowCreateSessionModal(true)
        } else if (option === REMOVE_SESSION) {
            setShowDeleteConfirmationModal(true)
        }
    }

    async function handleClose() {
        try {
            await AppointmentsService.deleteAppointmentSession(tableData.id, {
                date: pickedDate,
                timezoneOffset: getCurrentTimezoneOffset(),
            });
            await getSessionsByDate(pickedDate, true);
            await getAppointmentSlots(pickedDate)
        } catch (error) {
            if (MESSAGES[error.message]) {
                setErrorText(error.message)
                setShowCantDeleteModal(true)
            }
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    }

    async function handleAddAppointment(id, values) {
        try {
            await handleCreateAppointment(id, values);
            setSowCreateAppointmentModal(false);
        } catch {
            //empty
        }
    }

    useEffect(() => {
        setPickerSlot(selectedSlot);
    }, [selectedSlot]);

    const doctorName = tableData.doctor
        ? `${tableData.doctor?.firstName} ${tableData.doctor?.lastName[0]}.`
        : ""

    return (
        <>
            {!!tableData.appointments.length &&
                <Card className="session-table-card">

                    <CardBody className="p-3">
                        <CardTitle className="d-flex flex-row justify-content-between">
                            {doctorName} ({formatTimeString(tableData.startsAt)} - {formatTimeString(tableData.endsAt)})
                            <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop" tag="i">
                                    <img src={verticalOptionsLabel} alt="" className="cursor-pointer"/>
                                </DropdownToggle>
                                <DropdownMenu className="custom-dropdown-styles" right>
                                    <span
                                        className="table-text session-table-card__action-label cursor-default">{t("Action")}</span>
                                    {tableOptions.map((option) => (
                                        <DropdownItem key={option} className="table-text"
                                                      onClick={() => handleClickOption(option)}>{t(option)}</DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </CardTitle>
                        <div className="custom-design-scroll table-responsive mt-1 session-table">
                            <div className="border-bottom table-text">
                                <Table className="table mb-0">
                                    <TableHeader columns={tableHeader}/>
                                    <TableBody
                                        data={tableData.appointments}
                                        rowClassname="appointment-row appointment-row-hover"
                                        structure={dataStructure}
                                        clickRowAction={getChosenAppointment}
                                        updateTable={updateTable}
                                        getAppointmentSlots={getAppointmentSlots}
                                        pickedAppointment={pickedSlot}
                                        doctor={tableData.doctor}
                                    />
                                </Table>
                            </div>
                        </div>
                        <button className="btn btn-primary w-100 mt-2"
                                onClick={() => setSowCreateAppointmentModal(true)}>{t("+ Add Appointment")}</button>
                    </CardBody>
                </Card>
            }
            {showCreateSessionModal && <ManageSessionModal
                isOpen={showCreateSessionModal}
                handleClose={() => setShowCreateSessionModal(false)}
                handleSubmit={handleEditSession}
                currentSession={tableData}
                minDateToCreateSession={minDateToCreateSession}
                isEdit
            />
            }
            {showCreateAppointmentModal && <CreateAppointment
                visibleModal={showCreateAppointmentModal}
                handleSubmit={handleAddAppointment}
                handleClose={() => setSowCreateAppointmentModal(false)}
                defaultValues={tableData}
                pickedDate={pickedDate}
            />}
            {showDeleteConfirmationModal &&
                <CancelModal visibleModal={showDeleteConfirmationModal} setVisibleModal={setShowDeleteConfirmationModal}
                             handleClose={handleClose} text="Do you want to remove the session?"
                             header="Remove Session"/>}
            {showCantDeleteModal &&
                <InfoModal
                    visibleModal={showCantDeleteModal}
                    close={() => setShowCantDeleteModal(false)}
                    header="Remove Session"
                    buttonText="Ok"
                >
                    <p className="mt-4 w-75 font-size-16 mb-5">
                        {errorMessage}
                    </p>
                </InfoModal>
            }
        </>
    )
}

export default withNamespaces()(SessionTable);
