import React, {useLayoutEffect, useRef} from "react";


const MIN_TEXTAREA_HEIGHT = 32;
const TOP_AND_BOTTOM_BORDERS = 2;
export const AutoSizeTextArea = ({
    name,
    value,
    onBlur,
    onChange,
    placeholder,
    className
}) => {
    const textareaRef = useRef(null);

    useLayoutEffect(() => {
        // Reset height - important to shrink on delete
        textareaRef.current.style.height = "inherit";
        // Set height
        textareaRef.current.style.height = `${Math.max(
            (textareaRef.current.scrollHeight + TOP_AND_BOTTOM_BORDERS),
            MIN_TEXTAREA_HEIGHT
        )}px`;
    }, [value]);

    return (
        <textarea
            rows={1}
            placeholder={placeholder}
            name={name}
            value={value}
            onBlur={onBlur}
            className={className}
            onChange={onChange}
            ref={textareaRef}
        />
    )
};
