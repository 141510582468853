import React, {useCallback, useEffect, useState} from "react";
import {useDropzone} from "react-dropzone";

const DropzoneFile = ({
    onReceiveFile,
    title,
    className,
    isDropContainer = true,
    ButtonComponent,
    acceptedFormats,
    fileUrl,
    initialFile,
    isMultipleFiles = false,
    iconComponent
}) => {
    const [currentFile, setCurrentFIle] = useState({file: initialFile, fileUrl: fileUrl});

    const setPreviewFile = useCallback((file) => {
        setCurrentFIle({
            fileUrl: URL.createObjectURL(file),
        })
    }, [setCurrentFIle])

    const onDrop = useCallback(
        (acceptedFiles) => {
            if (!acceptedFiles.length) return;

            acceptedFiles.forEach(setPreviewFile);
            acceptedFiles.forEach((file) => onReceiveFile(file));
        },
        [setCurrentFIle]
    );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: acceptedFormats,
        multiple: isMultipleFiles,
    });

    useEffect(() => {
        if (initialFile) {
            onDrop(initialFile)
        }
    }, [initialFile])

    return (
        <div
            className={`${
                className ? className : " "
            } dropzone-file ${
                isDragActive ? "drag-active " : ""
            }`}
        >
            <div {...getRootProps()} className="w-100 h-100">
                <input {...getInputProps({className})} />
                {isDropContainer && !currentFile.file && !currentFile.fileUrl && (
                    <div className="upload-container">
                        {iconComponent? iconComponent : <i className="bx bxs-cloud-upload cloud"></i>}
                        <p className="upload-container__text">{title}</p>
                    </div>
                )}
                {ButtonComponent && <ButtonComponent/>}
                {currentFile.fileUrl && (
                    <div className="w-100 h-100 ">
                        <img src={currentFile.fileUrl} alt="img" className="uploaded-image-dropzone"/>
                    </div>
                )}

            </div>
        </div>
    );
}

export default DropzoneFile;
