import React from 'react';

export default function ConsultationNotes({ notes, noteLabels }) {
    return (
        <section>
            {notes ? Object.keys(notes).map((note, index) => (
                <section key={index} className="mt-2 ">
                    <label className="consultation-notes-labels">{noteLabels[note]}</label>
                    <p
                        className="consultation-notes"
                        dangerouslySetInnerHTML={{
                            __html: `${notes[note] || '-'}`
                        }}
                    ></p>
                </section>
            ))
                :
                <label>-</label>
            }
        </section>
    );
}