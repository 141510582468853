import React, {useState} from 'react';
import {withNamespaces} from 'react-i18next';
import {Modal} from "reactstrap";
import Select from "react-select";
import {ErrorMessage, Formik} from 'formik';

import getTimeOptionsArray from 'constants/timeOptions';
import {errorMessage} from 'constants/errorMessages';
import formatTimeToUK from 'helpers/formatTimeToUK';
import restrictIncorrectInput from 'helpers/restrictInput';
import formatTimeString from 'helpers/formatServerSessionTime';
import {sessionValidationSchema} from "helpers/validateFunctions/sessionValidationSchema";
import {useSessionCreateAndManage} from "hooks/useSessionCreateAndManage";
import {useSearchDoctors} from "hooks/useSearchDoctors";

import Label from '../Label/labelWithError';
import CustomDatePicker from '../Common/dateRangeDatepicker';
import {CustomSearchInput} from "../searchField/CustomSearchInput";

import CancelModal from './cancelModal';


const slotTimeFormat = "hh:mm A";

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: '150px',
        cursor: 'pointer',
    }),
    option: (styles) => ({
        ...styles,
        cursor: 'pointer',
    }),
    control: (styles) => ({
        ...styles,
        cursor: 'pointer',
        borderColor: "#CED4DA"
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        padding: 6,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
        color: "#555B6D"
    }),
}

function ManageSessionModal({
    isOpen,
    handleClose,
    handleSubmit,
    currentSession,
    isEdit
}) {
    const [startTimePicker, setStartTimePicker] = useState(formatTimeToUK(currentSession?.startsAt) || null);
    const [visibleModal, setVisibleModal] = useState(false);

    const initialValues = {
        doctorId: currentSession?.doctorId || "",
        startDate: currentSession?.startDate || "",
        endDate: currentSession?.endDate || "",
        startsAt: currentSession ? formatTimeString(currentSession?.startsAt, slotTimeFormat) : "",
        endsAt: currentSession ? formatTimeString(currentSession?.endsAt, slotTimeFormat) : "",
        appointmentDuration: currentSession?.appointmentDuration || "",
        priority: currentSession?.priority || ""
    }

    const defaultStartOptionValue = {
        label: formatTimeToUK(currentSession?.startsAt),
        value: formatTimeString(currentSession?.startsAt, slotTimeFormat),
    }

    const defaultEndOptionValue = {
        label: formatTimeToUK(currentSession?.endsAt),
        value: formatTimeString(currentSession?.endsAt, slotTimeFormat),
    }

    function filterTimeOptionsArray(start) {
        const isEndTime = true;
        const timeOptions = getTimeOptionsArray(takenTimeSlots, isEndTime);
        return timeOptions.filter((item, index) => index > timeOptions.findIndex(option => option.value === start));
    }

    const {
        isSessionDurationDisabled,
        isPriorityFieldDisabled,
        takenTimeSlots,
        availablePrioritiesOptions,
        setSessionParams,
        handleSessionValidityChange,
        isLoading
    } = useSessionCreateAndManage({ currentSession, isEdit });

    const {
        handleDoctorSearch,
        doctorsOptions
    } = useSearchDoctors();

    const manageSessionDoctorOptions = [
        {
            value: currentSession?.doctorId,
            label: `${currentSession?.doctor?.firstName} ${currentSession?.doctor?.lastName}`
        }
    ];


    return (
        <Modal
            isOpen={isOpen}
            toggle={() => setVisibleModal(true)}
            centered={true}
        >
            <label className="pl-5 pt-5 font-size-22">{currentSession ? "Manage" : "Create new"} Session</label>
            <Formik
                initialValues={initialValues}
                validationSchema={sessionValidationSchema}
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                }) => (
                    <form onSubmit={handleSubmit} className="form-horizontal px-5 mb-3">
                        <div className="form-group mb-2 mt-4">
                            <section className="pb-3 mb-3">
                                <h5 className="manage-session-caption font-size-16 mb-3">Doctor</h5>
                                <Label text="Select a doctor" isInvalid={errors.doctorId && touched.doctorId}/>
                                <CustomSearchInput
                                    handleSearch={handleDoctorSearch}
                                    options={isEdit ? manageSessionDoctorOptions : doctorsOptions}
                                    initialValue={isEdit
                                        ? manageSessionDoctorOptions.find(option => option.value === values.doctorId)
                                        : doctorsOptions.find(option => option.value === values.doctorId)
                                    }
                                    handleChange={selectedOption => {
                                        setFieldValue("doctorId", selectedOption?.value);
                                        setSessionParams(prevValue => {
                                            return {
                                                ...prevValue,
                                                doctorId: selectedOption?.value
                                            }
                                        });
                                    }}
                                    placeholder="Select a doctor"
                                    isDisabled={isLoading || isEdit}
                                />
                                <ErrorMessage name="doctorId" render={errorMessage}/>
                            </section>
                            <h5 className="manage-session-caption font-size-16">Session validity period</h5>
                            <CustomDatePicker
                                errors={errors}
                                touched={touched}
                                ComponentStart={() => (<ErrorMessage name="startDate" render={errorMessage}/>)}
                                ComponentEnd={() => (<ErrorMessage name="endDate" render={errorMessage}/>)}
                                setFieldValue={setFieldValue}
                                currentStartDay={currentSession?.startDate}
                                currentEndDay={currentSession?.endDate}
                                handleChange={handleSessionValidityChange}
                                isDisabled={isLoading || isEdit}
                            />
                            <div className="form-group mb-2 mt-2">
                                <label className="manage-session-caption font-size-16">Session duration</label>
                                <section className="d-flex flex-row justify-content-between mt-1">
                                    <section className="d-flex flex-column">
                                        <Label text="From" isInvalid={errors.startsAt && touched.startsAt}/>
                                        <Select
                                            onChange={(option) => {
                                                setStartTimePicker(option.value);
                                                setFieldValue("startsAt", option.value);
                                                setSessionParams(prevValue => {
                                                    return {
                                                        ...prevValue,
                                                        startsAt: option.value
                                                    }
                                                });
                                            }}
                                            options={getTimeOptionsArray(takenTimeSlots)}
                                            className="custom-calendar-output-field custom-design-scroll"
                                            placeholder="Enter start"
                                            styles={customStyles}
                                            defaultValue={currentSession && defaultStartOptionValue}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            isDisabled={isSessionDurationDisabled || isLoading}
                                        />
                                        <ErrorMessage name="startsAt" render={errorMessage}/>
                                    </section>
                                    <section className="d-flex flex-column">
                                        <Label text="To" isInvalid={errors.endsAt && touched.endsAt}/>
                                        <Select
                                            options={filterTimeOptionsArray(startTimePicker)}
                                            onChange={(option) => {
                                                setFieldValue("endsAt", option.value);
                                                setSessionParams(prevValue => {
                                                    return {
                                                        ...prevValue,
                                                        endsAt: option.value
                                                    }
                                                });
                                            }}
                                            className="custom-calendar-output-field custom-design-scroll"
                                            placeholder="Enter end"
                                            styles={customStyles}
                                            defaultValue={currentSession && defaultEndOptionValue}
                                            components={{
                                                IndicatorSeparator: () => null,
                                            }}
                                            isDisabled={isSessionDurationDisabled || isLoading}
                                        />
                                        <ErrorMessage name="endsAt" render={errorMessage}/>
                                    </section>
                                </section>
                            </div>
                            <section className="d-flex flex-column justify-content-start mt-2 position-relative">
                                <Label text="Appointments duration"
                                       isInvalid={errors.appointmentDuration && touched.appointmentDuration}/>
                                <input
                                    type="text"
                                    name="appointmentDuration"
                                    placeholder="Enter duration"
                                    className="custom-calendar-output-field form-control"
                                    onChange={(event) =>
                                        setFieldValue('appointmentDuration', restrictIncorrectInput(event.target.value))
                                    }
                                    onBlur={handleBlur}
                                    value={values.appointmentDuration}
                                    disabled={isLoading}
                                />
                                <label className="append-input-duration-min">min</label>
                                <ErrorMessage name="appointmentDuration" render={errorMessage}/>
                            </section>

                            <section className="mt-4">
                                <Label text="Select the priority" isInvalid={errors.priority && touched.priority}/>
                                <Select
                                    options={availablePrioritiesOptions}
                                    onChange={(option) => setFieldValue("priority", option.value)}
                                    placeholder="Select the priority"
                                    styles={customStyles}
                                    value={availablePrioritiesOptions.find(option => option.value === values.priority)}
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    isDisabled={isPriorityFieldDisabled || isLoading}
                                />
                                <ErrorMessage name="priority" render={errorMessage}/>
                            </section>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-5 mb-4">
                            <button
                                className="btn btn-primary modal-button-styles"
                                type="submit"
                                disabled={isSubmitting || isLoading}
                            >Save
                            </button>
                            <button
                                className="btn btn-danger modal-button-styles"
                                type="button"
                                onClick={() => setVisibleModal(true)}
                                disabled={isLoading}
                            >Cancel
                            </button>
                        </div>
                    </form>
                )}
            </Formik>
            <CancelModal visibleModal={visibleModal} setVisibleModal={setVisibleModal} handleClose={handleClose}/>
        </Modal>
    );
}

export default withNamespaces()(ManageSessionModal);
