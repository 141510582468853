// This optional code is used to register a service worker.
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://bit.ly/CRA-PWA

/* eslint-disable */

export function register(config) {
	if ('serviceWorker' in navigator) {
		var userAgent = navigator.userAgent.toLowerCase(),
			safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
			mobile = /(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i.test(userAgent)
		if (mobile || safari) {
			return;
		}
		// Service worker URL w/config variables
		// Convert environment variables to URL `search` parameters
		const firebaseConfig = new URLSearchParams({
			apiKey: process.env.REACT_APP_APIKEY,
			authDomain: process.env.REACT_APP_AUTHDOMAIN,
			databaseURL: process.env.REACT_APP_DATABASEURL,
			projectId: process.env.REACT_APP_PROJECTID,
			storageBucket: process.env.REACT_APP_STORAGEBUCKET,
			messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
			appId: process.env.REACT_APP_APPID,
		}).toString();

		// Service worker URL w/config variables
		const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?${firebaseConfig}`;
		navigator.serviceWorker.register(swUrl)
			.then(function (registration) {
			})
			.catch(error => console.log(error.message));
	}
}

export function unregister() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker.ready
			.then(registration => {
				registration.unregister();
			})
			.catch(error => {
				console.error(error.message);
			});
	}
}

