import React, { useState } from 'react';
import { AvField } from "availity-reactstrap-validation";

export default function InputWithEyeOption({ label, placeholder, validate, name, onChange, onBlur = () => {} }) {

    const [showPassword, setShowPassword] = useState(false);

    function handleShowPassword() {
        setShowPassword((prevState) => !prevState);
    }

    return (
        <>
            <AvField
                name={name}
                type={showPassword ? "text" : "password"}
                onChange={onChange ? onChange : undefined}
                label={label}
                validate={validate}
                placeholder={placeholder}
                id={name}
                className="password-input-padding"
                onBlur={onBlur}
            />
            <label htmlFor={name} className="eye-position cursor-pointer" onClick={handleShowPassword}><i className={showPassword ? "mdi eye-size mdi-eye" : "mdi eye-size mdi-eye-off"} /></label>
        </>
    )
}