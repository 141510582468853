import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Container } from "reactstrap";
import confirmedEmail from '../../assets/images/im-verification-link.svg';
import queryParser from '../../helpers/queryStringParser';
import PatientVerificationService from '../../services/patientVerification';
import qs from 'qs';

function EmailVerification() {

    const [showModal, setShowModal] = useState(false);

    const location = useLocation();
    const history = useHistory();

    const searchQuery = useMemo(() => queryParser(location.search), [location]);

    const checkTokenValidity = useCallback(
        async () => {
            try {
                await PatientVerificationService.checkExpiredDateEmail(searchQuery);
                await PatientVerificationService.verifyUsersEmail(searchQuery)
                setShowModal(true)
            } catch {
                history.push({
                    pathname: "/email-expired-link",
                    search: qs.stringify(searchQuery),
                })
            }
        },
        [searchQuery, history],
    )

    useEffect(() => {
        checkTokenValidity();
    }, [checkTokenValidity]);

    return (
        <Container>
            {showModal &&
                <Card className="success-password-modal expiredLinkCard overflow-hidden text-center mx-auto">
                    <div className="modal-body modal-text-styles text-center">
                        <img src={confirmedEmail} alt="" className="changePasswordSuccessLogo " />
                        <p className="text-center mt-4">
                            Your email was confirmed successfully
                    </p>
                    </div>
                </Card>
            }
        </Container>
    );
}

export default EmailVerification;