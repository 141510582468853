import React, { useState, useCallback } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { monthNames } from '../../constants/monthNames';
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import { MyContainer, CustomHeader } from './calendarComponents';

registerLocale("en-GB", en);

export default function CustomDatePicker({
    handleChangeDate,
    showCalendar,
    minDate,
    isSelected,
    customInput,
    maxDate,
    dayOfBirth,
    onChangeRaw
}) {
    const [startDate, setStartDate] = useState(new Date(dayOfBirth ? dayOfBirth : Date.now()));
    const [currentMonth, setCurrentMonth] = useState(startDate.getMonth())

    const monthIncreaseHandler = useCallback(
        () => {
            setCurrentMonth((prevState) => ++prevState % monthNames.length);
        },
        [],
    )

    const monthDecreaseHandler = useCallback(
        () => {
            setCurrentMonth((prevState) => prevState ? --prevState : monthNames.length - 1);
        },
        [],
    )

    const onChange = useCallback(value => {
        setStartDate(value)
        setCurrentMonth(value.getMonth());
        handleChangeDate(value);
    }, [handleChangeDate]);

    const manageDayClassname = (date) => (date.getMonth() !== currentMonth) ? "font-color-of-exclude-day" : undefined;

    return (
        <>
            <DatePicker
                calendarContainer={MyContainer}
                renderCustomHeader={(props) => <CustomHeader  {...props} monthDecreaseHandler={monthDecreaseHandler} monthIncreaseHandler={monthIncreaseHandler} />}
                locale="en-GB"
                formatWeekDay={nameOfDay => nameOfDay.substr(0, 3)}
                selected={isSelected ? new Date(isSelected) : startDate}
                dayClassName={customInput ? null : manageDayClassname}
                onChange={onChange}
                inline={showCalendar === undefined ? true : showCalendar}
                minDate={minDate ? minDate : undefined}
                customInput={customInput ? customInput : null}
                maxDate={maxDate ? maxDate : undefined}
                disabledKeyboardNavigation
                onChangeRaw={onChangeRaw ? onChangeRaw : null}
            />
        </>
    )
}