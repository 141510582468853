export const childBuilder = (child = {}, parent = {}) => {
    return {
        ...child,
        address: parent?.address || {},
        isInfoShared: parent?.isInfoShared || false,
        email: "used parent email",
        parent
    }
}

export const setChildIfExists = (appointment) => {
    if (appointment?.child && appointment?.patient) {
        return {...appointment, patient: childBuilder(appointment?.child, appointment?.patient)  }
    } else {
        return appointment
    }
}