import { takeEvery, fork, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESTORE_PASSWORD } from "./actionTypes";
import AuthentificationService from '../../../services/authentification';

function* forgetUser({ payload: { userValues, toastr } }) {
    try {
        yield call([AuthentificationService, AuthentificationService.sendRestorePassword], userValues);
        toastr.success("Check your email to restore password");
    } catch(error){
        //empty
    }
}

function* restorePassword({ payload: { newPassword, setShowSuccessModal } }) {
    try {
        yield call([AuthentificationService, AuthentificationService.changePassword], newPassword);
        setShowSuccessModal(true);
    } catch (error) {
        //empty
    }
}

export function* watchUserPasswordForget() {
    yield takeEvery(FORGET_PASSWORD, forgetUser);
    yield takeEvery(RESTORE_PASSWORD, restorePassword);
}

function* forgetPasswordSaga() {
    yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
