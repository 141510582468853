import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardBody } from "reactstrap";
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import AnimateHeight from 'react-animate-height';

import phoneIcon from 'assets/images/ic-phone-black.svg';
import dropDownIcon from 'assets/images/ic-drop-down.svg';
import dropDownTopIcon from 'assets/images/ic-drop-down-top.svg';
import { useChosenPatientContext } from 'pages/Dashboard/tableContext';
import getUsersAge from 'helpers/getUsersAge';
import getUsersType from 'helpers/getUsersType';
import getUsersGender from 'helpers/getUsersGender';
import briefPlaceholderIcon from 'assets/images/brief-placeholder.svg';
import formatDateOutput from 'helpers/formatDateOutput';
import videoIcon from 'assets/images/ic-video-black.svg';
import { AUDIO_TYPE, VIDEO_TYPE } from 'constants/communicationTypes';

import AvatarLogo from '../avatar/tableAvatar';

import ExtraInfo from './extraInfo';

const minHeight = 112;
const maxHeight = 346;

const communicationMethods = {
    [AUDIO_TYPE]: phoneIcon,
    [VIDEO_TYPE]: videoIcon,
}

const PlaceholderImage = () => (
    <img src={briefPlaceholderIcon} alt="" height="45" width="150" />
)

function ChosenPatient({ showPhoneFirst }) {
    const [showExtraInfo, setShowExtraInfo] = useState(false);

    const history = useHistory();

    const appointmentInfo = useChosenPatientContext();

    const communicationTypeField = appointmentInfo?.communicationType ? 'communicationType' : 'patient.communicationTypeFromNewestSlot';

    const patientInfo = {
        firstName: _.get(appointmentInfo, 'patient.firstName', null),
        lastName: _.get(appointmentInfo, 'patient.lastName', null),
        type: getUsersType(_.get(appointmentInfo, 'patient.birthday', null)),
        birthday: formatDateOutput(_.get(appointmentInfo, 'patient.birthday', null)),
        age: getUsersAge(_.get(appointmentInfo, 'patient.birthday', null)),
        id: _.get(appointmentInfo, 'patient.id', '-'),
        gender: getUsersGender(_.get(appointmentInfo, 'patient.gender', null)),
        email: _.get(appointmentInfo, 'patient.email', null),
        comMethod: _.get(appointmentInfo, communicationTypeField, 1),
    }

    const extraInfo = {
        address: _.get(appointmentInfo, 'patient.address.address', '-'),
        phone: _.get(appointmentInfo, 'patient.phone', "-"),
        reason: _.get(appointmentInfo, 'appointmentReason', null),
        parent: _.get(appointmentInfo, 'patient.parent', null),
        agreement: _.get(appointmentInfo, 'patient.isInfoShared', true) ? "Agreed" : "Not agreed",
    }

    const isPatientDeleted = _.get(appointmentInfo, 'patient.isDeleted', null);

    const structure = [
        { label: "Type", value: patientInfo.type },
        { label: "Sex", value: patientInfo.gender },
        { label: "Patient ID", value: patientInfo.id },
        { label: "Email address", value: patientInfo.email },
    ];

    if (showPhoneFirst) {
        structure.push(
            { label: "Phone number", value: _.get(appointmentInfo, 'patient.phone', "-"), },
        )
    } else {
        extraInfo.phone = _.get(appointmentInfo, 'patient.phone', "-");
    }

    function hanldeClickDropdown() {
        setShowExtraInfo((prevState) => !prevState)
    }

    const handleClickName = useCallback(() => {
        const url = appointmentInfo?.patient?.parentId ?
          `/patients-management/patients/child?id=${patientInfo.id}&parentId=${appointmentInfo?.patient?.parentId}`
          : `/patients-management/patients?id=${patientInfo.id}`

        history.push(url);
    }, [appointmentInfo?.patient?.parentId, patientInfo?.id])

    useEffect(() => {
        setShowExtraInfo(false);
    }, [appointmentInfo]);

    return (
        <>
            <Card>
                {appointmentInfo ?
                    (isPatientDeleted ?
                        <div className="w-100 text-center py-3 d-flex flex-column justify-content-center align-items-center">
                            <PlaceholderImage />
                            <label className="mb-0 patients-placeholder-label pt-2">Information about this patient is no longer available</label>
                        </div>
                        :
                        <AnimateHeight
                            height={showExtraInfo ? maxHeight : minHeight}
                        >
                            {appointmentInfo.patient ?
                                <>
                                    <CardBody>
                                        <section className="d-flex flex-row justify-content-between">
                                            <div className="d-flex flex-row">
                                                <AvatarLogo avatar={_.get(appointmentInfo, "patient.avatar.resizedLink", null)} height="62" width="62" name={_.get(appointmentInfo, 'patient.firstName', '-')} />
                                                <div className="d-flex flex-column ml-3 mt-1 cursor-pointer" onClick={handleClickName}>
                                                    <label className="font-size-16 cursor-pointer">{patientInfo.firstName + ' ' + patientInfo.lastName}</label>
                                                    <label className="font-size-13 custom-text-secondary cursor-pointer">{patientInfo.birthday} ({patientInfo.age} y)</label>
                                                </div>
                                            </div>

                                            {structure.map((row, index) => (
                                                <div className="d-flex flex-column mt-1" key={index}>
                                                    <label className="font-size-13 custom-text-secondary">{row.label}</label>
                                                    <label className="font-size-16">{row.value}</label>
                                                </div>
                                            ))}

                                            <section className="d-flex flex-column mr-4">
                                                <label className="custom-text-secondary mt-1">Communication method</label>
                                                <img src={communicationMethods[patientInfo.comMethod]} alt="" className="align-self-start" />
                                            </section>
                                            <img src={!showExtraInfo ? dropDownIcon : dropDownTopIcon} alt="" className="cursor-pointer drop-down-icon" onClick={hanldeClickDropdown} />
                                        </section>
                                    </CardBody>
                                    {showExtraInfo &&
                                        <CardBody className="pt-2">
                                            <ExtraInfo extraInfo={extraInfo} showPhoneFirst={showPhoneFirst} />
                                        </CardBody>
                                    }
                                </>
                                :
                                <div className="w-100 py-3 d-flex flex-column justify-content-center align-items-center">
                                    <PlaceholderImage />
                                    <label className="mb-0 patients-placeholder-label pt-2">No one booked this appointment</label>
                                </div>
                            }
                        </AnimateHeight>)
                    :
                    <div className="w-100 text-center py-3 d-flex flex-column justify-content-center align-items-center">
                        <PlaceholderImage />
                        <label className="mb-0 patients-placeholder-label pt-2">Choose the patient to view info</label>
                    </div>
                }
            </Card >
        </>
    )
}

export default ChosenPatient;
