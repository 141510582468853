import Http from './axios-config';

class CallsService {
    constructor() {
        this.http = new Http();
    }

    async startCall(id) {
        return await this.http.put(`/clinical-admins/appointments/slots/${id}/calls`);
    }

    async endCall(id) {
        return await this.http.delete(`/clinical-admins/appointments/slots/${id}/calls`);
    }

    async sendReferralRequest(id) {
        return await this.http.post(`/clinical-admins/referral-letters/request`, id);
    }

    async sendFitRequest(id) {
        return await this.http.post(`/clinical-admins/fit-sick-notes/request`, id);
    }

    async sendPrescriptionRequest(id) {
        return await this.http.post(`/clinical-admins/prescriptions`, id);
    }

    async sendFeedback(id, values) {
        return await this.http.post(`/clinical-admins/appointments/slots/${id}/calls/feedback`, values);
    }

    async sendReferralLetter(values) {
        return await this.http.put(`/clinical-admins/referral-letters`, values);
    }

    async sendSickLetter(values) {
        return await this.http.put(`/clinical-admins/fit-sick-notes`, values);
    }

    async sendPrescriptionLetter(values) {
        return await this.http.put(`/clinical-admins/prescriptions`, values);
    }

    async createAllDocuments(id){
        return await this.http.post(`/clinical-admins/appointments/${id}/documents`);
    }

    async createReferralLetter(values) {
        return await this.http.post(`/clinical-admins/referral-letters`, values);
    }

    async createSickLetter(values) {
        return await this.http.post(`/clinical-admins/fit-sick-notes`, values);
    }

    async getReferralLetter(id) {
        return await this.http.get(`/clinical-admins/referral-letters/${id}`);
    }

    async getSickLetter(id) {
        return await this.http.get(`/clinical-admins/fit-sick-notes/${id}`);
    }

    async generateAndSendReferralLetter(values) {
        return await this.http.post(`/clinical-admins/referral-letters/generate-send`, values);
    }

    async generateAndSendFitSickNote(values) {
        return await this.http.post(`/clinical-admins/fit-sick-notes/generate-send`, values);
    }
}

export default new CallsService();
