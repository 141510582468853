import React from 'react';
import { TWO_MINUTES_TO_END } from '../../../constants/callConstants';
import Countdown from "react-countdown";
import transformDigits from '../../../helpers/formatDigits';
import { TWO_MINUTES } from '../../../constants/timeConstants';
import addNewAlert from '../../../helpers/addNewAlert';

export default function CallTimer({ duration, setAlerts, alerts, isCA }) {
    const renderer = ({ minutes, seconds, milliseconds }) => {
        if (minutes === TWO_MINUTES && !seconds && !milliseconds) {
            if (!isCA && alerts[alerts.length - 1] !== TWO_MINUTES_TO_END) {
                addNewAlert(setAlerts, TWO_MINUTES_TO_END);
            }
        }
        return (
            <span>
                {transformDigits(minutes)}:{transformDigits(seconds)}
            </span>
        );
    }

    return (
        <Countdown date={duration} renderer={renderer} />
    );
}
