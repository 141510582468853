import moment from 'moment';

export const MAX_YEAR = 100;
export const EMAIL_MAX_LENGTH = 129;
export const ONE_TIME_PASSWORD_LENGTH = 8;
export const MAX_RANGE_LENGTH = 1000 * 60 * 60 * 24 * 14;
export const MIN_DURATION_VALUE = 5;
export const MAX_DURATION_VALUE = 60;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 50;
export const NAME_MAX_LENGTH = 50;
export const NAME_MIN_LENGTH = 1;
export const MIN_DATE = moment().subtract(MAX_YEAR, 'years').toDate();
export const MIN_NOTE = 1;
export const MAX_NOTE = 2500;
export const MAX_DRUGS_COUNT = 12;
export const SEARCH_LIMIT = 10;
export const MAX_COMMENT = 200;
export const TIME_PICKER_STEP = 30;
export const MAX_COMMENTS_COUNT = 5;
export const PHONE_LENGTH = 11;

export const MAX_RANGE_LENGTH_SICK = 1000 * 60 * 60 * 24 * 10;
export const MAX_RANGE_LENGTH_FIT = 1000 * 60 * 60 * 24 * 90;
export const MIN_DRUG = 1;
export const MAX_DRUG_NAME = 30;
export const MAX_DRUG_FORM = 20;
export const MAX_DRUG_DOSE = 10;
export const MAX_DRUG_AMOUNT = 100000;
export const MAX_DRUG_UNSTRUCTIONS = 100;

export const MAX_DRUG_INPUTS = 6;

export const MAX_ALERTS_COUNT = 7;

export const MIN_ACTIVATION_CODE_LENGTH = 1;
export const MAX_ACTIVATION_CODE_LENGTH = 15;

export const MIN_ACTIVATION_CODE_TIMES = 1;
export const MAX_ACTIVATION_CODE_TIMES = 1000;

export const MIN_ACTIVATION_CODE_COUNT = 1;
export const MAX_ACTIVATION_CODE_COUNT = 1000;

export const MIN_GMC_LENGTH = 1;
export const MAX_GMC_LENGTH = 10;

export const MAX_PATIENT_INFO = 5000;

export const APPOINTMENT_PRICE_MIN_LENGTH = 1;
export const APPOINTMENT_PRICE_MAX_LENGTH = 8;
