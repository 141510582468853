import React, {useState} from 'react';
import {Card} from "reactstrap";
import toastr from "toastr";

import PatientManagementService from "services/patientsManagement";

import {ResetPatientPasswordModal} from "./ResetPatientPasswordModal";


export const PatientPassword = ({ patient }) => {
    const [isResetPatientPasswordModalOpen, setIsResetPatientPasswordModalOpen] = useState(false);

    const handleResetPatientPassword = async (values) => {
        try {
            await PatientManagementService.resetPatientPassword(patient.id, { password: values.password });

            setIsResetPatientPasswordModalOpen(false);

            toastr.success("password was successfully reset");
        } catch {
            // empty
        }
    };

    return (
        <>
            <Card className="px-4 py-3">
                <section className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className="details-caption mr-2 my-1">
                        Password
                    </div>
                    <button
                        className="btn btn-primary px-4 my-1 disable-word-wrap"
                        onClick={() => setIsResetPatientPasswordModalOpen(true)}
                    >
                        Reset password
                    </button>
                </section>
            </Card>
            <ResetPatientPasswordModal
                isOpen={isResetPatientPasswordModalOpen}
                handleSubmit={handleResetPatientPassword}
                close={() => setIsResetPatientPasswordModalOpen(false)}
            />
        </>
    )
};
