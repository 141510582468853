import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import ChosenPatient from '../../components/chosenPatient';
import queryParser from '../../helpers/queryStringParser';
import Details from './appointmentsDetails/appointmentDetails';
import AttachedPhoto from './appointmentsDetails/attachedPhoto';
import Comments from './appointmentsDetails/Comments';
import AppointmentsService from '../../services/appointments';
import { useUpdateChosenPatientContext } from './tableContext';
import {setChildIfExists} from "../../helpers/childBuilder";

export default function AppointmentDetails() {
    const [appointment, setAppointment] = useState({});
    const location = useLocation();

    const { id } = useMemo(() => queryParser(location.search), [location]);

    const setChosenPatient = useUpdateChosenPatientContext();

    const getAppointment = useCallback(
        async () => {
            try {
                const serverResponse = await AppointmentsService.getAppointment(id);
                setAppointment(serverResponse.data);
                setChosenPatient(setChildIfExists(serverResponse.data));
            } catch {
                //empty
            }
        },
        [id, setChosenPatient],
    )

    useEffect(() => {
        getAppointment();
    }, [getAppointment]);

    return (
        <div className="page-content mx-2">
            <ChosenPatient />
            <Row>
                <Col lg={4}>
                    <Details appointment={appointment} />
                    <Comments appointmentId={id} />
                </Col>
                <Col>
                    <AttachedPhoto appointment={appointment} />
                </Col>
            </Row>
        </div>
    )
}