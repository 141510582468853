import React, {useCallback, useRef, useState} from 'react';
import {Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal} from "reactstrap";
import uploadFileIcon from '../../../../assets/images/ic-upload-file.svg';
import FileRow from "./fileRow";
import {DROPDOWN_ITEMS, FILE_TYPES} from "../../../../constants/uploadFileConstants";
import validateFile from "../../../../helpers/validateFunctions/validateFile";
import fileTypeFromMimeType from "../../../../helpers/fileTypeFromMimeType";
import toastr from '../../../../components/toastr/index';
import FullScreenMode from "../../../Dashboard/appointmentsDetails/fullScreenMode";
import CancelModal from "../../../../components/manageSessionModal/cancelModal";
import * as _ from "lodash";

const UploadedFiles = ({
    uploadedFiles = [],
    onSelectFile = _.noop,
    onDeleteFle = _.noop
}) => {
    const [isDropDownOpen, setIsDropdownOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [image, selectImage] = useState(null);

    const fileInput = useRef();

    const onUploadClick = (mimeType) => {
        fileInput.current.accept = mimeType;
        fileInput.current?.click();
    }

    const handleChange = event => {
        const selectedFile = event.target.files[0];

        if (validateFile(selectedFile, toastr.error)) {
            const fileType = fileTypeFromMimeType(selectedFile.type);
            onSelectFile(
                selectedFile,
                fileType,
            );
        }
    };

    const handleClick = useCallback((file) => {
        if (file?.type === FILE_TYPES.IMAGE) {
            selectImage(file?.link || "");
            setIsFullscreen(true);
        }
        if (file?.type === FILE_TYPES.PDF) {
            const a = document.createElement("a");
            a.setAttribute("href", file.link)
            a.setAttribute("target", "_blank")
            a.click();
        }
    }, [selectImage, setIsFullscreen])

    const handleDelete = useCallback(async () => {
        try {
            await onDeleteFle(deleteItemId);
        } finally {
            setDeleteItemId(null);
        }
    }, [setDeleteItemId, deleteItemId])

    const toggleDropdown = () => setIsDropdownOpen(prev => !prev)

    return (
        <Card className="patient-details px-4 pt-3 py-2 mb-4">
            <div className="d-flex flex-row justify-content-between">
                <label className="details-caption">Uploaded images and documents</label>
                <Dropdown isOpen={isDropDownOpen} toggle={toggleDropdown}>
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="span"
                    >
                        <img
                            src={uploadFileIcon}
                            alt="UploadFile"
                            className="cursor-pointer pr-3"
                        />
                    </DropdownToggle>
                    <DropdownMenu right>
                        {DROPDOWN_ITEMS.map((item, index) =>
                            <DropdownItem
                                key={index}
                                className="d-flex flex-row justify-content-between py-3 align-items-center"
                                onClick={() => onUploadClick(item.mimeType, item.fileType)}
                            >
                                <img
                                    src={item.icon}
                                    alt={item.alt}
                                />
                                <label className="font-size-16 font-weight-light-medium m-0">
                                    {item.label}
                                </label>
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                    <input
                        ref={fileInput}
                        className="d-none"
                        type="file"
                        onChange={handleChange}
                        multiple={false}
                    />
                </Dropdown>
            </div>
            {!!uploadedFiles?.length && (
                uploadedFiles.map((item, index) =>
                    <FileRow
                        key={index}
                        file={item}
                        handleClick={handleClick}
                        handleDelete={() => setDeleteItemId(item.id)}
                    />)
            )}
            {!uploadedFiles?.length && (
                <div className="d-flex flex-row justify-content-center">
                    <label className="font-size-20 font-weight-bold py-5 my-4">
                        No uploaded images and documents
                    </label>
                </div>
            )}
            <Modal isOpen={isFullscreen} size="xl" toggle={() => setIsFullscreen(false)}>
                <FullScreenMode photo={image} enableZoom={false} handleClose={() => setIsFullscreen(false)} />
            </Modal>
            {deleteItemId && (
                <CancelModal
                    visibleModal={!!deleteItemId}
                    setVisibleModal={() => setDeleteItemId(null)}
                    handleClose={handleDelete}
                    text="Are you sure you want to delete this file/image?"
                    header="Delete"
                />
            )}
        </Card>
    )
}

export default UploadedFiles;