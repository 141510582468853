import React, {useCallback, useState} from 'react';
import {Badge, Card, Col, Modal, Row} from 'reactstrap';

import EyeIcon from 'assets/images/ic-eye-o-gray.svg';
import thrashIcon from "assets/images/ic-thrash-red.svg";
import {CONFIRMED, documentStatuses, PENDING, REJECTED} from 'constants/documentStatuses';
import PatientManagementService from 'services/patientsManagement';
import FullScreenMode from 'pages/Dashboard/appointmentsDetails/fullScreenMode';
import CancelModal from "components/manageSessionModal/cancelModal";

import CompareModal from './compareMode';

const PENDING_STRING = "Pending";

const badgeColor = {
    "Pending": "primary",
    "Rejected": "danger",
    "Confirmed": "success",
};

export default function Document({ patientDocument, patientInfo, deletePatientID, showDeleteButton, getPatient }) {
    const [visibleModal, setVisibleModal] = useState(false);
    const [showFullscreen, setShowFullscreen] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [document, setDocument] = useState(patientDocument);
    const [deleteItemId, setDeleteItemId] = useState(null);

    async function handleChangeDocumentStatus(status) {
        try {
            await PatientManagementService.changeDocumentStatus(document.id, { status })
            setDocument(prevState => {
                return {
                    ...prevState,
                    status: documentStatuses[status]
                }
            })

            getPatient();
        } finally {
            setVisibleModal(false);
        }
    }

    const handleDelete = useCallback(async () => {
        try {
            await deletePatientID(deleteItemId);
        } finally {
            setDeleteItemId(null);
        }
    }, [setDeleteItemId, deleteItemId])

    return (
        <>
            <Card className="px-3">
                <Row className="pb-0">
                    <Col xl={6} className="d-flex flex-column justify-content-between patient-document">
                        <section>
                            <div className="d-flex justify-content-between align-items-center pb-1 mb-2">
                                <label className="document-title mb-0">{document.title}</label>
                                {showDeleteButton && (
                                    <img
                                        width="16px"
                                        height="18px"
                                        src={thrashIcon}
                                        alt=""
                                        className="cursor-pointer"
                                        onClick={() => setDeleteItemId(patientDocument?.id)}
                                    />
                                )}

                            </div>
                            <div>
                                <label className="status-label mr-3">Status</label>
                                <Badge className={`font-size-12 color-${badgeColor[document.status]}`}
                                       pill>{document.status}</Badge>
                            </div>
                        </section>
                        {document.status === PENDING_STRING && (
                            <section>
                                <button
                                    className="btn btn-primary mr-3 status-button"
                                    onClick={async () => handleChangeDocumentStatus(CONFIRMED)}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="btn btn-outline-primary status-button"
                                    onClick={async () => handleChangeDocumentStatus(REJECTED)}
                                >
                                    Reject
                                </button>
                            </section>)}
                    </Col>
                    <Col xl={6} className="patient-document px-0">
                        <section className="d-flex flex-row justify-content-between mx-2">
                            <img src={document.selfie} alt="" className="document-photo mr-2 cursor-pointer"
                                 onClick={() => {
                                     setPhoto(document.selfie);
                                     setShowFullscreen(true)
                                 }}/>
                            <img src={document.photo} alt="" className="document-photo cursor-pointer" onClick={() => {
                                setPhoto(document.photo);
                                setShowFullscreen(true)
                            }}/>
                        </section>
                        <section className="d-flex flex-row justify-content-center align-items-center mt-2">
                            <button className="btn btn-outline p-0" onClick={() => setVisibleModal(true)}>
                                <img src={EyeIcon} alt="" className="cursor-pointer mr-1"/>
                                <label className="compare-label cursor-pointer pb-0 mb-0">Compare</label>
                            </button>
                        </section>
                    </Col>
                </Row>
            </Card>
            <Modal isOpen={showFullscreen} size="xl" toggle={() => setShowFullscreen(false)}>
                <FullScreenMode photo={photo} handleClose={() => setShowFullscreen(false)}/>
            </Modal>
            <CompareModal
                visibleModal={visibleModal}
                handleCompare={async () => handleChangeDocumentStatus(CONFIRMED)}
                handleReject={async () => handleChangeDocumentStatus(REJECTED)}
                handleClose={() => setVisibleModal(false)}
                documents={document}
                info={patientInfo}
                showActionsButtons={document.status === documentStatuses[PENDING]}
            />
            {!!deleteItemId && (
                <CancelModal
                    visibleModal={!!deleteItemId}
                    setVisibleModal={() => setDeleteItemId(null)}
                    handleClose={handleDelete}
                    text="Are you sure you want to delete documents?"
                    header="Delete"
                    cancelButtonTitle="Cancel"
                />
            )}
        </>
    );
}
