
const parseChildren = (parents) => {

    return [...parents.flatMap((item) => {
        if (item?.children?.data?.length > 0) {
            return [item, ...item?.children?.data.map((child) => ({...child, isChild: true}))]
        }
        return [item]
    })]
}

export default parseChildren