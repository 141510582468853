import React from 'react';

import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authRoutes } from "./routes/allRoutes";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import LayoutWithoutSidebar from "./components/VerticalLayout/layoutWithoutSidebar";
import NonAuthLayout from "./components/NonAuthLayout";
import {PATIENT_ROUTES} from "./constants/routes";

import PrivateRoute from './routes/middleware/privateRoute';
import Dashboard from "./pages/Dashboard/index";
import EmptyPage from './pages/Dashboard/emtyPagePlaceholder';
import PatientManagement from './pages/patientManagement/patientManagement';
import PatientDetails from './pages/patientManagement/patientDetails';
import Call from './pages/Calls/call';
import EditPatientDetails from './pages/patientManagement/editPatientDetails';
import AppointmentDetails from './pages/Dashboard/appointmentDetails';
import Settings from './pages/settings/settings';
import ActivationCode from "./pages/ActivationCode/activationCode";
import Clinicians from "./pages/Clinicians/clinicians";
import LogHistory from "./pages/patientManagement/LogHistory";
import EditChildDetails from "./pages/patientManagement/editChildDetails";

import EmailVerification from './pages/patientVerification/email';
import EmailExpiredLink from './pages/patientVerification/expiredLink';
import ResendEmailLink from './pages/patientVerification/resendEmailLink';
import {ChangePasswordByPatient} from "./pages/patientVerification/ChangePasswordByPatient";
import {ExpiredPatientPasswordLink} from "./pages/patientVerification/ExpiredPatientPasswordLink";
import {ResendPasswordLink} from "./pages/patientVerification/ResendPasswordlink";


// Import scss
import "./assets/scss/theme.scss";

const App = (props) => {

	function getLayout() {
		let layoutCls = VerticalLayout;

		switch (props.layout.layoutType) {
			default:
				layoutCls = VerticalLayout;
				break;
		}
		return layoutCls;
	}

	const Layout = getLayout();

	const NonAuthmiddleware = ({
		component: Component,
		layout: Layout
	}) => (
		<Route
			render={props => {
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}}
		/>
	);

	return (
		<React.Fragment>
			<Router>
				<Switch>
					{authRoutes.map((route, idx) => (
						<NonAuthmiddleware
							path={route.path}
							layout={NonAuthLayout}
							component={route.component}
							key={idx}
						/>
					))}

					<PrivateRoute
						exact
						layout={Layout}
						path="/appointments"
						component={Dashboard}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/appointments/appointment"
						component={AppointmentDetails}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/patients-management"
						component={PatientManagement}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/activation-code"
						component={ActivationCode}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/clinicians-management"
						component={Clinicians}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/user-analytics"
						component={EmptyPage}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/settings"
						component={Settings}
					/>
					<PrivateRoute
						exact
						layout={LayoutWithoutSidebar}
						path="/call"
						component={Call}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/patients-management/patients"
						component={PatientDetails}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/patients-management/patients/child"
						component={PatientDetails}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/patients-management/patients/log"
						component={LogHistory}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/patients-management/patients/edit"
						component={EditPatientDetails}
					/>
					<PrivateRoute
						exact
						layout={Layout}
						path="/patients-management/patients/child/edit"
						component={EditChildDetails}
					/>
					<Route
						exact
						layout={Layout}
						path="/email-verification"
						component={EmailVerification}
					/>
					<Route
						exact
						layout={Layout}
						path="/email-expired-link"
						component={EmailExpiredLink}
					/>
					<Route
						exact
						layout={Layout}
						path="/resend-email-link"
						component={ResendEmailLink}
					/>
					<Route
						exact
						layout={Layout}
						path={PATIENT_ROUTES.restorePassword}
						component={ChangePasswordByPatient}
					/>
					<Route
						exact
						layout={Layout}
						path={PATIENT_ROUTES.passwordExpiredLink}
						component={ExpiredPatientPasswordLink }
					/>
					<Route
						exact
						layout={Layout}
						path={PATIENT_ROUTES.resendPasswordLink}
						component={ResendPasswordLink}
					/>
					<Redirect from="*" to="/appointments" />
				</Switch>
			</Router>
		</React.Fragment>
	);
}


const mapStateToProps = state => {
	return {
		layout: state.Layout
	};
};

export default connect(mapStateToProps)(App);
