import Select, { components } from "react-select";
import React from "react";
/*eslint-disable*/

const { DropdownIndicator, Option } = components;

const Arrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
            <path d="M0 0H24V24H0z"
                transform="translate(-876 -276) translate(470 142) translate(44 99) translate(362 35)" />
            <path fill="#555B6D" d="M7.41 8.58L12 13.17l4.59-4.59L18 10l-6 6-6-6 1.41-1.42z"
                transform="translate(-876 -276) translate(470 142) translate(44 99) translate(362 35)" />
        </g>
    </svg>
);

const CustomDropdownIndicator = (props) => {
    return (
        <DropdownIndicator {...props}>
            <Arrow />
        </DropdownIndicator>
    );
};

const IndicatorSeparator = () => {
    return null;
};

const CustomOption = props => {
    return (
        <div className="search-options">
            <Option {...props} />
        </div>
    );
};

const componentsMap = {
    DropdownIndicator: CustomDropdownIndicator,
    IndicatorSeparator,
    Option: CustomOption
};

export const customStyles = {
    menu: styles => ({
        ...styles,
        width: '40%',
        maxHeight: 225,
        right: 0,
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: 225,
    }),
    singleValue: styles => ({
        ...styles,
        color: '#495057',
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        padding: 6,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    control: (styles, { isDisabled }) => ({
        ...styles,
        maxHeight: 37,
        cursor: isDisabled ? 'not-allowed' : 'default',
        backgroundColor: isDisabled ? '#eff2f7' : 'white',
        color: isDisabled ? '#495057' : 'white',
        borderColor: isDisabled ? "#ced4da" : "#ced4da"
    }),
    valueContainer: () => ({
        paddingLeft: 12,

    }),
};

const {...styles} = customStyles;

const selectStyles = {
    ...styles,
    control: (styles, { isDisabled }) => ({
        ...styles,
        maxHeight: 37,
        fontSize: 13,
        cursor: isDisabled ? 'not-allowed' : 'default',
        backgroundColor: isDisabled ? '#FFFFFF' : 'white',
        color: isDisabled ? '#ffffff' : 'white'
    }),
    menu: styles => ({
        ...styles,
        width: '100%',
        maxHeight: 225,
    }),
    menuList: styles => ({
        ...styles,
        maxHeight: 225,
    }),
    singleValue: styles => ({
        ...styles,
        color: '#495057',
    }),
};

export default ({ onChange, options, defaultValue}) => {
    return (
        <Select
            styles={selectStyles}
            className="w-100"
            options={options}
            onChange={onChange}
            components={componentsMap}
            isSearchable={false}
            defaultValue={defaultValue}
        />
    )
}
