import React from "react";
import {ErrorMessage, Formik} from "formik";
import * as yup from "yup";
import {Row} from "reactstrap";

import {requiredError, errorMessage} from "constants/errorMessages";
import {MAX_NOTE, MIN_NOTE} from "constants/lengthValidity";
import {strWithNoOnlySpaces} from "constants/validityPatterns";
import {AutoSizeTextArea} from "components/form/AutoSizeTextArea";
import {consultationNotesFormFields} from "constants/appointment";

const commonErrorMessage = `Must be from ${MIN_NOTE} to ${MAX_NOTE} symbols and cannot contain spaces only`;

const commonValidationRules = yup.string()
    .min(MIN_NOTE, commonErrorMessage)
    .max(MAX_NOTE, commonErrorMessage)
    .matches(strWithNoOnlySpaces, commonErrorMessage)
    .required(requiredError);

const passwordValidationSchema = yup.object().shape({
    problem: commonValidationRules,
    history: commonValidationRules,
    pastMedicalHX: commonValidationRules,
    examination: commonValidationRules,
    comments: commonValidationRules,
    safetyNet: commonValidationRules
});

export const ConsultationNotesForm = ({ handleSubmit, handleCancel, notesInitialValues }) => {
    return (
        <>

            <Formik
                initialValues={notesInitialValues}
                onSubmit={handleSubmit}
                validationSchema={passwordValidationSchema}
            >
                {({
                    values,
                    handleBlur,
                    handleSubmit,
                    handleChange,
                    isSubmitting,
                    isValid,
                    dirty
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Row className="justify-content-between">
                                <label className="details-caption">Consultation notes</label>
                                <section>
                                    <button
                                        type="button"
                                        className="btn btn-danger px-4"
                                        onClick={handleCancel}
                                        disabled={isSubmitting || !isValid}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="btn btn-primary px-4 ml-3"
                                        type="submit"
                                        disabled={isSubmitting || !isValid || !dirty}
                                    >
                                        Save
                                    </button>
                                </section>
                            </Row>
                            <div>
                                {consultationNotesFormFields.map(note => (
                                    <div
                                        key={note.name}
                                        className="mt-4"
                                    >
                                        <label>{note.label}</label>
                                        <AutoSizeTextArea
                                            placeholder="Additional comment"
                                            name={note.name}
                                            value={values[note.name]}
                                            onBlur={handleBlur}
                                            className="form-control"
                                            onChange={handleChange}
                                        />
                                        <ErrorMessage name={note.name} render={errorMessage}/>
                                    </div>
                                ))}
                            </div>
                        </form>
                    )
                }}
            </Formik></>

    )
};
