import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import {FLATPICKER_FORMAT, FLATPICKER_FORMAT_ALT} from '../../constants/dateFormats';
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr";

export default function CustomDatePicker({
    handleChangeDate,
    minDate,
    dayOfBirth,
}) {

    const defaultValue = useMemo(() => dayOfBirth ? moment(dayOfBirth).toDate(): null, [dayOfBirth]);

    const onChange = useCallback(date => {
        handleChangeDate(date[0]);
    }, [handleChangeDate]);

    return (
        <Flatpickr
            value={defaultValue}
            className="form-control d-block"
            placeholder="Pick a date"
            options={{
                altInput: true,
                altFormat: FLATPICKER_FORMAT_ALT,
                dateFormat: FLATPICKER_FORMAT,
                maxDate: Date.now(),
                minDate: minDate,
            }}
            onChange={onChange}
        />
    )
}