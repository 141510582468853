import React, {useMemo, useState} from 'react';
import {Card} from 'reactstrap';
import * as _ from 'lodash';

import {CONFIRMED, documentStatuses} from 'constants/documentStatuses';

import Document from './patientDocument';
import {AddDocumentsModal} from "./AddDocumentsModal";

const MIN_NUMBER_OF_CONFIRMED_IDs = 1;

function getDocumentInfo(doc, name, avatar) {
    return {
        title: name,
        photo: _.get(doc, 'link', null),
        status: documentStatuses[_.get(doc, 'status', null)],
        selfie: _.get(avatar, 'link', null),
        id: _.get(doc, 'id', null),
    }
}

export default function PatientDocuments({ patient, handleSaveDocuments, deletePatientID, getPatient }) {
    const [isAddDocumentsModalOpen, setIsAddDocumentsModalOpen] = useState(false);

    function getDocumentsArray({
        driverLicense,
        passport,
        birthCertificate,
        identityCard,
        document: ChildDoc,
        avatar,
        photo = {}
    }) {

        const documents = [];

        if (driverLicense) {
            documents.push(getDocumentInfo(driverLicense, "Driving License", avatar));
        }

        if (passport) {
            documents.push(getDocumentInfo(passport, "Passport", avatar));
        }

        if (birthCertificate) {
            documents.push(getDocumentInfo(birthCertificate, "Birth Certificate", avatar));
        }

        if (identityCard) {
            documents.push(getDocumentInfo(identityCard, "National Identity Card", avatar));
        }

        if (ChildDoc) {
            documents.push(getDocumentInfo(ChildDoc, "Child ID", photo));
        }

        return documents;
    }

    const documents = useMemo(() => getDocumentsArray(patient), [patient]);

    const confirmedDocuments = documents.filter(document => document.status === documentStatuses[CONFIRMED]);

    const getShowDeleteButton = (documentStatus) => {
        return documentStatus !== documentStatuses[CONFIRMED] || (documentStatus === documentStatuses[CONFIRMED] && confirmedDocuments?.length > MIN_NUMBER_OF_CONFIRMED_IDs)
    };

    return (
        <>
            <Card className="px-4 py-3 details-documents">
                <div className="d-flex justify-content-between align-items-center mb-1">
                    <label className="details-caption">Documents</label>
                    {(!patient?.isDeleted && !patient?.parentWasDeleted) ? (
                        <button onClick={() => setIsAddDocumentsModalOpen(true)}
                                className="btn btn-outline-primary px-4 mb-2">+ Add documents
                        </button>
                    ) : null
                    }
                </div>
                {(!patient?.isDeleted && !patient?.parentWasDeleted && documents.length) ? documents.map((document, index) => (
                    <Document
                        patientDocument={document}
                        key={index}
                        patientInfo={patient}
                        deletePatientID={deletePatientID}
                        showDeleteButton={getShowDeleteButton(document.status)}
                        getPatient={getPatient}
                    />
                )) : <label>-</label>}
            </Card>
            <AddDocumentsModal
                visibleModal={isAddDocumentsModalOpen}
                handleClose={() => setIsAddDocumentsModalOpen(false)}
                patient={patient}
                handleSaveDocuments={handleSaveDocuments}
            />
        </>
    );
}
