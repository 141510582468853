import Http from './axios-config';
import {CLINICIANS_LIMIT} from "../constants/paginationLimits";

class CliniciansService {
    constructor() {
        this.http = new Http();
    }

    async getCliniciansList({limit = CLINICIANS_LIMIT, offset = 0, query=""}) {
        return await this.http.get(`/clinical-admins/doctors?limit=${limit}&offset=${offset}&query=${query}`);
    }

    async createClinician(model) {
        return await this.http.post('/clinical-admins/doctors', model);
    }

    async getClinician(id) {
        return await this.http.get(`/clinical-admins/doctors/${id}`);
    }

    async updateClinician(id, model) {
        return await this.http.put(`/clinical-admins/doctors/${id}`, model);
    }
}

export default new CliniciansService();
