import Http from "./axios-config";
import axios from "axios";

class S3Service {
    constructor() {
        this.http = new Http()
    }

    /**
     * Sending request on adding file to S3
     * fileType is a constants variable
     * */
    async requestLoading(model) {
        return await this.http.post("/files", model);
    }

    async putFile(model) {
        return await this.http.put("/files", model);
    }

    async uploadFileToUrl(url, values) {
        return await axios.post(url, values);
    }

    /**
     * Direct S3 file loading
     * */
    async uploadToS3(model) {
        const { data, file } = model;
        const formData = new FormData();

        Object.keys(data.meta.formData).forEach((key) => {
            formData.append(key, data.meta.formData[key].toString());
        });

        formData.append("file", file);

        await this.uploadFileToUrl(data.meta.url, formData);
        return data.data;
    }

    /**
     * Simplified image uploading process
     * */
    async uploadFile(file, fileType, isContentTypeRequired = true) {
        const data = await this.requestLoading({
            ...( isContentTypeRequired && { contentType: file.type } ),
            fileType
        });
        return await this.uploadToS3({ data, file });
    }
}

export default new S3Service();
