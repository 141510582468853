import React from 'react';
import { Container } from "reactstrap";

export default function EmptyPage() {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                </Container>
            </div>
        </React.Fragment>
    )
}