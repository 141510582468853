import React from 'react';
import sortOption from '../../../assets/images/ic-arrow.svg';

export default function TableHeader({ columns, clickCellAction }) {
    return (
        <thead className="thead-light ">
            <tr>
                {columns.map((column, index) =>
                (
                    <th
                        key={index}
                        className={`table-caption ${column.classname}`}>
                        <span
                            onClick={() => clickCellAction(column)}
                            className={column.sortOption ? "cursor-pointer" : ""}
                        >
                            {column.label}{column.sortOption && <img src={sortOption} alt="" className="ml-2" />}
                        </span>
                    </th>
                ))
                }
            </tr>
        </thead>
    )
}