import React from "react";
import * as _ from "lodash";

const DefaultComponent = ({ value: children }) => children;

const SimpleTableBody = ({ data, structure }) => {

    return (
        <tbody>
        {data.length > 0 && data.map((dataRow, index) => (
            <tr key={index}>
                {structure.map(({ name, classname, keyProp, Component = DefaultComponent }) => (
                    <td key={name} className={" " + classname}>
                        <Component value={_.get(dataRow, keyProp, dataRow)}/>
                    </td>
                ))}
            </tr>
        ))}
        </tbody>
    )
}

export default SimpleTableBody;