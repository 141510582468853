import React, { useState, useEffect, useRef } from "react";
import { TRACK_SUBSCRIBED, TRACK_UNSUBSCRIBED, AUDIO, VIDEO, TRACK_DISABLED, TRACK_ENABLED } from '../../../constants/callConstants';
import AvatarLogo from '../../../components/avatar/tableAvatar';
import * as _ from 'lodash';

const Participant = ({ participant, classname, isLocal, patient, newSpeakers, localVideo, volume, videoMuted }) => {
    const [videoTracks, setVideoTracks] = useState([]);
    const [audioTracks, setAudioTracks] = useState([]);
    const [videoOff, setVideoOff] = useState(false);

    const videoRef = useRef();
    const audioRef = useRef();

    const patientAvatar = _.get(patient, 'patient.avatar.resizedLink', null);
    const patientName = _.get(patient, 'patient.firstName', '-');

    const trackpubsToTracks = (trackMap) => {
        return Array.from(trackMap?.values())
            .map((publication) => publication.track)
            .filter((track) => track !== null);
    }

    useEffect(() => {
        setVideoTracks(trackpubsToTracks(participant.videoTracks));
        setAudioTracks(trackpubsToTracks(participant.audioTracks));
        const trackSubscribed = (track) => {
            if (track.kind === VIDEO) {
                setVideoOff(false)
                if (!track.isEnabled && !isLocal && !track.isTrackEnabled) {
                    setVideoOff(true)
                }
                setVideoTracks((videoTracks) => [...videoTracks, track]);
            } else if (track.kind === AUDIO) {
                setAudioTracks((audioTracks) => [...audioTracks, track]);

            }
        };

        const trackUnsubscribed = (track) => {
            if (track.kind === VIDEO) {
                setVideoOff(true)
                setVideoTracks((videoTracks) => videoTracks.filter((videoTrack) => videoTrack !== track));
            } else if (track.kind === AUDIO) {
                setAudioTracks((audioTracks) => audioTracks.filter((audioTrack) => audioTrack !== track));
            }
        };

        participant.on(TRACK_SUBSCRIBED, trackSubscribed);
        participant.on(TRACK_UNSUBSCRIBED, trackUnsubscribed);

        participant.videoTracks.forEach((track) => {
            track.on(TRACK_DISABLED, () => trackUnsubscribed(track));
            track.on(TRACK_ENABLED, () => trackSubscribed(track));
        })
        return () => {
            setVideoTracks([]);
            setAudioTracks([]);
            participant.removeAllListeners();
        };
    }, [participant, isLocal]);

    useEffect(() => {
        videoTracks.forEach(track => {
            let newTrack = track;
            if (track.isTrackEnabled) {
                newTrack = track.track;
            }
            if (newTrack) {
                newTrack.attach(videoRef.current);
                return () => {
                    newTrack.detach();
                };
            }
        })
    }, [videoTracks]);

    useEffect(() => {
        const audioTrack = audioTracks[0];
        if (audioTrack) {
            const speakers = audioTrack.attach(audioRef.current);
            if (newSpeakers) {
                speakers.setSinkId(newSpeakers).then(() => {
                    audioTrack.attach(speakers);
                    audioRef.current.volume = volume;
                });
            }
            audioRef.current.volume = volume;
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTracks, newSpeakers, volume]);

    useEffect(() => {
        if (localVideo) {
            setVideoTracks([localVideo])
        }
    }, [localVideo]);

    return (
        <div className="call-big-picture__remote-participant-container">
            {videoOff ?
                <div className={classname}>
                    {isLocal ?
                        <AvatarLogo width="65" height="65" name="Doctor" />
                        :
                        <div className=" d-flex align-items-center justify-content-center call-big-picture font-size-20">
                            <AvatarLogo width="220" height="220" name={patientName} avatar={patientAvatar} className="big-avatar-custom font-size-25" />
                        </div>
                    }
                </div>
                :
                <video
                    ref={videoRef}
                    autoPlay={true}
                    className={classname}
                />
            }
            <audio ref={audioRef} autoPlay={true} />
            {videoMuted ?
                <div className={classname}>
                    <AvatarLogo width="65" height="65" name="Doctor" />
                </div>
                : null}
        </div>
    );
};

export default Participant;