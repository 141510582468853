import React from 'react';
import { CardBody, Card } from "reactstrap";
import expiredLinkLogo from '../../assets/images/expired-verification-link.svg';
import { useHistory, useLocation } from 'react-router-dom';
import queryParser from '../../helpers/queryStringParser';

function EmailExpiredLink() {
    const history = useHistory();
    const location = useLocation();

    const { token } = queryParser(location.search);

    function handleResendLink() {
        history.push({
            pathname: '/resend-email-link',
            search: `token=${token}`,
        })
    }

    return (
        <Card className="expiredLinkCard overflow-hidden text-center mx-auto">
            <CardBody className="p-2">
                <img src={expiredLinkLogo} alt="" />
                <div className="">
                    <label className="expiredLinkLabel mt-4">Your link has expired</label>
                    <div className="mt-5 text-center">
                        <button className="btn btn-primary w-100" onClick={handleResendLink}>Resend Link</button>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}

export default EmailExpiredLink;