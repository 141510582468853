import React from "react";

const NoDataPlaceholder = ({ message = "No logs yet" }) => {

    return (
        <div className="d-flex align-items-center justify-content-center no-coupons">
            <div className="d-flex flex-column align-items-center">
                <label className="font-size-16 mt-4 no-coupons-label ">{message}</label>
            </div>
        </div>
    )
}

export default NoDataPlaceholder;