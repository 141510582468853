import React, { useState } from 'react';

import { connect } from "react-redux";

import { withRouter } from "react-router-dom";

import "./layout.scss";

import logoWhite from '../../assets/images/logo-white.svg';
import iconLogout from '../../assets/images/ic-log-out.svg'

//i18n
import { withNamespaces } from 'react-i18next';
// Redux Store
import { toggleLeftmenu, changeSidebarType, logoutUser } from "../../store/actions";
import logoBlue from '../../assets/images/logo-blue.svg';
import LogOutModal from './logOutModal';
import { DEFAULT, CONDENSED } from '../../constants/sidebarsTypes';
import GlobalSearch from '../searchField/globalSearchHeader';

const Header = ({ toggleLeftmenu, leftSideBarType, leftMenu, changeSidebarType, logoutUser, history, customHideSidebar }) => {

    const [search, setsearch] = useState(false);
    const [showLogOutModal, setShowLogOutModal] = useState(false);

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function tToggle() {
        toggleLeftmenu(!leftMenu);
        if (leftSideBarType === DEFAULT) {
            changeSidebarType(CONDENSED, isMobile);
        } else if (leftSideBarType === CONDENSED) {
            changeSidebarType(DEFAULT, isMobile);
        }
    }

    function handleLogOut() {
        logoutUser(history);
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className={"logo logo-light " + (customHideSidebar ? "custom-header" : "navbar-brand-box")}>
                            <span className="logo-lg">
                                <img src={customHideSidebar ? logoBlue : logoWhite} alt="" height="50" />
                            </span>
                        </div>
                        {!customHideSidebar &&
                            <button type="button" onClick={() => { tToggle() }} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                                <i className="fa fa-fw fa-bars"></i>
                            </button>
                        }
                        <section className="pt-3">
                            <GlobalSearch />
                        </section>
                    </div>

                    <div className="d-flex">
                        <div className="dropdown d-inline-block d-lg-none ml-2">
                            <button onClick={() => { setsearch(!search) }} type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                                <i className="mdi mdi-magnify"></i>
                            </button>
                            <div className={search ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                aria-labelledby="page-header-search-dropdown">
                                <form className="p-3">
                                    <div className="form-group m-0">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <img src={iconLogout} alt="" height="25" className="align-self-center ml-1 cursor-pointer" onClick={() => setShowLogOutModal(true)} />
                    </div>
                </div>
            </header>
            <LogOutModal handleSubmit={handleLogOut} visibleModal={showLogOutModal} handleClose={() => setShowLogOutModal(false)} />
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { layoutType, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, { logoutUser, toggleLeftmenu, changeSidebarType })(withNamespaces()(withRouter(Header)));
