import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import React, { useState } from 'react';
import { Badge } from "reactstrap";
import * as _ from 'lodash';
import { useLocation } from "react-router-dom";

import gorizontalOptionsLabel from 'assets/images/ic-more-black.svg';
import AppointmentsService from 'services/appointments';
import queryParser from "helpers/queryStringParser";
import { MAX_COMMENTS_COUNT } from 'constants/lengthValidity';
import CreateAppointment from 'components/appointments/appointmentWithDate';
import {
    SEEING_STATUS,
    LEFT_STATUS,
    DNA_STATUS,
    PENDING_STATUS,
    NEW_PENDING_STATUS,
    UNPAID_STATUS
} from 'constants/appointmentStatuses';
import checkForCallDisabled from 'helpers/checkCallButtonDisabled';
import formatIsoString from 'helpers/formatIso86Date';
import { PAID_APPOINTMENT, ADD_COSULTATION_LABEL } from "constants/appointmentTypes";
import StorageService from 'services/StorageService';
import { CALL_VARIABLES, NOTES_VARIABLES } from 'constants/storageConstants';
import {PENDING, WAITING_FOR_UPLOAD} from "constants/documentStatuses";
import { getFullName } from "helpers/getFullName";

import ConfirmModal from './confirmModal';
import ConsultationForm from './consultationForm';
import RescheduleAppointment from './rescheduleAppointment';
import CommentsCount from './commentsCount';
import CreateCommentModal from './createCommentModal';

function StartACall(row, history) {
    new StorageService().deleteValueByKey(CALL_VARIABLES);
    new StorageService().deleteValueByKey(NOTES_VARIABLES);
    history.push(`/call?id=${_.get(row, 'id', "")}`);
}

function ViewAppointmentDetails(row, history) {
    history.push(`/appointments/appointment?id=${_.get(row, 'id', "")}`);
}

export const NameComponent = ({ value }) => {
    const name = value?.child ? getFullName(value.child) : getFullName(value.patient);

    return (
        <span>
            {name}
        </span>
    )
}

export const tableHeader = [
    "Time",
    "Patient",
    "Com",
    "Status",
    "",
];

export const MANAGE_SESSION = 'Manage session';
export const REMOVE_SESSION = 'Remove session';

export const tableOptions = [
    MANAGE_SESSION,
    REMOVE_SESSION
];
const BLOCKED = "Block";
const PENDING_ID = 'Pending ID approval';
const AWAITING_ID = "Awaiting ID";

const badgeBackColors = ['', "transparent", "transparent", "primary", "warning", "success", "secondary", "blocked",  "violet", "danger", "info"];
const badgeValues = ['', "", "", "Arrived", "Seeing", "Left", "DNA",  BLOCKED, 'Unpaid', PENDING_ID, AWAITING_ID];

function checkIfBlockedOrPendingId(value) {
    if (value.isBlocked) {
        return badgeValues.indexOf(BLOCKED);
    }

    if (value.child && value.child.documentCheckStatus === PENDING) {
        return badgeValues.indexOf(PENDING_ID);
    }

    if (value.child && value.child.documentCheckStatus === WAITING_FOR_UPLOAD) {
        return badgeValues.indexOf(AWAITING_ID);
    }

    if (value.patient && value.patient.documentCheckStatus === PENDING) {
        return badgeValues.indexOf(PENDING_ID);
    }

    return value.status
}

export const dataStructure = [
    { name: "time", classname: 'appointment-row px-1 pt-1', keyProp: "startsAt", Component: ({ value }) => <span>{formatIsoString(value)}</span> },
    { name: "patientName", classname: 'appointment-row px-1 pt-1', Component: NameComponent },
    { name: "com", classname: 'appointment-row px-1 pt-1', Component: ({ value, updateTable }) => <CommentsCount value={value} updateTable={updateTable} /> },
    { name: "status", classname: 'appointment-row px-1 pt-1', Component: ({ value }) => <Badge className={`font-size-10 font-color-white background-${badgeBackColors[checkIfBlockedOrPendingId(value)]}`}>{badgeValues[checkIfBlockedOrPendingId(value)]}</Badge> },
    { name: "dropDown", classname: 'appointment-row px-1 pt-1', Component: ({ value, history, updateTable, getAppointmentSlots, doctor }) => DropDown(value, history, updateTable, getAppointmentSlots, doctor) },
];

export function DropDown(value, history, updateTable, getAppointmentSlots, doctor) {
    const [visibleModalStatus, setVisibleModalStatus] = useState(false);
    const [visibleModalConsultations, setVisibleModalConsultations] = useState(false);
    const [showCommentsModal, setShowCommentsModal] = useState(false);
    const [showRescheduleModal, setShowRescheduleModal] = useState(false);
    const [showDeleteModal, setShowdeleteModal] = useState(false);
    const [showCreateAppointmentModal, setSowCreateAppointmentModal] = useState(false);

    const location = useLocation();

    const isAppointmentPaid = value.appointmentType === PAID_APPOINTMENT;

    const isAppointmentUnpaid = value.status === UNPAID_STATUS;

    const pickedDate = queryParser(location.search).date || new Date();

    async function blockUnblockSlot() {
        await AppointmentsService.blockUnblockAppointmentSlot(value.id);
        updateTable((prevState) => {
            const currentSession = prevState.find(session => session.id === value.appointmentSessionId);
            const currentAppointment = currentSession.appointments.find((appointment) => appointment.id === value.id);
            currentAppointment.isBlocked = !currentAppointment.isBlocked;
            const newTable = [...prevState];
            return newTable;
        })
    }

    async function setNewStatus(status) {
        try {
            await AppointmentsService.setDNAStatus(value.id, { status: status });
            updateTable((prevState) => {
                const currentSession = prevState.find(session => session.id === value.appointmentSessionId);
                const currentAppointment = currentSession.appointments.find((appointment) => appointment.id === value.id);
                currentAppointment.status = status;
                const newTable = [...prevState];
                return newTable;
            })
        } finally {
            setVisibleModalStatus(false);
        }
    }

    function addConsultation() {
        if (value.status === LEFT_STATUS) {
            setVisibleModalConsultations(true);
        }
    }

    async function sendConsultation(e, values) {
        try {
            await AppointmentsService.sendConsultation(value.id, values);
        } finally {
            setVisibleModalConsultations(false);
        }
    }

    function tryToClearStatus() {
        setVisibleModalStatus(true);
    }

    function showAppointmentComments() {
        setShowCommentsModal(true);
    }

    async function rescheduleAppointment(values) {
        try {
            await AppointmentsService.rescheduleAppointment(value.id, values);
            setShowRescheduleModal(false);
            await getAppointmentSlots(pickedDate);
        } finally {
            //empty
        }
    }

    function tryToRescheduleAppointment() {
        if (value.patient) {
            setShowRescheduleModal(true)
        }
    }

    async function deleteAppointment() {
        try {
            await AppointmentsService.deleteAppointment(value.id);
            await getAppointmentSlots(pickedDate);
        } finally {
            setShowdeleteModal(false)
        }
    }

    async function handleCreateComment(e, values) {
        try {
            await AppointmentsService.addComment(value.id, values);
            updateTable((prevState) => {
                const currentSession = prevState.find(session => session.id === value.appointmentSessionId);
                const currentAppointment = currentSession.appointments.find((appointment) => appointment.id === value.id);
                currentAppointment.commentsCount = currentAppointment.commentsCount ? currentAppointment.commentsCount + 1 : 1;
                const newTable = [...prevState];
                return newTable;
            })
        } finally {
            setShowCommentsModal(false);
        }
    }

    async function bookAppointment(values) {
        try {
            await AppointmentsService.createAppointment(value.id, values);
            await getAppointmentSlots(pickedDate);
            setSowCreateAppointmentModal(false);
        } catch {
            //empty
        }
    }

    async function squeezeSlot() {
        try {
            await AppointmentsService.squeezeAppointmentSlot(value.id);
            await getAppointmentSlots(pickedDate);
        } catch {
            //empty
        }
    }

    const isStartCallDisabled = checkForCallDisabled(value);

    const commentDisabled = value.commentsCount >= MAX_COMMENTS_COUNT || !value.patient || !isAppointmentPaid;

    const detailsDisabled = !value.patient || value.patient?.isDeleted;

    const addConsultationDisabled = isAppointmentPaid || !value.patient;

    const squeezeInDisabled = false;

    const clearStatusDisabled = !isAppointmentPaid;

    const setPaidDisabled = !isAppointmentUnpaid;

    const markAsDNADisabled = !isAppointmentPaid;

    const isBlockOptionDisabled = value.status !== PENDING_STATUS;

    const isBookingDisabled = !!value.patient || value.isBlocked;

    const isRescheduleDisabled = !value.patient || !isAppointmentPaid;

    const deleteDisabled = value.status === SEEING_STATUS || !isAppointmentPaid;

    const startCallLabel = value.status === SEEING_STATUS ? "Rejoin the call" : "Start a call";

    const markAsLeftDisabled = false;

    const dropDownOptions = [
        { label: startCallLabel, action: StartACall, disabled: isStartCallDisabled, classname: isStartCallDisabled && "disabled-text" },
        { label: "Comment", action: showAppointmentComments, disabled: commentDisabled, classname: commentDisabled && "disabled-text" },
        { label: "View details", action: ViewAppointmentDetails, disabled: detailsDisabled, classname: detailsDisabled && "disabled-text" },
        { label: ADD_COSULTATION_LABEL, action: addConsultation, disabled: addConsultationDisabled, classname: addConsultationDisabled && "disabled-text"},
        { label: "Squeeze in", action: squeezeSlot, disabled: squeezeInDisabled, classname: squeezeInDisabled && "disabled-text"},
        { label: "Clear status", action: tryToClearStatus, disabled: clearStatusDisabled, classname: clearStatusDisabled && "disabled-text"},
        { label: "Clear unpaid appointment", action: async () => await setNewStatus(NEW_PENDING_STATUS), disabled: setPaidDisabled, classname: setPaidDisabled && "disabled-text"},
        { label: "Mark as DNA", action: () => setNewStatus(DNA_STATUS), disabled: markAsDNADisabled, classname: markAsDNADisabled && "disabled-text" },
        { label: "Mark as Left", action: () => setNewStatus(LEFT_STATUS), disabled: markAsLeftDisabled, classname: markAsLeftDisabled && "disabled-text" },
        { label: `${value.isBlocked ? 'Unblock' : 'Block'} slot`, action: blockUnblockSlot, disabled: isBlockOptionDisabled, classname: isBlockOptionDisabled && "disabled-text" },
        { label: 'Book appointment', action: () => setSowCreateAppointmentModal(true), disabled: isBookingDisabled, classname: isBookingDisabled && "disabled-text" },
        { label: "Reschedule appointment", action: tryToRescheduleAppointment, disabled: isRescheduleDisabled, classname: isRescheduleDisabled && "disabled-text" },
    ];

    return (
        <>
            <UncontrolledDropdown direction="left" >
                <DropdownToggle href="#" className="card-drop" tag="i">
                    <img src={gorizontalOptionsLabel} alt="" className="cursor-pointer" />
                </DropdownToggle>
                <DropdownMenu className="mb-4 custom-dropdown-styles">
                    {dropDownOptions.filter(option => {
                        if(isAppointmentPaid){
                            return option.label !== ADD_COSULTATION_LABEL;
                        }
                        return option;
                    }).map((option, index) => <DropdownItem
                        onClick={() => option.action && option.action(value, history)}
                        className={'table-text ' + option.classname}
                        disabled={option.disabled}
                        key={index}>{option.label}
                    </DropdownItem>
                    )}
                    <DropdownItem onClick={() => setShowdeleteModal(true)} disabled={deleteDisabled} className={!deleteDisabled ? "text-danger" : "disabled-text"}>Delete</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            <ConfirmModal visibleModal={visibleModalStatus}
                handleSubmit={async () => await setNewStatus(NEW_PENDING_STATUS)}
                handleClose={() => setVisibleModalStatus(false)}
                text="Do you want to discard current status?"
                header="Clear status"
            />
            <ConfirmModal visibleModal={showDeleteModal}
                handleSubmit={deleteAppointment}
                handleClose={() => setShowdeleteModal(false)}
                text="Do you want to delete this appointment?"
                header="Delete Appointments"
            />
            <ConsultationForm
                visibleModal={visibleModalConsultations}
                handleSubmit={sendConsultation}
                handleClose={() => setVisibleModalConsultations(false)}
            />
            {showCommentsModal && <CreateCommentModal
                visibleModal={showCommentsModal}
                handleSubmit={handleCreateComment}
                handleClose={() => setShowCommentsModal(false)}
            />}
            {showRescheduleModal && <RescheduleAppointment
                visibleModal={showRescheduleModal}
                handleClose={() => setShowRescheduleModal(false)}
                handleSubmit={rescheduleAppointment}
                appointment={value}
                pickedDate={pickedDate}
                doctor={doctor}
            />}
            {showCreateAppointmentModal && <CreateAppointment
                visibleModal={showCreateAppointmentModal}
                handleClose={() => setSowCreateAppointmentModal(false)}
                handleSubmit={bookAppointment}
                slot={value}
                pickedDate={pickedDate}
            />}
        </>
    )
}
