import React, { useState } from 'react';
import { Modal, Card } from "reactstrap";
import CancelModal from '../manageSessionModal/cancelModal';
import { Formik, ErrorMessage, Field } from 'formik';
import * as yup from 'yup';
import { requiredError, errorMessage, commentLengthError } from '../../constants/errorMessages';
import Search from '../searchField/searchFieldFormInput';
import formatDateOutput from '../../helpers/formatDateOutput';
import formatIsoString from '../../helpers/formatIso86Date';
import Label from '../Label/labelWithError';
import { MIN_NOTE, MAX_COMMENT } from '../../constants/lengthValidity';
import { SIMPLE_APPOINTMENT, PAID_APPOINTMENT } from '../../constants/appointmentTypes';

const schema = yup.object().shape({
    userId: yup.number().required(requiredError).typeError(requiredError),
    childId: yup.number().typeError(requiredError),
    reason: yup.string().required(requiredError).trim(commentLengthError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError),
});

export default function CreateAppointment({ visibleModal, handleClose, handleSubmit, slot, pickedDate }) {
    const [visibleCancelModal, setVisibleCancelModal] = useState(false);

    const initialValues = {
        userId: '',
        childId: '',
        date: formatDateOutput(pickedDate),
        time: formatIsoString(slot.startsAt),
        reason: '',
        appointmentType: [],
    }

    return (
        <Modal
            isOpen={visibleModal}
            centered={true}
            toggle={() => setVisibleCancelModal(true)}
        >
            <Card className="p-4 mb-0">
                <label className="pt-4 pl-4 font-size-22">Create Appointment</label>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        const appointmentType = values.appointmentType.length ? SIMPLE_APPOINTMENT : PAID_APPOINTMENT;

                        handleSubmit({
                            ...(values?.childId && { childId: values.childId } ),
                            userId: values.userId,
                            reason: values.reason,
                            appointmentType,
                        });
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit} className="form-horizontal px-4 mb-3">
                            <div className="form-group mb-2 mt-4">

                                <Label text="Patient" isInvalid={errors.userId && touched.userId} />
                                <Search
                                    handleChange={(value) => {
                                        if (value?.isChild) {
                                            setFieldValue("userId", value.parentId);
                                            setFieldValue("childId", value.id)
                                        } else {
                                            setFieldValue("userId", value.id)
                                        }
                                    }}
                                    displayChildren
                                />
                                <ErrorMessage name="userId" render={errorMessage} />

                                <section className="d-flex flex-row justify-content-between mt-4">
                                    <section className="d-flex flex-column mr-3">
                                        <label>Date</label>
                                        <input type="text" name="date" disabled className="form-control" value={values.date} />
                                    </section>
                                    <section className="d-flex flex-column ml-3">
                                        <label>Time</label>
                                        <input type="text" name="time" disabled className="form-control" value={values.time} />
                                    </section>
                                </section>
                            </div>
                            <div className="form-group mb-2 mt-4">
                                <section className="mt-2">
                                    <Label text="Reason" isInvalid={errors.reason && touched.reason} />
                                    <input type="text"
                                        name="reason"
                                        placeholder="Enter Reason"
                                        value={values.reason}
                                        className="w-100 form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="reason" render={errorMessage} />
                                </section>
                            </div>
                            <div className="custom-control custom-checkbox simple-appointment-checkbox">
                                <Field type="checkbox" name="appointmentType" value="2" id="2" className="custom-control-input" />
                                <label className="custom-control-label simple-appointment-checkbox__label" htmlFor="2">
                                    I’m creating the simple appointment
                                </label>
                            </div>
                            <div className="d-flex flex-row justify-content-between">
                                <button className="btn btn-primary modal-button-styles" type="submit" disabled={isSubmitting}>Save</button>
                                <button className="btn btn-danger modal-button-styles" type="button" onClick={() => setVisibleCancelModal(true)}>Cancel</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </Card>
            {visibleModal && <CancelModal visibleModal={visibleCancelModal} setVisibleModal={setVisibleCancelModal} handleClose={handleClose} />}
        </Modal>
    );
}