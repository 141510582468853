import React from 'react';
import {Card, Modal} from "reactstrap";

export default function CancelModal({
    visibleModal,
    setVisibleModal,
    handleClose,
    text,
    header,
    cancelButtonTitle = "No"
}) {
    return (
        <Modal
            isOpen={visibleModal}
            className="modal-size"
            centered={true}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body modal-text-styles">
                    <header className="font-size-22">{header ? header : "Cancel"}</header>
                    <p className="mt-4">
                        {text ? text : "Do you want to leave the page without saving?"}
                    </p>
                    <section className="d-flex flex-row justify-content-between">
                        <button className="btn btn-primary w-100 mt-4 mr-3" onClick={handleClose}>Yes</button>
                        <button
                            className="btn btn-outline-primary w-100 mt-4 ml-3"
                            onClick={() => setVisibleModal(false)}>
                            {cancelButtonTitle}
                        </button>
                    </section>
                </div>

            </Card>
        </Modal>
    )
}
