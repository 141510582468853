import React from 'react'
import { Card } from "reactstrap";
import {clinicalAdminFeedbacks, patientFeedbacks} from "../../../constants/patientMagagementTypes";
import Rating from "../../../components/Common/Rating";

export default function Feedbacks({ feedbacks }) {
    const CAFeedbacks = feedbacks.find(feedback => feedback.isCA);
    const patientFeedback = feedbacks.find(feedback => feedback.isPatient);

    return (
        <Card className="patient-details px-4 pt-3 pb-1 mb-0 feedbacks-block">
            {CAFeedbacks && <ClinicalAdminFeedBack feedback={CAFeedbacks}/>}
            {CAFeedbacks && patientFeedback && <hr />}
            {patientFeedback && <PatientFeedback feedback={patientFeedback}/>}
        </Card>
    );
}

const ClinicalAdminFeedBack = ({ feedback }) => (
    <>
        <label className="details-caption">Clinical Admin feedback</label>
        {
            Object
                .keys(clinicalAdminFeedbacks)
                .map((key, index) => {
                    return (
                        <React.Fragment key={index}>
                            {
                                key === 'hadConnectivityIssues'
                                    ? <label className="details-caption second-test">Patient Issue</label>
                                    : undefined
                            }
                            <div className="feedback" key={index}>
                                <span className="feedback-question">{clinicalAdminFeedbacks[key].label} :</span>
                                {key !== 'otherIssues' && <span>{feedback[key] ? 'Yes' : 'No'}</span>}
                                <p className="feedback-text mt-1">
                                    {feedback[clinicalAdminFeedbacks[key].keyForText] || ''}
                                </p>
                            </div>
                        </React.Fragment>
                    )
                })
        }
    </>
)

const PatientFeedback = ({ feedback }) => (
    <>
        <label className="details-caption">Patient feedback</label>
        {
            Object
                .keys(patientFeedbacks)
                .map((key, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className={`feedback align-items-center mb-1 ${key !== 'feedback' ? 'd-flex' : ''}`} key={index}>
                                <span className="feedback-question feedback-question-rating">{patientFeedbacks[key].label} :</span>
                                {
                                    key !== 'feedback'
                                        ? <Rating rating={feedback[key]} />
                                        : <p className="feedback-text">{feedback[key]}</p>
                                }

                            </div>
                        </React.Fragment>
                    )
                })
        }
    </>
)
