export const PENDING = 1;
export const CONFIRMED = 2;
export const REJECTED = 3;
export const WAITING_FOR_UPLOAD = 4;

export const documentStatuses = {
    [PENDING]: "Pending",
    [CONFIRMED]: "Confirmed",
    [REJECTED]: "Rejected",
}

export const REFERRAL_IDENTIFICATION = '18';
export const FIT_IDENTIFICATION = '06';
export const SICK_IDENTIFICATION = '19';
export const PRESCRIPTION_IDENTIFICATION = '16';