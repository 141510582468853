import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryParser from '../../helpers/queryStringParser';
import { Card, Row, Col, Spinner } from 'reactstrap';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import dropDown from '../../assets/images/ic-down-input.svg';
import parseIntFromStr from '../../helpers/parseIntFromStr';
import { components } from 'react-select';
import CustomDatePicker from '../../components/Common/datePickerWIthYearDropdown';
import InputMask from "react-input-mask";
import MaterialInput from "@material-ui/core/Input";
import CancelModal from '../../components/manageSessionModal/cancelModal';
import { NAME_MAX_LENGTH, NAME_MIN_LENGTH, EMAIL_MAX_LENGTH, MIN_DATE } from '../../constants/lengthValidity';
import { PhoneMask } from '../../constants/inputMasks';
import getUsersAge from '../../helpers/getUsersAge';
import PatientManagementService from '../../services/patientsManagement';
import { MALE, FEMALE, OTHER } from '../../constants/genders';
import {
    errorMessage,
    requiredError,
    incorrectDate,
    invalidEmailFormat,
    invalidPhone,
    stringValidationError
} from '../../constants/errorMessages';
import validatePhone from '../../helpers/validateFunctions/validatePhone';
import { strWithNoOnlySpaces } from '../../constants/validityPatterns';
import transformPhone from '../../helpers/transformPhoneNumber';
import * as _ from 'lodash';
import formatDateByFormat from '../../helpers/formatDateByFormat';
import formatDate from '../../helpers/formatDate';
import { customStyles } from "../../components/Select";
import GoogleAutocomplete from "../../components/GoogleAutocomplete";
import AddressService from '../../services/addressService';
import checkUsersAge from '../../helpers/checkUsersAge';
import validateRequired from '../../helpers/validateFunctions/validateParentRequired';
import { dateBySlash } from '../../constants/dateFormats';
import { googlePlaceIdMock } from "../../constants/googlePlaceIdMock";

const { DropdownIndicator, Option } = components;

const schema = yup.object().shape({
    firstName: yup.string()
        .required(requiredError)
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .matches(strWithNoOnlySpaces, stringValidationError),
    lastName: yup.string()
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .required(requiredError)
        .matches(strWithNoOnlySpaces, stringValidationError),
    email: yup.string().email(invalidEmailFormat).max(EMAIL_MAX_LENGTH, invalidEmailFormat).required(requiredError),
    gender: yup.number(requiredError).required(requiredError).typeError(requiredError),
    addressId: yup.object().required(requiredError),
    phone: yup.string().required(requiredError).test("length", invalidPhone, function (value) {
        return validatePhone(value)
    }),
    birthday: yup.date().min(MIN_DATE, incorrectDate).max(new Date(), incorrectDate).required().typeError(incorrectDate),
    surgeryAddressId: yup.object().required(requiredError),
    parentFirstName: yup.string()
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .matches(strWithNoOnlySpaces, stringValidationError)
        .test("isRequired", requiredError, function (value) {
            return validateRequired(this.parent.birthday, value);
        }),
    parentLastName: yup.string()
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .matches(strWithNoOnlySpaces, stringValidationError)
        .test("isRequired", requiredError, function (value) {
            return validateRequired(this.parent.birthday, value);
        }),
    parentPhone: yup.string().test("length", invalidPhone, function (value) {
        return this.parent.parentFirstName ? validatePhone(value) : true;
    }).test("isRequired", requiredError, function (value) {
        return validateRequired(this.parent.birthday, value);
    }),
    relationship: yup.string()
        .min(NAME_MIN_LENGTH, stringValidationError)
        .max(NAME_MAX_LENGTH, stringValidationError)
        .matches(strWithNoOnlySpaces, stringValidationError)
        .test("isRequired", requiredError, function (value) {
            return validateRequired(this.parent.birthday, value);
        }),
});

const CustomDropdownIndicator = (props) => {
    return (
        <DropdownIndicator {...props}>
            <img src={dropDown} alt="" className="cursor-pointer" />
        </DropdownIndicator>
    );
};

const IndicatorSeparator = () => {
    return null;
};

const CustomOption = props => {
    return (
        <div className="search-options">
            <Option {...props} />
        </div>
    );
};

const InputWrap = ({ children, label }) => {
    return (
        <div className={"d-flex flex-row justify-content-between align-items-center mt-2 input-heigth"}>
            <label className={"pt-1 w-25 checkbox-label"}>{label}</label>
            <section className={`d-flex flex-column w-75`}>
                {children}
            </section>
        </div>
    )
};

const Phone = props => {
    return <InputMask
        mask={PhoneMask}
        defaultValue={props.defaultValue}
        value={props.value}
        name={props.name}
        className="form-control input-heigth"
        onChange={props.onChange}
    >
        {inputProps => (
            <MaterialInput {...inputProps} type="tel" disableUnderline />
        )}
    </InputMask>
};

const genders = [
    { label: "Male", value: MALE },
    { label: "Female", value: FEMALE },
    { label: "Other", value: OTHER },
];

const agreement = [
    { label: "Agreed", value: true },
    { label: "Not agreed", value: false },
];

export default function EditPatientDetails() {
    const [visibleModal, setVisibleModal] = useState(false);
    const [patient, setPatient] = useState(null);
    const [dateOfBirth, setDateOfBirth] = useState(null);

    const history = useHistory();
    const location = useLocation();

    const { id } = useMemo(() => queryParser(location.search), [location]);

    const initialValues = {
        firstName: _.get(patient, 'firstName', ''),
        lastName: _.get(patient, 'lastName', ''),
        email: _.get(patient, 'email', ''),
        addressId: _.get(patient, 'address', ''),
        phone: _.get(patient, 'phone', ''),
        gender: _.get(patient, 'gender', null),
        birthday: _.get(patient, 'birthday', null),
        isInfoShared: _.get(patient, 'isInfoShared', true),
        surgeryAddressId: _.get(patient, 'surgeryAddress', ''),
        parentFirstName: _.get(patient, 'parent.firstName', ''),
        parentLastName: _.get(patient, 'parent.lastName', ''),
        parentPhone: _.get(patient, 'parent.phone', ''),
        relationship: _.get(patient, 'parent.relationship', ''),
    };

    const firstColInputFields = [
        { label: "First name", inputName: "firstName", inputType: "text", defaultValue: initialValues.firstName },
        { label: "Last name", inputName: "lastName", inputType: "text", defaultValue: initialValues.lastName },
        {
            label: "Patient ID",
            inputName: "patientId",
            inputType: "text",
            defaultValue: parseIntFromStr(_.get(patient, 'id', null)),
            disabled: true
        },
        {
            label: "Adding date",
            inputName: "createdAt",
            inputType: "text",
            defaultValue: formatDateByFormat(_.get(patient, 'createdAt', null), dateBySlash),
            disabled: true
        },
        { label: "Email address", inputName: "email", inputType: "email", defaultValue: initialValues.email },
    ];

    const homeAddress = useMemo(() => ({
        label: "Home address",
        name: "addressId",
        defaultValue: _.get(patient, 'address.address', ''),
    }), [patient]);

    const secondColInputFields = [
        {
            label: "Phone number",
            inputName: "phone",
            inputType: "number",
            defaultValue: initialValues.phone,
            Component: Phone
        },
        {
            label: "Gender",
            inputName: "gender",
            inputType: "select",
            options: genders,
            defaultValue: genders[initialValues.gender - 1]
        },
        {
            label: "Date of birth, age",
            inputName: "birthday",
            inputType: "text",
            secondComponent: true,
            defaultValue: initialValues.birthday
        },
        {
            label: <label className="pt-1 input-heigth">Agreed to share info <br /> with GP</label>,
            inputName: "isInfoShared",
            inputType: "select",
            options: agreement,
            defaultValue: agreement.find(item => item.value === initialValues.isInfoShared)
        },
    ];

    const surgeryAddress = useMemo(() => ({
        label: "Surgery address",
        name: "surgeryAddressId",
        defaultValue: _.get(patient, 'surgeryAddress.address', ''),
    }), [patient]);

    const parentsInfoFirstCol = [
        {
            label: 'First name',
            inputName: 'parentFirstName',
            inputType: 'text',
            defaultValue: _.get(patient, 'parent.firstName', '')
        },
        {
            label: 'Last name',
            inputName: 'parentLastName',
            inputType: 'text',
            defaultValue: _.get(patient, 'parent.lastName', '')
        },
    ];

    const parentsInfoSecondCol = [
        {
            label: "Phone number",
            inputName: "parentPhone",
            inputType: "number",
            defaultValue: _.get(patient, 'parent.phone', ''),
            Component: Phone
        },
        {
            label: 'Relationship to the child',
            inputName: 'relationship',
            inputType: 'text',
            defaultValue: _.get(patient, 'parent.relationship', '')
        },
    ];

    function handleChangeDate(date, setFieldValue) {
        const age = getUsersAge(date);
        setDateOfBirth(age + " y.o.");
        if (setFieldValue) {
            setFieldValue('birthday', formatDate(date));
        }
    }

    function handleLeavePage() {
        setVisibleModal(true)
    }

    function handleSave() {
        history.goBack()
    }

    function transformValues({ parentFirstName, parentLastName, parentPhone, relationship, phone, ...other }) {
        const newValues = {
            ...other,
            phone: transformPhone(phone),
        }
        if (checkUsersAge(newValues.birthday)) {
            newValues.parent = {
                firstName: parentFirstName,
                lastName: parentLastName,
                phone: transformPhone(parentPhone),
                relationship,
            }
        }
        return newValues;
    }

    async function addNewAddress(inputValues) {
        try {
            const addressValues = {
                googlePlaceId: googlePlaceIdMock,
                address: inputValues.address,
            }
            const newAddressId = await AddressService.addAddress(addressValues);
            return newAddressId;
        } finally {
            // empty
        }
    }

    async function editPatientInfo({ addressId, surgeryAddressId, ...other }) {
        try {
            const newValues = {
                ...other,
                addressId: addressId.id,
                surgeryAddressId: surgeryAddressId.id,
            }

            if (!addressId.id) {
                const newAddress = await addNewAddress(addressId);
                newValues.addressId = newAddress?.data?.id;
            }

            if (!surgeryAddressId.id) {
                const newSurgeryAddress = await addNewAddress(surgeryAddressId);
                newValues.surgeryAddressId = newSurgeryAddress?.data?.id;
            }

            await PatientManagementService.changeUsersInfo(patient.id, newValues);
            history.goBack()
        } finally {
            //empty
        }
    }

    const getPatient = useCallback(
        async () => {
            try {
                const serverResponse = await PatientManagementService.getPatientById(id);
                setPatient(serverResponse.data);
            } catch {
                //empty
            }
        },
        [id],
    )

    useEffect(() => {
        getPatient();
    }, [getPatient]);

    useEffect(() => {
        handleChangeDate(initialValues.birthday);
    }, [patient, initialValues.birthday]);

    return (
        <div className="page-content edit-page">
            <Card className="edit-patient-card m-2">
                {patient ?
                    <Formik
                        initialValues={initialValues}
                        validationSchema={schema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true)
                            editPatientInfo(transformValues(values));
                            setSubmitting(false)
                        }}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <label className="details-caption">Edit Patient’s Personal Info</label>
                                <Row>
                                    <Col lg={6}>
                                        {firstColInputFields.map((field, index) => (
                                            <React.Fragment key={index}>
                                                <div
                                                    className={"d-flex flex-row justify-content-between align-items-center mt-2 input-heigth"}>
                                                    <label className={"pt-1 w-25 checkbox-label"}>{field.label}</label>
                                                    <section className="d-flex flex-column w-75">
                                                        <input
                                                            type={field.inputType}
                                                            name={field.inputName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.inputName}
                                                            defaultValue={field.defaultValue}
                                                            className="form-control"
                                                            disabled={field.disabled}
                                                        />
                                                        <ErrorMessage name={field.inputName} render={errorMessage} />
                                                    </section>
                                                </div>
                                            </React.Fragment>
                                        ))}

                                        <InputWrap label={homeAddress.label}>
                                            <input
                                                className="form-control"
                                                name={homeAddress.name}
                                                onChange={(event) => {
                                                    const values = {
                                                        address: event?.target?.value,
                                                        placeId: googlePlaceIdMock
                                                    }
                                                    setFieldValue(homeAddress.name, values)
                                                }}
                                                onBlur={(event) => {
                                                    const values = {
                                                        address: event?.target?.value,
                                                        placeId: googlePlaceIdMock
                                                    }
                                                    setFieldValue(homeAddress.name, values)
                                                }}
                                                defaultValue={values[homeAddress.name].address}
                                            />
                                            <ErrorMessage name={homeAddress.name} render={errorMessage} />
                                        </InputWrap>
                                    </Col>
                                    <Col lg={6}>
                                        {secondColInputFields.map((field, index) => (
                                            <React.Fragment key={index}>
                                                <div
                                                    className={"d-flex flex-row justify-content-between align-items-center mt-2 input-heigth"}>
                                                    <label className={"pt-1 w-25 checkbox-label"}>{field.label}</label>
                                                    {!field.options ?
                                                        (
                                                            <>
                                                                {!field.secondComponent ?
                                                                    field.Component ?
                                                                        <section className="d-flex flex-column w-75">
                                                                            <field.Component
                                                                                defaultValue={field.defaultValue}
                                                                                onChange={handleChange}
                                                                                value={values.phone}
                                                                                name={field.inputName} />
                                                                            <ErrorMessage name={field.inputName} render={errorMessage} />
                                                                        </section>
                                                                        :
                                                                        <section
                                                                            className={`d-flex flex-column ` + (field.secondComponent ? "w-50" : "w-75")}>
                                                                            <input
                                                                                type={field.inputType}
                                                                                name={field.inputName}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                value={values.inputName}
                                                                                className={"form-control"}
                                                                                defaultValue={field.defaultValue}
                                                                                disabled={field.disabled}
                                                                            />
                                                                        </section>
                                                                    :
                                                                    <>
                                                                        <div className="w-50 d-flex flex-row">
                                                                            <section className="d-flex flex-column w-100">
                                                                                <CustomDatePicker
                                                                                    handleChangeDate={(date) => handleChangeDate(date, setFieldValue)}
                                                                                    minDate={MIN_DATE}
                                                                                    dayOfBirth={field.defaultValue}
                                                                                />
                                                                                <ErrorMessage name={field.inputName} render={errorMessage} />
                                                                            </section>
                                                                        </div>
                                                                        <label
                                                                            className="input-patient-year">{dateOfBirth}
                                                                        </label>
                                                                    </>
                                                                }

                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <section className="w-75 d-flex flex-column">
                                                                <Select
                                                                    className="w-100"
                                                                    options={field.options.map((option) => ({
                                                                        label: option.label,
                                                                        value: option.value,
                                                                    }))}
                                                                    defaultValue={field.defaultValue && {
                                                                        label: field.defaultValue.label,
                                                                        value: field.defaultValue.value,
                                                                    }}
                                                                    onChange={(option) => setFieldValue(field.inputName, option.value)}
                                                                    styles={customStyles}
                                                                    isDisabled={field.disabled}
                                                                    components={{
                                                                        DropdownIndicator: CustomDropdownIndicator,
                                                                        IndicatorSeparator,
                                                                        Option: CustomOption
                                                                    }}
                                                                    isSearchable={false}
                                                                />
                                                                <ErrorMessage name={field.inputName} render={errorMessage} />
                                                            </section>
                                                        )}

                                                </div>
                                            </React.Fragment>
                                        ))}
                                        <InputWrap label={surgeryAddress.label}>
                                            <GoogleAutocomplete
                                                allowClear
                                                name={surgeryAddress.name}
                                                placeId={values[surgeryAddress.name].placeId || ""}
                                                onSelect={(values) => {
                                                    setFieldValue(surgeryAddress.name, values)
                                                }}
                                                defaultValue={values[surgeryAddress.name].address}
                                            />
                                            <ErrorMessage name={surgeryAddress.name} render={errorMessage} />

                                        </InputWrap>
                                    </Col>
                                </Row>

                                {checkUsersAge(values.birthday) &&
                                    <>
                                        <label className="details-caption mt-2">Parents info</label>
                                        <Row>
                                            <Col lg={6}>
                                                {parentsInfoFirstCol.map((field, index) => (
                                                    <div key={index}
                                                        className={"d-flex flex-row justify-content-between align-items-center mt-2 input-heigth"}>
                                                        <label className={"pt-1 w-25 checkbox-label"}>{field.label}</label>
                                                        <section className="d-flex flex-column w-75">
                                                            <input
                                                                type={field.inputType}
                                                                name={field.inputName}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.inputName}
                                                                defaultValue={field.defaultValue}
                                                                className="form-control"
                                                                disabled={field.disabled}
                                                            />
                                                            <ErrorMessage name={field.inputName} render={errorMessage} />
                                                        </section>
                                                    </div>
                                                ))}
                                            </Col>

                                            <Col lg={6}>
                                                {parentsInfoSecondCol.map((field, index) => (
                                                    <div key={index}
                                                        className={"d-flex flex-row justify-content-between align-items-center mt-2 input-heigth"}>
                                                        <label className={"pt-1 w-25 checkbox-label"}>{field.label}</label>
                                                        {field.Component ?
                                                            <section className="d-flex flex-column w-75">
                                                                <field.Component defaultValue={field.defaultValue}
                                                                    onChange={handleChange}
                                                                    value={values.parentPhone}
                                                                    name={field.inputName} />
                                                                <ErrorMessage name={field.inputName} render={errorMessage} />
                                                            </section>
                                                            :
                                                            <section className="d-flex flex-column w-75">
                                                                <input
                                                                    type={field.inputType}
                                                                    name={field.inputName}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.inputName}
                                                                    defaultValue={field.defaultValue}
                                                                    className="form-control"
                                                                    disabled={field.disabled}
                                                                />
                                                                <ErrorMessage name={field.inputName} render={errorMessage} />
                                                            </section>
                                                        }
                                                    </div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </>
                                }
                                <section className="d-flex flex-row justify-content-between w-25 mt-4 ml-2">
                                    <button type="submit" className="btn btn-primary w-50 mr-2" disabled={isSubmitting}>
                                        Save
                                    </button>
                                    <button type="button" className="btn btn-outline-primary w-50 ml-2"
                                        onClick={handleLeavePage}>
                                        Cancel
                                    </button>
                                </section>
                            </form>
                        )}
                    </Formik>
                    :
                    <Spinner className="mx-auto" color="info" />
                }
            </Card>
            <CancelModal
                visibleModal={visibleModal}
                setVisibleModal={setVisibleModal}
                handleClose={handleSave}
            />
        </div>
    )
}
