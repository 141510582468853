import React from 'react';
import * as _ from 'lodash';
import { useHistory } from 'react-router-dom';

const DefaultComponent = ({ value: children }) => children;

export default function TableBody({ data, structure, clickRowAction, updateTable, getAppointmentSlots, rowClassname, pickedAppointment = null, doctor }) {
    const history = useHistory();
    const pickedRowId = pickedAppointment?.id;
    return (
        <tbody>
            {data.map((dataRow, index) => {
                return (
                    <tr key={index}
                        className={(pickedRowId === dataRow.id && "picked-slot-bg ") + " cursor-pointer " + rowClassname}
                        onClick={() => clickRowAction && clickRowAction(dataRow)}
                    >
                        {structure.map(({ name, classname, keyProp, Component = DefaultComponent }) => {
                            return (
                                <td key={name} className={" " + classname} ><Component className="table-text" value={_.get(dataRow, keyProp, dataRow)} history={history} updateTable={updateTable} getAppointmentSlots={getAppointmentSlots} doctor={doctor}/></td>
                            )
                        })}
                    </tr>
                )
            })}
        </tbody>
    )
}
