import React, { useState, useCallback, useMemo } from 'react';
import DatePicker, { registerLocale, CalendarContainer } from 'react-datepicker';
import { monthNames } from '../../constants/monthNames';
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import Shevron from '../Label/shevronLabel';
import getShortMonthName from '../../helpers/getShortMonthName';

registerLocale("en-GB", en);

const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, monthDecreaseHandler, monthIncreaseHandler }) => {
    return (
        <div className="custom-datepicker-header mb-2">
            <label className="ml-2 font-weight-bold date-label pt-3">{monthNames[date.getMonth()]} {date.getFullYear()}</label>
            <section className="text-center mr-2 mt-1">
                <Shevron
                    className="chevron-button-small"
                    iconClassName="mdi mdi-chevron-left chevron-size-small"
                    onClick={() => { decreaseMonth(); monthDecreaseHandler() }}
                    disabled={prevMonthButtonDisabled}
                />

                <Shevron
                    className="chevron-button-small"
                    iconClassName="mdi mdi-chevron-right chevron-size-small"
                    onClick={() => { increaseMonth(); monthIncreaseHandler() }}
                    disabled={nextMonthButtonDisabled}
                />
            </section>
        </div>
    )
};

export default function SmallCustomDatePicker({
    handleChangeDate,
    selected,
}) {

    const today = useMemo(() => new Date(), []);

    const [startDate, setStartDate] = useState(new Date(selected));
    const [currentMonth, setCurrentMonth] = useState(startDate.getMonth())

    const monthIncreaseHandler = useCallback(
        () => {
            setCurrentMonth((prevState) => ++prevState % monthNames.length);
        },
        [],
    )

    const monthDecreaseHandler = useCallback(
        () => {
            setCurrentMonth((prevState) => prevState ? --prevState : monthNames.length - 1);
        },
        [],
    )

    const onChange = useCallback(value => {
        setStartDate(value)
        setCurrentMonth(value.getMonth());
        handleChangeDate(value);
    }, [handleChangeDate]);

    const manageDayClassname = (date) => (date.getMonth() !== currentMonth) ? "font-color-of-exclude-day" : undefined;

    const MyContainer = ({ className, children }) => {
        return (
            <div className="custom-calendar-small">
                <CalendarContainer className={className}>
                    {children}
                    <section className="w-100 px-3 py-3">
                        <button className="btn btn-outline-primary w-100" disabled={today.toDateString() === startDate.toDateString()} onClick={() => onChange(today)}>Today</button>
                    </section>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <>
            <DatePicker
                calendarContainer={MyContainer}
                renderCustomHeader={(props) => <CustomHeader  {...props} monthDecreaseHandler={monthDecreaseHandler} monthIncreaseHandler={monthIncreaseHandler} />}
                locale="en-GB"
                currentMonth={startDate}
                formatWeekDay={nameOfDay => getShortMonthName(nameOfDay)}
                selected={selected ? new Date(selected) : startDate}
                dayClassName={ manageDayClassname}
                onChange={onChange}
                inline={true}
                customInput={null}
                disabledKeyboardNavigation
                fixedHeight
            />
        </>
    )
}