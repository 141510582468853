import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import * as _ from 'lodash';
import {Col, Row, Spinner, TabContent, Table, TabPane} from "reactstrap";
import qs from "qs";

import ChosenPatient from 'components/chosenPatient';
import Tabs from 'components/tabPane';
import parseIntFromStr from 'helpers/parseIntFromStr';
import {APPOINTMENTS_LIMIT} from 'constants/paginationLimits';
import {
    APPOINTMENTS_HISTORY,
    CHILDREN,
    PatientDetailsTabs,
    PatientDetailsTabsWithoutChildren,
    PERSONAL_INFO
} from 'constants/patientMagagementTypes';
import tablePlaceholder from 'assets/images/im-placeholder-table.svg';
import {FIT_MODAL, PRESCRIPTION_MODAL, REFERRAL_MODAL} from "constants/callConstants";
import useAppointmentsUtils from "hooks/useAppointmentsUtils";
import queryParser from "helpers/queryStringParser";
import TableBody from "components/sessionTable/tableBody";
import StorageService from "services/StorageService";
import {USER} from "constants/storageConstants";
import {SUPER_ADMIN} from "constants/roles";

import CreateReferralLetter from "../Calls/letters/referralLetter";
import Prescription from "../Calls/letters/prescription";
import CreateSickLetter from "../Calls/letters/fitSickNote";

import Feedbacks from "./feedbacks/Feedbacks";
import UploadedFiles from "./appointmentHistory/uploadedFiles";
import ParentsInfo from './patientPersonalInfo/parentInfo';
import AppointmentDetails from './appointmentHistory/appointmentDetails';
import AttachedPhoto from './appointmentHistory/attachedPhoto';
import DoctorsInfo from './appointmentHistory/doctorsInfo';
import PaginationTableFooter from './tableComponents/paginationFooter';
import TableHeader from "./tableComponents/tableHeader";
import {patientChildrenStructure, patientChildrenTabHeader} from "./tableComponents/patientTableDataStructure";
import NoDataPlaceholder from "./tableComponents/NoDataPlaceholder";
import PersonalInfo from './patientPersonalInfo/patientPersonalInfo';
import PatientDocuments from './patientPersonalInfo/patientDocuments';
import {PatientPassword} from "./patientPersonalInfo/PatientPassword";
import {ConsultationNotesSection} from "./components/ConsultationNotesSection";


export default function PatientDetails() {
    const location = useLocation();
    const history = useHistory();

    const searchQuery = queryParser(location.search);

    const isChild = useMemo(() => Boolean(searchQuery?.parentId), [searchQuery]);
    const isUserSuperAdmin = new StorageService().getValueByKey(USER)?.role === SUPER_ADMIN;

    function setNewSearchQuery(incomeQuery) {
        const newQuery = {
            ...searchQuery,
            ...incomeQuery,
        }
        const query = qs.stringify(newQuery);
        history.push({
            search: query,
        });
    }

    function toggleCustomJustified(tab) {
        setNewSearchQuery({ type: tab, offset: 0 });
    }

    function handleChangePage({ selected }) {
        if (!isNaN(selected)) {
            setNewSearchQuery({ offset: selected * APPOINTMENTS_LIMIT });
        }
    }

    const [showNoteModalType, setShowNoteModalType] = useState('');

    const {
        isLoading,
        isPatientInfoLoading,
        uploadFile,
        createNote,
        patient,
        getPatient,
        getPatientHistory,
        patientHistory,
        historyPagination,
        patientAppointments,
        currentAppointment,
        referralID,
        sickID,
        prescriptionID,
        closeSickFitModal,
        closeReferralModal,
        closePrescriptionModal,
        sendReferralLetter,
        sendSickLetter,
        generateAndSendFitSickNote,
        generateAndSendReferralLetter,
        deleteFile,
        deleteReasonFile,
        sendEmail,
        sendTrustPilot,
        blockUnblockConsultationNotes,
        handleSaveDocuments,
        deletePatientID,
        editConsultation
    } = useAppointmentsUtils({ setShowNoteModalType, searchQuery });

    const logHistoryPath = useMemo(() => ({
            pathname: "/patients-management/patients/log",
            search: qs.stringify({
                id: patient.id,
                from: parseIntFromStr(_.get(searchQuery, "type", APPOINTMENTS_HISTORY)),
            })
        }),
        [patient.id, searchQuery]
    )

    const handleClickChildRow = useCallback((childRow) => {
        history.push(`/patients-management/patients/child?id=${childRow?.id}&parentId=${patient.id}`);
    }, [history, patient])

    const backToParentPath = useMemo(() => `/patients-management/patients?id=${searchQuery?.parentId}`, [searchQuery?.parentId])

    const isChildOrNoChildren = useMemo(() => {
        return isChild || patient?.children?.data?.length <= 0;
    }, [patient, isChild]);

    useEffect(() => {
        getPatient();
    }, [getPatient]);

    useEffect(() => {
        getPatientHistory();
    }, [getPatientHistory]);

    return (
        <div className="page-content mx-2 pb-2">
            <ChosenPatient/>
            <Col lg={12} className="mb-2 pl-0">
                {!isChild && (
                    <Link
                        className="btn btn-primary call-form-button mx-0 my-0 mr-3 mb-2"
                        to={logHistoryPath}
                    >
                        <div className="mx-lg-3 px-4">
                            Log History
                        </div>
                    </Link>
                )}
                {isChild && (
                    <Link
                        className="btn btn-outline-primary call-form-button w-auto mx-0 my-0 mr-3 mb-2 px-4"
                        to={backToParentPath}
                    >
                        Back to Parent
                    </Link>
                )}
            </Col>
            <Tabs tabs={isChildOrNoChildren ? PatientDetailsTabsWithoutChildren : PatientDetailsTabs}
                  activeTab={parseIntFromStr(_.get(searchQuery, "type", APPOINTMENTS_HISTORY))}
                  toggleCustomJustified={toggleCustomJustified}/>
            <TabContent activeTab={parseIntFromStr(_.get(searchQuery, "type", APPOINTMENTS_HISTORY))} className="h-100">
                <TabPane tabId={APPOINTMENTS_HISTORY} className="p-2 h-100">
                    {isLoading &&
                        <div className="patients-table d-flex justify-content-center align-items-center">
                            <Spinner className="mx-auto" color="info"/>
                        </div>}
                    {!isLoading && patientHistory.map((appointment, index) => {

                        return (
                            <React.Fragment key={index}>
                                <Row>
                                    <Col lg={4}>
                                        <AppointmentDetails
                                            appointmentDetails={appointment.appointmentsInfo}
                                            sendEmailFn={() => sendEmail(appointment.id)}
                                            trustPilotFn={() => sendTrustPilot(appointment.id)}
                                        >
                                            <Row className="d-flex flex-wrap">
                                                <Col lg={6}>
                                                    <button
                                                        className="btn btn-primary call-form-button w-100 my-3"
                                                        type="button"
                                                        onClick={() => createNote(patientAppointments[index], FIT_MODAL)}
                                                    >
                                                        Create sick/fit
                                                    </button>
                                                </Col>
                                                <Col lg={6}>
                                                    <button
                                                        className="btn btn-primary call-form-button w-100 my-3"
                                                        type="button"
                                                        onClick={() => createNote(patientAppointments[index], REFERRAL_MODAL)}
                                                    >
                                                        Create Referral
                                                    </button>
                                                </Col>
                                            </Row>
                                        </AppointmentDetails>
                                        <AttachedPhoto files={appointment?.attachedPhoto}
                                                       onDeleteFile={deleteReasonFile}/>
                                        <DoctorsInfo doctorsInfo={appointment?.doctorsInfo}/>
                                    </Col>
                                    <Col lg={8}>
                                        <ConsultationNotesSection
                                            appointment={appointment}
                                            blockUnblockConsultationNotes={blockUnblockConsultationNotes}
                                            editConsultation={editConsultation}
                                        />
                                        {appointment.feedbacks && !!appointment.feedbacks.length &&
                                            <Feedbacks feedbacks={appointment.feedbacks}/>}
                                    </Col>
                                </Row>
                                {index < (patientHistory.length - 1) && <hr className="horizontal-line"/>}
                            </React.Fragment>
                        )
                    })}
                    <section className="mt-5 pt-4">
                        {!!historyPagination.totalCount &&
                            <PaginationTableFooter
                                total={historyPagination.totalCount}
                                handleChangePage={handleChangePage}
                                currentOffset={parseIntFromStr(searchQuery.offset)}
                                limit={APPOINTMENTS_LIMIT}
                            />
                        }
                    </section>
                    {!isLoading && !patientHistory.length &&
                        <section className="d-flex flex-column align-items-center history-placeholder">
                            <img src={tablePlaceholder} alt=""/>
                            <label className="patients-placeholder-label">There is no information yet</label>
                        </section>
                    }
                </TabPane>
                <TabPane tabId={PERSONAL_INFO} className="p-2 h-100">
                    {!isPatientInfoLoading ?
                        <Row>
                            <Col lg={4}>
                                <PersonalInfo patient={patient} isChild={isChild}/>
                                {isUserSuperAdmin && !isChild && <PatientPassword patient={patient}/>}
                            </Col>
                            <Col lg={8}>
                                <PatientDocuments
                                    patient={patient}
                                    handleSaveDocuments={handleSaveDocuments}
                                    deletePatientID={deletePatientID}
                                    getPatient={getPatient}
                                />
                                {patient.parent &&
                                    <ParentsInfo parent={patient.parent} relationship={patient?.relationship}/>}
                                <UploadedFiles
                                    uploadedFiles={patient?.patientFilesByCA?.data || []}
                                    onSelectFile={uploadFile}
                                    onDeleteFle={deleteFile}
                                />
                            </Col>
                        </Row>
                        :
                        <section className="d-flex flex-row justify-content-center align-items-center w-100 h-100">
                            <Spinner color="info"/>
                        </section>
                    }
                </TabPane>
                {!isChild && (
                    <TabPane tabId={CHILDREN}>
                        {!isPatientInfoLoading && patient?.children?.data?.length > 0 && (
                            <Col className="patient-table card p-20px">
                                <section className="d-flex flex-column justify-content-between">
                                    <section>
                                        <div className="table-responsive">
                                            <div className="border-top table-text">
                                                <Table className="table mb-0 h-100">
                                                    <TableHeader clickCellAction={_.noop}
                                                                 columns={patientChildrenTabHeader}/>
                                                    <TableBody
                                                        data={patient?.children?.data || []}
                                                        clickRowAction={handleClickChildRow}
                                                        structure={patientChildrenStructure}
                                                    />
                                                </Table>
                                            </div>
                                        </div>
                                    </section>
                                </section>
                            </Col>
                        )}
                        {!isPatientInfoLoading && !patient?.children?.data &&
                            <NoDataPlaceholder message="No children yet"/>}
                        {isPatientInfoLoading && (
                            <section className="d-flex flex-row justify-content-center align-items-center w-100 h-100">
                                <Spinner color="info"/>
                            </section>
                        )}
                    </TabPane>
                )}
            </TabContent>
            {showNoteModalType === REFERRAL_MODAL && currentAppointment && referralID && (
                <CreateReferralLetter
                    onClose={closeReferralModal}
                    appointment={currentAppointment}
                    consultationNotes={currentAppointment?.consultation}
                    isConsultationRequired={false}
                    referralID={referralID}
                    patientID={patient?.id}
                    patient={patient}
                    saveReferralLetter={sendReferralLetter}
                    sendReferralLetter={generateAndSendReferralLetter}
                    submitButton="Save"
                />
            )}
            {showNoteModalType === PRESCRIPTION_MODAL && currentAppointment && prescriptionID && (
                <Prescription
                    onClose={closePrescriptionModal}
                    prescriptionID={prescriptionID}
                    patientID={patient?.id}
                    sendPrescriptionLetter={_.noop}
                    appointment={currentAppointment}
                    submitButton="Save"
                />
            )}
            {showNoteModalType === FIT_MODAL && currentAppointment && sickID && (
                <CreateSickLetter
                    onClose={closeSickFitModal}
                    sickID={sickID}
                    patientID={patient?.id}
                    patient={patient}
                    sendSickLetter={generateAndSendFitSickNote}
                    saveSickLetter={sendSickLetter}
                    appointment={currentAppointment}
                    submitButton="Save"
                />
            )}
        </div>
    )
}
