import * as yup from "yup";

import {requiredError, signatureFormatError, signatureSizeError,} from "constants/errorMessages";
import {ALLOWED_SELFIE_FILE, MAX_SELFIE_SIZE,} from "constants/filesValidity";
import {
    PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES,
    PATIENT_IDENTIFICATION_DOCUMENT_TYPES
} from "constants/patientMagagementTypes";

export const addDocumentsValidationSchema = yup.object().shape({
    documentType: yup.string().required(requiredError),
    selfie: yup.mixed()
        .required(requiredError)
        .test(
            "fileSize",
            signatureSizeError,
            value => value && value.size <= MAX_SELFIE_SIZE
        )
        .test(
            "FileType",
            signatureFormatError,
            value => value && ALLOWED_SELFIE_FILE.includes(value.type)
        ),
    document: yup.mixed()
        .required(requiredError)
        .test(
            "fileSize",
            signatureSizeError,
            value => value && value.size <= MAX_SELFIE_SIZE
        )
        .test(
            "FileType",
            signatureFormatError,
            value => value && ALLOWED_SELFIE_FILE.includes(value.type)
        )
});

export const getPatientIDTypesList = (patient) => {
    const isChild = Boolean(patient?.parentId)

    return PATIENT_IDENTIFICATION_DOCUMENT_TYPES.map(type => {
        const isDisabled = (isChild && type.value === PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES.driverLicenseId)
            || (!isChild && type.value === PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES.birthCertificateId)

        return {
            ...type,
            isDisabled
        }
    });

}
