import React from 'react';

export const createContextStore = (initialState) => {
    const StateContext = React.createContext(initialState);
    const DispatchContext = React.createContext(() => { });
    const Provider = ({ children, value = initialState }) => {
        const [state, update] = React.useState(value);
        return (
            <DispatchContext.Provider value={update}>
                <StateContext.Provider value={state}>
                    {children}
                </StateContext.Provider>
            </DispatchContext.Provider>
        );
    };
    return {
        State: StateContext,
        DispatchContext,
        Provider
    }
};

export const DocTableContext = createContextStore([]);
export const useDocTables = () => React.useContext(DocTableContext.State);
export const useUpdateDocTables = () => React.useContext(DocTableContext.DispatchContext);

export const ChosenPatientContext = createContextStore(null);
export const useChosenPatientContext = () => React.useContext(ChosenPatientContext.State);
export const useUpdateChosenPatientContext = () => React.useContext(ChosenPatientContext.DispatchContext);
