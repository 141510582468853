import React from 'react'

export const requiredError = "This field is required";
export const incorrectDate = "Incorrect date";
export const incorrectTime = 'Incorrect time';
export const sessionDurationError = "Session duration is required";
export const appointmentDurationError = "The duration should be from 5 to 60 minutes";
export const cliniciansNumberError = "Number of clinicians is required";
export const doctorsNumberError = "Number of doctors is required";
export const passwordDoesntMatch = "Passwords don't match, try again";
export const passwordRequired = "Password is required";
export const currentPasswordRequired = "Current password is required";
export const passwordLength = "Password must be from 8 to 50 symbols";
export const passwordContains = "Password should contain at least one letter and one digit and one capital letter, can’t contain spaces only";
export const invalidEmailFormat = "Invalid email format";
export const phoneRequired = "Phone number is required";
export const invalidPhone =  "Phone number is invalid";
export const stringValidationError = 'Must be from 1 to 50 symbols and cannot contain spaces only';
export const commentLengthError = "Must be from 1 to 200 symbols and cannot contain spaces only";
export const endTimeSmallerThenStart = 'This field is invalid'; 
export const notesLengthError = 'Must be from 1 to 2500 symbols and cannot contain spaces only';

export const drugNameError = 'Must be from 1 to 30 symbols and cannot contain spaces only';
export const drugDoseError = 'Must be from 1 to 10 symbols and cannot contain spaces only';
export const drugFormError = 'Must be from 1 to 20 symbols and cannot contain spaces only';
export const drugInstructionsError = 'Must be from 1 to 100 symbols and cannot contain spaces only';
export const supplyNumericError = 'This field is invalid';

export const activationCodeRequiredError = "Can’t be empty";
export const activationCodeMinTimesError = "Should be more than 1 symbols";
export const activationCodeMaxTimesError = "Should be less than 15 symbols";
export const activationCodeCountError = "Should be between 1 and 1000";
export const activationCodeFormatError = "Should contain only letters and numbers";

export const signatureSizeError = "Invalid image size";
export const signatureFormatError = "Invalid image format";
export const gmcLengthError = "Invalid gmc length";

export const patientInfoValidationError = 'Must be from 1 to 5000 symbols and cannot contain spaces only';

export const appointmentPriceError = "Must be from 1 to 8 symbols and cannot contain spaces only";

export const errorMessage = (msg) => (
    <div className="error-text">{msg}</div>
);

