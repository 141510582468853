import React, { useEffect, useState, useCallback } from 'react';
import { Card } from 'reactstrap';
import timeIcon from '../../../assets/images/bx-time-five.svg';
import AppointmentsService from '../../../services/appointments';
import * as _ from 'lodash';
import formatDateByFormat from '../../../helpers/formatDateByFormat';
import placeholder from '../../../assets/images/im-placeholder-comment.svg';

export default function Comments({ appointmentId }) {
    const [comments, setComments] = useState([]);

    const getComments = useCallback(
        async () => {
            try {
                const serversResponse = await AppointmentsService.getAppointmentComments(appointmentId);
                setComments(serversResponse.data);
            } catch {
                //empty
            }
        },
        [appointmentId],
    )

    function concatFullName(row) {
        return _.get(row, 'user.firstName', '-') + " " + _.get(row, "user.lastName", '-');
    }

    useEffect(() => {
        getComments();
    }, [getComments]);

    return (
        <Card className="details-documents comments-section history-comments-card">
            <label className="details-caption">Comments</label>
            {!!comments.length &&
                <section className="comments-section history-comments-card--list pr-2 pb-4">
                    {comments.map((comment) => (
                        <section key={comment.id} className="mt-2">
                            <label>{concatFullName(comment)}</label>
                            <p>{comment.text}</p>
                            <section className="d-flex flex-row justify-content-between details-comments-section">
                                <label className="comment-time">{formatDateByFormat(comment.createdAt, 'D MMM, YYYY')}</label>
                                <span>
                                    <img src={timeIcon} alt="" className="mr-1" />
                                    <label className="comment-time">{formatDateByFormat(comment.createdAt, 'LT')}</label>
                                </span>
                            </section>
                        </section>
                    ))}
                </section>
            }
            {!comments.length &&
                <section className="d-flex flex-column align-items-center justify-content-center comments-section--section">
                    <img src={placeholder} alt='' className="comments-section--section__comments-image" />
                    <label className='comments-section--section__label'>No comments yet</label>
                </section>
            }
        </Card>
    );
}