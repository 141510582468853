import React, { useCallback, useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';
import { useField } from 'formik';
import { UK, ESTABLISHMENT, HOSPITAL, ADDRESS } from '../../constants/googleConstants';

const searchOptionsForHospital = {
    types: [ESTABLISHMENT],
    componentRestrictions: { country: UK },
    fields: [HOSPITAL],
};

const searchOptionsForAddress = {
    types: [ADDRESS],
    componentRestrictions: { country: UK },
};

const GoogleAutocomplete = ({ name, type = "text", onSelect, placeholder, placeId, defaultValue, allowClear = true, isForAddress }) => {
    const [isOpenAutocomplete, updateIsOpenAutocomplete] = useState(false);
    const [locationAddress, setLocationAddress] = useState('');
    const [, meta] = useField({ name, type, placeholder });

    const onUpdate = (address, placeId) => {
        onSelect({ address, placeId })
    };

    const handleSelect = (addressName, placeId) => {
        updateIsOpenAutocomplete(false);
        onUpdate(addressName, placeId);
    };

    const handleChange = (address) => {
        updateIsOpenAutocomplete(true);
        setLocationAddress(address.trimLeft());
    };

    const onError = (status, clearSuggestions) => {
        clearSuggestions();
    };

    const getLocationName = useCallback(() => {
        if (!placeId) {
            setLocationAddress(defaultValue);
            return;
        }

        geocodeByPlaceId(placeId)
            .then(results => {
                const [{ formatted_address }] = results;

                setLocationAddress(formatted_address);
                updateIsOpenAutocomplete(false);
            })
    }, [placeId, defaultValue]);

    // Clear selection
    const onReset = () => {
        onSelect({
            address: "",
            placeId: ""
        })
    };

    const onBlur = () => {
        if (allowClear && !locationAddress) {
            onReset();
            return
        }

        getLocationName();
    };

    // Get address name
    useEffect(() => {
        getLocationName()
    }, [getLocationName]);

    return (
        <PlacesAutocomplete
            debounce={200}
            value={locationAddress}
            onChange={(address) => handleChange(address)}
            onSelect={(address, placeId) => handleSelect(address, placeId)}
            searchOptions={isForAddress ? searchOptionsForAddress : searchOptionsForHospital}
            onError={onError}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                const inputProps = getInputProps({
                    onBlur,
                    name,
                    type,
                    placeholder: placeholder,
                    className: `form-control ${meta.error && meta.touched ? 'c-invalid-input' : ''}`,
                });
                return (
                    <div className="autocomplete-wrapper">

                        <input
                            {...inputProps}
                        />

                        {
                            isOpenAutocomplete && (loading || !!suggestions.length) &&
                            <div className="autocomplete-dropdown-container">

                                {
                                    loading &&
                                    <div className={"autocomplete-dropdown__loading"}>Loading...</div>
                                }

                                {
                                    suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion__item--active'
                                            : 'suggestion__item';
                                        return (
                                            <div
                                                key={`ofghare${index}`}
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                })}
                                            >
                                                <span className="suggestion__text">{suggestion.description}</span>
                                            </div>
                                        );
                                    })
                                }
                            </div>}
                    </div>
                )
            }}
        </PlacesAutocomplete>
    )
};

export default GoogleAutocomplete;
