import Http from './axios-config';
import {ACTIVATION_CODE_LIMIT} from "../constants/paginationLimits";

class ActivationCodeService {
    constructor() {
        this.http = new Http();
    }

    async getActivationCodesList({ limit = ACTIVATION_CODE_LIMIT, offset = 0 }) {
        return await this.http.get(`/clinical-admins/activation-codes?limit=${limit}&offset=${offset}`);
    }

    async createActivationCode(model) {
        return await this.http.post('/clinical-admins/activation-codes', model);
    }

    async getActivationCode(id) {
        return await this.http.get(`/clinical-admins/activation-codes/${id}`);
    }

    async updateActivationCode(id, model) {
        return await this.http.put(`/clinical-admins/activation-codes/${id}`, model);
    }

    async deleteActivationCode(id) {
        return await this.http.delete(`/clinical-admins/activation-codes/${id}`);
    }
}

export default new ActivationCodeService();