import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import searchIcon from '../../assets/images/ic-search-gray.svg';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import DoctorManagementService from '../../services/doctorsManagement';
import PatientManagementService from '../../services/patientsManagement';
import { SEARCH_LIMIT } from '../../constants/paginationLimits';
import formatDateSlash from '../../helpers/formatTimeSlash';
import getUsersType from '../../helpers/getUsersType';
import { doctorTypes } from '../../constants/doctorsRoles';
import checkOnSpace from '../../helpers/checkSpaceInput';
import parseChildren from "../../helpers/parseChildren";

const { Input, Menu, Placeholder } = components;

const DOCTOR = 1;

const DropdownIndicator = () => {
    return null;
};

const IndicatorSeparator = () => {
    return null;
};

const customStyles = {
    control: () => ({
        width: 325,
        height: 35,
        paddingLeft: "35px",
        border: "1px solid #ced4da",
        borderRadius: '50px',
    }),
    menu: styles => ({
        ...styles,
        width: '325px',
        height: 'fit-content',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    }),
    menuList: styles => ({
        ...styles,
        width: '325px',
        height: 'fit-content',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
    }),
    valueContainer: base => ({
        ...base,
        paddingLeft: 0,
    })
}

const CustomInput = props => {
    if (props.isHidden) {
        return <Input {...props} />;
    }
    return (
        <div className="search-input w-100">
            <Input {...props} />
        </div>
    );
};

const NoOptionsMessage = () => {
    return (
        <div className="search-options">
            No options
        </div>
    );
};

const IndicatorsContainer = () => {
    return null;
};

const CustomPlaceholder = props => {
    return <Placeholder {...props} ><span className="search-placeholder">Search...</span></Placeholder>;
};

export default function GlobalSearch() {
    const [query, setQuery] = useState("");
    const [patients, setPatients] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [doctorsOffset, setDoctorsOffset] = useState(0);
    const [patientsOffset, setPatientsOffset] = useState(0);
    const [doctorsPagination, setDoctorsPagination] = useState({});
    const [patientPagination, setPatientPagination] = useState({});

    const history = useHistory();

    const groupedOptions = [
        {
            label: 'Patient',
            options: patients,
        },
        {
            label: 'Doctor',
            options: doctors,
        },
    ];

    function onInputChange(inputValue, action) {
        if (action.action !== "input-blur" && action.action !== "menu-close") {
            setQuery(inputValue)
        }
    }

    function handleChange({ value }) {
        if (value?.isChild) {
            history.push(`/patients-management/patients/child?id=${value?.id}&parentId=${value?.parentId}`);
        } else {
            history.push(`/patients-management/patients?id=${value.id}`)
        }
    }

    function generateLabel(option, type) {
        return (
            <Row>
                <Col lg={5}>{option.firstName + " " + option.lastName}</Col>
                <Col>{formatDateSlash(option.birthday)}</Col>
                <Col>{type === DOCTOR ? doctorTypes[option.role] : getUsersType(option.birthday)}</Col>
            </Row>
        );
    }

    function generateInputLabel(option, type) {
        return (
            <section className="d-flex flex-row justify-content-between">
                <span>{option.firstName + " " + option.lastName}</span>
                <span className="mx-2">{formatDateSlash(option.birthday)}</span>
                <span>{type === DOCTOR ? doctorTypes[option.role - 1] : getUsersType(option.birthday)}</span>
            </section>
        );
    }

    async function getDoctors(offset, query) {
        const { data, pagination } = await DoctorManagementService.getDoctors({
            query,
            offset,
            limit: SEARCH_LIMIT,
        })
        setDoctorsPagination(pagination);
        setDoctors((prevState) => {
            return offset ? [...prevState, ...data] : [...data];
        });
    }

    async function getPatients(offset, query) {
        const { data, pagination } = await PatientManagementService.getPatients({
            query,
            offset,
            limit: SEARCH_LIMIT,
        })
        setPatientPagination(pagination);
        setPatients((prevState) => {
            // flat children
            const newData = parseChildren(data || [])

            return offset ? [...prevState, ...newData] : [...newData];
        });
    }

    useEffect(() => {
        getDoctors(doctorsOffset, query)
    }, [doctorsOffset, query]);

    useEffect(() => {
        getPatients(patientsOffset, query)
    }, [patientsOffset, query]);

    useEffect(() => {
        getDoctors(0, query);
        getPatients(0, query);
    }, [query]);

    const CustomMenu = ({ options, ...props }) => {
        return (
            <div className="custom-design-scroll">
                <Menu {...props} className="custom-design-scroll">
                    {options.map(({ label, options }, index) => (
                        <section key={index} className="p-2">
                            <label className="drug-supply">{label}</label>
                            {options.length ? (
                                <>
                                    {options.map(option => {
                                        const additionalProps = {
                                            className: "cursor-default",
                                            label: generateLabel(option, DOCTOR),
                                        }
                                        if (!index) {
                                            additionalProps.className = "global-search-options";
                                            additionalProps.label = generateLabel(option);
                                            additionalProps.onClick = () => props.selectOption({ label: index ? generateInputLabel(option, DOCTOR) : generateInputLabel(option), value: option });
                                        }
                                        return (
                                            <div key={option.id} className={additionalProps.className} onClick={additionalProps.onClick}>
                                                {additionalProps.label}
                                            </div>
                                        )
                                    })}

                                    {!!index && doctorsPagination.totalCount > doctors.length &&
                                        <label className="show-more-search cursor-pointer mt-1"
                                            onClick={() => setDoctorsOffset((prevState) => prevState + SEARCH_LIMIT)}
                                        >
                                            Show more
                                    </label>
                                    }
                                    {!index && patientPagination.totalCount > patients.length &&
                                        <label className="show-more-search cursor-pointer mt-1"
                                            onClick={() => setPatientsOffset((prevState) => prevState + SEARCH_LIMIT)}
                                        >
                                            Show more
                                    </label>
                                    }
                                </>
                            ) : (<NoOptionsMessage />)}
                        </section>
                    ))}
                </Menu>
            </div >
        );
    };

    return (
        <span className="d-flex flex-row align-items-center">
            <img src={searchIcon} alt="" className="search-icon" />
            
            <Select
                closeMenuOnSelect={true}
                styles={customStyles}
                className="custom-design-scroll"
                components={{
                    DropdownIndicator,
                    IndicatorSeparator,
                    Placeholder: CustomPlaceholder,
                    Input: CustomInput,
                    NoOptionsMessage,
                    IndicatorsContainer,
                    Menu: CustomMenu
                }}
                onKeyDown={event => checkOnSpace(event, query)}
                options={groupedOptions}
                onChange={handleChange}
                inputValue={query}
                onInputChange={onInputChange}
                filterOption={() => { return true }}
                value={null}
            />
        </span>
    )
}