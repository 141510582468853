import React, {useRef, useState} from "react";
import {useFormikContext} from "formik";

import {errorMessage} from "constants/errorMessages";
import Icon from "components/Icon";
import {ICONS} from "constants/svgIcons";
import {getParsedData, validateEmptyFile, validateFileSize, validateFileType} from "helpers/parseFile";
import {
    ALLOWED_CSV_FILE_EXTENSION,
    CSV_FILE_EXTENSION,
    ERROR_ALLOWED_CSV_FILE_TYPE_MESSAGE,
    ERROR_CSV_FILE_SIZE_MESSAGE,
    MAX_CSV_FILE_SIZE
} from "constants/uploadFileConstants";


export const UploadFileSection = () => {
    const fileInput = useRef();
    const { setFieldValue } = useFormikContext();

    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileErrorMessage, setFIleErrorMessage] = useState("");

    const handleChange = async (event) => {
        const selectedFile = event.target.files[0];

        setFIleErrorMessage("");

        if (!selectedFile) return;

        try {
            validateFileType(selectedFile, ALLOWED_CSV_FILE_EXTENSION, ERROR_ALLOWED_CSV_FILE_TYPE_MESSAGE);
            validateFileSize(selectedFile, MAX_CSV_FILE_SIZE, ERROR_CSV_FILE_SIZE_MESSAGE);
            validateEmptyFile(selectedFile);

            const parsedData = await getParsedData(selectedFile);

            const fieldsWithValue = parsedData.filter(email => email);

            setFieldValue("emails", fieldsWithValue );

            setUploadedFile(selectedFile);

        } catch ({ message }) {
            setFIleErrorMessage(message);
        }

    };

    const onUploadClick = () => {
        fileInput.current?.click();
    }

    const handleDelete = () => {
        setUploadedFile(null);
        setFieldValue("emails", []);
    };

    return (
        <div className="d-flex flex-column">
            <label className="mt-2">Emails to use code</label>
            {uploadedFile
                ? (
                    <div className="border rounded px-3_5 py-2 d-flex flex-row justify-content-between mb-3_5 mt-1">
                        <div className="d-flex flex-row align-items-center">
                          <span className="coupon-form-file-icon">
                                <Icon icon={ICONS.fileSimple}/>
                          </span>
                            <span
                                className="font-size-13 file-row-label pl-4 m-0 text-break"

                            >
                                {uploadedFile.name}
                            </span>
                        </div>
                        <span
                            className="cursor-pointer"
                            onClick={handleDelete}
                        >
                        <Icon icon={ICONS.trashIcon}/></span>
                    </div>
                )
                : (
                    <>
                        <button
                            type="button"
                            className="btn btn-outline-primary w-full"
                            onClick={onUploadClick}
                        >
                            <span>+ Upload file</span>
                        </button>
                        <input
                            ref={fileInput}
                            className="d-none"
                            type="file"
                            onChange={handleChange}
                            multiple={false}
                            accept={CSV_FILE_EXTENSION}
                        />
                    </>
                )
            }
            {!!fileErrorMessage && <div className="mt-1">{errorMessage(fileErrorMessage)}</div>}
        </div>
    )
};
