import React from "react";
import {Modal} from "reactstrap";
import {ErrorMessage, Formik} from "formik";
import * as yup from "yup";

import {
    errorMessage,
    passwordContains,
    passwordDoesntMatch,
    passwordLength,
    requiredError,
} from "constants/errorMessages";
import {patientPasswordValidation} from "constants/validityPatterns";
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from "constants/lengthValidity";


const passwordValidationSchema = yup.object().shape({
    password: yup.string(requiredError)
        .required(requiredError)
        .typeError(requiredError)
        .min(PASSWORD_MIN_LENGTH, passwordLength)
        .max(PASSWORD_MAX_LENGTH, passwordLength)
        .matches(patientPasswordValidation, passwordContains),
    confirmPassword: yup.string()
        .required(requiredError)
        .oneOf([yup.ref("password")], passwordDoesntMatch),
});

export const ResetPatientPasswordModal = ({ isOpen, handleSubmit, close }) => {
    return (
        <Modal
            isOpen={isOpen}
            centered
        >
            <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                onSubmit={handleSubmit}
                validationSchema={passwordValidationSchema}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid
                }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="p-40px h-100 w-100">
                                <label className="font-size-22 font-weight-normal mb-1">
                                    Create new password
                                </label>
                                <div className="mb-4 mt-2">
                                    <label className="mb-2">New password</label>
                                    <input
                                        type="text"
                                        name="password"
                                        placeholder="Enter new password"
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        disabled={isSubmitting}
                                    />
                                    <ErrorMessage name="password" render={errorMessage}/>
                                </div>
                                <div className="mb-4 mt-2">
                                    <label className="mb-2">Confirm new password</label>
                                    <input
                                        type="text"
                                        name="confirmPassword"
                                        placeholder="Confirm new password"
                                        className="form-control"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.confirmPassword}
                                        disabled={isSubmitting}
                                    />
                                    <ErrorMessage name="confirmPassword" render={errorMessage}/>
                                </div>
                                <section
                                    className="d-flex flex-column flex-sm-row w-100 justify-content-center modal-actions coupon-form-wrapper-buttons pt-4 mt-0">
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        Reset password
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-danger w-100"
                                        onClick={close}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </button>
                                </section>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </Modal>
    )
};
