import React, { useState } from 'react';
import { Modal, Card } from "reactstrap";
import closeIcon from '../../../assets/images/ic-close-black.svg';
import filterList from '../../../helpers/toast/filtersList';

const WAITING_CONFIRMATION = 1;

const dateFilters = [
    { id: 'week', label: "Week", value: 1 },
    { id: 'month', label: "Month", value: 2 },
    { id: '3month', label: "3 months", value: 3 },
    { id: '6month', label: "6 months", value: 4 },
    { id: 'year', label: "Year", value: 5 },
];

const typeFilters = [
    { id: 'adult', label: "Adult", value: 1 },
    { id: 'child', label: "Child", value: 2 },
];

const accountFilters = [
    { id: 'active', label: "Active", value: 0 },
    { id: 'blocked', label: "Blocked", value: 1 },
];

const confirmationFilters = [
    { id: 'pending', label: "Pending", value: 1 },
    { id: 'rejected', label: "Rejected", value: 3 },
];

const MAX_FILTERS_TYPE = typeFilters.length;
const MAX_FILTERS_STATUS = accountFilters.length;
const MAX_FILTERS_DATE = dateFilters.length;

export default function Filters({ visibleModal, applyFilters, handleClose, activeTab, searchQuery }) {

    const [filters, setFilters] = useState({
        ageType: filterList(searchQuery.ageType),
        isBlocked: filterList(searchQuery.isBlocked),
        addingDateTypes: filterList(searchQuery.addingDateTypes),
        isWaiting: filterList(searchQuery.isWaiting),
    })

    function updateFilters(key, value) {
        setFilters((prevState) => {
            return {
                ...prevState,
                [key]: prevState[key].includes(value) ? prevState[key].filter(item => item !== value) : [...prevState[key], value],
            }
        })
    }

    function handleCheckDate(value) {
        updateFilters('addingDateTypes', value)
    }

    function handleCheckType(value) {
        updateFilters('ageType', value)
    }

    function handleCheckStatus(value) {
        updateFilters('isBlocked', value)
    }

    function handleWaitingStatus(value) {
        updateFilters('isWaiting', value)
    }

    function setAllChecked(key, length, arr) {
        setFilters((prevState) => {
            return {
                ...prevState,
                [key]: prevState[key].length >= length ? [] : arr.map(item => item.value),
            }
        })
    }

    function selectAllDate() {
        setAllChecked('addingDateTypes', MAX_FILTERS_DATE, dateFilters);
    }

    function selectAllTypes() {
        setAllChecked('ageType', MAX_FILTERS_TYPE, typeFilters);
    }

    function selectAllStatus() {
        setAllChecked('isBlocked', MAX_FILTERS_STATUS, accountFilters);
    }
    
    function selectAllStatusWaiting() {
        setAllChecked('isWaiting', MAX_FILTERS_STATUS, confirmationFilters);
    }

    return (
        <Modal
            isOpen={visibleModal}
            className="filter-modal mt-0 p-0"
            contentClassName="modal-custom-content"
            toggle={handleClose}
        >
            <Card className=" mb-0 filter-modal-card">
                <header className="details-caption d-flex flex-row justify-content-between pt-4 px-4 mb-4">Filter <img src={closeIcon} alt="" onClick={handleClose} className="cursor-pointer" /></header>
                <span className="splash-line" />
                <div className="modal-body modal-text-styles pt-0 px-4">

                    <section >
                        <label className="group-label">Adding date</label>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                className="custom-control-input"
                                name="addingDateTypes"
                                value="allDates"
                                id="allDates"
                                checked={filters.addingDateTypes.length >= MAX_FILTERS_DATE}
                                onChange={() => selectAllDate()}
                            />
                            <label className="custom-control-label checkbox-label" htmlFor="allDates">All</label>
                        </div>
                        {dateFilters.map((option) => (
                            <div className="custom-control custom-checkbox" key={option.id}>
                                <input type="checkbox"
                                    className="custom-control-input"
                                    name="addingDateTypes"
                                    value={option.id}
                                    id={option.id}
                                    checked={filters.addingDateTypes.includes(option.value)}
                                    onChange={() => handleCheckDate(option.value)}
                                />
                                <label className="custom-control-label checkbox-label" htmlFor={option.id}>{option.label}</label>
                            </div>
                        ))}
                    </section>

                    <section className="mt-5">
                        <label className="group-label">Type</label>
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox"
                                className="custom-control-input"
                                name="ageType"
                                value="allTypes"
                                id="allTypes"
                                checked={filters.ageType.length >= MAX_FILTERS_TYPE}
                                onChange={() => selectAllTypes()}
                            />
                            <label className="custom-control-label checkbox-label" htmlFor="allTypes">All</label>
                        </div>
                        {typeFilters.map((option) => (
                            <div className="custom-control custom-checkbox" key={option.id}>
                                <input type="checkbox"
                                    className="custom-control-input"
                                    name="ageType"
                                    value={option.id}
                                    id={option.id}
                                    checked={filters.ageType.includes(option.value)}
                                    onChange={() => handleCheckType(option.value)}
                                />
                                <label className="custom-control-label checkbox-label" htmlFor={option.id}>{option.label}</label>
                            </div>
                        ))}
                    </section>

                    {activeTab === WAITING_CONFIRMATION ? (
                        <section className="mt-5">
                            <label className="group-label">Confirmation status</label>
                            <div className="custom-control custom-checkbox">
                                <input type="checkbox"
                                    className="custom-control-input"
                                    name="isWaiting"
                                    value="allAccounts"
                                    id="allAccounts"
                                    checked={filters.isWaiting?.length >= MAX_FILTERS_STATUS}
                                    onChange={() => selectAllStatusWaiting()}
                                />
                                <label className="custom-control-label checkbox-label" htmlFor="allAccounts">All</label>
                            </div>
                            {confirmationFilters.map((option) => (
                                <div className="custom-control custom-checkbox" key={option.id}>
                                    <input type="checkbox"
                                        className="custom-control-input"
                                        name="isWaiting"
                                        value={option.id}
                                        id={option.id}
                                        checked={filters.isWaiting?.includes(option.value)}
                                        onChange={() => handleWaitingStatus(option.value)}
                                    />
                                    <label className="custom-control-label checkbox-label" htmlFor={option.id}>{option.label}</label>
                                </div>
                            ))}
                        </section>
                    ) : (
                            <section className="mt-5">
                                <label className="group-label">Account status</label>
                                <div className="custom-control custom-checkbox">
                                    <input type="checkbox"
                                        className="custom-control-input"
                                        name="isBlocked"
                                        value="allAccounts"
                                        id="allAccounts"
                                        checked={filters.isBlocked.length >= MAX_FILTERS_STATUS}
                                        onChange={() => selectAllStatus()}
                                    />
                                    <label className="custom-control-label checkbox-label" htmlFor="allAccounts">All</label>
                                </div>
                                {accountFilters.map((option) => (
                                    <div className="custom-control custom-checkbox" key={option.id}>
                                        <input type="checkbox"
                                            className="custom-control-input"
                                            name="isBlocked"
                                            value={option.id}
                                            id={option.id}
                                            checked={filters.isBlocked.includes(option.value)}
                                            onChange={() => handleCheckStatus(option.value)}
                                        />
                                        <label className="custom-control-label checkbox-label" htmlFor={option.id}>{option.label}</label>
                                    </div>
                                ))}
                            </section>
                        )}
                    <button className="btn btn-primary w-75 mt-5 apply-button" onClick={() => applyFilters(filters)}>Apply</button>
                </div>
            </Card>
        </Modal>
    )
}