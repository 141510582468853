import { MALE, FEMALE, OTHER } from '../constants/genders';
import * as _ from 'lodash';

const genders = {
    [MALE]: 'Male',
    [FEMALE]: 'Female',
    [OTHER]: 'Other',
}

export default function getUsersGender(gender) {
    return _.get(genders, gender, '-');
}