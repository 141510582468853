import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, Card, Container } from "reactstrap";
import changePasswordSuccess from '../../assets/images/changePasswordSuccess.svg';

function ChangePasswordSuccessModal({ handleSubmit, visibleModal, t }) {
    return (
        <Container>
            <Modal
                isOpen={visibleModal}
                className="modal-size p-4"
                centered={true}
            >
                <Card className="success-password-modal">
                    <div className="modal-body modal-text-styles text-center">
                        <img src={changePasswordSuccess} alt="" className="changePasswordSuccessLogo " />
                        <p className="text-center mt-4">
                            {t("Your password was successfully changed")}
                        </p>
                    </div>
                    <button className="btn btn-primary w-100 mt-4" onClick={handleSubmit}>{t("Log in")}</button>
                </Card>
            </Modal>
        </Container>
    );
}

export default withNamespaces()(ChangePasswordSuccessModal);