import React, { useState, useEffect, useCallback, useMemo } from "react";
import Video from "twilio-video";
import { Card, Spinner } from 'reactstrap';
import { Alert, Fade } from 'reactstrap';

import { ReactComponent as MicroOff } from '../../assets/images/callIcons/ic-mic-off-black.svg';
import { ReactComponent as MicroOn } from '../../assets/images/callIcons/ic-mic-on-black.svg';
import { ReactComponent as VideoOn } from '../../assets/images/callIcons/ic-video-on-black.svg';
import { ReactComponent as VideoOff } from '../../assets/images/callIcons/ic-video-off-black.svg';
import { ReactComponent as Phone } from '../../assets/images/callIcons/ic-phone-call-white.svg';
import { ReactComponent as Volume } from '../../assets/images/callIcons/ic-volume-white.svg';
import { ReactComponent as Settings } from '../../assets/images/callIcons/ic-settings-white.svg';
import { ReactComponent as Placeholder } from '../../assets/images/im-placeholders.svg';
import CallButton from './callButtons/callButton';
import toastr from '../../components/toastr';
import CallTimer from './components/timer';
import Participant from './components/participant';
import DeviceSettingsModal from "./components/DeviceSettingsModal";
import {
    PARTICIPANT_CONNECTED,
    CALL_COMPLETED,
    DISCONNECTED,
    MAX_VOLUME_PERCENT,
    INITIAL_VOLUME,
    MEDIA_CONNECTION_FAILED_OR_MEDIA_ACTIVITY_CEASED,
} from 'constants/callConstants';
import { AUDIO_TYPE } from '../../constants/communicationTypes';
import CallsService from '../../services/calls';
import AppointmentsService from '../../services/appointments';
import dangerIcon from '../../assets/images/ic-notification-white.svg';
import closeIcon from '../../assets/images/ic-close.svg';
import Modal from "../../components/Modal";
import {
    NOTIFICATION_TYPES,
    ALERT_TEXT,
    AGREED_PRESCRIPTION,
    ALERT_COLORS,
    AGREED_DOCUMENTS,
    PRESCRIPTION_AGREED_TYPE,
} from '../../constants/callConstants';
import descSort from '../../helpers/sortFunctions/descSort';
import StorageService from '../../services/StorageService';
import {CALL_VARIABLES, USER} from '../../constants/storageConstants';
import { BEFORE_UNLOAD } from '../../constants/windowActions';
import addNewAlert from '../../helpers/addNewAlert';
import {CLINICAL_ADMIN, SUPER_ADMIN} from "constants/roles";
import CancelModal from "components/manageSessionModal/cancelModal";


export default function VideoComponent({ token,
    roomName,
    formRef,
    appointment,
    duration,
    isAgreedToPrescription,
    setDocumentsToFill,
    isSubmitting
}) {
    const userRole = new StorageService().getValueByKey(USER)?.role;
    const isUserClinicalAdminOrSuperAdmin =  (userRole === CLINICAL_ADMIN) || (userRole === SUPER_ADMIN);

    const [room, setRoom] = useState(null);
    const [participant, setParticipant] = useState(null);
    const [microMuted, setMicroMuted] = useState(false);
    const [videoMuted, setVideoMuted] = useState(false);
    const [newSpeakers, setNewSpeakers] = useState(null);
    const [isOpenDeviceSettings, toggleDeviceSettings] = useState(false);
    const [isCallEnded, setCallEnded] = useState(false);
    const [localVideo, setLocalVideo] = useState(null);
    const [selectedDevices, setSelectedDevices] = useState({})
    const [showVolumeSlider, setShowVolumeSlider] = useState(false);
    const [volume, setVolume] = useState(INITIAL_VOLUME);
    const [isLoading, setIsLoading] = useState(true);
    const [alerts, setAlerts] = useState([]);
    const [timerDuration, setTimerDuration] = useState();
    const [isEndCallModalOpen, setIsEndCallModalOpen] = useState(false);

    const savedValues = useMemo(() => new StorageService().getValueByKey(CALL_VARIABLES), []);

    const leaveRoomIfJoined = useCallback(
        async (error) => {
            if (error?.code === CALL_COMPLETED) {
                setRoom(currentRoom => {
                    try {
                        stopDevices(currentRoom.localParticipant.tracks)
                        currentRoom.disconnect();
                    } catch {
                        //empty
                    }
                    return null;
                });
                setCallEnded(true);
                await AppointmentsService.updateAppointmentActualEndsTime(appointment.id, new Date())
                return;
            }

            if (error?.code === MEDIA_CONNECTION_FAILED_OR_MEDIA_ACTIVITY_CEASED) {
                toastr.error("Please refresh the page or rejoin the call")
            }

            await CallsService.endCall(appointment.id);
        },
        [appointment.id],
    );

    function toggleService(service, servicesArray) {
        service((prevState) => {
            !prevState ? disableDevices(servicesArray) : enableDevices(servicesArray);
            return !prevState;
        });
    }

    function disableDevices(devices) {
        devices.forEach(track => track.track.disable());
    }

    function enableDevices(devices) {
        devices.forEach(track => track.track.enable());
    }

    function stopDevices(devices) {
        devices.forEach(track => track.track.stop());
    }

    const participantConnected = useCallback(
        async (participant) => {
            if (savedValues) {
                setTimerDuration(savedValues.timer);
            } else {
                setTimerDuration(Date.now() + duration);
            }
            setParticipant(participant);
            await AppointmentsService.updateAppointmentActualStartsTime(appointment.id, new Date())
        },
        [duration, savedValues],
    )

    function detachTracks(tracksMap, participant) {
        const tracks = Array.from(tracksMap).map(
            (trackPublication) => {
                return trackPublication.track;
            }
        );
        participant.unpublishTracks(tracks);
    }

    function updateVideoDevice(deviceId) {
        const localParticipant = room.localParticipant;
        detachTracks(localParticipant.videoTracks.values(), localParticipant);

        Video.createLocalVideoTrack({
            deviceId: { exact: deviceId }
        }).then(async (localVideoTrack) => {
            const newVideo = await localParticipant.publishTrack(localVideoTrack);
            if (videoMuted) {
                disableDevices([newVideo])
            }
            setLocalVideo(localVideoTrack)
        });
    }

    function updateAudioDevice(deviceId) {
        const localParticipant = room.localParticipant;
        detachTracks(localParticipant.audioTracks.values(), localParticipant);

        Video.createLocalAudioTrack({
            deviceId: { exact: deviceId }
        }).then(async (localAudioTrack) => {
            const newAudio = await localParticipant.publishTrack(localAudioTrack);
            if (microMuted) {
                disableDevices([newAudio])
            }
        });
    }

    useEffect(() => {
        setIsLoading(true)
        Video.connect(token, {
            name: roomName,
        }).then(room => {
            setRoom(room);
            setIsLoading(false);
            room.on(PARTICIPANT_CONNECTED, participantConnected);
            room.once(DISCONNECTED, (room, error) => leaveRoomIfJoined(error));
            room.participants.forEach(participantConnected);
        })
            .catch(error => {
                setIsLoading(false);
                toastr.error(error.message)
            });
    }, [roomName, token, participantConnected, leaveRoomIfJoined]);

    function selectDevices(video, audio, speaker) {
        const newDevices = {};
        if (video) {
            updateVideoDevice(video);
            newDevices.video = video;
        }
        if (audio) {
            updateAudioDevice(audio);
            newDevices.audio = audio;
        }
        if (speaker) {
            setNewSpeakers(speaker);
            newDevices.speaker = speaker;
        }
        setSelectedDevices(prevState => ({
            ...prevState,
            ...newDevices,
        }))
    }

    useEffect(() => {
        if (room && appointment.communicationType === AUDIO_TYPE) {
            setVideoMuted(true)
            disableDevices(room.localParticipant.videoTracks)
        }
    }, [room, appointment.communicationType]);

    const getMessages = useCallback(
        async () => {
            const firebase = await import('../../services/firebase');
            const messaging = firebase.default.messaging();
            messaging.onMessage((payload) => {
                const newNotification = NOTIFICATION_TYPES[payload.data.type];
                addNewAlert(setAlerts, newNotification);
                if (AGREED_DOCUMENTS.includes(newNotification)) {
                    setDocumentsToFill(prevState => [...prevState, payload.data.type]
                        .sort(descSort));
                }
            });
        },
        [setDocumentsToFill],
    );

    useEffect(() => {
        getMessages();
    }, [getMessages])

    useEffect(() => {
        if (isAgreedToPrescription) {
            addNewAlert(setAlerts, AGREED_PRESCRIPTION);
            setDocumentsToFill(prevState => [...prevState, PRESCRIPTION_AGREED_TYPE]);
        }
    }, [isAgreedToPrescription, setDocumentsToFill])

    const saveValuesHandler = useCallback(
        () => {
            new StorageService().addValueByKey(CALL_VARIABLES, {
                timer: timerDuration,
            })
        },
        [timerDuration],
    );

    useEffect(() => {
        window.addEventListener(BEFORE_UNLOAD, saveValuesHandler);
        return () => {
            window.removeEventListener(BEFORE_UNLOAD, saveValuesHandler);
        }
    }, [saveValuesHandler]);


    useEffect(() => {
        return () => {
            leaveRoomIfJoined();
        }
    }, []);

    return (
        <>
            {!isCallEnded ?
                (room &&
                    <section className={"position-relative mb-4 " + (!participant && 'w-100 h-100')}>
                        {participant ?
                            <>
                                <Participant
                                    participant={participant}
                                    classname="remote-participant back-color-call"
                                    patient={appointment}
                                    newSpeakers={newSpeakers}
                                    volume={volume}
                                />
                                <section className="call-timer-section">
                                    <div className="call-red-oval" />
                                    <section className="call-timer ml-3">
                                        <CallTimer
                                            duration={timerDuration}
                                            setAlerts={setAlerts}
                                            alerts={alerts}
                                            isCA={isUserClinicalAdminOrSuperAdmin}
                                        />
                                    </section>
                                </section>
                            </>
                            :
                            <section className="w-100 h-100 back-color-call">
                                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                    <label>Patient will be with you shorty</label>
                                </div>
                            </section>
                        }
                        <Participant
                            participant={room.localParticipant}
                            patient={appointment}
                            isLocal
                            localVideo={localVideo}
                            classname="call-small-picture off-camera-placeholder d-flex align-items-center justify-content-center "
                            volume={volume}
                            videoMuted={videoMuted}
                        />
                        <section className="call-buttons-section w-100">
                            <CallButton classname="call-button-transparent ml-5" component={<Volume />} clickAction={() => setShowVolumeSlider(prevState => !prevState)} />
                            <div>
                                <CallButton classname="call-button-white" clickAction={() => toggleService(setVideoMuted, room.localParticipant.videoTracks)} component={!videoMuted ? <VideoOn /> : <VideoOff />} />
                                <CallButton classname="call-button-red" component={<Phone />} clickAction={() => setIsEndCallModalOpen(true)} />
                                <CallButton classname="call-button-white" clickAction={() => toggleService(setMicroMuted, room.localParticipant.audioTracks)} component={microMuted ? <MicroOff /> : <MicroOn />} />
                            </div>
                            <CallButton classname="call-button-transparent right mr-5" component={<Settings />} clickAction={() => toggleDeviceSettings(true)} />
                        </section>
                        {showVolumeSlider &&
                            <input
                                type="range"
                                className="custom-range volume-slider"
                                id="customRange1"
                                onBlur={() => setShowVolumeSlider(false)} onChange={(event) => setVolume(event.target.value / MAX_VOLUME_PERCENT)}
                                value={volume * MAX_VOLUME_PERCENT}
                            />
                        }
                        <section className="call-notifications">
                            {alerts.map((alert, index) => (
                                <Fade key={index}>
                                    <Alert
                                        className={
                                            "alert-dismissible fade show custom-notification ml-4 position-relative call-notifications__"
                                            +
                                            ALERT_COLORS[alert]
                                        }
                                        isOpen={true}
                                        toggle={() => setAlerts(prevState => prevState.filter((value, oldIndex) => oldIndex !== index))}
                                    >
                                        <img src={dangerIcon} alt='info' className='ml-2 position-absolute' />
                                        <p className="ml-5 mb-0 mr-2">{ALERT_TEXT[alert]}</p>
                                        <img
                                            src={closeIcon}
                                            alt='close'
                                            className='mr-2 custon-notification-two-minutes__close'
                                            onClick={() => setAlerts(prevState => prevState.filter((value, oldIndex) => oldIndex !== index))}
                                        />
                                    </Alert>
                                </Fade>
                            ))}
                        </section>
                        <Modal
                            isOpen={isOpenDeviceSettings}
                            className={"device-settings-popup"}
                        >
                            <DeviceSettingsModal
                                onClose={() => {
                                    toggleDeviceSettings(false)
                                }}
                                selectDevices={selectDevices}
                                selectedDevices={selectedDevices}
                            />
                        </Modal>
                    </section>
                )
                :
                <Card className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                    <label className="call-ended-label mb-2">Call Ended</label>
                    <Placeholder />
                    <button
                        className="btn btn-outline leave-consultation-button mt-5"
                        onClick={() => formRef.current.submit()}
                        disabled={isSubmitting}
                    >
                        { isSubmitting && (
                            <div className="submitting">
                                <Spinner color="light" size="sm" className="text-center"/>
                            </div>
                        )}
                        { !isSubmitting && "Leave Consultation" }
                    </button>
                </Card>
            }
            {isLoading &&
                <div className="patients-table d-flex justify-content-center align-items-center">
                    <Spinner className="mx-auto" color="info" />
                </div>
            }
            {isEndCallModalOpen && (
                <CancelModal
                    visibleModal={isEndCallModalOpen}
                    setVisibleModal={() => setIsEndCallModalOpen(false)}
                    handleClose={() => {
                        leaveRoomIfJoined();
                        setIsEndCallModalOpen(false);
                    }}
                    text="Are you sure you want to end appointment?"
                    header="End call"
                />
            )}
        </>
    );
}
