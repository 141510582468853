import React from 'react';
import { Modal, Card, Row, Col, Spinner } from "reactstrap";
import { Formik, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { requiredError, commentLengthError } from '../../../constants/errorMessages';
import { MIN_NOTE, MAX_COMMENT } from '../../../constants/lengthValidity';

const schema = yup.object().shape({
    videoQuality: yup.string(requiredError).required(requiredError).typeError(requiredError),
    videoIssues: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
    soundQuality: yup.string(requiredError).required(requiredError).typeError(requiredError),
    soundIssues: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
    microQuality: yup.string(requiredError).required(requiredError).typeError(requiredError),
    microphoneIssues: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
    connectQuality: yup.string(requiredError).required(requiredError).typeError(requiredError),
    connectivityIssues: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
    connentToPatient: yup.string(requiredError).required(requiredError).typeError(requiredError),
    clinicianConnectionIssues: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
    connectToDoc: yup.string(requiredError).required(requiredError).typeError(requiredError),
    patientConnectionIssues: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
    otherIssue: yup.string(requiredError).typeError(requiredError).min(MIN_NOTE, commentLengthError).max(MAX_COMMENT, commentLengthError).trim(commentLengthError),
});

const errorMessage = (msg) => (
    <div className="error-text">{msg}</div>
);

const qualityFields = [
    { label: "Video Quality / Problems", fieldName: 'videoQuality', commentName: "videoIssues", id: 1 },
    { label: "Sound Quality / Problems", fieldName: 'soundQuality', commentName: "soundIssues", id: 2 },
    { label: "Microphone Problems", fieldName: 'microQuality', commentName: "microphoneIssues", id: 3 },
];

const patietIssueFields = [
    { label: "Connectivity Problems", fieldName: 'connectQuality', commentName: "connectivityIssues", id: 4 },
    { label: "I could not connect with the patient", fieldName: 'connentToPatient', commentName: "clinicianConnectionIssues", id: 5 },
    { label: "The patient could not connect with me", fieldName: 'connectToDoc', commentName: "patientConnectionIssues", id: 6 },
];

export default function FeedbackModal({ visibleModal, handleSubmit, isDocumentsLoading }) {

    const initialValues = {
        videoQuality: '',
        soundQuality: '',
        microQuality: '',
        connectQuality: '',
        connentToPatient: '',
        connectToDoc: '',
    }

    return (
        <Modal
            isOpen={visibleModal}
            centered={true}
            size="xl"
            className={isDocumentsLoading && "feedback-modal-content"}
        >
            {!isDocumentsLoading ?
                <Card className="p-4 mb-0">
                    <div className="modal-body modal-text-styles custom-design-scroll">
                        <header className="font-size-22 d-flex flex-row justify-content-between">Feedback</header>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true)
                                handleSubmit(values)
                                setSubmitting(false)
                            }}
                        >
                            {({
                                values,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                            }) => (
                                <form onSubmit={handleSubmit} className="form-horizontal">

                                    {qualityFields.map((field, index) => (
                                        <div className="form-group mb-2 mt-4" key={index}>
                                            <Row>
                                                <Col lg={4}>
                                                    <label className="feedback-field">{field.label}</label>
                                                </Col>
                                                <Col lg={8}>
                                                    <section className="d-flex flex-row w-100 justify-content-between align-items-center">
                                                        <div className="custom-control custom-radio">
                                                            <Field type="radio" name={field.fieldName} value={'1'} id={"customRadio" + field.id} className="custom-control-input custom-z-index" />
                                                            <label className="custom-control-label feedback-field" htmlFor={"customRadio" + field.id}>Yes</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <Field type="radio" name={field.fieldName} value={'0'} id={field.id + "customRadio"} className="custom-control-input" />
                                                            <label className="custom-control-label feedback-field" htmlFor={field.id + "customRadio"}>No</label>
                                                        </div>
                                                        <div className="w-75">
                                                            <input
                                                                type="text"
                                                                name={field.commentName}
                                                                placeholder="Additional comment"
                                                                className="form-control w-100"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.commentName}
                                                            />
                                                            <ErrorMessage name={field.commentName} render={errorMessage} />
                                                        </div>
                                                    </section>
                                                    <ErrorMessage name={field.fieldName} render={errorMessage} />
                                                </Col>
                                            </Row>

                                        </div>
                                    ))}

                                    <label className="drug-supply mt-2">Patient Issue</label>

                                    {patietIssueFields.map((field, index) => (
                                        <div className="form-group mb-2 mt-2" key={index}>
                                            <Row>
                                                <Col lg={4}>
                                                    <label className="feedback-field">{field.label}</label>
                                                </Col>
                                                <Col lg={8}>
                                                    <section className="d-flex flex-row w-100 justify-content-between align-items-center">
                                                        <div className="custom-control custom-radio">
                                                            <Field type="radio" name={field.fieldName} value={'1'} id={"customRadio" + field.id} className="custom-control-input custom-z-index" />
                                                            <label className="custom-control-label feedback-field" htmlFor={"customRadio" + field.id}>Yes</label>
                                                        </div>
                                                        <div className="custom-control custom-radio">
                                                            <Field type="radio" name={field.fieldName} value={'0'} id={field.id + "customRadio"} className="custom-control-input" />
                                                            <label className="custom-control-label feedback-field" htmlFor={field.id + "customRadio"}>No</label>
                                                        </div>
                                                        <div className="w-75">
                                                            <input
                                                                type="text"
                                                                name={field.commentName}
                                                                placeholder="Additional comment"
                                                                className="form-control w-100"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.commentName}
                                                            />
                                                            <ErrorMessage name={field.commentName} render={errorMessage} />
                                                        </div>
                                                    </section>
                                                    <ErrorMessage name={field.fieldName} render={errorMessage} />
                                                </Col>
                                            </Row>

                                        </div>
                                    ))}

                                    <Row className="mt-5">
                                        <Col>
                                            <label className="feedback-field">Other issue</label>
                                        </Col>
                                        <Col lg={8}>
                                            <input
                                                type="text"
                                                name="otherIssues"
                                                placeholder="Comment"
                                                className="form-control w-100"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.commentName}
                                            />
                                            <ErrorMessage name="otherIssues" render={errorMessage} />
                                        </Col>
                                    </Row>
                                    <p className="w-75 drug-supply mt-4">*This feedback is for Audit purposes only and is not reviewed on a regular basis. Should you have any concerns about a patient, please contact the duty GP.</p>
                                    <div className="d-flex flex-row justify-content-center mt-5">
                                        <button className="btn btn-primary modal-button-styles" type="submit" disabled={isSubmitting}>Save</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </Card>
                :
                <section className="rating-container">
                    <div className="patients-table d-flex justify-content-center align-items-center">
                        <Spinner className="mx-auto" color="info" />
                    </div>
                </section>
            }
        </Modal>
    )
}