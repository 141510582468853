import React from "react";
import noCouponsImg from "../../../assets/images/ic-no-coupons.svg";

const NoCouponsPlaceholder = () => {

    return (
            <div className="d-flex align-items-center justify-content-center no-coupons">
                <div className="d-flex flex-column align-items-center">
                    <img src={noCouponsImg} className="no-coupons-img" alt=""/>
                    <label className="font-size-16 mt-4 no-coupons-label ">There are no activation codes yet</label>
                </div>
            </div>
    )
}

export default NoCouponsPlaceholder;