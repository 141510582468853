import React, { useMemo, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";

import { MAX_RANGE_LENGTH } from 'constants/lengthValidity';
import formatDateOutput from 'helpers/formatDateOutput';
import sliceMonthName from 'helpers/sliceMonthName';

import Label from '../Label/labelWithError';
import { MyContainer, CustomHeader } from './calendarComponents';

registerLocale("en-GB", en);

export default function CustomDatePicker({ errors, touched, ComponentStart, ComponentEnd, setFieldValue, currentStartDay, currentEndDay, handleChange, isDisabled }) {
    const [startDate, setStartDate] = useState(currentStartDay && new Date(currentStartDay));
    const [endDate, setEndDate] = useState(currentEndDay ? new Date(currentEndDay) : null);

    const maxDate = useMemo(() => new Date().getTime() + MAX_RANGE_LENGTH, [])

    const onChange = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setFieldValue("startDate", start)
        setEndDate(end);
        setFieldValue("endDate", end || '');

        handleChange?.(start, end);
    };

    const CustomInput = ({ value, onClick }) => (
        <section className="d-flex flex-row justify-content-between mt-1">
            <section className="d-flex flex-column">
                <Label text="From" isInvalid={errors.startDate && touched.startDate} />
                <input
                    type="text"
                    placeholder="Enter start day"
                    value={value && formatDateOutput(value)}
                    onClick={onClick}
                    className="custom-calendar-output-field cursor-pointer form-control"
                    disabled={isDisabled}
                />
                <ComponentStart />
            </section>
            <section className="d-flex flex-column">
                <Label text="To" isInvalid={errors.endDate && touched.endDate} />
                <input
                    type="text"
                    placeholder="Enter end day"
                    value={endDate && formatDateOutput(endDate)}
                    onClick={onClick}
                    className="custom-calendar-output-field cursor-pointer form-control"
                    disabled={isDisabled}
                />
                <ComponentEnd />
            </section>
        </section>
    );

    return (
        <>
            <DatePicker
                calendarContainer={MyContainer}
                renderCustomHeader={(props) => <CustomHeader  {...props} />}
                locale="en-GB"
                formatWeekDay={sliceMonthName}
                customInput={<CustomInput />}
                startDate={startDate}
                endDate={endDate}
                minDate={new Date()}
                maxDate={maxDate}
                disabledKeyboardNavigation
                selected={startDate}
                onChange={onChange}
                selectsRange
                shouldCloseOnSelect={!endDate}
            />  
        </>
    )
}
