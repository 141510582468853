import { PENDING_STATUS, CHECKED_STATUS, WAITING_FOR_UPLOAD } from '../constants/appointmentStatuses';

export default function getBadgeValues(user) {
    if (user.isDeleted) {
        return { color: 'danger', value: 'Inactive' }
    }

    if (user.documentCheckStatus === CHECKED_STATUS) {
        return user.isBlocked ? { color: 'danger', value: 'Blocked' } : { color: 'success', value: 'Active' }
    }

    if (user.documentCheckStatus === WAITING_FOR_UPLOAD) {
        return user.isBlocked ? { color: 'danger', value: 'Blocked' } : { color: 'info', value: 'Awaiting ID' }
    }

    return user.documentCheckStatus === PENDING_STATUS ? { color: 'warning', value: 'Pending' } : { color: 'danger', value: 'Rejected' }
}