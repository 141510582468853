import Papa from "papaparse";

import {EMPTY_FILE_ERROR, EMPTY_FILE_SIZE} from "constants/uploadFileConstants";


export const getExtensionFile = ({ name }) => {
    return name.substring(name.lastIndexOf(".") + 1).toLowerCase();
};

export const isAllowedExtension = (file, allowedExtensions) => {
    const fileExtension = getExtensionFile(file);

    return allowedExtensions.some((allowedExtension) => {
        return fileExtension === allowedExtension;
    });
};

export const validateEmptyFile = (file) => {
    if (file?.size === EMPTY_FILE_SIZE) {
        throw new Error(EMPTY_FILE_ERROR);
    }
}

export const validateFileSize = (file, size, error) => {
    if (file?.size > size) {
        throw new Error(error);
    }
}

export const validateFileType = (file, type, error) => {
    if (!isAllowedExtension(file, type)) {
        throw new Error(error);
    }
}
export const getParsedData = (file) => {
    return new Promise((resolve, reject) => {
        Papa.parse(file, {
            header: false,
            skipEmptyLines: true,
            complete: (results) => {
                const dataArray = [];

                results.data.map((d) => {
                    dataArray.push(d[0]);
                });

                resolve(dataArray);
            },
            error: (error) => {
                reject(error)
            }
        })
    })
}
