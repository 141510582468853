import Http from './axios-config';

class AuthentificationService {

    constructor() {
        this.http = new Http();
    }

    /**
     * 
     * @param {*} values 
     */
    async changePassword(values) {
        return await this.http.put(`/verifications/password`, values);
    }

    /**
     * 
     * @param {*} values 
     */
    async sendRestorePassword(values) {
        return await this.http.post(`/verifications/password`, values);
    }

    async logout() {
        return await this.http.delete('/clinical-admins/sessions');
    }

    /**
     * 
     * @param {*} values
     */
    async sendOneTimePassword(values) {
        return await this.http.post('/verifications/one-time-passwords', values);
    }

    /**
     * 
     * @param {*} values 
     */
    async login(values) {
        return await this.http.post('/clinical-admins/sessions', values);
    }

    /**
     * 
     * @param {*} token
     */
    async checkExpiredDate(token){
        return await this.http.put("/verifications/tokens/password", token);
    }
    /**
     * 
     * @param {*} token
     */
    async checkExpiredDateEmail(token){
        return await this.http.put("/verifications/tokens/email", token);
    }

    async changePasswordUseOldPassword(values){
        return await this.http.put("/users/me/password", values);
    }
}

export default new AuthentificationService();