import React from "react";

const FormSection = ({ children, className = "" }) => {
    return (
        <div
            className={className}
        >
            {children}
        </div>
    )
};

export default FormSection


