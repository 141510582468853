import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import { restrictMobile, restrictSafari } from './constants/validityPatterns';

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);
const userAgent = navigator.userAgent.toLowerCase(),
    safari = restrictSafari.test(userAgent),
    mobile = restrictMobile.test(userAgent)
if (!(mobile || safari)) {
    serviceWorker.register();
}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
