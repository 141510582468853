export const WAITING_CONFIRMATION = 1;
export const CHECKED = 2;
export const WAITING_CONFIRMATION_v2 = 3;

export const tabsLabels = {
    [WAITING_CONFIRMATION]: "Waiting confirmation",
    [CHECKED]: "Checked",
}

export const PatientManagementTabs = [
    { index: WAITING_CONFIRMATION, label: tabsLabels[WAITING_CONFIRMATION] },
    { index: CHECKED, label: tabsLabels[CHECKED] },
];

export const APPOINTMENTS_HISTORY = 1;
export const PERSONAL_INFO = 2;
export const CHILDREN = 3;

export const PatientDetailsTabs = [
    { index: APPOINTMENTS_HISTORY, label: "Appointments history" },
    { index: PERSONAL_INFO, label: "Personal info" },
    { index: CHILDREN, label: "Children" },
];

export const PatientDetailsTabsWithoutChildren = [
    { index: APPOINTMENTS_HISTORY, label: "Appointments history" },
    { index: PERSONAL_INFO, label: "Personal info" },
];

export const noteLabels = {
    problem: "Problem",
    history: "History",
    pastMedicalHX: "Past medical HX",
    examination: "Examination",
    comments: "Comments",
    safetyNet: "Safety net",
}

export const clinicalAdminFeedbacks = {
    hadVideoIssues: {
        label: 'Video Quality / Problems',
        keyForText: 'videoIssues'
    },
    hadSoundIssues: {
        label: 'Sound Quality / Problems',
        keyForText: 'soundIssues'
    },
    hadMicrophoneIssues: {
        label: 'Microphone Problems',
        keyForText: 'microphoneIssues'
    },
    hadConnectivityIssues: {
        label: 'Connectivity Problems',
        keyForText: 'connectivityIssues'
    },
    hadPatientConnectionIssues: {
        label: 'I could not connect with the patient:',
        keyForText: 'clinicianConnectionIssues'
    },
    hadClinicianConnectionIssues: {
        label: 'The patient could not connect with me:',
        keyForText: 'patientConnectionIssues'
    },
    otherIssues: {
        label: 'Other issue',
        keyForText: 'otherIssues'
    }
}

export const patientFeedbacks = {
    appNavigationRating: {
        label: 'Navigating the app'
    },
    receivedCareRating: {
        label: 'The care received'
    },
    moneyValueRating: {
        label: 'Value for money'
    },
    waitingTimeRating: {
        label: 'Waiting time'
    },
    feedback: {
        label: 'Other'
    }
}

export const PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES = {
    passportId: "passportId",
    driverLicenseId: "driverLicenseId",
    birthCertificateId: "birthCertificateId",
};

export const PATIENT_IDENTIFICATION_DOCUMENT_TYPES = [
    {value: PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES.passportId, label: "Passport"},
    {value: PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES.driverLicenseId, label: "Driving License"},
    {value: PATIENT_IDENTIFICATION_DOCUMENT_TYPE_CODES.birthCertificateId, label: "Birth Certificate"}
];
