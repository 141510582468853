import React from 'react';
import {Card, Col, Modal, Row} from "reactstrap";
import {AvField, AvForm} from 'availity-reactstrap-validation';

import {MAX_NOTE, MIN_NOTE} from 'constants/lengthValidity';
import {strWithNoOnlySpaces} from 'constants/validityPatterns';
import {consultationNotesFormFields} from "constants/appointment";

import {AutoSizeTextArea} from "../form/AutoSizeTextArea";


export default function ConsultationForm({ visibleModal, handleClose, handleSubmit }) {

    const errorMessage = `Must be from ${MIN_NOTE} to ${MAX_NOTE} symbols and cannot contain spaces only`
    const validate = {
        required: { value: true, errorMessage: errorMessage },
        pattern: { value: strWithNoOnlySpaces, errorMessage: errorMessage },
        minLength: { value: MIN_NOTE, errorMessage: errorMessage },
        maxLength: { value: MAX_NOTE, errorMessage: errorMessage }
    }

    return (
        <Modal
            isOpen={visibleModal}
            centered={true}
            toggle={handleClose}
            size="lg"
        >
            <Card className="consultation-modal-form mb-0">
                <label className="consultation-modal-form-label">Add consultation</label>
                <AvForm onValidSubmit={handleSubmit}>
                    {consultationNotesFormFields.map(note => (
                        <section
                            key={note.name}
                            className={note.name === consultationNotesFormFields[0].name ? "mt-1" : "mt-4"}
                        >
                            <AvField
                                type="textarea"
                                rows={1}
                                name={note.name}
                                label={note.label}
                                placeholder="Additional comment"
                                validate={validate}
                                className="form-control"
                                tag={AutoSizeTextArea}
                            />
                        </section>
                    ))}
                    <Row className="d-flex flex-wrap mt-5">
                        <Col lg={4}>
                            <button type="submit" className="btn btn-primary w-100">Save</button>
                        </Col>
                        <Col lg={4}>
                            <button className="btn btn-danger w-100" type="button" onClick={handleClose}>Cancel</button>
                        </Col>
                    </Row>
                </AvForm>
            </Card>
        </Modal>
    )
}
