import React, {useState} from "react";
import {AvField, AvForm} from "availity-reactstrap-validation";

import PatientVerificationService from "services/patientVerification";
import Button from "components/buttons/button";

import {ResetPatientPasswordLinkSuccessModal} from "./components/ResendPatientPasswordLinkSuccessModal";
import {EMAIL_VALIDATION_RULES} from "./helpers";


export const ResendPasswordLink = () => {
    const [isEmailSentModalOpen, setIsEmailSentModalOpen] = useState(false);

    async function handleValidSubmit(event, values) {
        const params = {
            email: values.email
        }

        try {
            await PatientVerificationService.resendPasswordLink(params);
            setIsEmailSentModalOpen(true);
        } catch {
            //empty
        }
    }

    return (
        <>
            <div className="public-patient-resend-password d-flex align-items-end justify-content-center">
                <div className="public-patient-resend-password__content mb-5 pb-2">
                    <AvForm
                        className="form-horizontal mt-4"
                        onValidSubmit={(event, values) => handleValidSubmit(event, values)}
                    >
                        <div className="form-group">
                            <AvField
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder="Enter your email"
                                type="email"
                                validate={EMAIL_VALIDATION_RULES}
                            />
                        </div>
                        <Button className="primary" text="Send restore link" disabled={false}/>
                    </AvForm>
                </div>
            </div>
            <ResetPatientPasswordLinkSuccessModal visibleModal={isEmailSentModalOpen}/>
        </>
    );
}
