import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Card, Modal} from "reactstrap";
import {ErrorMessage, Formik} from 'formik';
import * as yup from 'yup';
import Select from "react-select";

import cancelIcon from 'assets/images/ic-close-black.svg';
import {errorMessage, incorrectDate, incorrectTime} from 'constants/errorMessages';
import formatDateOutput from 'helpers/formatDateOutput';
import AppointmentsService from 'services/appointments';
import uploadAllData from 'helpers/uploadAllData';
import formatDate from 'helpers/formatDate';
import {MAX_RANGE_LENGTH} from 'constants/lengthValidity';
import getTimeOptions from 'helpers/getTimeOptions';
import formatIsoString from 'helpers/formatIso86Date';
import formatDateByFormat from 'helpers/formatDateByFormat';
import {MM_DD_YYYY} from 'constants/dateFormats';
import {useSearchDoctors} from "hooks/useSearchDoctors";

import CancelModal from '../manageSessionModal/cancelModal';
import CustomDatePicker from '../Common/customDatePicker';
import Label from '../Label/labelWithError';
import {CustomSearchInput} from "../searchField/CustomSearchInput";

const schema = yup.object().shape({
    date: yup.string(incorrectDate).required(incorrectDate).typeError(incorrectDate),
    time: yup.string(incorrectTime).required(incorrectTime).typeError(incorrectTime),
});

const customStyles = {
    menuList: base => ({
        ...base,
        maxHeight: '150px',
        cursor: 'pointer',
    }),
    option: (styles) => ({
        ...styles,
        cursor: 'pointer',
    }),
    control: (styles) => ({
        ...styles,
        cursor: 'pointer',
    }),
    dropdownIndicator: () => ({
        display: "none"
    }),
    indicatorSeparator: () => ({
        display: "none"
    })
}

function compareSlotsArrays(verifiable) {
    return (new Date(verifiable.startsAt) > new Date())
}

export default function RescheduleAppointment({
    visibleModal,
    handleSubmit,
    handleClose,
    appointment,
    pickedDate,
    doctor
}) {
    const [visibleCancelModal, setVisibleCancelModal] = useState(false);
    const [dateOutput, setDateOutput] = useState(pickedDate);
    const [options, setOptions] = useState([]);
    const [selectedDoctorId, setSelectedDoctorId] = useState(appointment.doctorId);
    const [selectedTime, setSelectedTime] = useState({
        label: formatIsoString(appointment.startsAt),
        value: appointment.startsAt,
    });

    const maxDate = useMemo(() => new Date().getTime() + MAX_RANGE_LENGTH, []);

    const initialValues = {
        date: formatDate(pickedDate),
        time: appointment.startsAt,
        doctorId: appointment.doctorId || ""
    };

    const initialDoctorOption = appointment.userIdOfDoctor === doctor?.id
        ? {
            value: appointment.doctorId || "",
            label: `${doctor?.firstName || ""} ${doctor?.lastName || ""}`
        }
        : null;

    const getDaySlots = useCallback(
        async () => {
            try {
                const slots = await uploadAllData(AppointmentsService.getAppointmentsSlotsByDate.bind(AppointmentsService), {
                    date: dateOutput,
                    doctorId: selectedDoctorId
                })

                setOptions(getTimeOptions(slots.filter(slot => compareSlotsArrays(slot, appointment))));

            } catch {
                //empty
            }

        },
        [dateOutput, appointment, selectedDoctorId],
    )

    useEffect(() => {
        getDaySlots();
    }, [getDaySlots])

    const DateInput = ({ onClick }) => (
        <>
            <input
                placeholder="Enter date"
                value={formatDateOutput(dateOutput)}
                onClick={onClick}
                className="custom-calendar-output-field cursor-pointer form-control"
            />
        </>
    );

    const {
        handleDoctorSearch,
        doctorsOptions
    } = useSearchDoctors();

    const isInitialDoctorInTheList = doctorsOptions.find(option => option.value === appointment?.doctorId);
    const doctorsOptionsWithInitialOption = isInitialDoctorInTheList ? doctorsOptions : [...doctorsOptions, initialDoctorOption];

    return (
        <Modal
            isOpen={visibleModal}
            centered={true}
            toggle={() => setVisibleCancelModal(true)}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body">
                    <header className="font-size-22 d-flex flex-row justify-content-between">
                        Reschedule appointment
                        <img src={cancelIcon} alt="" onClick={() => setVisibleCancelModal(true)}
                             className="cursor-pointer"/>
                    </header>
                    <section className="d-flex flex-column mt-4">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={schema}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(true)
                                handleSubmit(values)
                                setSubmitting(false)
                            }}
                        >
                            {({
                                errors,
                                touched,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                values
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mb-2 h-100 w-100">
                                        <section className="pb-3 mb-3">
                                            <Label text="Select a doctor"
                                                   isInvalid={errors.doctorId && touched.doctorId}/>
                                            <CustomSearchInput
                                                handleSearch={handleDoctorSearch}
                                                options={doctorsOptionsWithInitialOption}
                                                initialValue={doctorsOptionsWithInitialOption.find(option => option.value === values.doctorId)}
                                                handleChange={selectedOption => {
                                                    setFieldValue("doctorId", selectedOption?.value);
                                                    setSelectedDoctorId(selectedOption?.value);
                                                }}
                                                placeholder="Select a doctor"
                                            />
                                            <ErrorMessage name="doctorId" render={errorMessage}/>
                                        </section>
                                        <label className="patient-label">Patient</label>
                                        <section className="d-flex flex-row">
                                            <label
                                                className="patient-info">{appointment.patient.firstName + " " + appointment.patient.lastName}</label>
                                            <label
                                                className="ml-4 patient-info">{formatDateByFormat(appointment.patient?.birthday, MM_DD_YYYY)}</label>
                                        </section>

                                        <label className="patient-label mt-2">Reason</label>
                                        <p className="patient-info">{appointment.appointmentReason.reason}</p>

                                        <section
                                            className="d-flex flex-column flex-sm-row justify-content-between mt-1">
                                            <div className="d-flex flex-column">
                                                <Label text="Date" isInvalid={errors.date && touched.date}/>
                                                <CustomDatePicker
                                                    showCalendar={false}
                                                    customInput={<DateInput/>}
                                                    handleChangeDate={(date) => {
                                                        setDateOutput(date);
                                                        setFieldValue('date', formatDate(date));

                                                        setSelectedTime(null);
                                                        setFieldValue('time', "");
                                                    }}
                                                    closeOnSelect={true}
                                                    minDate={new Date()}
                                                    maxDate={maxDate}
                                                    isSelected={pickedDate}
                                                />
                                                <ErrorMessage name="date" render={errorMessage}/>
                                            </div>

                                            <section className="d-flex flex-column">
                                                <Label text="Time" isInvalid={errors.time && touched.time}/>
                                                <Select
                                                    onChange={(option) => {
                                                        setFieldValue("time", option?.value?.startsAt);
                                                        setSelectedTime({
                                                            label: formatIsoString(option?.value?.startsAt),
                                                            value: option?.value?.startsAt,
                                                        });
                                                    }}
                                                    options={options}
                                                    className="custom-calendar-output-field custom-design-scroll"
                                                    placeholder="Enter start"
                                                    styles={customStyles}
                                                    value={selectedTime}
                                                />
                                                <ErrorMessage name="time" render={errorMessage}/>
                                            </section>
                                        </section>
                                    </div>
                                    <section
                                        className="d-flex flex-column flex-sm-row modal-actions justify-content-between mt-5">
                                        <button className="btn btn-primary w-100" type="submit"
                                                disabled={isSubmitting}>Reschedule
                                        </button>
                                        <button className="btn btn-danger w-100" type="button"
                                                onClick={() => setVisibleCancelModal(true)}>Cancel
                                        </button>
                                    </section>
                                </form>
                            )}
                        </Formik>
                    </section>
                </div>
            </Card>
            <CancelModal visibleModal={visibleCancelModal} setVisibleModal={setVisibleCancelModal}
                         handleClose={handleClose}/>
        </Modal>
    )
}
