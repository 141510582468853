import React from 'react';

import { icons } from 'constants/svgIcons';

export default function Icon({ icon }) {
    const Icon = icons[icon];
    return (
        <Icon />
    )
}
