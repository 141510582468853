import Http from './axios-config';

class UserDevicesService {
    constructor() {
        this.http = new Http();
    }

    async addDevice(id) {
        return await this.http.post("/user-devices", { token: id });
    }
}

export default new UserDevicesService();