import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import ChangePassword from '../pages/Authentication/changePassword';
import ExpiredLink from '../pages/expiredLink/expiredLink';
import TermsAndConditions from '../pages/infoPages/termsAndConditions';
import PrivacyPolicy from '../pages/infoPages/privacyPolicy';

import Dashboard from "../pages/Dashboard/index";
import EmptyPage from '../pages/Dashboard/emtyPagePlaceholder';
import PatientManagement from '../pages/patientManagement/patientManagement';
import PatientDetails from '../pages/patientManagement/patientDetails';

const userRoutes = [
	{ path: "/appointments", component: Dashboard },
	{ path: "/patients-management", component: PatientManagement },
	{ path: "/user-analytics", component: EmptyPage },
	{ path: "/settings", component: EmptyPage },
	{ path: "/patients-management/patients", component: PatientDetails},
	{ path: "/", exact: true, component: () => <Redirect to="/appointments" /> }
];

const authRoutes = [
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/register", component: Register },
	{ path: "/expired-link", component: ExpiredLink },
	{ path: "/clinical-admins/restore-password", component: ChangePassword },
	{ path: "/terms-and-conditions", component: TermsAndConditions },
	{ path: "/privacy-policy", component: PrivacyPolicy },
];

export { userRoutes, authRoutes };
