import React from 'react';
import {Card, Modal} from "reactstrap";
import PatientInfo from '../../Calls/letters/generalInfo';
import formatData from '../../../helpers/formatServerResponse';
import * as _ from 'lodash';
import formatDateSlash from '../../../helpers/formatTimeSlash';
import getUsersAge from '../../../helpers/getUsersAge';
import getUsersType from '../../../helpers/getUsersType';
import getUsersGender from '../../../helpers/getUsersGender';
import closeIcon from '../../../assets/images/ic-close-black.svg';

const infoLabels = {
    firstName: "First name :",
    lastName: "Last name :",
    id: "Patient ID :",
    agreement: "Agreed to share info with GP :",
    createdAt: "Adding date :",
    email: "Email address :",
    surgeryAddress: "Surgery address :",
    adress: "Home address :",
    type: "Type :",
    gender: "Gender :",
    dob: "Date of birth, age:",
    phone: "Phone number :",
}

const FIRST_COLUMN = 3;
const SECOND_COLUMN = 7;

export default function CompareModal({
    visibleModal,
    handleClose,
    handleCompare,
    documents,
    info,
    handleReject,
    showActionsButtons
}) {

    const patientInfo = {
        firstName: _.get(info, 'firstName', '-'),
        lastName: _.get(info, 'lastName', '-'),
        id: _.get(info, 'id', '-'),
        agreement: _.get(info, 'isInfoShared', true) ? "Agreed" : "Not agreed",
        createdAt: formatDateSlash(_.get(info, 'createdAt', '-')),
        email: _.get(info, 'email', '-'),
        surgeryAddress: _.get(info, 'surgeryAddress.address', '-'),
        adress: _.get(info, 'address.address', '-'),
        type: getUsersType(_.get(info, 'birthday', '-')),
        gender: getUsersGender(_.get(info, 'gender', '-')),
        dob: `${formatDateSlash(_.get(info, 'birthday', null)) + ", " + getUsersAge(_.get(info, 'birthday', null)) + ' y.o.'}`,
        phone: _.get(info, 'phone', '-'),
    }

    const formatedData = formatData(patientInfo, infoLabels, "col-sm-6");

    const firstColumn = formatedData.filter((item, index) => index <= FIRST_COLUMN).map((item) => {
        const newItem = { ...item };
        newItem.classname = null;
        return newItem;
    });

    return (
        <Modal
            isOpen={visibleModal}
            className="big-modal"
            centered={true}
            toggle={handleClose}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body modal-text-styles">
                    <header className="font-size-22 d-flex flex-row justify-content-between">
                        Compare
                        <img
                            src={closeIcon}
                            alt=""
                            onClick={handleClose}
                            className="cursor-pointer"/>
                    </header>
                    <section className="d-flex flex-row justify-content-between h-50">
                        <PatientInfo info={firstColumn}/>
                        <PatientInfo
                            info={formatedData.filter((item, index) => index <= SECOND_COLUMN && index > FIRST_COLUMN)}
                            classname="mx-5"/>
                        <PatientInfo info={formatedData.filter((item, index) => index > SECOND_COLUMN)}/>
                    </section>

                    <section className="d-flex flex-row justify-content-between mt-5">
                        <img src={documents.selfie} alt="" className="compressed-photo"/>
                        <img src={documents.photo} alt="" className="compressed-photo"/>
                    </section>

                    {showActionsButtons && (
                        <section className="d-flex flex-row justify-content-between w-50 mx-auto mt-2">
                            <button
                                className="btn btn-primary w-100 mt-4 mr-3"
                                onClick={handleCompare}
                            >
                                Confirm
                            </button>
                            <button
                                className="btn btn-outline-primary w-100 mt-4 ml-3"
                                onClick={handleReject}
                            >
                                Reject
                            </button>
                        </section>
                    )}

                </div>

            </Card>
        </Modal>
    )
}
