import React from 'react';

export default function PatientInfo({ info, classname }) {
    return (
        <dl className={"row mb-0 pl-2 mt-2 " + classname}>
            {info.map((row, index) => (
                < React.Fragment key={index} >
                    <dt className={"py-2 pt-3 font-size-13 field-height " + (row.classname ? row.classname : "col-sm-8")}>{row.label}</dt>
                    <dd className={"py-2 pt-3 font-size-13 field-height " + (row.classname ? row.classname : "col-sm-4")}>{row.value}</dd>
                    <span className="border-bottom w-100" />
                </React.Fragment>
            ))}
        </dl >
    )
}