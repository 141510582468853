import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Col, Container, Row, Spinner} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import StorageService from "services/StorageService";
import {USER} from "constants/storageConstants";
import {SUPER_ADMIN} from "constants/roles";
import SessionTable from 'components/sessionTable/sessionTable';
import ManageSessionModal from 'components/manageSessionModal/manageSessionModal';
import ChosenPatient from 'components/chosenPatient';
import AppointmentsService from 'services/appointments';
import formatDate from 'helpers/formatDate';
import formatTime from 'helpers/formatTime';
import bigPlaceholder from 'assets/images/im-placeholderBig.svg';
import parseIntFromStr from 'helpers/parseIntFromStr';
import uploadAllData from 'helpers/uploadAllData';
import queryStringParser from 'helpers/queryStringParser';
import getCurrentTimezoneOffset from 'helpers/getCurrentTimezoneOffset';

import {DocTableContext, useDocTables, useUpdateDocTables} from './tableContext';
import {AppointmentPriceSection} from "./components/AppointmentPriceSection";


const Dashboard = ({ t }) => {
    const [showCreateSessionModal, setShowCreateSessionModal] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isSessionsLoading, setIsSessionsLoading] = useState(false);
    const [allSlots, setAllSlots] = useState([]);
    const [allSessions, setAllSessions] = useState([]);

    const currentDate = useMemo(() => Date.now(), []);

    const location = useLocation();

    const date = useMemo(() => queryStringParser(location.search).date || new Date(), [location]);

    const doctorsSessions = useDocTables();
    const setDoctorsSessions = useUpdateDocTables();

    const isUserSuperAdmin = new StorageService().getValueByKey(USER)?.role === SUPER_ADMIN;

    async function handleCreateSession(values) {
        const session = {
            ...values,
            startDate: formatDate(values.startDate),
            endDate: formatDate(values.endDate),
            startsAt: formatTime(values.startsAt),
            timezoneOffset: getCurrentTimezoneOffset(),
            endsAt: formatTime(values.endsAt),
            appointmentDuration: parseIntFromStr(values.appointmentDuration),
        };
        try {
            await AppointmentsService.createAppointmentsSession(session);
            setShowCreateSessionModal(false);
        } catch {
            //empty
        }
    }

    const getSessionsByDate = useCallback(
        async (date) => {
            setIsSessionsLoading(true);
            try {
                const serverResponse = await AppointmentsService.getAppointmentsSessionByDate(date);
                setAllSessions(serverResponse.data);
            } finally {
                setIsSessionsLoading(false);
            }
        },
        [],
    )

    const getAppointmentSlots = useCallback(
        async (date) => {
            try {
                setIsDataLoading(true);
                const slots = await uploadAllData(AppointmentsService.getAppointmentsSlotsByDate.bind(AppointmentsService), { date: date })
                setAllSlots(slots);
            } finally {
                setIsDataLoading(false);
            }
        },
        [],
    )

    const filterSlots = useCallback(
        () => {
            const sessionsWithSlots = allSessions.map((session) => {
                return {
                    ...session,
                    appointments: allSlots.filter(slot => slot.appointmentSessionId === session.id),
                }
            })

            setDoctorsSessions(sessionsWithSlots);
        },
        [allSlots, allSessions, setDoctorsSessions],
    );

    async function handleCreateAppointment(id, values) {
        try {
            await AppointmentsService.createAppointment(id, values)
            await getAppointmentSlots(date);
        } catch {
            //empty
        }
    }

    useEffect(() => {
        if (!showCreateSessionModal) {
            setIsDataLoading(true)
            getSessionsByDate(date);
            getAppointmentSlots(date);
        }
    }, [showCreateSessionModal, getSessionsByDate, getAppointmentSlots, date]);

    useEffect(() => {
        filterSlots();
    }, [filterSlots]);

    return (
        <>
            <div className="page-content appointments">
                <Container fluid>
                    <section className="d-flex flex-column">
                        <ChosenPatient/>
                        <section className="appointments__header-actions d-flex justify-content-between mb-3">
                            <button className="btn create-session-button px-5" onClick={() => {
                                setShowCreateSessionModal(true)
                            }}>{t("Create Session")}</button>
                            {isUserSuperAdmin && <AppointmentPriceSection/>}
                        </section>
                    </section>
                    <Row>
                        <section className="appointments__sessions-section">
                            <div className="appointments__sessions-heading w-100 p-2">Appointments schedule</div>
                            {!isDataLoading && !isSessionsLoading && (doctorsSessions.length && allSlots.length) ?
                                <div className="appointments__group-wrapper">
                                    <div className="appointments__group-content py-3">
                                        <div className="appointments__group__row">
                                            {doctorsSessions.map((session => (
                                                    <SessionTable
                                                        tableData={session}
                                                        key={session.id}
                                                        handleCreateAppointment={handleCreateAppointment}
                                                        pickedDate={date}
                                                        updateTable={setDoctorsSessions}
                                                        getAppointmentSlots={getAppointmentSlots}
                                                        getSessionsByDate={getSessionsByDate}
                                                    />
                                                )
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                :
                                <Col>
                                    {isDataLoading || isSessionsLoading ?
                                        <div className="d-flex justify-content-center align-items-center h-100">
                                            <Spinner color="info"/>
                                        </div>
                                        :
                                        <section
                                            className="d-flex flex-column justify-content-center align-items-center text-center h-100">
                                            <img src={bigPlaceholder} alt="" height="200" width="300"/>
                                            <label className="session-placeholder-big">Click on the « <b>Create
                                                Session</b> » button to create a new session</label>
                                        </section>
                                    }
                                </Col>
                            }
                        </section>
                    </Row>
                </Container>
            </div>
            {
                showCreateSessionModal && <ManageSessionModal
                    isOpen={showCreateSessionModal}
                    handleClose={() => setShowCreateSessionModal(false)}
                    handleSubmit={handleCreateSession}
                    minDateToCreateSession={currentDate}
                />
            }
        </>
    );
}

const Page = withNamespaces()(Dashboard);

export default props => {
    return (
        <DocTableContext.Provider>
            <Page {...props} />
        </DocTableContext.Provider>
    )
}
