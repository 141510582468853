import React, {useState} from "react";
import {Card, Row} from "reactstrap";

import {ICONS} from "constants/svgIcons";
import {noteLabels} from "constants/patientMagagementTypes";
import Icon from "components/Icon";
import {MILLISECONDS_IN_24_HOURS} from "constants/timeConstants";
import StorageService from "services/StorageService";
import {USER} from "constants/storageConstants";

import ConsultationNotes from "../appointmentHistory/consultationNotes";
import {ConsultationNotesForm} from "./ConsultationNotesForm";



const BLOCK = "Block";
const UNBLOCK = "Unblock";

export const ConsultationNotesSection = ({ appointment, blockUnblockConsultationNotes, editConsultation }) => {
    const [isEditMode, setIsEditMode] = useState(false);

    const currentUserId = new StorageService().getValueByKey(USER)?.id;

    const currentUTCTimeIsoStr = new Date().toISOString();
    const currentUTCTime = new Date(currentUTCTimeIsoStr).getTime();
    const NotesCreationUTCTime = new Date(appointment?.consultationNotesCreationTime).getTime();

    const showEditButton = (!!appointment?.consultationNotesCreationTime
        && ((currentUTCTime - NotesCreationUTCTime) < MILLISECONDS_IN_24_HOURS)) && (currentUserId === appointment.userIdOfDoctor);

    const saveUpdatedNotes = async (values) => {
        await editConsultation(appointment.id, values);
        setIsEditMode(false);
    };
    
    const notesInitialValues = {
        problem: appointment.consultationNotes?.problem || "",
        history: appointment.consultationNotes?.history || "",
        pastMedicalHX: appointment.consultationNotes?.pastMedicalHX || "",
        examination: appointment.consultationNotes?.examination || "",
        comments: appointment.consultationNotes?.comments || "",
        safetyNet: appointment.consultationNotes?.safetyNet || ""
    };


    return (
        <Card className="patient-details px-4 pt-3 py-2 mb-4">
            <Row className="justify-content-between">
                {!isEditMode && (
                    <>
                        <label className="details-caption">Consultation notes</label>
                        <div>
                            {showEditButton && (
                                <button
                                    className="btn btn-outline-primary appointment-price-section__change-price-button px-4"
                                    onClick={() => setIsEditMode(true)}
                                >
                                    <Icon icon={ICONS.editIcon} alt=""/>
                                    <span>Edit</span>
                                </button>
                            )}

                            {appointment?.consultationNotesIsHidden !== undefined && (
                                <button
                                    className="btn btn-primary px-4 ml-3"
                                    onClick={() => blockUnblockConsultationNotes(
                                        appointment.id,
                                        !appointment.consultationNotesIsHidden
                                    )}
                                >
                                    {appointment.consultationNotesIsHidden ? UNBLOCK : BLOCK}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </Row>
            {isEditMode
                ? (
                    <ConsultationNotesForm
                        handleSubmit={saveUpdatedNotes}
                        appointment={appointment}
                        handleCancel={() => setIsEditMode(false)}
                        notesInitialValues={notesInitialValues}
                    />
                )
                : (
                    <ConsultationNotes
                        notes={appointment.consultationNotes}
                        noteLabels={noteLabels}/>
                )}

        </Card>
    )
};
