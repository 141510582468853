import React from "react";
import {
    Card,
    CardBody,
} from "reactstrap";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import zoomPlus from '../../../assets/images/ic-zoom-black.svg';
import zoomMinus from '../../../assets/images/ic-reduce-black.svg';

import closeIcon from '../../../assets/images/ic-close-black.svg';

const INITIAL_ZOOM = 1;

function FullScreenMode({ photo, handleClose, enableZoom = true }) {
    return (
        <Card className="px-5 pt-5 pb-4 mb-0">
            <img src={closeIcon} alt="" className="align-self-end cursor-pointer" onClick={handleClose} />
            <CardBody className=" mx-auto">
                { enableZoom && (
                    <TransformWrapper defaultScale={INITIAL_ZOOM}>
                        {({ zoomIn, zoomOut }) => (
                            <React.Fragment>
                                <TransformComponent className="w-100">
                                    <img src={photo} alt="reason" className="w-100 h-100"/>
                                </TransformComponent>
                                <div className="d-flex flex-row justify-content-center mt-5">
                                    <button className="btn btn-outline" onClick={zoomOut}><img src={zoomMinus} alt="" /></button>
                                    <button className="btn btn-outline" onClick={zoomIn}><img src={zoomPlus} alt="" /></button>
                                </div>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                )}
                {!enableZoom && (
                    <img src={photo} alt="reason" className="w-100 h-100 mb-5"/>
                )}
            </CardBody>
        </Card>
    );
}

export default FullScreenMode;