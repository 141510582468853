import {
  FORGET_PASSWORD,
} from "./actionTypes";

const initialState = {
  email: null,
  forgetSuccessMsg: null,
  forgetError: null
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state,
        forgetSuccessMsg: null,
        forgetError: null
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
