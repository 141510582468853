import { Card, Modal as ModalComponent } from "reactstrap";
import React from "react";

const ModalBody = ({ children, className = "" }) => {
    return (
        <Card className={`p-4 mb-0 ${className}`}>
            <div className="modal-body modal-text-styles custom-design-scroll">
                {children}
            </div>
        </Card>
    )
};


const Modal = ({ isOpen, children, className }) => {
    return (
        <ModalComponent
            isOpen={isOpen}
            centered={true}
            size="md"
        >
            <ModalBody className={className} >
                {children}
            </ModalBody>
        </ModalComponent>
    )
};

export default Modal
