import React, { useState } from 'react';
import { Card, Col, Row, Modal } from 'reactstrap';
import FullScreenMode from '../../Dashboard/appointmentsDetails/fullScreenMode';
import * as _ from 'lodash';
import { documentStatuses } from '../../../constants/documentStatuses';

export default function ParentsInfo({ parent, relationship }) {
    const [showFullscreen, setShowFullscreen] = useState(false);

    const isDocumentExist = _.get(parent, 'document', null);

    const parentDocument = {
        title: _.get(parent, 'document.name', '-'),
        status: documentStatuses[_.get(parent, 'document.status', null)],
        photo: _.get(parent, 'document.link', null),
    }

    const parentInfo = [
        { label: 'First name :', value: parent.firstName || '-' },
        { label: 'Last name :', value: parent.lastName || '-' },
        { label: 'Phone number :', value: parent.phone || '-' },
        { label: 'Relationship to the child :', value: relationship || parent.relationship || '-' },
    ];

    return (
        <Card className="px-3 py-3">
            <Row>
                <Col lg={6} className="pr-4">
                    <label className="details-caption mb-2">Parents info</label>
                    <dl className="row mb-0 pl-2">
                        {parentInfo.map((row, index) => (
                            <React.Fragment key={index}>
                                <dt className={"py-2 pt-3 " + _.get(row, "labelClassname", "col-sm-6")}>{row.label}</dt>
                                <dd className={"py-2 pt-3 " + _.get(row, "valueClassname", "col-sm-6")}>{row.value}</dd>
                                {index !== parentInfo.length - 1 && <span className="border-bottom w-100 mr-5 ml-2" />}
                            </React.Fragment>
                        ))}
                    </dl>
                </Col>
                {isDocumentExist &&
                    <>
                        <Col lg={6}>
                            <section className="d-flex flex-column">
                                <label className="details-caption mb-4">Parent document</label>
                                {parentDocument.photo ? <img src={parentDocument.photo} alt="" className="document-photo cursor-pointer" onClick={() => setShowFullscreen(true)} /> : '-'}
                            </section>
                        </Col>
                        <Modal isOpen={showFullscreen} size="xl" toggle={() => setShowFullscreen(false)}>
                            <FullScreenMode photo={parentDocument.photo} handleClose={() => setShowFullscreen(false)} />
                        </Modal>
                    </>
                }
            </Row>
        </Card>
    );
}