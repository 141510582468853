import React from 'react';
import * as _ from 'lodash';

import formatDateByFormat from 'helpers/formatDateByFormat';
import { dateBySlash } from 'constants/dateFormats';
import formatIsoString from 'helpers/formatIso86Date';
import { doctorTypes } from 'constants/doctorsRoles';
import { FIT_MODAL, REFERRAL_MODAL } from "constants/callConstants";

import { compareDatesInObject } from "./compareDates";

export const addActionHandlers = (appointment, handlers = {}) => {
    const newAppointmentInfo = [ ...appointment?.appointmentsInfo || [] ];

    Object.keys(handlers).forEach((fieldType) => {
        newAppointmentInfo.forEach((field) => {
            if (field?.fieldType === fieldType) {
                field.actionHandler = handlers[fieldType]
            }
        })
    })

    return {
        ...appointment,
        appointmentsInfo: newAppointmentInfo,
    };
}

export default function transformAppointmentHistory(appointment) {
    const id = appointment.id;
    const consultationNotesCreationTime = appointment.consultation?.createdAt;
    const userIdOfDoctor = appointment.userIdOfDoctor;

    const feedbacks = [];

    const appointmentsInfo = [
        { label: "Date :", value: formatDateByFormat(appointment.date, dateBySlash) },
        { label: "Time :", value: formatIsoString(appointment.startsAt) },
        { label: "Start time :", value: appointment.actualStartsAt ? formatIsoString(appointment.actualStartsAt) : '-' },
        { label: "End time :", value: appointment.actualEndsAt ? formatIsoString(appointment.actualEndsAt) : '-' },
        { label: "Reason :", value: appointment.appointmentReason?.reason || '-' },
    ];

    if (appointment.consultation && appointment.consultation?.file) {
        appointmentsInfo.push({
            label: "Consultation:",
            value: "See details",
            valueClassname: "text-underline",
            Component: ({ value }) => (<a href={appointment.consultation?.file.link} target="_blank" rel="noreferrer" className="see-details">{value}</a>)
        })
    }

    if (appointment?.referralLetters?.data?.length > 0) {
        const referralLetters = appointment.referralLetters.data
            .sort(compareDatesInObject('createdAt'))
            .map((referralLetter, index) => {
                let info = {
                    label: `Referral to ${index + 1}:`,
                }

                if (referralLetter?.file) {
                    info = {
                        ...info,
                        value: "See details",
                        valueClassname: "text-underline",
                        Component: ({ value }) => (<a href={referralLetter?.file.link} target="_blank" rel="noreferrer" className="see-details">{value}</a>),
                    }
                }

                if (referralLetter?.id) {
                    info = {
                        ...info,
                        action: "Edit",
                        actionClassname: "text-underline primary",
                        actionHandler: _.noop,
                        fieldType: REFERRAL_MODAL,
                        ActionComponent: ({ value, handler }) => (
                            <a
                                className="cursor-pointer see-details text-primary"
                                onClick={() => handler(appointment, REFERRAL_MODAL, referralLetter.id)}
                            >
                                {value}
                            </a>
                        )
                    }
                }
                return info;
            })

        appointmentsInfo.push(...referralLetters);
    }

    if (appointment?.fitSickNotes?.data?.length > 0) {
        const fitSickNotes = appointment.fitSickNotes.data
            .sort(compareDatesInObject('createdAt'))
            .map((fitSickNote, index) => {
                let info = {
                    label: `Sick/fit note ${index + 1}:`,
                }

                if (fitSickNote?.file) {
                    info = {
                        ...info,
                        value: "See details",
                        valueClassname: "text-underline",
                        Component: ({ value }) => (<a href={fitSickNote?.file.link} target="_blank" rel="noreferrer" className="see-details">{value}</a>),
                    }
                }

                if (fitSickNote?.id) {
                    info = {
                        ...info,
                        action: "Edit",
                        actionClassname: "text-underline primary",
                        actionHandler: _.noop,
                        fieldType: FIT_MODAL,
                        ActionComponent: ({ value, handler }) => (
                            <a
                                className="cursor-pointer see-details text-primary"
                                onClick={() => handler(appointment, FIT_MODAL, fitSickNote.id)}
                            >
                                {value}
                            </a>
                        )
                    }
                }
                return info;
            })

        appointmentsInfo.push(...fitSickNotes)
    }

    // todo: remove " && appointment.prescription?.file"
    if (appointment.prescription && appointment.prescription?.file) {

        let info = {
            label: "Prescription:",
        }

        if (appointment.prescription?.file) {
            info = {
                ...info,
                value: "See details",
                valueClassname: "text-underline",
                Component: ({ value }) => (<a href={appointment.prescription?.file.link} target="_blank" rel="noreferrer" className="see-details">{value}</a>),
            }
        }

        // todo: uncomment if need update prescription
        // if (appointment.prescription?.id) {
        //     info = {
        //         ...info,
        //         action: "Edit",
        //         actionClassname: "text-underline primary",
        //         actionHandler: _.noop,
        //         fieldType: PRESCRIPTION_MODAL,
        //         ActionComponent: ({ value, handler }) => (<a className="cursor-pointer see-details text-primary" onClick={handler}>{value}</a>)
        //     }
        // }
        appointmentsInfo.push(info)
    }

    // appointment.appointmentReason.reasonFiles
    const attachedPhotosArray = _.get(appointment, 'appointmentReason.reasonFiles.data', []);
    const attachedPhoto = attachedPhotosArray.map((photo) => ({
        id: photo.id,
        link: photo.link,
        type: photo.fileType,
        name: photo.name,
        fileId: photo.fileId,
    }))

    const doctorsInfo = [
        { label: "First name :", value: appointment.doctor?.firstName || '-' },
        { label: "Last name :", value: appointment.doctor?.lastName || '-' },
        { label: "Type :", value: doctorTypes[appointment.doctor?.role] || '-' },
    ];

    const consultationNotes = {
        problem: _.get(appointment, 'consultation.problem', null),
        history: _.get(appointment, 'consultation.history', null),
        pastMedicalHX: _.get(appointment, 'consultation.pastMedicalHX', null),
        examination: _.get(appointment, 'consultation.examination', null),
        comments: _.get(appointment, 'consultation.comments', null),
        safetyNet: _.get(appointment, 'consultation.safetyNet', null),
    };

    const consultationNotesIsHidden = _.get(appointment, 'consultation.isHidden', undefined);

    if (appointment.clinicianFeedback) {
        appointment.clinicianFeedback.isCA = true;
        feedbacks.push(appointment.clinicianFeedback)
    }

    if (appointment.patientFeedback) {
        appointment.patientFeedback.isPatient = true;
        feedbacks.push(appointment.patientFeedback)
    }

    return {
        id,
        appointmentsInfo,
        attachedPhoto,
        doctorsInfo,
        consultationNotes,
        consultationNotesIsHidden,
        feedbacks,
        consultationNotesCreationTime,
        userIdOfDoctor
    }
}
