import React from 'react'
import Shevron from '../Label/shevronLabel';
import { CalendarContainer } from 'react-datepicker';
import { monthNames } from '../../constants/monthNames';

export const MyContainer = ({ className, children }) => {
    return (
        <div className="custom-calendar">
            <CalendarContainer className={className}>
                {children}
            </CalendarContainer>
        </div>
    );
};

export const CustomHeader = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled, monthDecreaseHandler, monthIncreaseHandler }) => {
    return (
        <div className="custom-datepicker-header mb-3 ">
            <label className="ml-4 font-weight-bold date-label">{monthNames[date.getMonth()]} {date.getFullYear()}</label>
            <section className="text-center mr-3 mt-1">
                <Shevron
                    className="chevron-left-button"
                    iconClassName="mdi mdi-chevron-left chevron-size"
                    onClick={() => { decreaseMonth(); monthDecreaseHandler && monthDecreaseHandler() }}
                    disabled={prevMonthButtonDisabled}
                />
                <Shevron
                    className="chevron-right-button"
                    iconClassName="mdi mdi-chevron-right chevron-size"
                    onClick={() => { increaseMonth(); monthIncreaseHandler && monthIncreaseHandler() }}
                    disabled={nextMonthButtonDisabled}
                />
            </section>
        </div>
    )
};