import Http from './axios-config';

class DoctorManagementService {
    constructor() {
        this.http = new Http();
    }

    async getDoctors(values) {
        return await this.http.get('/clinical-admins/doctors', {
            params: values
        });
    }
}

export default new DoctorManagementService();