import Http from './axios-config';

class PatientManagementService {
    constructor() {
        this.http = new Http();
    }

    async getPatients(values) {
        return await this.http.get('/clinical-admins/patients', {
            params: values
        });
    }

    async blockUnBlockPatient(id, values) {
        return await this.http.patch(`/clinical-admins/patients/${id}/block`, values);
    }

    async changeUsersInfo(id, values) {
        return await this.http.put(`/clinical-admins/patients/${id}`, values);
    }

    async getPatientById(id) {
        return await this.http.get(`/clinical-admins/patients/${id}`);
    }

    async deletePatientById(id) {
        return await this.http.delete(`/clinical-admins/patients/${id}`);
    }

    async getPatientsHistory(id, params) {
        return await this.http.get(`/clinical-admins/patients/${id}/appointments`, {
            params,
        });
    }

    async changeDocumentStatus(id, status) {
        return await this.http.patch(`/clinical-admins/patients/documents/${id}/status`, status);
    }

    async attachPatientFile(model) {
        return await this.http.put(`/clinical-admins/patients/files`, model);
    }

    async deletePatientFile(id) {
        return await this.http.delete(`/clinical-admins/patients/files/${id}`)
    }

    async deleteReasonPatientFile(id) {
        return await this.http.delete(`/clinical-admins/patients/files/reason/${id}`)
    }

    async getLogHistory(id, params) {
        return await this.http.get(`/clinical-admins/patients/${id}/history`, {params})
    }

    async getPatientChildById(id) {
        return await this.http.get(`/clinical-admins/patients/children/${id}`);
    }

    async changeChildInfo(id, values) {
        return await this.http.put(`/clinical-admins/patients/child/${id}`, values);
    }

    async savePatientDocuments(id, values) {
        return await this.http.post(`/clinical-admins/patients/${id}/documents`, values);
    }

    async saveChildDocuments(id, values) {
        return await this.http.post(`/clinical-admins/patients/children/${id}/documents`, values);
    }

    async resizeFile(id, params) {
        return await this.http.post(`/files/${id}/resize`, params);
    }

    async deletePatientID(id) {
        return await this.http.delete(`/clinical-admins/patients/documents/${id}`);
    }

    async resetPatientPassword(id, params) {
        return await this.http.put(`/clinical-admins/patients/${id}/password`, params);
    }
}

export default new PatientManagementService();
