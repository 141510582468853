import React from 'react';
import {Card, Modal} from "reactstrap";

import cancelIcon from 'assets/images/ic-close-black.svg';

export default function InfoModal({ visibleModal, close, header, buttonText, children, modalClassName="" }) {
    return (
        <Modal
            isOpen={visibleModal}
            className="modal-size"
            centered={true}
            toggle={close}
            modalClassName={modalClassName}
        >
            <Card className="p-4 mb-0">
                <div className="modal-body modal-text-styles">
                    <header className="font-size-22 d-flex flex-row justify-content-between">{header}<img
                        src={cancelIcon} alt="" onClick={close} className="cursor-pointer"/></header>
                    {children}
                    <section className="d-flex justify-content-center w-100">
                        <button className="btn btn-primary w-50" onClick={close}>{buttonText}</button>
                    </section>
                </div>

            </Card>
        </Modal>
    )
}
