export const passwordValidation = /(?=.*[^_ ])(?=.*[0-9])(?=.*)(?=.*[a-z])(?=.*[A-Z])[\S]/;
export const patientPasswordValidation = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()?|]?)(?=.*\d)[\S]+$/;
export const oneTimePasswordValidation = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]/s;
export const emailValidation = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
export const strWithNoOnlySpaces = /[^\s]/;
export const deleteUselessChars = /[^\d]/g;
export const restrictMobile = /(android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos)/i;
export const restrictSafari = /^((?!chrome|android).)*safari/i;
export const onlyNumbers = /\D/g;
export const digitsWithNoSpaces = /^\d+[,.]?\d*$/;
export const lettersAndNumbers = /^[a-z0-9]+$/ig;