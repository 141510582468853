import axios from "axios";
import STATUS_CODE from "../constants/statusCodes";
import { sendRefreshToken } from "./sendRefreshToken";
import StorageService from "./StorageService";
import { AUTH, LANGUAGE, SKIP_AUTH } from "../constants/headers";
import toastr from '../components/toastr/index';
import { MESSAGES } from '../constants/serverMessages';
import { USER, SESSION } from '../constants/storageConstants';

const storageService = new StorageService();

const interceptorsResponse = [
    response => (response.data),
    error => {
        let dataObject = {}, statusCode;
        const { response } = error;
        const { UNAUTHORIZED } = STATUS_CODE;
        const refreshToken = storageService.getValueByKey(SESSION)?.refreshToken;
        if (response) {
            dataObject = response.data;
        }

        statusCode = dataObject.code || dataObject.statusCode || (response && response.status);
        const isUnauth = (statusCode === UNAUTHORIZED || statusCode === "Unauthorized");

        if (isUnauth && refreshToken) {
            let isRefreshed = false;
            return sendRefreshToken({ refreshToken })
                .then(({ data }) => {
                    const session = data.data;
                    error.config.headers[AUTH] = `Bearer ${ session.accessToken }`;
                    isRefreshed = true;
                    // retry request
                    return axios
                        .request(error.config)
                        .then(res => res.data);
                })
                .catch(err => {
                    if (!isRefreshed) {
                        let location = window.location;
                        location.href = location.origin + "/landing/home";
                        storageService.deleteValueByKey(USER);
                        storageService.deleteValueByKey(SESSION);
                    }
                    throw err;
                });
        }
        if(MESSAGES[response.data.message] && response.config.disableToast){
            return Promise.reject(dataObject);
        }
        toastr.error(dataObject.message);
        return Promise.reject(dataObject);
    }
];

const interceptorsRequest = [
    request => {
        const pattern = /^((http|https):\/\/s3)/;
        const skipAuthorization = request.headers[SKIP_AUTH] || false;
        if (!(pattern.test(request.url) || skipAuthorization)) {
            const accessToken = storageService.getValueByKey(SESSION)?.accessToken;
            request.headers = {
                [AUTH]: accessToken ? `Bearer ${ accessToken }` : "",
                [LANGUAGE]: "en"
            };
        }

        delete request.headers[SKIP_AUTH];
        return request;
    },
    err => Promise.reject(err)
];

class Http {
    constructor() {
        this.instance = Http.createInstance({
            baseURL: `${ Http.api.common }/${ Http.versions.v1 }`,
            headers: {
                "Access-Control-Allow-Origin": "*",
            }
        });

        this.instance.interceptors.request.use(...interceptorsRequest);
        this.instance.interceptors.response.use(...interceptorsResponse);
    }

    static createInstance() {
        return axios.create.apply(axios, arguments);
    }

    get() {
        return this.instance.get.apply(this.instance, arguments);
    }

    patch() {
        return this.instance.patch.apply(this.instance, arguments);
    }

    put() {
        return this.instance.put.apply(this.instance, arguments);
    }

    post() {
        return this.instance.post.apply(this.instance, arguments);
    }

    delete() {
        return this.instance.delete.apply(this.instance, arguments);
    }
}

Http.api = {
    common: process.env.REACT_APP_BASE_URL
};

Http.versions = {
    v1: process.env.REACT_APP_API_VERSION
};

export default Http;
