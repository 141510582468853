import { LEFT_STATUS, UNPAID_STATUS } from 'constants/appointmentStatuses';
import { SIMPLE_APPOINTMENT } from 'constants/appointmentTypes';
import { PENDING } from "constants/documentStatuses";

const excludeStatusArray = [LEFT_STATUS, UNPAID_STATUS];


export default function checkForCallDisabled(appointment) {
    return !appointment.patient
        || excludeStatusArray.includes(appointment.status)
        || appointment.patient?.isDeleted
        || appointment.appointmentType === SIMPLE_APPOINTMENT
        || appointment.patient?.documentCheckStatus === PENDING;
}
