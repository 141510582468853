import { deleteUselessChars } from '../../constants/validityPatterns';
import { PHONE_LENGTH } from '../../constants/lengthValidity';

export default function validatePhone(value) {
    const stringLengthWithoutMask = value?.replace(deleteUselessChars, '').length;
    if (stringLengthWithoutMask === PHONE_LENGTH) {
        return true;
    } else {
        return false;
    }
}