import React, { useState, useMemo, useEffect } from 'react';
import { Card, Modal } from 'reactstrap';
import logo from '../../../assets/images/logo.jpg';
import PDFFooter from './pdfFooter';
import moment from 'moment';
import DrugForm from './drugForm';
import { MAX_DRUGS_COUNT } from '../../../constants/lengthValidity';
import ModalButtons from './letterComponents/modalButtons';
import { PRESCRIPTION_IDENTIFICATION } from '../../../constants/documentStatuses';
import generateDocumentID from '../../../helpers/generateDocumentID';
import { dateBySlash } from '../../../constants/dateFormats';

const DRUG_ITEM = 1;

export default function Prescription({
    prescriptionID,
    patientID,
    sendPrescriptionLetter,
    appointment,
    patient = null,
    onClose = null,
    submitButton = "Send"
}) {
    const [drugsCount, setDrugsCount] = useState([DRUG_ITEM]);
    const [drugs, setDrugs] = useState([]);
    const [sendButtonDisable, setSendButtonDisable] = useState(false);
    const [formErrors, setFormErrors] = useState([]);

    const documentID = useMemo(() => generateDocumentID(PRESCRIPTION_IDENTIFICATION, prescriptionID, patientID), [prescriptionID, patientID]);

    function handleCreateDrug(drug, currentFormCounter) {
        if (drugs.length < MAX_DRUGS_COUNT) {
            setDrugs((prevState) => {
                const newDrugsArray = [...prevState];
                newDrugsArray[currentFormCounter] = drug;
                return newDrugsArray;
            });
        }
    }

    const doctorsInfo = useMemo(() => [
        { label: "Doctor’s name:", value: `${appointment?.doctor?.firstName} ${appointment?.doctor?.lastName}` },
        { label: "Appointment date:", value: moment(appointment.date).format(dateBySlash) },
        { label: "GMC:", value: "9391904" },
        { label: "Doctor’s signature:", value: <img src="https://static.cdn.wisestamp.com/wp-content/uploads/2020/08/Serena-Williams-handwritten-signature-1024x636.png" alt="" width="50" height="36" />, classname: 'col-sm-3 my-auto' },
    ], [appointment]);

    const appointmentInfo = useMemo(() => [
        {
            label: "Patient’s name:",
            value: `${patient?.firstName || appointment?.patient?.firstName} ${ patient?.lastName || appointment?.patient?.lastName}`
        },
        {
            label: "Patient’s address:",
            value: patient?.address?.address || appointment?.patient?.address?.address
        },
        {
            label: "Date of birth:",
            value: moment(patient?.birthday || appointment?.patient?.birthday).format(dateBySlash)
        },
        {
            label: "Reason:",
            value: appointment?.appointmentReason?.reason
        },
    ], [appointment]);


    function handleSubmitDrugs() {
        sendPrescriptionLetter(drugs)
    }

    useEffect(() => {
        if (!formErrors.filter(item => item).length && drugs.length) {
            setSendButtonDisable(false);
            return;
        }
        setSendButtonDisable(true);
    }, [formErrors, drugs]);

    return (
        <Modal
            isOpen={true}
            className="pdf-modal"
        >
            <div className="p-5 h-100 w-100 modal-container">
                <label className="ml-3 mt-2 font-size-22 font-weight-normal">Create prescription</label>
                <div className="pdf-header mt-2  ml-3 w-100" />
                <Card className="ml-3 pdf-container mb-0 pt-0 pb-4 prescription-card">
                    <section className="d-flex flex-row justify-content-between mt-5">
                        <section className="d-flex flex-column">
                            <label className="info-page-header">Private prescription</label>
                            <label className="pdf-label-colleague">Pharmacy stamp</label>
                        </section>
                        <img src={logo} alt="" width="140" height="72" />
                    </section>

                    <section className="d-flex flex-row justify-content-between w-100 prescription-card--section">
                        {appointmentInfo.map((info, index) => (
                            <section key={index} className="d-flex flex-column">
                                <span className="prescription-card--section__label">{info.label}</span>
                                <span className="prescription-card--section__value">{info.value}</span>
                            </section>
                        ))}
                    </section>

                    <label className="pdf-label-colleague mt-4">List of drugs:</label>

                    {drugsCount.map((item, index) => (
                        <DrugForm key={index}
                            createDrug={handleCreateDrug}
                            showButton={(drugsCount.length - 1) === index}
                            currentDrugFormCount={index}
                            setFormErrors={setFormErrors}
                        />
                    ))}

                    {drugsCount.length < MAX_DRUGS_COUNT &&
                        <button
                            onClick={() => setDrugsCount(prevState => [...prevState, 1])}
                            className="btn btn-outline w-25 align-self-center mt-4 pdf-label-ask"
                            disabled={drugsCount.length > drugs.length || sendButtonDisable}
                        >
                            + Add new drug
                        </button>
                    }

                    <section className="d-flex flex-row justify-content-between w-100 prescription-card__bottom-section">
                        {doctorsInfo.map((info, index) => (
                            <section key={index} className="d-flex flex-column">
                                <span className="prescription-card--section__label">{info.label}</span>
                                <span className="prescription-card--section__value">{info.value}</span>
                            </section>
                        ))}
                    </section>
                </Card>

                <PDFFooter id={documentID} text="Prescription ID" />

                <ModalButtons
                    handleSubmit={handleSubmitDrugs}
                    disabledSubmit={sendButtonDisable}
                    handleCancel={onClose}
                    submitButtonName={submitButton}
                />
            </div>
        </Modal>
    );
}



