export const VIDEO = "video";
export const AUDIO = "audio";
export const TRACK_SUBSCRIBED = 'trackSubscribed';
export const TRACK_UNSUBSCRIBED = 'trackUnsubscribed';
export const CONNECTED = 'connected';
export const PARTICIPANT_CONNECTED = 'participantConnected';
export const PARTICIPANT_DISCONNECTED = 'participantDisconnected';
export const TRACK_ENABLED = 'trackEnabled';
export const TRACK_DISABLED = 'trackDisabled';
export const DISCONNECTED = 'disconnected';
export const MAX_VOLUME_PERCENT = 100;
export const INITIAL_VOLUME = 1;
export const CALL_COMPLETED = 53118;
export const MEDIA_CONNECTION_FAILED_OR_MEDIA_ACTIVITY_CEASED = 53405;
export const DEVICE_OUTPUT = 'audiooutput';
export const DEVICE_VIDEO = 'videoinput';
export const DEVICE_AUDIO = 'audioinput';

export const TWO_MINUTES_TO_END = "TWO_MINUTES_TO_END";
export const EXTRA_TIME = "EXTRA_TIME";
export const AGREED_REFERRAL = 'AGREED_REFERRAL';
export const REJECT_REFERRAL = 'REJECT_REFERRAL';
export const AGREED_FIT_NOTE = 'AGREED_FIT_NOTE';
export const REJECT_FIT_NOTE = 'REJECT_FIT_NOTE';
export const AGREED_PRESCRIPTION = "AGREED_PRESCRIPTION";
export const REJECT_PRESCRIPTION = "REJECT_PRESCRIPTION";
export const REJECT_PROLONGATION = "REJECT_PROLONGATION";

export const REFERRAL_REJECT_TYPE = "3";
export const REFERAL_AGREED_TYPE = "4";
export const FIT_NOTE_REJECT_TYPE = "6";
export const FIT_NOTE_AGREED_TYPE = "7";
export const PRESCRIPTION_AGREED_TYPE = "10";
export const PRESCRIPTION_REJECT_TYPE = "9";
export const EXTRA_TIME_TYPE = "18";
export const EXTRA_TIME_DECLINE = "22";

export const NOTIFICATION_TYPES = {
    [REFERRAL_REJECT_TYPE]: REJECT_REFERRAL,
    [REFERAL_AGREED_TYPE]: AGREED_REFERRAL,
    [FIT_NOTE_REJECT_TYPE]: REJECT_FIT_NOTE,
    [FIT_NOTE_AGREED_TYPE]: AGREED_FIT_NOTE,
    [EXTRA_TIME_TYPE]: EXTRA_TIME,
    [PRESCRIPTION_AGREED_TYPE]: AGREED_PRESCRIPTION,
    [PRESCRIPTION_REJECT_TYPE]: REJECT_PRESCRIPTION,
    [EXTRA_TIME_DECLINE]: REJECT_PROLONGATION,
}

export const AGREED_DOCUMENTS = [
    // AGREED_REFERRAL,
    // AGREED_FIT_NOTE,
    AGREED_PRESCRIPTION
];

export const EXTRA_TIME_TEXT = 'Patient bought extra time';
export const TWO_MINUTES_TO_END_TEXT = 'You have two minutes left until the end of the consultation!';
export const AGREED_REFERRAL_TEXT = 'Patient is agreed to receive the referral letter';
export const REJECT_REFERRAL_TEXT = 'Patient is refused from receiving the referral letter';
export const AGREED_FIT_NOTE_TEXT = 'Patient is agreed to receive the fit/sick note';
export const REJECT_FIT_NOTE_TEXT = 'Patient is refused from receiving the fit/sick note';
export const REJECT_PRESCRIPTION_TEXT = 'Patient is refused from sharing the info with GP';
export const AGREED_PRESCRIPTION_TEXT = 'Patient has agreed to share the info with his GP. The prescription can be sent';
export const REJECT_PROLONGATION_TEXT = 'Patient has not agreed to had extra time';

export const DANGER_COLOR = "danger";
export const SUCCESS_COLOR = "success";
export const PRIMARY_COLOR = "primary";

export const ALERT_TEXT = {
    [TWO_MINUTES_TO_END]: TWO_MINUTES_TO_END_TEXT,
    [AGREED_REFERRAL]: AGREED_REFERRAL_TEXT,
    [REJECT_REFERRAL]: REJECT_REFERRAL_TEXT,
    [AGREED_FIT_NOTE]: AGREED_FIT_NOTE_TEXT,
    [REJECT_FIT_NOTE]: REJECT_FIT_NOTE_TEXT,
    [EXTRA_TIME]: EXTRA_TIME_TEXT,
    [AGREED_PRESCRIPTION]: AGREED_PRESCRIPTION_TEXT,
    [REJECT_PRESCRIPTION]: REJECT_PRESCRIPTION_TEXT,
    [REJECT_PROLONGATION]: REJECT_PROLONGATION_TEXT,
}

export const ALERT_COLORS = {
    [TWO_MINUTES_TO_END]: PRIMARY_COLOR,
    [AGREED_REFERRAL]: SUCCESS_COLOR,
    [REJECT_REFERRAL]: DANGER_COLOR,
    [AGREED_FIT_NOTE]: SUCCESS_COLOR,
    [REJECT_FIT_NOTE]: DANGER_COLOR,
    [EXTRA_TIME]: SUCCESS_COLOR,
    [AGREED_PRESCRIPTION]: PRIMARY_COLOR,
    [REJECT_PRESCRIPTION]: DANGER_COLOR,
    [REJECT_PROLONGATION]: DANGER_COLOR,
}

export const FIVE_MINUTES_IN_MS = 300000;

export const ACCEPT_STATUS = 2;

export const phasedReturn = 'phasedReturn';
export const amendedDuties = 'amendedDuties';
export const alteredHours = 'alteredHours';
export const workplaceAdaptations = 'workplaceAdaptations';

export const sickArray = [
    phasedReturn,
    amendedDuties,
    alteredHours,
    workplaceAdaptations,
]

export const BenefitsMaskValues = {
    [phasedReturn]: 0b1000,
    [amendedDuties]: 0b0100,
    [alteredHours]: 0b0010,
    [workplaceAdaptations]: 0b0001,
};

export const FIT_MODAL = 'FIT';
export const REFERRAL_MODAL = 'REFERRAL';
export const PRESCRIPTION_MODAL = 'PRESCRIPTION';

export const MODAL_NAME_TO_OBJECT_FIELD = {
    [FIT_MODAL]: "fitSickNote",
    [REFERRAL_MODAL]: "referralLetter",
    [PRESCRIPTION_MODAL]: "prescription",
}
