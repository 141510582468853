import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Modal, Card } from "reactstrap";
import closeIcon from '../../assets/images/ic-close-black.svg';

function LogOutModal({ handleSubmit, visibleModal, handleClose, t }) {
    return (
        <Modal
            isOpen={visibleModal}
            toggle={handleClose}
            centered={true}
        >
            <Card className="p-4">
                <div className="modal-header border-bottom-0 pb-1">
                    <h5 className="log-out-header">{t("Sign Out")}</h5>
                    <button
                        type="button"
                        onClick={handleClose}
                        className="close close-logout-button"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <img src={closeIcon} alt=""/>
                    </button>
                </div>
                <div className="modal-body modal-text-styles">
                    <p>
                        {t("Do you want to Sign Out?")}
                    </p>
                </div>
                <div className="d-flex flex-row justify-content-around mt-4">
                    <button className="btn btn-primary modal-button-styles" onClick={handleClose}> {t("No")}</button>
                    <button className="btn btn-danger modal-button-styles" onClick={handleSubmit}> {t("Sign out")}</button>
                </div>
            </Card>
        </Modal>
    );
}

export default withNamespaces()(LogOutModal);