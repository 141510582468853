import React, {useMemo} from 'react';
import {connect} from "react-redux";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import qs from "qs";
import SimpleBar from "simplebar-react";
import {withNamespaces} from 'react-i18next';

import doctorTAP from 'assets/images/doctorTAP.svg';
import formatDate from "helpers/formatDate";
import queryStringParser from "helpers/queryStringParser";

import SmallCustomDatePicker from "../Common/smallDatePicker";

import SidebarContent from "./SidebarContent";

const APPOINTMENTS_PAGE_PATH_NAME = "/appointments";

const Sidebar = (props) => {
    const history = useHistory();
    const location = useLocation();

    const date = useMemo(() => queryStringParser(location.search).date || new Date(), [location]);

    function handleChangeDate(date) {
        history.push({
            search: qs.stringify({ date: formatDate(date) })
        })
    }

    return (
        <React.Fragment>
            <div className="vertical-menu">
                <div data-simplebar className="h-100">
                    {props.type !== "condensed" ? (
                            <SimpleBar className="h-100">
                                <SidebarContent/>
                                <div className="d-flex justify-content-center">
                                    {location.pathname === APPOINTMENTS_PAGE_PATH_NAME
                                        ? <SmallCustomDatePicker handleChangeDate={handleChangeDate} selected={date}/>
                                        : <img src={doctorTAP} alt="" className="ml-3 logo-doctor-sidebar"/>
                                    }
                                </div>
                            </SimpleBar>
                        ) :
                        <SidebarContent/>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return {
        layout: state.Layout
    };
};
export default connect(mapStatetoProps, {})(withRouter(withNamespaces()(Sidebar)));
