import React from "react";

import expiredLinkLogo from "assets/images/expired-verification-link.svg";

export const SwitchToMobileDevice = () => {
    return (
        <div className="switch-to-mobile-device d-flex flex-column align-items-center justify-content-center">
            <img src={expiredLinkLogo} alt=""/>
            <div className="switch-to-mobile-device__text text-center pt-1 mt-4">Open the reset password email on your mobile device to change your password.</div>
        </div>

    );
};
