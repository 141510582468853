import React, {useEffect, useMemo, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import qs from "qs";
import * as _ from "lodash";
import {Col, Container, Spinner, Table} from "reactstrap";

import queryParser from "helpers/queryStringParser";
import parseIntFromStr from "helpers/parseIntFromStr";
import {APPOINTMENTS_HISTORY} from "constants/patientMagagementTypes";
import arrowLeft from "assets/images/ic-arrow-left.svg";
import {LOG_LIMIT} from "constants/paginationLimits";
import SimpleTableBody from "components/simpleTable/simpleTableBody";
import PatientsManagementService from "services/patientsManagement";

import TableHeader from "./tableComponents/tableHeader";
import {logTableHeader, logTableStructure,} from "./tableComponents/patientTableDataStructure";
import PaginationTableFooter from "./tableComponents/paginationFooter";
import NoDataPlaceholder from "./tableComponents/NoDataPlaceholder";

const LogHistory = () => {
    const history = useHistory();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [historyPagination, setHistoryPagination] = useState({});
    const [logHistory, setLogHistory] = useState([]);

    const searchQuery = queryParser(location.search)

    const setNewSearchQuery = (incomeQuery) => {
        const newQuery = {
            ...searchQuery,
            ...incomeQuery,
        }
        const query = qs.stringify(newQuery);
        history.push({
            search: query,
        });
    }

    const handleChangePage = ({ selected }) => {
        if (!isNaN(selected)) {
            setNewSearchQuery({ offset: selected * LOG_LIMIT });
        }
    }


    const goBackLink = useMemo(() => ({
            pathname: "/patients-management/patients",
            search: qs.stringify({
                id: parseIntFromStr(_.get(searchQuery, "id", 0)),
                type: parseIntFromStr(_.get(searchQuery, "from", APPOINTMENTS_HISTORY)),
            })
        }),
        []
    )

    const getLogHistory = async () => {
        try {
            setIsLoading(true);
            const { data, pagination } = await PatientsManagementService.getLogHistory(searchQuery.id, {
                limit: LOG_LIMIT,
                offset: searchQuery.offset
            })
            setLogHistory(data)
            setHistoryPagination(pagination)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getLogHistory();
    }, [searchQuery.offset, searchQuery.id])

    return (
        <div className="page-content">
            <Container fluid>
                <Col lg={2} className="mb-20px px-0">
                    <Link
                        className="d-flex align-items-center log-page-nav-button"
                        to={goBackLink}
                    >
                        <img src={arrowLeft} alt="" className="icon mr-1"/>
                        <span className="text">
                            back
                        </span>
                    </Link>
                </Col>
                {isLoading &&
                    <div className="patients-table d-flex justify-content-center align-items-center">
                        <Spinner className="mx-auto" color="info"/>
                    </div>}
                {!isLoading && logHistory.length > 0 && (
                    <Col className="patient-table card p-20px">
                        <label className="details-caption pb-3">History of changes</label>
                        <section className="d-flex flex-column justify-content-between">
                            <section className="patient-history-log-table-wrap">
                                <div className="table-responsive">
                                    <div className="border-top table-text">
                                        <Table className="table mb-0 h-100">
                                            <TableHeader columns={logTableHeader} clickCellAction={_.noop}/>
                                            <SimpleTableBody data={logHistory} structure={logTableStructure}/>
                                        </Table>
                                    </div>
                                </div>
                            </section>
                            <section className="mt-5">
                                <PaginationTableFooter
                                    total={historyPagination?.totalCount}
                                    handleChangePage={handleChangePage}
                                    currentOffset={parseIntFromStr(searchQuery.offset)}
                                    limit={LOG_LIMIT}
                                />
                            </section>
                        </section>
                    </Col>
                )}
                {!isLoading && !logHistory.length && <NoDataPlaceholder/>}
            </Container>
        </div>
    )
}

export default LogHistory;
