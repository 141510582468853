import Http from './axios-config';

class PatientVerificationService {

    constructor() {
        this.http = new Http();
    }

    /**
     * 
     * @param {*} token
     */
    async checkExpiredDateEmail(token) {
        return await this.http.put("/verifications/tokens/email", token);
    }

    /**
     * 
     * @param {*} email 
     */
    async resendEmailLink(email) {
        return await this.http.post("/verifications/emails", email);
    }

    /**
     * 
     * @param {*} token 
     */
    async verifyUsersEmail(token) {
        return await this.http.put("/verifications/emails", token);
    }

    /**
     *
     * @param {*} token
     */
    async checkExpiredDatePassword(token) {
        return await this.http.put("/verifications/tokens/password", token);
    }

    async saveNewPassword(params) {
        return await this.http.put("/verifications/password", params);
    }

    async resendPasswordLink(params) {
        return await this.http.post("/verifications/password", params);
    }
}

export default new PatientVerificationService();
