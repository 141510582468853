import AvatarLogo from '../../../components/avatar/tableAvatar';
import React from 'react';
import moment from 'moment';

import getUsersType from '../../../helpers/getUsersType';
import StatusBadge from './statusBadge';
import formatDateByFormat from "../../../helpers/formatDateByFormat";
import { LOG_DATE_FORMAT } from "../../../constants/dateFormats";
import { LOG_HISTORY_ACTION_TYPES } from "../../../constants/logHistoryActions";

export const defaultStructure = [
    {
        name: "image", classname: "pt-3 border-top pr-0", keyProp: "avatar.link",
        Component: ({ value }) => value.length ? <AvatarLogo avatar={value} height="35" width="35" />
            :
            <div className="avatar-xs avatar-placeholder m-0">
                <span className="avatar-title rounded-circle">
                    {value.firstName ? value.firstName.charAt(0).toUpperCase() : "-"}
                </span>
            </div>
    },
    { name: "patientName", classname: "font-size-14 pt-4 border-top fullname-table-cell", Component: (({ value }) => (value.firstName || value.lastName) ? (value.firstName + " " + value.lastName) : "-") },
    { name: "id", classname: "font-size-14 pt-4 border-top", keyProp: "id" },
    { name: "email", classname: "font-size-14 pt-4 border-top email-table-cell text-truncate", keyProp: "email", Component: ({ value }) => value ? value : "-" },
    { name: "addingDate", classname: "font-size-14 pt-4 border-top date-table-cell", keyProp: "createdAt", Component: ({ value }) => moment(value).format("L") },
    { name: "type", classname: "font-size-14 pt-4 border-top", keyProp: "birthDay", Component: ({ value }) => getUsersType(value.birthday) },
    { name: "status", classname: "pt-4 border-top", Component: ({ value }) => <StatusBadge user={value} /> },
]

export const childStructure = [
    {
        name: "image", classname: "child-cell cell-spacing border-top-0", keyProp: "avatar.link",
        Component: ({ value }) => value.length ? <AvatarLogo avatar={value} height="35" width="35" />
            :
            <div className="avatar-xs avatar-placeholder m-0">
                <span className="avatar-title rounded-circle">
                    {value.firstName ? value.firstName.charAt(0).toUpperCase() : "-"}
                </span>
            </div>
    },
    { name: "patientName", classname: "font-size-14 child-cell cell-spacing  fullname-table-cell", Component: (({ value }) => (value.firstName || value.lastName) ? (value.firstName + " " + value.lastName) : "-") },
    { name: "id", classname: "font-size-14 child-cell ", keyProp: "id", Component: () => "-"  },
    { name: "email", classname: "font-size-14 child-cell email-table-cell text-truncate ", keyProp: "email", Component: () => "used parent email" },
    { name: "addingDate", classname: "font-size-14 child-cell date-table-cell ", keyProp: "createdAt", Component: ({ value }) => moment(value).format("L") },
    { name: "type", classname: "font-size-14 child-cell ", keyProp: "birthDay", Component: ({ value }) => getUsersType(value.birthday) },
    { name: "status", classname: "child-cell ", Component: ({ value }) => <StatusBadge user={value} /> },
]

export const tableHeaderChecked = [
    { label: " ", sortOption: false, classname: "font-size-18 disable-word-wrap" },
    { label: "Full Name", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "lastName" },
    { label: "Patient ID", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Email address", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Adding date", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "createdAt" },
    { label: "Type", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "birthday" },
    { label: "Account status", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "isBlocked" },
];

export const tableHeaderWaiting = [
    { label: " ", sortOption: false, classname: "font-size-18 p-0 disable-word-wrap" },
    { label: "Full Name", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "lastName" },
    { label: "Patient ID", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Email address", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Adding date", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "createdAt" },
    { label: "Type", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "birthday" },
    { label: "Confirmation status", sortOption: true, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "documentCheckStatus" },
];


export const badgeColors = {
    "Pending": "warning",
    "Rejected": "danger",
    "Active": "success",
    "Blocked": "danger",
}

export const logTableHeader = [
    { label: "First/Last name", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Action ", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Date and time", sortOption: false, classname: "font-size-13 table-caption-label text-center disable-word-wrap" },
];


export const logTableStructure = [
    { name: "admin", classname: "font-size-14 pt-20px pb-20px border-bottom fullname-table-cell", keyProp: "admin", Component: (({ value }) => (value?.firstName || value?.lastName) ? (value?.firstName + " " + value?.lastName) : "-") },
    { name: "actionType", classname: "font-size-13 pt-20px pb-20px border-bottom", keyProp: "actionType", Component: (({value}) => `${LOG_HISTORY_ACTION_TYPES?.[value] || "unknown action"}`) },
    { name: "createdAt", classname: "font-size-13 pt-20px pb-20px border-bottom text-center", keyProp: "createdAt", Component: (({value}) => formatDateByFormat(value, LOG_DATE_FORMAT)) },
]

export const patientChildrenTabHeader = [
    { label: "#", sortOption: false, classname: "font-size-13 disable-word-wrap" },
    { label: "Full Name", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "lastName" },
    { label: "Email address", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
    { label: "Adding date", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap", sort: "createdAt" },
    { label: "Account status", sortOption: false, classname: "font-size-13 table-caption-label disable-word-wrap" },
];

export const patientChildrenStructure = [
    {
        name: "image", classname: "pt-3 border-bottom pr-0", keyProp: "avatar.link",
        Component: ({ value }) => value.length ? <AvatarLogo avatar={value} height="35" width="35" />
            :
            <div className="avatar-xs avatar-placeholder m-0">
                <span className="avatar-title rounded-circle">
                    {value.firstName ? value.firstName.charAt(0).toUpperCase() : "-"}
                </span>
            </div>
    },
    { name: "patientName", classname: "font-size-14 pt-4 border-bottom fullname-table-cell", Component: (({ value }) => (value.firstName || value.lastName) ? (value.firstName + " " + value.lastName) : "-") },
    { name: "email", classname: "font-size-14 pt-4 border-bottom email-table-cell text-truncate", keyProp: "email", Component: () => "used parent email" },
    { name: "addingDate", classname: "font-size-14 pt-4 border-bottom date-table-cell", keyProp: "createdAt", Component: ({ value }) => moment(value).format("L") },
    { name: "status", classname: "font-size-14 pt-4 border-bottom", Component: ({ value }) => <StatusBadge user={value} /> },
]

