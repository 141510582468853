import React, {useCallback, useState} from 'react';
import { Card, Modal } from 'reactstrap';
import FullScreenMode from './fullScreenMode';
import * as _ from 'lodash';
import placeholder from '../../../assets/images/im-placeholder-attached-photo.svg';
import { FILE_TYPES } from "../../../constants/uploadFileConstants";
import FileRow from "../../patientManagement/appointmentHistory/uploadedFiles/fileRow";

export default function AttachedPhoto({ appointment }) {
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    const handleClick = useCallback((file) => {
        if (file?.type === FILE_TYPES.APPOINTMENT_IMAGE) {
            setSelectedPhoto(file?.link || "");
        }
        if (file?.type === FILE_TYPES.APPOINTMENT_PDF) {
            const a = document.createElement("a");
            a.setAttribute("href", file.link)
            a.setAttribute("target", "_blank")
            a.click();
        }
    }, [selectedPhoto])

    const files = _.get(appointment, 'appointmentReason.reasonFiles.data', []);

    return (
        <>
            <Card className="px-4 pt-3 attached-photo-card h-100">
                <label className="details-caption mb-4">Attached photo</label>
                {files.length > 0 ? (
                        files.map((file) => {
                            file.type = file.fileType;

                            return (
                                <FileRow
                                    key={file.id}
                                    file={file}
                                    handleClick={handleClick}
                                />
                            )
                        }

                        ))
                    :
                    <section className="d-flex flex-column align-items-center justify-content-center attached-photo-card--placeholder">
                        <img src={placeholder} alt='' className="attached-photo-card--placeholder__image" />
                        <label className='attached-photo-card--placeholder__label'>No photos added yet</label>
                    </section>
                }
            </Card>
            <Modal isOpen={selectedPhoto} size="xl" toggle={() => setSelectedPhoto(null)}>
                <FullScreenMode photo={selectedPhoto} handleClose={() => setSelectedPhoto(null)} />
            </Modal>
        </>
    );
}