import React, { useEffect, useState } from "react";
import { ErrorMessage, Field } from "formik";
import { errorMessage } from "../../../constants/errorMessages";

const FIXED_COUNT = "1";

const OneOrMoreInput = ({ fieldName, value, error, touched, setFieldValue, resetTo, initialValue }) => {
    const [timesNumber, setTimesNumber] = useState(String(value) !== "1" ? String(value) : "0");
    const disabled = resetTo !== null;

    useEffect(() => {
        if (timesNumber !== "0") {
            setFieldValue(fieldName, timesNumber)
        }
    }, [timesNumber])

    useEffect(() => {
        if (resetTo) {
            setFieldValue(fieldName, resetTo)
        }
    }, [resetTo])

    return (
        <div role="group" className="mt-2">
            <label className={"mb-3" + (error && touched && "error-text")}>This code can be used:</label>

            <div className="m-0 d-flex flex-wrap justify-content-sm-between">
                <div  className="d-flex align-items-center">
                    <div className="custom-control custom-radio mb-3 custom-z-index coupon-form-radio-wrapper">
                        <Field
                            type="radio"
                            name={fieldName}
                            value={FIXED_COUNT}
                            id="customRadio1"
                            className="custom-control-input custom-z-index custom"
                            disabled={disabled}
                        />
                        <label className="custom-control-label" htmlFor="customRadio1">Once</label>
                    </div>
                </div>
                <div className="custom-control custom-radio mb-3 custom-z-index d-inline-flex align-items-center ">
                    <div >
                        <Field
                            type="radio"
                            name={fieldName}
                            value={timesNumber}
                            id="customRadio2"
                            className="custom-control-input custom-z-index"
                            disabled={disabled}
                        />
                        <label className="custom-control-label coupon-form-label " htmlFor="customRadio2" />
                    </div>
                    <div>
                        <input
                            type="number"
                            name={fieldName}
                            className="form-control coupon-form-input coupon-form-radio-input"
                            placeholder="Enter amount"
                            onBlur={(event) => setTimesNumber(event.target.value)}
                            disabled={disabled}
                            {...(initialValue && initialValue !== FIXED_COUNT && { defaultValue: initialValue })}
                        />
                        <div className="position-relative">
                            <div className="position-absolute">
                                <ErrorMessage name={fieldName} render={errorMessage} />
                            </div>
                        </div>
                    </div>
                    <label className={`ml-2 mb-0 ${disabled && "coupon-form-label-disabled" }`}>number of times</label>
                </div>
            </div>

        </div>
    )
}

export default OneOrMoreInput;